// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sc-ion-input-md-h {
  --padding-start: 0 !important;
}

ul {
  margin-bottom: 1rem;
  -webkit-padding-start: 18px;
          padding-inline-start: 18px;
  margin-top: 0 !important;
}`, "",{"version":3,"sources":["webpack://./../../libs/therapy/components/learning/therapy-learning-content-input/therapy-learning-content-input.component.scss"],"names":[],"mappings":"AAAA;EACI,6BAAA;AACJ;;AAEA;EACI,mBAAA;EACA,2BAAA;UAAA,0BAAA;EACA,wBAAA;AACJ","sourcesContent":[".sc-ion-input-md-h {\n    --padding-start: 0 !important;\n}\n\nul {\n    margin-bottom: 1rem;\n    padding-inline-start: 18px;\n    margin-top: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
