import { User, UserRoles } from '../../../auth/entities/user';
import { ButtonConfig } from '../../../common/entities/modal/modal-button';
import { ModalConfig } from '../../../common/entities/modal/modal-config';
import { ModalTyp } from '../../../common/entities/modal/modal-typ';
import { IonicColor } from '../../../common/entities/toast/ionic-color';
import { ModalAlertService } from '../../../common/services/modal';
import { ToastService } from '../../../common/services/toast-service/toast-service.service';
import { ContentDto } from '../../entities/content';
import { UserExerciseSessionsService } from '../../services/user-exercise-sessions';
import { ExerciseSessionState } from '../../entities/exerciseSession';
import { Logger, LoggingService } from '../../../logging/logging.service';
import { TranslateService } from '@ngx-translate/core';
import { TaskResource } from './task.resource';
import { AuthorizationPipe } from '../../../hateoas/authorization.pipe';
import { TaskService } from '../../services/task/task.service';

export class UserTaskPreview {
    freeTextValue = '';
    taskResource: TaskResource;
    patient: User;
    userRoles: UserRoles[] = [];
    protected readonly log: Logger;

    constructor(
        protected taskService: TaskService,
        protected userExerciseSessionsService: UserExerciseSessionsService,
        protected toastService: ToastService,
        protected modalAlertService: ModalAlertService,
        protected loggingService: LoggingService,
        protected translateService: TranslateService,
        protected readonly authorizationPipe: AuthorizationPipe,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    userIsResponsibleForTask() {
        return this.userRoles.includes(this.taskResource.responsibility.role);
    }

    freeTextHasEdits() {
        const previousEditsArray = this.taskResource?.exerciseSessionUserResults ?? [];
        if (previousEditsArray.length === 0) return this.freeTextValue !== '';
        return this.freeTextValue !== previousEditsArray[0]?.content.description;
    }

    async saveContentFreeText() {
        const content = new ContentDto();
        content.mimeType = 'text/plain';
        content.description = this.freeTextValue;
        try {
            if (
                this.taskResource.exerciseSessionState !== ExerciseSessionState.ACTIVE &&
                this.taskResource.exerciseSessionState !== ExerciseSessionState.FINISHED
            ) {
                await this.userExerciseSessionsService.postUsersExerciseSessionActivate(
                    this.patient.username,
                    Number(this.taskResource.id),
                );
            }
            if (this.authorizationPipe.transform(this.taskResource, 'freeText', 'PUT')) {
                await this.userExerciseSessionsService.updateExerciseSessionUserResult(
                    this.patient.username,
                    Number(this.taskResource.id),
                    this.taskResource.exerciseSessionUserResults[0].contentUuid,
                    content,
                );
            } else {
                await this.userExerciseSessionsService.createExerciseSessionUserResult(
                    this.patient.username,
                    Number(this.taskResource.id),
                    content,
                );
            }
            this.taskResource = await this.taskService.fetchTask(this.taskResource.id);
            this.toastService.showToast(ToastService.changeSavedMessage, IonicColor.success);
        } catch (e) {
            this.log.error(e);
            this.toastService.showToast(ToastService.errorMessageSave, IonicColor.danger);
        }
    }

    async discardFreeText() {
        const modalConfig = new ModalConfig();
        modalConfig.modalTyp = ModalTyp.INFORMATION;
        modalConfig.title = `„${this.taskResource.title}“ ${this.translateService.instant('CANCEL_LOWER')}`;
        modalConfig.titleIcon = 'warning-outline';
        modalConfig.description = this.translateService.instant('DISCARD_CHANGES_PROMPT');
        modalConfig.buttonLeft = new ButtonConfig();
        modalConfig.buttonLeft.buttonText = 'BACK';
        modalConfig.buttonLeft.buttonColor = 'primary';
        modalConfig.buttonRight = new ButtonConfig();
        modalConfig.buttonRight.buttonText = this.translateService.instant('DISCARD_CHANGES');
        modalConfig.buttonRight.buttonColor = 'danger';
        const action = await this.modalAlertService.showModal(modalConfig);
        if (action && action.action === 'right') {
            this.initFreeTextContent();
        }
    }

    protected async initFreeTextContent() {
        if (this.taskResource.exerciseSessionUserResults?.length > 0) {
            this.freeTextValue = this.taskResource?.exerciseSessionUserResults[0]?.content.description;
        } else {
            this.freeTextValue = '';
        }
    }
}
