// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid-item {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.form-error {
  color: var(--ion-color-danger);
  font-size: x-small;
  text-align: center;
}

.invalid {
  border: 1px solid #ea6153;
}`, "",{"version":3,"sources":["webpack://./../../libs/therapy/components/training/feedback/pulse/pulse.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,yBAAA;AACJ;;AAEA;EACI,8BAAA;EACA,kBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,yBAAA;AACJ","sourcesContent":[".grid-item {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n}\n\n.form-error {\n    color: var(--ion-color-danger);\n    font-size: x-small;\n    text-align: center;\n}\n\n.invalid {\n    border: 1px solid #ea6153;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
