export class FeedbackCommentDto {
    text: string;
    flag: FeedbackFlag;
}
export class FeedbackComment extends FeedbackCommentDto {
    uuid: string;
    text: string;
    flag: FeedbackFlag;
    deletedDate: string;
}

export enum FeedbackFlag {
    GREEN = 'GREEN',
    YELLOW = 'YELLOW',
    RED = 'RED',
    NONE = 'NONE',
}
