import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CurafidaAuthService } from '../../../auth/services';
import { ApiService } from '../../../api';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private readonly authService: CurafidaAuthService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (
            !(request.url.includes('realms') || request.url.includes('directus')) &&
            this.authService.getSession()?.backendUrl
        ) {
            // fetch token
            const token = this.authService.getSession().tokenSet.access_token;
            // Add Token and header options
            const authReq = request.clone({
                url: request.url.includes('http') ? request.url : ApiService.url + request.url,
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json',
                    contentType: 'application/json',
                },
                withCredentials: true,
            });
            return next.handle(authReq);
        }

        return next.handle(request);
    }
}
