import { Injectable } from '@angular/core';
import { PaginatedResponse } from '../../../common/entities/paginated-response';
import { Therapy, TherapyDto } from '../../entities/therapy';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../../api';
import { httpParams } from '../../../api/http-query.params';
import { TaskPlanCreationDto, TaskPlanSearchParams } from './task-plan.dto';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TaskPlanService {
    constructor(protected http: HttpClient) {}

    search(params: TaskPlanSearchParams): Observable<PaginatedResponse<Therapy[]>> {
        return this.http.get<PaginatedResponse<Therapy[]>>(`${ApiService.url}task-plans`, {
            ...ApiService.options,
            params: httpParams(params),
        });
    }

    fetch(id: number): Observable<Therapy> {
        return this.http.get<Therapy>(`${ApiService.url}task-plans/${id}`, ApiService.options);
    }

    create(dto: TaskPlanCreationDto): Observable<Therapy> {
        return this.http.post<Therapy>(`${ApiService.url}task-plans`, dto, ApiService.options);
    }

    update(id: number, dto: TherapyDto): Observable<Therapy> {
        return this.http.put<Therapy>(`${ApiService.url}task-plans/${id}`, dto, ApiService.options);
    }

    delete(id: number) {
        return this.http.delete(`${ApiService.url}task-plans/${id}`, ApiService.options);
    }
}
