import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TrainingService {
    private therapySavedSubject = new Subject<void>();

    therapySaved$ = this.therapySavedSubject.asObservable();

    therapySaved() {
        this.therapySavedSubject.next();
    }
}
