import { AfterViewInit, Component } from '@angular/core';
import { JitsiService } from '../jitsi.service';
import { Router } from '@angular/router';
import { Logger, LoggingService } from '../../../logging/logging.service';

@Component({
    selector: 'lib-jitsi',
    templateUrl: './jitsi.component.html',
    styleUrls: ['./jitsi.component.scss'],
})
export class JitsiComponent implements AfterViewInit {
    protected readonly log: Logger;

    constructor(
        readonly router: Router,
        readonly jitsiService: JitsiService,
        private loggingService: LoggingService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    ngAfterViewInit(): void {
        this.jitsiService.startJitsiConference();
    }

    hangUp(): void {
        this.log.log('Meeting end, navigate back: ' + this.jitsiService.current.lastNavigationUrl);
        if (this.jitsiService.current.lastNavigationUrl) {
            this.router.navigateByUrl(this.jitsiService.current.lastNavigationUrl).catch(console.error);
        } else {
            this.router.navigateByUrl('/').catch(console.error);
        }
    }
}
