import { NgModule } from '@angular/core';
import { NgChartsModule } from 'ng2-charts';
import { CurafidaLineChartComponent } from './curafida-line-chart/curafida-line-chart.component';

@NgModule({
    declarations: [CurafidaLineChartComponent],
    imports: [NgChartsModule],
    exports: [CurafidaLineChartComponent],
})
export class ChartComponentsModule {}
