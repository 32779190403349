import { Injectable } from '@angular/core';
import { HypermediaResource } from '../../../../hateoas/hateoas.model';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../../../api';
import { AuthorizationPipe } from '../../../../hateoas/authorization.pipe';
import { ParticipationCreationDto, ParticipationDto } from '../../../entities/exerciseSession/participation.dto';
import { TaskResource } from '../task.resource';
import { ParticipationModel } from './participation.model';
import { PaginatedResponse, SortOrder } from '../../../../common/entities/paginated-response';
import { User, UserRoles } from '../../../../auth/entities/user';
import { httpParams } from '../../../../api/http-query.params';
import { map, tap } from 'rxjs/operators';
import { FullNamePipe } from '../../../../user/full-name.pipe';

export interface ParticipantsSelectionOptionsSearchParameter {
    role: UserRoles.CAREGIVER | UserRoles.SUPERVISOR;
    sortBy?: string;
    sortOrder?: SortOrder;
    limit?: number;
    offset?: number;
    patientUsername?: string;
    textSearchTerm?: string;
    tagUuids?: string[];
}

@Injectable({
    providedIn: 'root',
})
export class ParticipationService {
    readonly model$ = new BehaviorSubject<ParticipationModel>(ParticipationModel.initialState());
    readonly caregiverSelectionOptions$ = this.model$.pipe(
        map((model) => (model.selectionOptions?.caregivers?.items ? model.selectionOptions.caregivers.items : [])),
    );
    readonly supervisorSelectionOptions$ = this.model$.pipe(
        map((model) => (model.selectionOptions?.supervisors?.items ? model.selectionOptions.supervisors.items : [])),
    );
    readonly taskCaregivers$ = this.model$.pipe(
        map((model) => {
            if (
                !(model.selectionOptions?.caregivers?.items?.length > 0) ||
                !(model.taskParticipation?.caregivers?.length > 0)
            ) {
                return [];
            }
            return model.selectionOptions.caregivers.items.filter((user) =>
                model.taskParticipation.caregivers.includes(user.username),
            );
        }),
    );
    readonly taskSupervisors$ = this.model$.pipe(
        map((model) => {
            if (
                !(model.selectionOptions?.supervisors?.items?.length > 0) ||
                !(model.taskParticipation?.supervisors?.length > 0)
            ) {
                return [];
            }
            return model.selectionOptions.supervisors.items.filter((user) =>
                model.taskParticipation.supervisors.includes(user.username),
            );
        }),
    );
    private readonly linkName = 'participation';

    constructor(
        private readonly httpClient: HttpClient,
        private readonly authorizationPipe: AuthorizationPipe,
        private readonly fullNamePipe: FullNamePipe,
    ) {}

    init(taskResource: TaskResource): void {
        forkJoin([
            this.fetchParticipation(taskResource),
            this.fetchSelectionOptions({ offset: 0, limit: 9999, role: UserRoles.CAREGIVER }),
            taskResource.concernedUsername
                ? this.fetchSelectionOptions({
                      offset: 0,
                      limit: 9999,
                      role: UserRoles.SUPERVISOR,
                      patientUsername: taskResource.concernedUsername,
                  })
                : of(ParticipationModel.initialState().selectionOptions.supervisors),
        ]).subscribe(([participation, caregiverOptions, supervisorOptions]) =>
            this.model$.next({
                taskParticipation: participation,
                selectionOptions: { caregivers: caregiverOptions, supervisors: supervisorOptions },
            }),
        );
    }

    fetchParticipation(resource: HypermediaResource): Observable<ParticipationDto> {
        if (!this.authorizationPipe.transform(resource, this.linkName, 'read')) {
            return of(undefined);
        }
        return this.httpClient.get<ParticipationDto>(
            `${ApiService.url}${resource._links[this.linkName].href}`,
            ApiService.options,
        );
    }

    fetchSelectionOptions(
        parameters: ParticipantsSelectionOptionsSearchParameter,
    ): Observable<PaginatedResponse<User[]>> {
        return this.httpClient
            .get<PaginatedResponse<User[]>>(`${ApiService.url}tasks/participation/user-selection-options`, {
                ...ApiService.options,
                params: httpParams(parameters),
            })
            .pipe(tap((it) => it.items.forEach((user) => (user.fullName = this.fullNamePipe.transform(user)))));
    }

    create(taskResource: TaskResource, dto: ParticipationCreationDto): Observable<ParticipationDto> {
        return this.httpClient
            .post<ParticipationDto>(ApiService.url + taskResource._links[this.linkName].href, dto, ApiService.options)
            .pipe(tap((it) => this.model$.next({ ...this.model$.value, taskParticipation: it })));
    }

    clear(): void {
        this.model$.next(ParticipationModel.initialState());
    }
}
