import { Component, Input } from '@angular/core';
import { Platform } from '@ionic/angular';
import { ItemAdapterComponent, TableItem } from '../../entities/table';

@Component({
    template: `
        <div [ngClass]="{ 'mobile-font': isMobile, 'web-width': !isMobile, 'mobile-title': itemSetting.isMobileBold }">
            {{ data[itemSetting.prop] | duration: itemSetting.unit }}
        </div>
    `,
})
export class DurationItemAdapterComponent implements ItemAdapterComponent {
    @Input() data: any;
    @Input() itemSetting: TableItem;
    isMobile: boolean;

    constructor(private platform: Platform) {
        if (platform.is('mobile')) this.isMobile = true;
    }
}
