import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ToastService } from '../../../../common/services/toast-service/toast-service.service';
import { Logger, LoggingService } from '../../../../logging/logging.service';
import { User, UserRoles } from '../../../../auth/entities/user';
import {
    ActionEmitter,
    ActionType,
    ButtonItemAdapterComponent,
    ItemType,
    TableConfig,
} from '../../../../table/entities/table';
import { tableItemsConfig } from '../../../../user/components/user-main-info/caregiver-supervisor-table.config';
import { ModalController } from '@ionic/angular';
import { ModalAlertService } from '../../../../common/services/modal';
import { SelectInputItem } from '../../../../common/components/curafida-input/curafida-select-input/curafida-select-input.component';
import { UsersService } from '../../../../user/services/user';
import { ParticipationForm } from './participation.form';
import { StringItemAdapterComponent } from '../../../../table/components/table-adapter/string-item-adapter.component';
import { FrontendUser } from '../../../../user/pages/user-management/user-base-list';
import { ParticipationService } from './participation.service';
import { ParticipantListModalComponent } from './participant-list/participant-list.modal';
import { Subscription } from 'rxjs/internal/Subscription';
import { take } from 'rxjs/operators';
import { PaginatedResponse } from '../../../../common/entities/paginated-response';

@Component({
    selector: 'curafida-participation',
    templateUrl: './participation.component.html',
    styleUrls: ['./participation.component.scss'],
})
export class ParticipationComponent implements OnInit, OnDestroy {
    private readonly subscriptions: Subscription[] = [];
    readonly log: Logger;

    readonly meetingPatientOptions: SelectInputItem[] = [
        new SelectInputItem(true, 'Zum Termin einladen'),
        new SelectInputItem(false, 'Zum Termin nicht einladen'),
    ];
    readonly caregiverListConfig = new TableConfig<User[]>();
    readonly supervisorListConfig = new TableConfig<User[]>();
    @Input()
    isMobile: boolean;
    @Input()
    patient: User;

    constructor(
        readonly toastService: ToastService,
        readonly modalCtrl: ModalController,
        readonly modalAlertService: ModalAlertService,
        readonly usersService: UsersService,
        loggingService: LoggingService,
        private readonly participationService: ParticipationService,
    ) {
        this.log = loggingService.getLogger(this.constructor.name);
    }

    private _isEditEnabled: boolean;

    get isEditEnabled(): boolean {
        return this._isEditEnabled;
    }

    @Input() set isEditEnabled(value: boolean) {
        this._isEditEnabled = value;
        if (this.caregiverListConfig.itemSettings) {
            this.caregiverListConfig.itemSettings.find((x) => x.id === 'action_delete').isHidden = !value;
        }
        if (this.supervisorListConfig.itemSettings) {
            this.supervisorListConfig.itemSettings.find((x) => x.id === 'action_delete').isHidden = !value;
        }
    }

    private _participationForm: ParticipationForm;

    get participationForm(): ParticipationForm {
        return this._participationForm;
    }

    @Input()
    set participationForm(value: ParticipationForm) {
        this._participationForm = value;
    }

    ngOnInit(): void {
        const itemSettings = [
            {
                id: 'fullName',
                prop: 'fullName',
                header: '',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '85%',
                sortOrderWeb: 0,
            },
            {
                id: 'action_delete',
                prop: 'id',
                header: '',
                type: ItemType.ADAPTER,
                adapter: ButtonItemAdapterComponent,
                icon: 'close',
                actionType: ActionType.DELETE,
                width: '15%',
                color: 'danger',
                sortOrderWeb: 1,
            },
        ];
        this.caregiverListConfig.hideHeader = true;
        this.caregiverListConfig.itemSettings = itemSettings;
        this.caregiverListConfig.list.items = [];
        this.supervisorListConfig.hideHeader = true;
        this.supervisorListConfig.itemSettings = itemSettings;
        this.supervisorListConfig.list.items = [];
        this.subscriptions.push(
            this.participationService.taskCaregivers$.subscribe(
                (it) => (this.caregiverListConfig.list = PaginatedResponse.init<User>(it)),
            ),
            this.participationService.taskSupervisors$.subscribe(
                (it) => (this.supervisorListConfig.list = PaginatedResponse.init<User>(it)),
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((it) => it.unsubscribe());
    }

    addCaregiver(): void {
        this.participationService.caregiverSelectionOptions$.pipe(take(1)).subscribe(() =>
            this.modalCtrl
                .create({
                    component: ParticipantListModalComponent,
                    cssClass: 'full-width-modal',
                    componentProps: {
                        title: 'USER.CAREGIVER.CHOOSE',
                        selectedUsers: this.caregiverListConfig.list.items,
                        role: UserRoles.CAREGIVER,
                        patientUsername: this.patient?.username,
                        tableItems: [...tableItemsConfig],
                        searchString: 'USER.CAREGIVER.SEARCH',
                        anyItem: 'ANY_CAREGIVER',
                        isMultipleChoice: true,
                        showSelectedUser: false,
                    },
                })
                .then(async (it) => {
                    await it.present();
                    await it.onDidDismiss().then((event) => {
                        if (!event.data || event.data.length < 1) {
                            return;
                        }
                        this.caregiverListConfig.list.items.push(...event.data);
                        this.caregiverListConfig.list.items.forEach((x) => this.setFullNameOfUser(x));
                        this.refreshListConfigMetadata();
                        this.participationForm.group.controls.caregivers.patchValue(
                            this.caregiverListConfig.list.items.map((it) => it.username),
                        );
                    });
                })
                .catch(this.log.error),
        );
    }

    setActionOfCaregiverTable(actionEmitter: ActionEmitter<User>): void {
        if (actionEmitter.actionType === ActionType.DELETE) {
            this.deleteCaregiver(actionEmitter);
        }
    }

    deleteCaregiver(actionEmitter: ActionEmitter<User>): void {
        this.participationForm.group.controls.caregivers.patchValue(
            this.participationForm.group.controls.caregivers.value.filter(
                (value) => value !== actionEmitter.item.username,
            ),
        );
        this.caregiverListConfig.list.items = this.caregiverListConfig.list.items.filter(
            (user) => user.username !== actionEmitter.item.username,
        );
        this.refreshListConfigMetadata();
    }

    addSupervisor(): void {
        this.participationService.supervisorSelectionOptions$.pipe(take(1)).subscribe(() =>
            this.modalCtrl
                .create({
                    component: ParticipantListModalComponent,
                    cssClass: 'full-width-modal',
                    componentProps: {
                        title: 'USER.SUPERVISOR.CHOOSE',
                        selectedUsers: this.supervisorListConfig.list.items,
                        role: UserRoles.SUPERVISOR,
                        patientUsername: this.patient?.username,
                        tableItems: [...tableItemsConfig],
                        searchString: 'USER.SUPERVISOR.SEARCH',
                        anyItem: 'ANY_SUPERVISOR',
                        isMultipleChoice: true,
                        showSelectedUser: false,
                    },
                })
                .then(async (it) => {
                    await it.present();
                    await it.onDidDismiss().then((event) => {
                        if (!event.data || event.data.length < 1) {
                            return;
                        }
                        this.supervisorListConfig.list.items.push(...event.data);
                        this.supervisorListConfig.list.items.forEach((x) => this.setFullNameOfUser(x));
                        this.refreshListConfigMetadata();
                        this.participationForm.group.controls.supervisors.patchValue(
                            this.supervisorListConfig.list.items.map((it) => it.username),
                        );
                    });
                })
                .catch(this.log.error),
        );
    }

    setActionOfSupervisorTable(actionEmitter: ActionEmitter<User>): void {
        if (actionEmitter.actionType === ActionType.DELETE) {
            this.deleteSupervisor(actionEmitter);
        }
    }

    deleteSupervisor(actionEmitter: ActionEmitter<User>): void {
        this.participationForm.group.controls.supervisors.patchValue(
            this.participationForm.group.controls.supervisors.value.filter(
                (value) => value !== actionEmitter.item.username,
            ),
        );
        this.supervisorListConfig.list.items = this.supervisorListConfig.list.items.filter(
            (user) => user.username !== actionEmitter.item.username,
        );
        this.refreshListConfigMetadata();
    }

    private refreshListConfigMetadata(): void {
        this.caregiverListConfig.list.total =
            this.caregiverListConfig.list.limit =
            this.caregiverListConfig.list.count =
                this.caregiverListConfig.list.items.length;
        this.caregiverListConfig.list.offset = 0;
        this.supervisorListConfig.list.total =
            this.supervisorListConfig.list.limit =
            this.supervisorListConfig.list.count =
                this.supervisorListConfig.list.items.length;
        this.supervisorListConfig.list.offset = 0;
    }

    private setFullNameOfUser(user: User): FrontendUser {
        const userWithFullName = user as FrontendUser;
        userWithFullName.fullName = `${userWithFullName.firstname} ${userWithFullName.lastname}`;
        return userWithFullName;
    }
}
