import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiService } from '../../../api';
import { IXapiCourseInfo } from '../../entities/xapi/x-api-course-information';
import { XapiFinishedLessonsDto } from '../../entities/xapi/xapi-finished-lessons.dto';

@Injectable({
    providedIn: 'root',
})
export class TherapyXApiService {
    constructor(
        public router: Router,
        protected http: HttpClient,
    ) {}

    async getLaunchUrl(username: string, exerciseSessionId: number): Promise<{ url: string }> {
        const url = `${ApiService.url}users/${username}/therapyXapi/${exerciseSessionId}/launchUrl`;
        return this.http.get<{ url: string }>(url, ApiService.options).toPromise();
    }
    async getCourseInfo(username: string, exerciseSessionId): Promise<IXapiCourseInfo> {
        const url = `${ApiService.url}users/${username}/therapyXapi/${exerciseSessionId}/courseInfo`;
        return this.http.get<IXapiCourseInfo>(url, ApiService.options).toPromise();
    }
    async getFinishedLessions(username: string, exerciseSessionId): Promise<XapiFinishedLessonsDto> {
        const url = `${ApiService.url}users/${username}/therapyXapi/${exerciseSessionId}/finishedLessons`;
        return this.http.get<XapiFinishedLessonsDto>(url, ApiService.options).toPromise();
    }
}
