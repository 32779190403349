// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.precondition {
  border-bottom: 1px solid var(--ion-color-light-shade);
  padding: 0.5rem 0 0.5rem 0;
}
.precondition ion-label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}`, "",{"version":3,"sources":["webpack://./../../libs/therapy/components/modal/therapy-task-template-config/therapy-task-template-config-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,qDAAA;EACA,0BAAA;AACJ;AACI;EACI,gBAAA;EACA,mBAAA;EACA,uBAAA;AACR","sourcesContent":[".precondition {\n    border-bottom: 1px solid var(--ion-color-light-shade);\n    padding: 0.5rem 0 0.5rem 0;\n\n    ion-label {\n        overflow: hidden;\n        white-space: nowrap;\n        text-overflow: ellipsis;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
