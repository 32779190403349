import { Component, Input, OnInit } from '@angular/core';
import { ExerciseSessionOfUser } from '../../entities/exerciseSession';
import { ItemAdapterComponent, TableItem } from '../../../table/entities/table';
import { FeedbackFlag } from '../../entities/feedback/feedback-comment';
import { EffortUnit, PainUnit } from '../../entities/exerciseGoal/exerciseGoalDto';

@Component({
    template: `
        <div
            *ngIf="data?.feedback"
            class="flex-container-column">
            <div
                *ngIf="hasBorg"
                class="flex-container-row">
                <div class="value-col">
                    Borg: {{ data.feedback.borg }}
                    <ion-icon
                        *ngIf="data.feedback.borgFlag !== FeedbackFlag.NONE"
                        class="flag-icon"
                        [ngClass]="{
                            red: data.feedback.borgFlag === FeedbackFlag.RED,
                            yellow: data.feedback.borgFlag === FeedbackFlag.YELLOW,
                            green: data.feedback.borgFlag === FeedbackFlag.GREEN
                        }"
                        name="flag"></ion-icon>
                </div>
            </div>
            <div
                *ngIf="hasPain"
                class="flex-container-row"
                style=" position: relative;">
                <div
                    class="value-col"
                    style="margin: 0; position: absolute;">
                    <div>
                        Schmerz: {{ data.feedback.pain }}
                        <ion-icon
                            *ngIf="data.feedback.painFlag !== FeedbackFlag.NONE"
                            class="flag-icon"
                            [ngClass]="{
                                red: data.feedback.painFlag === FeedbackFlag.RED,
                                yellow: data.feedback.painFlag === FeedbackFlag.YELLOW,
                                green: data.feedback.painFlag === FeedbackFlag.GREEN
                            }"
                            name="flag"></ion-icon>
                    </div>
                </div>
            </div>
            <div *ngIf="!hasPain && !hasBorg">-</div>
        </div>
    `,
})
export class BorgPainFeedbackAdapterComponent implements ItemAdapterComponent, OnInit {
    @Input() data: ExerciseSessionOfUser;
    @Input() itemSetting: TableItem;

    hasPain = true;
    hasBorg = true;
    disabled = false;
    FeedbackFlag = FeedbackFlag;

    ngOnInit(): void {
        this.hasBorg = this.data.exerciseGoal.effortUnit !== EffortUnit.NONE;
        this.hasPain = this.data.exerciseGoal.painUnit !== PainUnit.NONE;
    }
}
