import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TaskResource } from '../../../therapy/components/task/task.resource';

@Component({
    selector: 'lib-kiosk-task-modal',
    templateUrl: './kiosk-task-modal.component.html',
    styleUrls: ['./kiosk-task-modal.component.scss'],
})
export class KioskTaskModalComponent implements OnInit {
    title: string;
    @Input()
    taskResource: TaskResource;

    constructor(private modalCtrl: ModalController) {}

    async ngOnInit() {
        this.title = this.taskResource.title;
    }

    async dismissModal() {
        await this.modalCtrl.dismiss();
    }

    async finishedTask() {
        await this.modalCtrl.dismiss({ isFinished: true });
    }
}
