import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ButtonsModalDefinition, KioskActionButton } from '../../entities/buttons-modal-definition';

@Component({
    selector: 'lib-kiosk-action-element-modal',
    templateUrl: './kiosk-action-element-modal.component.html',
    styleUrls: ['./kiosk-action-element-modal.component.scss'],
})
export class KioskActionElementModalComponent {
    buttonsModalDefinition: ButtonsModalDefinition;

    constructor(private modalCtrl: ModalController) {}

    async dismissModal() {
        await this.modalCtrl.dismiss();
    }

    openAction(button: KioskActionButton) {
        this.modalCtrl.dismiss({ action: button.action });
    }
}
