import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TherapyTemplate } from '../../../entities/therapy/therapy-template';
import { Therapy, TherapyState } from '../../../entities/therapy';
import { TherapyTemplatesService } from '../../../services/therapy-templates';
import { TherapySession } from '../../../entities/therapy-session/therapy-session';
import { Logger, LoggingService } from '../../../../logging/logging.service';
import { StyleService } from '../../../../common/services/style/style.service';
import { format } from 'date-fns';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'lib-program-main-information',
    templateUrl: './program-main-information.component.html',
    styleUrls: ['./program-main-information.component.scss'],
})
export class ProgramMainInformationComponent implements OnInit {
    therapyForm: FormGroup;
    therapyTemplate: TherapyTemplate;
    @Input()
    therapy: Therapy;
    @Input()
    therapySession: TherapySession;
    @Output()
    therapyChange = new EventEmitter<Therapy>();
    isEditEnabled = false;
    isMobile = false;
    templateLabel: string;
    protected readonly log: Logger;

    constructor(
        protected formBuilder: FormBuilder,
        protected route: ActivatedRoute,
        private therapyTemplatesService: TherapyTemplatesService,
        private loggingService: LoggingService,
        private styleService: StyleService,
        private translateService: TranslateService,
    ) {
        this.isMobile = this.styleService.isMobile$;
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    async ngOnInit() {
        await this.initTherapy();
    }

    async initTherapy() {
        this.templateLabel = 'PROGRAM.TEMPLATE.SINGULAR';
        const templateId = this.therapy.templateId == null ? '' : this.therapy.templateId;
        this.isEditEnabled = !this.therapy.id;
        let templateName: string;
        if (this.therapy?.therapyTemplate) {
            templateName = this.therapy?.therapyTemplate?.title;
            this.therapyTemplate = this.therapy?.therapyTemplate;
        } else if (templateId) {
            this.therapyTemplate = await this.therapyTemplatesService.getTherapyTemplateId(templateId);
            templateName = this.therapyTemplate.title;
        } else {
            this.isEditEnabled = true;
        }
        const title = this.therapy?.title == null ? '' : this.therapy.title;
        const notes = this.therapy?.notes == null ? '' : this.therapy.notes;
        const description = this.therapy?.description == null ? '' : this.therapy.description;
        const startDate =
            this.therapy?.startDate == null ? null : format(new Date(this.therapy.startDate), 'dd.MM.yyyy');
        const endDate = this.therapy?.endDate == null ? null : format(new Date(this.therapy.endDate), 'dd.MM.yyyy');
        let therapyState =
            this.therapy?.therapyState == null ? `${TherapyState.DONE}_STATE` : `${this.therapy.therapyState}_STATE`;
        this.translateService.get(therapyState).subscribe((translatedValue) => {
            therapyState = translatedValue;
        });
        this.therapyForm = this.formBuilder.group({
            title: new FormControl({ value: title, disabled: !this.isEditEnabled }, [
                Validators.required,
                Validators.maxLength(255),
            ]),
            description: new FormControl(
                {
                    value: description,
                    disabled: !this.isEditEnabled,
                },
                Validators.required,
            ),
            startDate: new FormControl({
                value: startDate,
                disabled: !this.isEditEnabled,
            }),
            endDate: new FormControl({
                value: endDate,
                disabled: !this.isEditEnabled,
            }),
            templateName: new FormControl(
                {
                    value: templateName,
                    disabled: !this.isEditEnabled,
                },
                Validators.required,
            ),
            notes: new FormControl({ value: notes, disabled: !this.isEditEnabled }),
            therapyState: new FormControl({ value: therapyState, disabled: !this.isEditEnabled }),
        });
    }
}
