// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-footer {
  position: fixed;
  bottom: 0;
}

ion-content {
  position: fixed;
  --padding-top: var(--ion-safe-area-top);
}

.menu-item {
  color: var(--ion-color-dark) !important;
  height: 1.5rem !important;
  line-height: 1.5rem;
}

.menu-item-active {
  color: var(--ion-color-primary) !important;
  height: 1.5rem !important;
  line-height: 1.5rem;
}

.menu-sub-item {
  color: var(--ion-color-medium) !important;
  height: 1.5rem !important;
  line-height: 1.5rem;
  -webkit-margin-start: 1rem;
          margin-inline-start: 1rem;
}

.menu-sub-item-active {
  color: var(--ion-color-primary) !important;
  height: 1.5rem !important;
  line-height: 1.5rem;
  -webkit-margin-start: 1rem;
          margin-inline-start: 1rem;
}`, "",{"version":3,"sources":["webpack://./../../libs/common/components/side-menu/side-menu.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,SAAA;AACJ;;AAEA;EACI,eAAA;EACA,uCAAA;AACJ;;AAEA;EACI,uCAAA;EACA,yBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,0CAAA;EACA,yBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,yCAAA;EACA,yBAAA;EACA,mBAAA;EACA,0BAAA;UAAA,yBAAA;AACJ;;AAEA;EACI,0CAAA;EACA,yBAAA;EACA,mBAAA;EACA,0BAAA;UAAA,yBAAA;AACJ","sourcesContent":["ion-footer {\n    position: fixed;\n    bottom: 0;\n}\n\nion-content {\n    position: fixed;\n    --padding-top: var(--ion-safe-area-top);\n}\n\n.menu-item {\n    color: var(--ion-color-dark) !important;\n    height: 1.5rem !important;\n    line-height: 1.5rem;\n}\n\n.menu-item-active {\n    color: var(--ion-color-primary) !important;\n    height: 1.5rem !important;\n    line-height: 1.5rem;\n}\n\n.menu-sub-item {\n    color: var(--ion-color-medium) !important;\n    height: 1.5rem !important;\n    line-height: 1.5rem;\n    margin-inline-start: 1rem;\n}\n\n.menu-sub-item-active {\n    color: var(--ion-color-primary) !important;\n    height: 1.5rem !important;\n    line-height: 1.5rem;\n    margin-inline-start: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
