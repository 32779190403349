import { Component, Input, OnInit } from '@angular/core';
import { Logger } from '../../../logging/logging.service';
import { ExerciseContentsService } from '../../services/exercise-contents';
import { Content } from '../../entities/content';
import { MimeTypeCurafida } from '../../../common/entities/mime.type';
import { StyleService } from '../../../common/services/style/style.service';

@Component({
    selector: 'lib-exercise-contents-preview',
    templateUrl: './exercise-content-preview.component.html',
    styleUrls: ['./exercise-content-preview.component.scss'],
})
export class ExerciseContentPreviewComponent implements OnInit {
    isMobile: boolean;
    @Input()
    contents: Content[];
    Content = Content;
    protected readonly log: Logger;

    constructor(
        private exerciseContentsService: ExerciseContentsService,
        private styleService: StyleService,
    ) {}

    async downloadData(data: Content): Promise<void> {
        await this.exerciseContentsService.downloadObjectURLinNewWindow(data);
    }

    ngOnInit(): void {
        this.isMobile = this.styleService.isMobile$;
        this.contents = this.contents.filter((i) => i.mimeType !== MimeTypeCurafida.MY_MEDAX);
        this.contents.forEach((data) => {
            if (data.description && data.description?.length > 35) {
                data.description = data.description.slice(0, 30) + '...';
            }
            if (data.origFileName && data.origFileName?.length > 35) {
                data.origFileName = data.origFileName.slice(0, 30) + '...';
            }
        });
        this.contents = this.contents.sort((a, b) =>
            a.origFileName.toLocaleLowerCase().localeCompare(b.origFileName.toLocaleLowerCase()),
        );
    }
}
