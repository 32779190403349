import { Injectable } from '@angular/core';
import { UserRoles } from '../../auth/entities/user';
import { ApiService } from '../../api';
import { FileContentService } from '../../common/services/content/file-content.service';
import { Capacitor } from '@capacitor/core';

export enum ModuleName {
    USER_MASTER_DATA = 'USER_MASTER_DATA',
    MEDICAL_HISTORY = 'MEDICAL_HISTORY',
    CHAT = 'CHAT',
    TASK = 'TASK',
    CONSULTATION = 'CONSULTATION',
    ORDER = 'ORDER',
    MEASUREMENT = 'MEASUREMENT',
    TRAINING = 'TRAINING',
    LEARNING = 'LEARNING',
    MEDICATION = 'MEDICATION',
    NOTE = 'NOTE',
    LOGBOOK = 'LOGBOOK',
    SCREENING = 'SCREENING',
}
export enum ModuleLabel {
    USER_MASTER_DATA = 'USER_MASTER_DATA',
    MEDICAL_HISTORY = 'MEDICAL_HISTORY.SINGULAR',
    CHAT = 'CHAT.SINGULAR',
    TASK = 'TASK.SINGULAR',
    CONSULTATION = 'CONSULTATION.PLURAL',
    ORDER = 'ORDER.PLURAL',
    MEASUREMENT = 'MEASUREMENT',
    TRAINING = 'TRAINING.SINGULAR',
    LEARNING = 'LEARNING.SINGULAR',
    MEDICATION = 'MEDICATION',
    NOTE = 'NOTE.SINGULAR',
    LOGBOOK = 'LOGBOOK',
    SCREENING = 'SCREENING.NAME',
}

@Injectable({
    providedIn: 'root',
})
export class PatientDataExportService {
    constructor(private fileContentService: FileContentService) {}

    async getPatientDataExport(
        groupPath: string,
        moduleNames: ModuleName[],
        searchSubgroups?: boolean,
        role?: UserRoles,
        disabled?: boolean,
        filter?: string,
    ) {
        const url = new URL(`${ApiService.url}groups/${encodeURIComponent(groupPath)}/-/patient-data-export`);
        url.searchParams.set('moduleNames', moduleNames.toString());
        if (searchSubgroups) url.searchParams.set('searchSubgroups', searchSubgroups.toString());
        if (role) url.searchParams.set('role', role.toString());
        if (disabled) url.searchParams.set('disabled', disabled.toString());
        if (filter) url.searchParams.set('filter', filter.toString());
        if (Capacitor.isNativePlatform()) {
            await this.fileContentService.openObjectURLinMobileDevice(url.toString());
        }
        if (!Capacitor.isNativePlatform()) {
            await this.fileContentService.downloadObjectURLinBrowser(url.toString());
        }
    }
}
