import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaginatedResponse } from '../../../common/entities/paginated-response';
import { ApiService } from '../../../api';
import { FeedbackComment, FeedbackCommentDto } from '../../entities/feedback/feedback-comment';

@Injectable({
    providedIn: 'root',
})
export class FeedbackCommentsService {
    constructor(protected http: HttpClient) {}

    async getFeedbackComments(offset?: number, limit?: number): Promise<PaginatedResponse<FeedbackComment[]>> {
        let url = `${ApiService.url}feedbackComments`;

        // build query param string
        let queryParams = '';
        if (offset) queryParams = `${queryParams}offset=${offset}&`;
        if (limit) queryParams = `${queryParams}limit=${limit}&`;

        // check if query params are set, if so ...
        if (queryParams.length > 0) {
            // ... remove the last char '&' and append the query param string to the url
            queryParams = queryParams.substring(0, queryParams.length - 1);
            url = url + '?' + queryParams;
        }
        return this.http.get<PaginatedResponse<FeedbackComment[]>>(url, ApiService.options).toPromise();
    }

    async createFeedbackComments(feedbackCommentDto: FeedbackCommentDto): Promise<FeedbackComment> {
        return this.http
            .post<FeedbackComment>(`${ApiService.url}feedbackComments`, feedbackCommentDto, ApiService.options)
            .toPromise();
    }

    async updateFeedbackComments(
        feedbackCommentUuid: string,
        feedbackCommentDto: FeedbackCommentDto,
    ): Promise<FeedbackComment> {
        return this.http
            .put<FeedbackComment>(
                `${ApiService.url}feedbackComments/${feedbackCommentUuid}`,
                feedbackCommentDto,
                ApiService.options,
            )
            .toPromise();
    }

    async deleteFeedbackComments(feedbackCommentUuid: string) {
        return this.http
            .delete(`${ApiService.url}feedbackComments/${feedbackCommentUuid}`, ApiService.options)
            .toPromise();
    }
}
