import { Component, Input } from '@angular/core';
import { ItemAdapterComponent, TableItem } from '../../entities/table';
import { StyleService } from '../../../common/services/style/style.service';

// prettier-ignore
@Component({
    template: `
        <div>
            <div
                *ngIf="data[itemSetting.tooltip]"
                [title]="data[itemSetting.tooltip]"
                style="display: -webkit-box; -webkit-box-orient: vertical; overflow: hidden; word-wrap: anywhere"
                [ngClass]="{
                    'mobile-font': isMobile,
                    'web-width': !isMobile,
                    'mobile-title': itemSetting.isMobileBold && isMobile
                }">{{data[itemSetting.prop] | percent | handleEmptyValue }}
            </div>
            <div
                *ngIf="!data[itemSetting.tooltip]"
                style="display: -webkit-box; -webkit-box-orient: vertical; overflow: hidden; word-wrap: anywhere"
                [ngStyle]="{ '-webkit-line-clamp': itemSetting.maxTextLines }"
                [ngClass]="{
                    'mobile-font': isMobile,
                    'web-width': !isMobile,
                    'mobile-title': itemSetting.isMobileBold && isMobile,
                    'web-bold': data.isBold
                }">{{(data[itemSetting.prop]) | percent | handleEmptyValue }}
            </div>
        </div>
    `,
})
export class PercentItemAdapterComponent implements ItemAdapterComponent {
    @Input() data: { disabled: boolean; isBold: boolean };
    @Input() itemSetting: TableItem;
    isMobile: boolean;

    constructor(private styleService: StyleService) {
        this.isMobile = this.styleService.isMobile$;
    }
}
