import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../../api';
import { CurafidaAuthService } from '../../../auth/services';
import { FilterSetting, FilterSettingItem, UserPreferences } from '../../entities/user-preferences/user-preferences';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class UsersPreferencesService {
    private readonly model$ = new BehaviorSubject<UserPreferences>(null);

    constructor(
        protected http: HttpClient,
        private authService: CurafidaAuthService,
    ) {}

    get userPreferences(): UserPreferences {
        return this.model$.value;
    }

    set userPreferences(value: UserPreferences) {
        this.model$.next(value);
    }

    settingByName<T extends FilterSettingItem>(name: string): Observable<T> {
        return this.model$.pipe(
            filter((it) => Boolean(it?.filterSettings?.filterSettingItem)),
            map((it) => it.filterSettings.filterSettingItem.find((i) => i.name === name) as T),
        );
    }

    async init(): Promise<void> {
        const username = this.authService.getSession().user.username;
        const url = new URL(`${ApiService.url}users/${username}/preferences`);
        this.userPreferences = await this.http.get<UserPreferences>(url.toString(), ApiService.options).toPromise();
    }

    async update(): Promise<void> {
        const username = this.authService.getSession().user.username;
        const url = new URL(`${ApiService.url}users/${username}/preferences`);
        this.userPreferences = await this.http
            .put<UserPreferences>(url.toString(), this.userPreferences, ApiService.options)
            .toPromise();
    }

    async updateFilterSettingItem<T extends FilterSettingItem>(filterSettingItem: T) {
        const filterSettingItemIndex = this.userPreferences.filterSettings?.filterSettingItem?.findIndex(
            (i) => i.name === filterSettingItem.name.toString(),
        );
        if (!this.userPreferences.filterSettings) {
            this.userPreferences.filterSettings = new FilterSetting();
            this.userPreferences.filterSettings.filterSettingItem = [];
        }
        if (filterSettingItemIndex !== -1) {
            this.userPreferences.filterSettings.filterSettingItem[filterSettingItemIndex] = filterSettingItem;
        } else {
            this.userPreferences.filterSettings.filterSettingItem.push(filterSettingItem);
        }
        await this.update();
    }
}
