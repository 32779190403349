import { UserRoles } from '../../../auth/entities/user';
import { HypermediaLinks } from '../../../hateoas/hateoas.model';
import { Content } from '../content';
import { TherapyExerciseGoal } from '../exerciseGoal/exerciseGoalDto';
import { ExerciseType, ExerciseSession, ExerciseSessionOfUser } from '../exerciseSession';
import { Tag } from '../tag/tag.entity';
import { TherapyDto } from '../therapy';
import { TherapySession } from '../therapy-session/therapy-session';
import { TrainingPlanTemplateResource } from './training-plan-template.resource';

export class TrainingPlanResource extends TherapyDto {
    id: number;
    created_at: string;
    updated_at: string;
    exercises: TrainingExerciseResource[];
    deletedDate?: Date;
    therapyTemplate: TrainingPlanTemplateResource;
    therapySessions?: TherapySession[];
    _links: HypermediaLinks;
}

export class TrainingExerciseResource {
    id: number;
    therapyExerciseId: number;
    title: string;
    description: string;
    order: number;
    duration?: string;
    needsSchedule: boolean;
    autoActivation: boolean;
    responsibleUserRole: string = UserRoles.PATIENT;
    exerciseType: ExerciseType;
    notifyOnStartTime?: boolean;
    notifyOnEndTime?: boolean;
    notifyOnDelayedTime?: boolean;
    isAutoFinished?: boolean;
    created_at: Date;
    updated_at: Date;
    trainingProgression?: TrainingProgressionResource;
    trainingProgressionStep?: number;
    tags?: Tag[];
    contents?: Content[];
    exerciseGoals?: TherapyExerciseGoal[];
    exerciseSessions?: ExerciseSession[];
    exerciseSessionsOfUser?: ExerciseSessionOfUser[];
    _links: HypermediaLinks;
}

export class TrainingProgressionResource {
    uuid: string;
    title: string;
    description: string;
    steps: TrainingProgressionStepResource[];
    tags: Tag[];
    active: boolean;
    created_at: Date;
    updated_at: Date;
    _links: HypermediaLinks;
}

export class TrainingProgressionStepResource {
    uuid: string;
    order: number;
    seriesOperator: string;
    seriesValue: number;
    durationOperator: string;
    durationValue: number;
    intensityOperator?: string;
    intensityValue?: number;
    stressOperator?: string;
    stressValue?: number;
    created_at: Date;
    updated_at: Date;
    _links: HypermediaLinks;
}
