import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ApiService } from '../../api';
import { Content } from '../../therapy/entities/content';
import { catchError } from 'rxjs/operators';
import { Logger, LoggingService } from '../../logging/logging.service';
import { UpdateWebshopContentDto } from '../entities/webshop-content.model';

@Injectable({
    providedIn: 'root',
})
export class WebshopContentService {
    protected readonly log: Logger;

    constructor(
        private readonly http: HttpClient,
        private readonly loggingService: LoggingService,
    ) {
        this.log = this.loggingService.getLogger(WebshopContentService.name);
    }

    private static logErrors(error: HttpErrorResponse) {
        let errorMessage;
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.error(errorMessage);
        return throwError(errorMessage);
    }

    uploadMediaFileForArticle(formData: FormData, articleUuid: string): Observable<HttpEvent<Content>> {
        const headers = new HttpHeaders().append('authorization', ApiService.options.headers.get('authorization'));
        return this.http
            .post<Content>(`${ApiService.url}webshopContents/${articleUuid}/file-upload`, formData, {
                reportProgress: true,
                observe: 'events',
                headers,
                withCredentials: true,
            })
            .pipe(catchError(WebshopContentService.logErrors));
    }

    async createTempDownloadUrl(contentUuid: string, isInline?: boolean): Promise<string> {
        this.log.debug(`Requesting temporary download URL for Content ${contentUuid}`);
        const inlineQueryParam = isInline ? '?isInline=true' : '?isInline=false';
        const url = `${ApiService.url}webshopContents/${contentUuid}/download/temp`;
        const tempDownload = await this.http.post<{ downloadUrl: string }>(url, null, ApiService.options).toPromise();
        return ApiService.url + tempDownload.downloadUrl + inlineQueryParam;
    }

    async updateContent(contentUuid: string, updateWebshopContentDto: UpdateWebshopContentDto): Promise<Content> {
        this.log.debug(`Requesting to PATCH update Content ${contentUuid}`);
        const headers = new HttpHeaders().append('authorization', ApiService.options.headers.get('authorization'));
        return this.http
            .patch<Content>(`${ApiService.url}webshopContents/${contentUuid}`, updateWebshopContentDto, { headers })
            .toPromise();
    }

    async deleteContent(contentUuid: string): Promise<void> {
        this.log.debug(`Requesting to DELETE Content ${contentUuid}`);
        const headers = new HttpHeaders().append('authorization', ApiService.options.headers.get('authorization'));
        return this.http.delete<void>(`${ApiService.url}webshopContents/${contentUuid}`, { headers }).toPromise();
    }
}
