import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'lib-star-processing',
    templateUrl: './star-processing.component.html',
    styleUrls: ['./star-processing.component.scss'],
})
export class StarProcessingComponent implements OnInit {
    color = '';
    starArray: string[] = [];

    get tableInput(): { value: number; max: number } {
        return { value: this._value, max: this._max };
    }
    @Input() starcolor = 'primary';
    // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
    @Input()
    set tableInput(value: { value: number; max: number }) {
        this._max = value?.max;
        this._value = value?.value;
        this.update();
    }

    private _max: number;

    get max(): number {
        return this._max;
    }

    @Input()
    set max(value: number) {
        this._max = value;
        this.update();
    }

    private _value: number;

    get value(): number {
        return this._value;
    }

    @Input()
    set value(value: number) {
        this._value = value;
        this.update();
    }

    private _disabled = false;

    get disabled(): boolean {
        return this._disabled;
    }

    @Input()
    set disabled(value: boolean) {
        this._disabled = value;
        this.update();
    }

    async ngOnInit() {
        this.update();
    }

    private update() {
        this.color = this._disabled ? 'light' : this.starcolor;
        if (this._value !== undefined && this._max !== undefined) {
            this.starArray = [];
            const percentage = (this._value * 100) / this._max;
            if (percentage >= 10 && percentage < 20) {
                this.starArray.push('star-half-outline');
            } else {
                if (percentage >= 20) {
                    this.starArray.push('star');
                } else {
                    this.starArray.push('star-outline');
                }
            }
            if (percentage >= 30 && percentage < 40) {
                this.starArray.push('star-half-outline');
            } else {
                if (percentage >= 40) {
                    this.starArray.push('star');
                } else {
                    this.starArray.push('star-outline');
                }
            }

            if (percentage >= 50 && percentage < 60) {
                this.starArray.push('star-half-outline');
            } else {
                if (percentage >= 60) {
                    this.starArray.push('star');
                } else {
                    this.starArray.push('star-outline');
                }
            }

            if (percentage >= 70 && percentage < 80) {
                this.starArray.push('star-half-outline');
            } else {
                if (percentage >= 80) {
                    this.starArray.push('star');
                } else {
                    this.starArray.push('star-outline');
                }
            }

            if (percentage >= 90 && percentage < 100) {
                this.starArray.push('star-half-outline');
            } else {
                if (percentage >= 100) {
                    this.starArray.push('star');
                } else {
                    this.starArray.push('star-outline');
                }
            }
        }
    }
}
