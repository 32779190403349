import { HttpParams } from '@angular/common/http';

export function httpParams(flatObject: object): HttpParams {
    const copy = { ...flatObject };
    Object.keys(copy).forEach((key) => {
        if (copy[key] === null || copy[key] === undefined || copy[key] === '') {
            delete copy[key];
        }
    });
    return new HttpParams({ fromObject: copy as any });
}
