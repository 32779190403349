// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-label-user-info {
  min-width: 10vw !important;
  max-width: 14vw;
}

ion-item {
  border: 1px solid var(--ion-color-light);
  margin-bottom: 2px;
  margin-top: 3px;
  width: 50%;
  margin-left: 10px;
}

.curafida-item-input {
  --padding-start: 0 !important;
}

ion-card {
  margin-top: 18px;
  margin-bottom: 18px;
}`, "",{"version":3,"sources":["webpack://./../../libs/user/components/user-main-info/user-main-info.component.scss"],"names":[],"mappings":"AAAA;EACI,0BAAA;EACA,eAAA;AACJ;;AAEA;EACI,wCAAA;EACA,kBAAA;EACA,eAAA;EACA,UAAA;EACA,iBAAA;AACJ;;AAEA;EACI,6BAAA;AACJ;;AAGA;EACI,gBAAA;EACA,mBAAA;AAAJ","sourcesContent":[".item-label-user-info {\n    min-width: 10vw !important;\n    max-width: 14vw;\n}\n\nion-item {\n    border: 1px solid var(--ion-color-light);\n    margin-bottom: 2px;\n    margin-top: 3px;\n    width: 50%;\n    margin-left: 10px;\n}\n\n.curafida-item-input {\n    --padding-start: 0 !important;\n    //width: 60%!important;\n}\n\nion-card {\n    margin-top: 18px;\n    margin-bottom: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
