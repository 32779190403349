import { Pipe, PipeTransform } from '@angular/core';
import { TableItem } from '../entities/table';

@Pipe({
    name: 'filterWeb',
})
export class FilterWebPipe implements PipeTransform {
    transform(itemTable: TableItem[]) {
        if (itemTable) return itemTable.filter((item) => !!item.sortOrderWeb || item.sortOrderWeb === 0);
    }
}
