import { ParticipationDto } from '../../../entities/exerciseSession/participation.dto';
import { PaginatedResponse } from '../../../../common/entities/paginated-response';
import { User } from '../../../../auth/entities/user';

export interface UserSelectionOptions {
    caregivers: PaginatedResponse<User[]>;
    supervisors: PaginatedResponse<User[]>;
}

export class ParticipationModel {
    selectionOptions: UserSelectionOptions;
    taskParticipation: ParticipationDto;

    static initialState(): ParticipationModel {
        return {
            selectionOptions: {
                caregivers: undefined,
                supervisors: undefined,
            },
            taskParticipation: undefined,
        };
    }
}
