import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { addHours, addSeconds, endOfDay, isAfter, isBefore, set, startOfDay, subHours, subMinutes } from 'date-fns';
import { StyleService } from '../../../common/services/style/style.service';
import { ExerciseSessionAppointment, ExerciseSessionAppointmentDto } from '../../entities/appointement';

export enum DateControl {
    StartTime = 'startTime',
    DelayedTime = 'delayedTime',
    EndTime = 'endTime',
}

@Component({
    selector: 'curafida-exercise-session-appointment-input',
    templateUrl: './exercise-session-appointment-input.component.html',
    styleUrls: ['./exercise-session-appointment-input.component.scss'],
})
export class ExerciseSessionAppointmentInputComponent implements OnInit {
    @Input()
    startTimeLabel: string;
    @Input()
    delayedTimeLabel: string;
    @Input()
    endTimeLabel: string;
    @Input()
    appointment: ExerciseSessionAppointment;
    @Input()
    parentFormGroup: FormGroup;
    @Input()
    parentFormControlName: string;
    @Output()
    appointmentChanged: EventEmitter<ExerciseSessionAppointmentDto> = new EventEmitter<ExerciseSessionAppointmentDto>();
    exerciseSessionAppointmentForm: FormGroup;
    startTimePickerMin = addSeconds(new Date(), 1).toISOString();
    endTimePickerMin = set(endOfDay(new Date()), { seconds: 0, milliseconds: 0 }).toISOString();
    delayedTimePickerMin = subMinutes(endOfDay(new Date()), 1).toISOString();
    dateControl = DateControl;
    isMobile: boolean;
    previousNewAppointment: ExerciseSessionAppointmentDto;

    constructor(
        private formBuilder: FormBuilder,
        private styleService: StyleService,
    ) {
        this.isMobile = this.styleService.isMobile$;
    }

    _isEditEnabled: boolean;

    get isEditEnabled(): boolean {
        return this._isEditEnabled;
    }

    @Input()
    set isEditEnabled(value: boolean) {
        this._isEditEnabled = value;
        if (!value && this.exerciseSessionAppointmentForm) this.exerciseSessionAppointmentForm.disable();
        if (value && this.exerciseSessionAppointmentForm) this.exerciseSessionAppointmentForm.enable();
    }

    ngOnInit(): void {
        if (this.appointment === null) {
            this.appointment = new ExerciseSessionAppointment(
                startOfDay(new Date()).toISOString(),
                set(endOfDay(new Date()), { seconds: 0, milliseconds: 0 }).toISOString(),
                subMinutes(endOfDay(new Date()), 1).toISOString(),
            );
        }
        const { startTime, delayedTime, endTime } = this.appointment ?? {};
        this.exerciseSessionAppointmentForm = this.formBuilder.group({
            startTime: new FormControl({ value: startTime, disabled: !this.isEditEnabled }, Validators.required),
            delayedTime: new FormControl({ value: delayedTime, disabled: !this.isEditEnabled }, Validators.required),
            endTime: new FormControl({ value: endTime, disabled: !this.isEditEnabled }, Validators.required),
        });
        this.appointmentChanged.emit(this.exerciseSessionAppointmentForm.value);
        this.exerciseSessionAppointmentForm.controls['startTime'].statusChanges.subscribe((status) => {
            switch (status) {
                case 'VALID':
                    this.parentFormGroup.controls[this.parentFormControlName].setErrors(null);
                    break;
                case 'INVALID':
                    const errors = this.exerciseSessionAppointmentForm.controls['startTime'].errors;
                    this.parentFormGroup.controls[this.parentFormControlName].setErrors(errors);
                    break;
                default:
                    break;
            }
        });
        this.exerciseSessionAppointmentForm.controls['delayedTime'].statusChanges.subscribe((status) => {
            switch (status) {
                case 'VALID':
                    this.parentFormGroup.controls[this.parentFormControlName].setErrors(null);
                    break;
                case 'INVALID':
                    const errors = this.exerciseSessionAppointmentForm.controls['delayedTime'].errors;
                    this.parentFormGroup.controls[this.parentFormControlName].setErrors(errors);
                    break;
                default:
                    break;
            }
        });
        this.exerciseSessionAppointmentForm.controls['endTime'].statusChanges.subscribe((status) => {
            switch (status) {
                case 'VALID':
                    this.parentFormGroup.controls[this.parentFormControlName].setErrors(null);
                    break;
                case 'INVALID':
                    const errors = this.exerciseSessionAppointmentForm.controls['endTime'].errors;
                    this.parentFormGroup.controls[this.parentFormControlName].setErrors(errors);
                    break;
                default:
                    break;
            }
        });
    }

    toEndOfDay(timeRepresentation: string | Date): string {
        return set(endOfDay(new Date(timeRepresentation)), { seconds: 0, milliseconds: 0 }).toISOString();
    }

    toStartOfDay(timeRepresentation: string | Date): string {
        return startOfDay(new Date(timeRepresentation)).toISOString();
    }

    toOneMinuteFromEndOfDay(timeRepresentation: string | Date): string {
        return subMinutes(endOfDay(new Date(timeRepresentation)), 1).toISOString();
    }

    adjustToFitDelayedTimeChange(
        exerciseSessionAppointment: ExerciseSessionAppointmentDto,
    ): ExerciseSessionAppointmentDto {
        const delayedTime = new Date(exerciseSessionAppointment.delayedTime);
        const startTime = new Date(exerciseSessionAppointment.startTime);
        const endTime = new Date(exerciseSessionAppointment.endTime);
        if (isAfter(delayedTime, endTime)) {
            return this.adjustToFitEndTimeChange({
                ...exerciseSessionAppointment,
                endTime: this.toEndOfDay(delayedTime),
            });
        }
        if (isBefore(delayedTime, startTime)) {
            return this.adjustToFitStartTimeChange({
                ...exerciseSessionAppointment,
                startTime: this.toStartOfDay(delayedTime),
            });
        }
        return exerciseSessionAppointment;
    }

    adjustToFitEndTimeChange(exerciseSessionAppointment: ExerciseSessionAppointmentDto): ExerciseSessionAppointmentDto {
        const endTime = new Date(exerciseSessionAppointment.endTime);
        const delayedTime = new Date(exerciseSessionAppointment.delayedTime);
        if (isBefore(endTime, delayedTime)) {
            return this.adjustToFitDelayedTimeChange({
                ...exerciseSessionAppointment,
                delayedTime: this.toOneMinuteFromEndOfDay(endTime),
            });
        }
        return exerciseSessionAppointment;
    }

    adjustToFitStartTimeChange(
        exerciseSessionAppointment: ExerciseSessionAppointmentDto,
    ): ExerciseSessionAppointmentDto {
        const startTime = new Date(exerciseSessionAppointment.startTime);
        const delayedTime = new Date(exerciseSessionAppointment.delayedTime);
        if (isAfter(startTime, delayedTime)) {
            return this.adjustToFitDelayedTimeChange({
                ...exerciseSessionAppointment,
                delayedTime: this.toOneMinuteFromEndOfDay(startTime),
            });
        }
        return exerciseSessionAppointment;
    }

    getNewDtoWithAdjustments(
        exerciseSessionAppointment: ExerciseSessionAppointmentDto,
        controlName: DateControl,
    ): ExerciseSessionAppointmentDto {
        switch (controlName) {
            case DateControl.StartTime:
                return this.adjustToFitStartTimeChange(exerciseSessionAppointment);
            case DateControl.DelayedTime:
                return this.adjustToFitDelayedTimeChange(exerciseSessionAppointment);
            case DateControl.EndTime:
                return this.adjustToFitEndTimeChange(exerciseSessionAppointment);
        }
    }

    getExerciseAppointmentWithUpdatedValues(
        exerciseSessionAppointment: ExerciseSessionAppointmentDto,
        controlName: DateControl,
        updatedDateString: string,
    ): ExerciseSessionAppointmentDto {
        switch (controlName) {
            case DateControl.StartTime:
                return {
                    ...exerciseSessionAppointment,
                    startTime: this.toStartOfDay(updatedDateString),
                };
            case DateControl.DelayedTime:
                return {
                    ...exerciseSessionAppointment,
                    delayedTime: this.toOneMinuteFromEndOfDay(updatedDateString),
                };
            case DateControl.EndTime:
                return {
                    ...exerciseSessionAppointment,
                    endTime: this.toEndOfDay(updatedDateString),
                };
        }
    }

    onAppointmentChanged(updatedDateString: string, controlName: DateControl) {
        if (
            this.previousNewAppointment &&
            this.exerciseSessionAppointmentForm.controls[controlName].value === this.previousNewAppointment[controlName]
        ) {
            return;
        }

        const exerciseSessionAppointmentWithoutNulls = {
            startTime:
                this.exerciseSessionAppointmentForm.controls.startTime.value ?? startOfDay(new Date()).toISOString(),
            delayedTime:
                this.exerciseSessionAppointmentForm.controls.delayedTime.value ??
                subMinutes(endOfDay(new Date()), 1).toISOString(),
            endTime:
                this.exerciseSessionAppointmentForm.controls.endTime.value ??
                set(endOfDay(new Date()), { seconds: 0, milliseconds: 0 }).toISOString(),
        };

        const userGeneratedExerciseSessionAppointment = this.getExerciseAppointmentWithUpdatedValues(
            exerciseSessionAppointmentWithoutNulls,
            controlName,
            updatedDateString,
        );

        const validExerciseSessionAppointment = this.getNewDtoWithAdjustments(
            userGeneratedExerciseSessionAppointment,
            controlName,
        );

        //TODO: this is a hack to make the time picker work properly, check if it works in china...
        this.delayedTimePickerMin = addHours(new Date(validExerciseSessionAppointment.startTime), 6).toISOString();
        this.endTimePickerMin = subHours(new Date(validExerciseSessionAppointment.delayedTime), 6).toISOString();

        this.previousNewAppointment = validExerciseSessionAppointment;

        this.exerciseSessionAppointmentForm.controls.startTime.setValue(validExerciseSessionAppointment.startTime);

        this.exerciseSessionAppointmentForm.controls.delayedTime.setValue(validExerciseSessionAppointment.delayedTime);

        this.exerciseSessionAppointmentForm.controls.endTime.setValue(validExerciseSessionAppointment.endTime);

        this.appointmentChanged.emit(validExerciseSessionAppointment);
    }
}
