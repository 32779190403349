import { NgModule } from '@angular/core';
import { MenuItemsFilterPipe } from './menu-items-filter.pipe';
import { CheckFeaturesPipe } from './check-features.pipe';

@NgModule({
    declarations: [MenuItemsFilterPipe, CheckFeaturesPipe],
    imports: [],
    exports: [MenuItemsFilterPipe, CheckFeaturesPipe],
})
export class CoreCurafidaPipesModule {}
