import { Injectable } from '@angular/core';
import { QuestionnaireType } from '../entities/questionnaire-type';
import { PaginatedResponse, SortBy, SortOrder } from '../../common/entities/paginated-response';
import { ApiService } from '../../api';
import { HttpClient } from '@angular/common/http';
import {
    MyMedaxQuestionnaireSubmission,
    QuestionnaireSubmissionVersioning,
} from '../entities/my-medax-questionnaire-submission';
import { Content } from '../../therapy/entities/content';
import { QuestionnaireTemplate } from '../entities/questionnaire-template';
import { MyMedaxFillLink } from '../entities/my-medax-fill-link';
import { Logger, LoggingService } from '../../logging/logging.service';
import { FileContentService } from '../../common/services/content/file-content.service';
import { LoadingService } from '../../common/services/loading/loading.service';
import { format, isAfter, isBefore, parse } from 'date-fns';
import { Capacitor } from '@capacitor/core';
import { TaskResource } from '../../therapy/components/task/task.resource';
import { MimeTypeCurafida } from '../../common/entities/mime.type';
import { QuestionAndAnswer } from '../entities/question-and.answer';
import { ScreeningMarker } from '../../screening/entities/screening.marker';

@Injectable({
    providedIn: 'root',
})
export class MyMedaxService {
    protected readonly log: Logger;

    constructor(
        protected http: HttpClient,
        private loggingService: LoggingService,
        private loadingService: LoadingService,
        private fileContentService: FileContentService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    async getQuestionnaireTemplates(
        offset?: number,
        limit?: number,
        sortOrder?: SortOrder,
        sortBy?: SortBy,
    ): Promise<PaginatedResponse<QuestionnaireTemplate[]>> {
        const url = new URL(`${ApiService.url}myMedax/questionnaireContents`);
        if (offset) url.searchParams.set('offset', offset.toString());
        if (limit) url.searchParams.set('limit', limit.toString());
        if (sortOrder) url.searchParams.set('sortOrder', sortOrder.toString());
        if (sortBy) url.searchParams.set('sortBy', sortBy.toString());
        const paginatedQuestionnaireTemplates = new PaginatedResponse<QuestionnaireTemplate[]>();
        const paginatedQuestionnaireContents = await this.http
            .get<PaginatedResponse<Content[]>>(url.toString(), ApiService.options)
            .toPromise();
        paginatedQuestionnaireTemplates.items = [];
        paginatedQuestionnaireTemplates.total = paginatedQuestionnaireContents.total;
        paginatedQuestionnaireTemplates.limit = paginatedQuestionnaireContents.limit;
        paginatedQuestionnaireTemplates.offset = paginatedQuestionnaireContents.offset;
        for (const questionnaireContent of paginatedQuestionnaireContents.items) {
            paginatedQuestionnaireTemplates.items.push(questionnaireContent.jsonData as QuestionnaireTemplate);
        }
        return paginatedQuestionnaireTemplates;
    }

    async getScreeningTemplates(
        offset?: number,
        limit?: number,
        sortOrder?: SortOrder,
        sortBy?: SortBy,
    ): Promise<PaginatedResponse<QuestionnaireTemplate[]>> {
        const url = new URL(`${ApiService.url}myMedax/questionnaireContents`);
        if (offset) url.searchParams.set('offset', offset.toString());
        if (limit) url.searchParams.set('limit', limit.toString());
        if (sortOrder) url.searchParams.set('sortOrder', sortOrder.toString());
        if (sortBy) url.searchParams.set('sortBy', sortBy.toString());
        const paginatedQuestionnaireTemplates = new PaginatedResponse<QuestionnaireTemplate[]>();
        const paginatedQuestionnaireContents = await this.http
            .get<PaginatedResponse<Content[]>>(url.toString(), ApiService.options)
            .toPromise();
        paginatedQuestionnaireTemplates.items = [];
        for (const questionnaireContent of paginatedQuestionnaireContents.items) {
            if (questionnaireContent.contentUsage == 'SCREENING') {
                {
                    paginatedQuestionnaireTemplates.items.push(questionnaireContent.jsonData as QuestionnaireTemplate);
                }
            }
        }
        paginatedQuestionnaireTemplates.total =
            paginatedQuestionnaireTemplates.count =
            paginatedQuestionnaireTemplates.limit =
                paginatedQuestionnaireTemplates.items.length;
        paginatedQuestionnaireTemplates.offset = 0;
        return paginatedQuestionnaireTemplates;
    }

    async getQuestionnaireSubmissionsByUser(
        username: string,
        offset?: number,
        limit?: number,
        sortBy: SortBy = SortBy.UPDATED_AT,
        sortOrder: SortOrder = SortOrder.DESC,
        questionnaireType?: QuestionnaireType,
        marker?: string,
        answererUsername?: string,
        resolveAnswererName?: boolean,
        myMedaxQuestionnaireId?: string,
        pdfContent?: string,
    ): Promise<PaginatedResponse<MyMedaxQuestionnaireSubmission[]>> {
        const url = new URL(`${ApiService.url}myMedax/concernedUser/${username}/questionnaireSubmissions`);
        if (offset) url.searchParams.set('offset', offset.toString());
        if (limit) url.searchParams.set('limit', limit.toString());
        url.searchParams.set('sortBy', sortBy.toString());
        url.searchParams.set('sortOrder', sortOrder.toString());
        if (questionnaireType) url.searchParams.set('questionnaireType', questionnaireType.toString());
        if (marker) url.searchParams.set('marker', marker.toString());
        if (answererUsername) url.searchParams.set('answererUsername', answererUsername.toString());
        if (resolveAnswererName) url.searchParams.set('resolveAnswererName', resolveAnswererName.toString());
        if (myMedaxQuestionnaireId) url.searchParams.set('myMedaxQuestionnaireId', myMedaxQuestionnaireId.toString());
        if (pdfContent) url.searchParams.set('pdfContent', pdfContent.toString());
        return this.http
            .get<PaginatedResponse<MyMedaxQuestionnaireSubmission[]>>(url.toString(), ApiService.options)
            .toPromise();
    }

    // TODO: deprecated endpoint, needs to be used because new endpoint can not be used by supervisors
    async getSupervisedQuestionnaireSubmissions(
        concernedUsername?: string,
        answererUsername?: string,
        questionnaireType?: QuestionnaireType,
        myMedaxQuestionnaireId?: string,
        sortOrder: SortOrder = SortOrder.DESC,
        marker?: string,
        limit?: number,
        offset?: number,
        resolveAnswererName = false,
        pdfContent?: string,
    ): Promise<PaginatedResponse<MyMedaxQuestionnaireSubmission[]>> {
        let url = `${ApiService.url}myMedax/questionnaireSubmissions?`;
        if (concernedUsername) url = `${url}concernedUsername=${concernedUsername}&`;
        if (answererUsername) url = `${url}answererUsername=${answererUsername}&`;
        if (questionnaireType) url = `${url}questionnaireType=${questionnaireType}&`;
        if (myMedaxQuestionnaireId) url = `${url}myMedaxQuestionnaireId=${myMedaxQuestionnaireId}&`;
        if (pdfContent) url = `${url}pdfContent=${pdfContent}&`;
        if (sortOrder) url = `${url}sortOrder=${sortOrder}&`;
        if (marker) url = `${url}marker=${marker}&`;
        if (limit) url = `${url}limit=${limit.toString()}&`;
        if (offset) url = `${url}offset=${offset.toString()}&`;
        if (resolveAnswererName) url = `${url}resolveAnswererName=${resolveAnswererName.toString()}&`;
        url = `${url}sortBy=UPDATED_AT&`;
        url = url.slice(0, -1);

        return await this.http
            .get<PaginatedResponse<MyMedaxQuestionnaireSubmission[]>>(url, ApiService.options)
            .toPromise();
    }

    async getQuestionnaireSubmissions(
        groupPath: string,
        answererUsername?: string,
        concernedUsername?: string,
        questionnaireType?: QuestionnaireType,
        myMedaxQuestionnaireId?: string,
        pdfContent?: string,
        marker?: string,
        sortBy: SortBy = SortBy.UPDATED_AT,
        sortOrder: SortOrder = SortOrder.DESC,
        limit?: number,
        offset?: number,
    ): Promise<PaginatedResponse<MyMedaxQuestionnaireSubmission[]>> {
        const url = new URL(`${ApiService.url}myMedax/groups/${groupPath}/-/questionnaireSubmissions`);
        if (answererUsername) url.searchParams.set('answererUsername', answererUsername.toString());
        if (concernedUsername) url.searchParams.set('concernedUsername', concernedUsername.toString());
        if (questionnaireType) url.searchParams.set('questionnaireType', questionnaireType.toString());
        if (myMedaxQuestionnaireId) url.searchParams.set('myMedaxQuestionnaireId', myMedaxQuestionnaireId.toString());
        if (pdfContent) url.searchParams.set('pdfContent', pdfContent.toString());
        if (marker) url.searchParams.set('marker', marker.toString());
        url.searchParams.set('sortBy', sortBy.toString());
        url.searchParams.set('sortOrder', sortOrder.toString());
        if (limit) url.searchParams.set('limit', limit.toString());
        if (offset) url.searchParams.set('offset', offset.toString());
        return this.http
            .get<PaginatedResponse<MyMedaxQuestionnaireSubmission[]>>(url.toString(), ApiService.options)
            .toPromise();
    }

    async getFillLink(
        username: string,
        questionnaireId: string,
        returnUri?: string,
        prefill?: boolean,
        prefillSubmissions?: string[],
        submissionVersioningUuid?: string,
    ): Promise<MyMedaxFillLink> {
        const url = new URL(
            `${ApiService.url}myMedax/concernedUser/${username}/questionnaire/${questionnaireId}/fillLink`,
        );
        if (returnUri) url.searchParams.set('returnUri', encodeURI(returnUri));
        if (prefill) url.searchParams.set('prefill', prefill.toString());
        if (prefillSubmissions) url.searchParams.set('prefillSubmissions', prefillSubmissions.toString());
        if (submissionVersioningUuid) {
            url.searchParams.set('submissionVersioningUuid', submissionVersioningUuid.toString());
        }
        return await this.http.get<MyMedaxFillLink>(url.toString(), ApiService.options).toPromise();
    }

    async openSubmissionPdf(username: string, questionnaireSubmissionId: string): Promise<void> {
        const url = new URL(
            `${ApiService.url}myMedax/concernedUser/${username}/questionnaireSubmissions/${questionnaireSubmissionId}/downloadPdf`,
        );
        if (Capacitor.isNativePlatform()) await this.fileContentService.openObjectURLinMobileDevice(url.toString());
        if (!Capacitor.isNativePlatform()) await this.fileContentService.openObjectURLinNewWindow(url.toString());
    }

    async setMarkerToScreening(
        username: string,
        submissionUuid: string,
        screeningMarker: ScreeningMarker,
    ): Promise<void> {
        this.loadingService.startLoadingModal();
        await this.http
            .put(
                `${ApiService.url}myMedax/concernedUser/${username}/questionnaireSubmissions/${submissionUuid}/mark/${screeningMarker}`,
                null,
                ApiService.options,
            )
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    processQuestionnaireSubmissionVersions(
        submissions: MyMedaxQuestionnaireSubmission[],
    ): QuestionnaireSubmissionVersioning[] {
        const questionnaireSubmissionsByVersion = [];
        for (const item of submissions) {
            const existingVersion = questionnaireSubmissionsByVersion.find(
                (x) => x.versionUuid === item.submissionVersioningUuid,
            );
            if (existingVersion && isBefore(new Date(existingVersion.latestCreation), new Date(item.created_at))) {
                existingVersion.latestCreation = item.created_at;
            }
            if (existingVersion && isAfter(new Date(existingVersion.firstCreation), new Date(item.created_at))) {
                existingVersion.firstCreation = item.created_at;
            }
            if (!existingVersion) {
                questionnaireSubmissionsByVersion.push(new QuestionnaireSubmissionVersioning(item));
            } else {
                existingVersion.questionnaireSubmissions.push(item);
            }
        }
        for (const item of questionnaireSubmissionsByVersion) item.sortSubmissionsAscending();
        return questionnaireSubmissionsByVersion;
    }

    filterOutOldVersions(submissions: MyMedaxQuestionnaireSubmission[]): MyMedaxQuestionnaireSubmission[] {
        const result: MyMedaxQuestionnaireSubmission[] = [];
        const versioningUuidList = this.processQuestionnaireSubmissionVersions(submissions);
        for (const submission of submissions) {
            const latestVersion = versioningUuidList.find(
                (x) =>
                    x.versionUuid === submission.submissionVersioningUuid && x.latestCreation === submission.created_at,
            );
            if (!latestVersion) continue;
            result.push(submission);
        }
        return result;
    }

    fetchQuestionsAndAnswer(args: {
        questionAndAnswers: QuestionAndAnswer[];
        taskResource?: TaskResource;
        showIcons?: boolean;
    }) {
        const questions = args.taskResource.exerciseSessionUserResults.find(
            (it) => it.content?.mimeType === MimeTypeCurafida.FIHR_JSON,
        );
        if (questions?.content?.jsonData?.item) {
            for (const question of questions?.content?.jsonData?.item) {
                this.processQuestion({
                    question: question,
                    questionAndAnswers: args.questionAndAnswers,
                    taskResource: args.taskResource,
                    showIcons: args.showIcons,
                });
            }
        }
    }

    processQuestion(args: {
        question;
        questionAndAnswers: QuestionAndAnswer[];
        taskResource?: TaskResource;
        showIcons?: boolean;
        questionPrefix?: string;
    }): QuestionAndAnswer[] {
        const questionAndAnswer = new QuestionAndAnswer();
        if (args.question.answer) {
            questionAndAnswer.question = args.question.text;
            if (args.questionPrefix) questionAndAnswer.question = `${args.questionPrefix}${questionAndAnswer.question}`;
            questionAndAnswer.answer = '';
            questionAndAnswer.icons = [];
            for (const answer of args.question.answer) {
                const value = this.formatStringIfDate(this.processAnswer(answer));
                questionAndAnswer.answer = `${questionAndAnswer.answer}${value}\n`;
                if (args.showIcons) {
                    const icon_source = this.processIcon(answer);
                    if (icon_source !== '-') {
                        questionAndAnswer.icons.push(
                            args.taskResource.icons.find(
                                (icon) =>
                                    icon.source.split(new RegExp('idef[0-9]*'), 2)[1] ===
                                    icon_source.split(new RegExp('idef[0-9]*'), 2)[1],
                            ),
                        );
                    }
                }
            }
            args.questionAndAnswers.push(questionAndAnswer);
        } else if (args.question.item) {
            questionAndAnswer.question = args.question.text;
            for (const innerQuestion of args.question.item) {
                this.processQuestion({
                    question: innerQuestion,
                    questionAndAnswers: args.questionAndAnswers,
                    questionPrefix: `${questionAndAnswer.question} `,
                });
            }
        }
        return args.questionAndAnswers;
    }

    private processIcon(answer): string {
        let result = '-';
        for (const key in answer) {
            if (['linkId'].includes(key)) {
                result = answer[key].toString();
            } else if (key.includes('answer') || key.includes('item')) {
                result = this.processIcon(answer[key][0]);
            }
        }
        return result;
    }

    private processAnswer(answer): string {
        let result = '-';
        for (const key in answer) {
            // So far we have only had cases of 'valueString' and 'valueDecimal'
            // For new value types that do not work as strings this might have to be adjusted
            if (['valueString', 'valueDecimal', 'valueDate'].includes(key)) {
                result = answer[key].toString();
            } else if (key.includes('answer') || key.includes('item')) {
                result = this.processAnswer(answer[key][0]);
            }
        }
        return result;
    }

    private formatStringIfDate(dateString: string): string {
        const myMedaxDateRegex = new RegExp('[0-9]{4}-[0-9]{2}-[0-9]{2}');
        if (!myMedaxDateRegex.test(dateString)) return dateString;
        return format(parse(dateString, 'yyyy-MM-dd', new Date()), 'dd.MM.yyyy');
    }
}
