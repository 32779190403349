import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { TherapySession } from '../../../therapy/entities/therapy-session/therapy-session';
import { KioskTrainingVideoModalComponent } from '../kiosk-training-video-modal/kiosk-training-video-modal.component';
import { ContentMetaDataType } from '../../../therapy/entities/content';
import { TranslateService } from '@ngx-translate/core';
import { UserExerciseSessionsService } from '../../../therapy/services/user-exercise-sessions';
import { User } from '../../../auth/entities/user';
import { ExerciseSessionState, ExerciseSessionUserState } from '../../../therapy/entities/exerciseSession';
import { ButtonsModalDefinition, KioskActionButton } from '../../entities/buttons-modal-definition';
import { KioskActionElementModalComponent } from '../kiosk-action-element-modal/kiosk-action-element-modal.component';
import { UsersTherapySessionsService } from '../../../therapy/services/users-therapy-sessions/users-therapy-sessions.service';
import { TherapyWithTherapySessions } from '../../../therapy/entities/therapy';
import { FeedbackFlag } from '../../../therapy/entities/feedback/feedback-comment';

@Component({
    selector: 'lib-kiosk-training-modal',
    templateUrl: './kiosk-training-modal.component.html',
    styleUrls: ['./kiosk-training-modal.component.scss'],
})
export class KioskTrainingModalComponent implements OnInit {
    title: string;
    therapySession: TherapySession;
    exerciseIndex = 0;
    isTrainingFinished = false;
    srcImg: string;
    iconColor: string;
    feedbackForUser: string;
    training: TherapyWithTherapySessions;
    private loggedInUser: User;

    constructor(
        private modalCtrl: ModalController,
        private params: NavParams,
        private translateService: TranslateService,
        private userExerciseSessionsService: UserExerciseSessionsService,
        private usersTherapySessionsService: UsersTherapySessionsService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.training = this.params.get('training');
        this.loggedInUser = this.params.get('user');
        this.title = this.training.title;
        this.therapySession = this.training.runningTherapySession.items[0];
        this.therapySession.exerciseSessionsOfUser.sort((a, b) => {
            if (a?.exerciseSession?.therapyExercise?.order < b?.exerciseSession?.therapyExercise?.order) {
                return -1;
            } else if (a?.exerciseSession?.therapyExercise?.order > b?.exerciseSession?.therapyExercise?.order) {
                return 1;
            }
            return 0;
        });
        for (const exerciseSessionOfUser of this.therapySession.exerciseSessionsOfUser) {
            exerciseSessionOfUser.exerciseSession.exercise.contents =
                exerciseSessionOfUser.exerciseSession.exercise.contents.filter(
                    (i) => i.jsonData?.contentMetaDataType !== ContentMetaDataType.THUMBNAIL,
                );

            exerciseSessionOfUser.exerciseSession.exercise.exerciseParameters = '';
            if (exerciseSessionOfUser.exerciseGoal.series) {
                exerciseSessionOfUser.exerciseSession.exercise.exerciseParameters = `${exerciseSessionOfUser.exerciseSession.exercise.exerciseParameters} ${exerciseSessionOfUser.exerciseGoal.series} x`;
            }
            if (exerciseSessionOfUser.exerciseGoal.duration) {
                exerciseSessionOfUser.exerciseSession.exercise.exerciseParameters = `${exerciseSessionOfUser.exerciseSession.exercise.exerciseParameters} ${exerciseSessionOfUser.exerciseGoal.duration}`;
            }
            if (exerciseSessionOfUser.exerciseGoal.durationUnit) {
                this.translateService.get(exerciseSessionOfUser.exerciseGoal.durationUnit).subscribe((value) => {
                    exerciseSessionOfUser.exerciseSession.exercise.exerciseParameters = `${exerciseSessionOfUser.exerciseSession.exercise.exerciseParameters} ${value}`;
                });
            }

            // TODO : add other exerciseGoal informations
        }
    }

    dismissModal(): void {
        this.modalCtrl.dismiss();
    }

    async startExercise(): Promise<void> {
        const currentExercise = this.therapySession.exerciseSessionsOfUser[this.exerciseIndex];
        const modal = await this.modalCtrl.create({
            component: KioskTrainingVideoModalComponent,
            cssClass: 'full-screen-modal',
            componentProps: {
                exercise: currentExercise,
                training: this.training,
                exerciseIndex: this.exerciseIndex,
                therapySession: this.therapySession,
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data.action === 'nextExercise') {
            if (
                this.therapySession.exerciseSessionsOfUser[this.exerciseIndex].exerciseSessionUserState ===
                    ExerciseSessionUserState.PLANNED ||
                this.therapySession.exerciseSessionsOfUser[this.exerciseIndex].exerciseSessionUserState ===
                    ExerciseSessionUserState.ACTIVE
            ) {
                await this.userExerciseSessionsService.postUsersExerciseSessionActivate(
                    this.loggedInUser.username,
                    Number(this.therapySession.exerciseSessionsOfUser[this.exerciseIndex].exerciseSession_id),
                );
                this.therapySession.exerciseSessionsOfUser[this.exerciseIndex].exerciseSessionUserState =
                    ExerciseSessionUserState.ACTIVE;
            }
            if (
                this.therapySession.exerciseSessionsOfUser[this.exerciseIndex].exerciseSession.exerciseSessionState ===
                ExerciseSessionState.ACTIVE
            ) {
                if (data.feedBack !== null && data.feedBack !== undefined) {
                    const feedbackResponse = await this.userExerciseSessionsService.postUsersExerciseSessionFeedback(
                        this.loggedInUser.username,
                        Number(this.therapySession.exerciseSessionsOfUser[this.exerciseIndex].exerciseSession_id),
                        data.feedBack,
                    );
                    this.therapySession.exerciseSessionsOfUser[this.exerciseIndex].feedback = feedbackResponse;
                }
                await this.userExerciseSessionsService.postUsersExerciseSessionFinish(
                    this.loggedInUser.username,
                    Number(this.therapySession.exerciseSessionsOfUser[this.exerciseIndex].exerciseSession_id),
                );
                this.therapySession.exerciseSessionsOfUser[this.exerciseIndex].exerciseSessionUserState =
                    ExerciseSessionUserState.FINISHED;
            }

            if (this.exerciseIndex === this.training.exercises.length - 1) {
                this.setConclusionPictureAndText();
            } else {
                this.changeExerciseIndexValue();
            }
        } else if (data.action === 'cancelExercise') {
            if (this.exerciseIndex <= this.training.exercises.length - 1) {
                if (this.exerciseIndex === this.training.exercises.length - 1) {
                    this.setConclusionPictureAndText();
                }
                return this.changeExerciseIndexValue();
            }
            this.isTrainingFinished = true;
        }
    }

    changeExerciseIndexValue(): void {
        if (this.exerciseIndex < this.training.exercises.length - 1) this.exerciseIndex++;
    }

    async nextExercise(): Promise<void> {
        const buttonsModalDefinition = new ButtonsModalDefinition();
        buttonsModalDefinition.title = 'KIOSK.TRAINING.EXERCISE_ACTION_TITLE';
        buttonsModalDefinition.actionElements = [];

        let button = new KioskActionButton();
        button.title = 'KIOSK.TRAINING.CONTINUE_EXERCISE';
        button.description = 'KIOSK.TRAINING.CONTINUE_EXERCISE_DESCRIPTION';
        button.icon = 'checkmark-circle';
        button.iconColor = 'success';
        button.action = 'back';
        buttonsModalDefinition.actionElements.push(button);

        button = new KioskActionButton();
        button.title = 'KIOSK.TRAINING.LEAVE_EXERCISE';
        button.description = 'KIOSK.TRAINING.LEAVE_EXERCISE_DESCRIPTION';
        button.icon = 'close-circle';
        button.iconColor = 'danger';
        button.action = 'nextExercise';
        buttonsModalDefinition.actionElements.push(button);

        const modal = await this.modalCtrl.create({
            component: KioskActionElementModalComponent,
            cssClass: 'kiosk-modal',
            backdropDismiss: false,
            componentProps: {
                buttonsModalDefinition: buttonsModalDefinition,
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data.action === 'nextExercise') {
            if (this.exerciseIndex === this.training.exercises.length - 1) {
                this.setConclusionPictureAndText();
            } else {
                return this.changeExerciseIndexValue();
            }
        }
    }

    async finishedTraining(): Promise<void> {
        if (
            this.therapySession.exerciseSessionsOfUser.filter(
                (i) => i.exerciseSessionUserState === ExerciseSessionUserState.FINISHED,
            ).length !== this.therapySession.exerciseSessionsOfUser.length
        ) {
            await this.usersTherapySessionsService.setTherapySessionStatusToComplete(
                this.loggedInUser.username,
                this.therapySession.uuid,
            );
        }
        this.dismissModal();
    }

    setConclusionPictureAndText(): void {
        this.iconColor = 'medium';
        if (
            this.therapySession.exerciseSessionsOfUser.filter(
                (i) => i.exerciseSessionUserState === ExerciseSessionUserState.PLANNED,
            ).length === this.therapySession.exerciseSessionsOfUser.length
        ) {
            this.title = this.translateService.instant('KIOSK.TRAINING.CONCLUSION.FINISHED_NONE_EXERCISES');
            this.srcImg = 'assets/imgs/man-running.png';
            this.isTrainingFinished = true;
            this.feedbackForUser = this.translateService.instant('KIOSK.TRAINING.CONCLUSION.REINRORCED_FEEDBACK');
            return;
        } else {
            if (
                this.therapySession.exerciseSessionsOfUser.filter(
                    (i) => i.exerciseSessionUserState === ExerciseSessionUserState.FINISHED,
                ).length === this.therapySession.exerciseSessionsOfUser.length
            ) {
                this.title = this.translateService.instant('KIOSK.TRAINING.CONCLUSION.FINISHED_ALL_EXERCISES');
            } else {
                this.title = this.translateService.instant('KIOSK.TRAINING.CONCLUSION.FINISHED_SOME_EXERCISES', {
                    exercises: this.therapySession.exerciseSessionsOfUser.filter(
                        (i) => i.exerciseSessionUserState === ExerciseSessionUserState.FINISHED,
                    ).length,
                    allExercises: this.therapySession.exerciseSessionsOfUser.length,
                });
            }
            this.srcImg = 'assets/imgs/training-man.png';
            this.feedbackForUser = this.translateService.instant('KIOSK.TRAINING.CONCLUSION.POSITIVE_FEEDBACK');
            const redFlag = this.therapySession.exerciseSessionsOfUser.find(
                (exercise) => exercise?.feedback?.borgFlag === FeedbackFlag.RED,
            );
            if (redFlag) {
                this.srcImg = 'assets/imgs/man-exhausted.png';
                this.iconColor = 'danger';
                this.feedbackForUser = this.translateService.instant('KIOSK.TRAINING.CONCLUSION.ALERT_FEEDBACK');
            }
            this.isTrainingFinished = true;
            return;
        }
    }
}
