import { Component, Input, OnInit } from '@angular/core';
import {
    ExerciseSession,
    ExerciseSessionOfUser,
    ExerciseSessionOfUserSortProperties,
    ExerciseSessionState,
    ExerciseType,
} from '../../../../entities/exerciseSession';
import { User, UserRoles } from '../../../../../auth/entities/user';
import { ActionEmitter, ItemType, TableConfig, TableUpdateValue } from '../../../../../table/entities/table';
import { PaginatedResponse, SortOrder } from '../../../../../common/entities/paginated-response';
import { ToastService } from '../../../../../common/services/toast-service/toast-service.service';
import { ExerciseSessionsService } from '../../../../services/exercise-sessions';
import { IonicColor } from '../../../../../common/entities/toast/ionic-color';
import { UserExerciseSessionsService } from '../../../../services/user-exercise-sessions';
import { ModalController } from '@ionic/angular';
import { StyleService } from '../../../../../common/services/style/style.service';
import { CurafidaAuthService } from '../../../../../auth/services';
import { CurafidaSegmentItem } from '../../../../../common/entities/curafida-segment.item';
import { SegmentType } from '../../../../../common/entities/segment.type';
import { TherapyXApiService } from '../../../../services/therapy-xapi/therapy-x-api.service';
import { XapiFinishedLessonsDto } from '../../../../entities/xapi/xapi-finished-lessons.dto';
import { Logger, LoggingService } from '../../../../../logging/logging.service';
import { StringItemAdapterComponent } from '../../../../../table/components/table-adapter/string-item-adapter.component';
import { PatientLearningDetailComponent } from '../patient-learning-detail/patient-learning-detail.component';
import { StarProcessingAdapterComponent } from '../../../../../table/components/table-adapter/star-processing-adapter.component';
import { IconAdapterComponent } from '../../../../../table/components/table-adapter/icon-adapter.component';
import { format, isFuture, parseISO } from 'date-fns';
import { TranslateService } from '@ngx-translate/core';

interface PatientLearningListItem extends ExerciseSession {
    dueDate: Date | string;
    startDate: Date | string;
    finished_at: Date | string;
    iconName?: string;
    iconColor?: string;
    dashedCardBorder?: boolean;
    state: string;
    checkBoxActive: boolean;
    checkBoxDisabled: boolean;
    starValue: {
        value: number;
        max: number;
    };
}

@Component({
    selector: 'lib-patient-learning-list',
    templateUrl: './patient-learning-list.component.html',
    styleUrls: ['./patient-learning-list.component.scss'],
})
export class PatientLearningListComponent implements OnInit {
    isLoading = true;
    isLoadingSuccess = false;

    @Input() user: User;
    searchTerm: string;
    patientLearningTableConfig: TableConfig<PatientLearningListItem[]> = new TableConfig();
    isMobile = false;
    loggedInUser: User;
    userRoles: UserRoles[] = [];
    UserRoles = UserRoles;
    segmentListLearning: CurafidaSegmentItem<SegmentType>[] = [];
    segmentType = SegmentType;
    limit = 10;
    displayedSegmentType = SegmentType.PLANNED;
    finishedLearningStates = [ExerciseSessionState.FINISHED];
    cancelLearningStates = [ExerciseSessionState.CANCELLED, ExerciseSessionState.PATIENT_CANCELLED];
    activeLearningStates = [ExerciseSessionState.ACTIVE];
    plannedLearningStates = [ExerciseSessionState.NOT_PLANNED, ExerciseSessionState.PLANNED];
    ExerciseType = ExerciseType;
    protected readonly log: Logger;
    private offset = 0;

    constructor(
        private exerciseSessionService: ExerciseSessionsService,
        private toastService: ToastService,
        private userExerciseSessionsService: UserExerciseSessionsService,
        private styleService: StyleService,
        private modalCtrl: ModalController,
        private authService: CurafidaAuthService,
        private therapyXApiService: TherapyXApiService,
        private loggingService: LoggingService,
        private translateService: TranslateService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
        this.isMobile = this.styleService.isMobile$;
        this.initComponent();
    }

    static mapState(state: ExerciseSessionState): string {
        switch (state) {
            case ExerciseSessionState.NOT_PLANNED:
            case ExerciseSessionState.PLANNED:
            case ExerciseSessionState.ACTIVE:
            case ExerciseSessionState.DELAYED:
                return 'OPEN';
            case ExerciseSessionState.PATIENT_CANCELLED:
            case ExerciseSessionState.CANCELLED:
                return 'DECLINED';
            case ExerciseSessionState.NO_SHOW:
                return 'EXPIRED';
            default:
                return state;
        }
    }

    async ngOnInit() {
        this.loggedInUser = this.authService.getSession()?.user;
        await this.initTableConfig();
        await this.setLearningSegmentType(this.displayedSegmentType);
    }

    async initTableConfig() {
        if (this.loggedInUser.username === this.user.username) {
            this.patientLearningTableConfig.itemSettings = [
                {
                    id: 'icon',
                    prop: 'iconName',
                    header: '',
                    type: ItemType.ADAPTER,
                    adapter: IconAdapterComponent,
                    color: 'iconColor',
                    width: '8%',
                    sortOrderMobile: 2,
                },
                {
                    id: 'dueDate',
                    prop: 'dueDate',
                    header: 'DUE_DATE',
                    type: ItemType.ADAPTER,
                    adapter: StringItemAdapterComponent,
                    width: '15%',
                    sortOrderWeb: 0,
                    sortOrderMobile: 2,
                    showColNameOnMobile: true,
                },
                {
                    id: 'title',
                    prop: 'title',
                    header: 'LEARNING',
                    type: ItemType.ADAPTER,
                    adapter: StringItemAdapterComponent,
                    width: '60%',
                    sortOrderWeb: 1,
                    sortOrderMobile: 1,
                    isMobileBold: true,
                },
                {
                    id: 'Fortschritt',
                    prop: 'starValue',
                    header: '',
                    type: ItemType.ADAPTER,
                    adapter: StarProcessingAdapterComponent,
                    width: '25%',
                    sortOrderWeb: 1,
                    sortOrderMobile: 0,
                },
                {
                    id: 'mobileCardBorder',
                    prop: 'dashedCardBorder',
                    header: '',
                },
            ];
        } else {
            this.patientLearningTableConfig.itemSettings = [
                {
                    id: 'dueDate',
                    prop: 'dueDate',
                    header: 'DUE_DATE',
                    type: ItemType.ADAPTER,
                    adapter: StringItemAdapterComponent,
                    width: '15%',
                    sortOrderWeb: 0,
                    sortOrderMobile: 1,
                    showColNameOnMobile: true,
                },
                {
                    id: 'responsibleFullName',
                    prop: 'responsibleFullName',
                    header: 'RESPONSIBLE_FOR',
                    type: ItemType.ADAPTER,
                    adapter: StringItemAdapterComponent,
                    width: '24%',
                    sortOrderWeb: 1,
                },
                {
                    id: 'title',
                    prop: 'title',
                    header: 'LEARNING',
                    type: ItemType.ADAPTER,
                    adapter: StringItemAdapterComponent,
                    width: '36%',
                    sortOrderWeb: 2,
                    sortOrderMobile: 0,
                    isMobileBold: true,
                },
                {
                    id: '',
                    prop: 'starValue',
                    header: '',
                    type: ItemType.ADAPTER,
                    adapter: StarProcessingAdapterComponent,
                    width: '22%',
                    sortOrderWeb: 3,
                    sortOrderMobile: 0,
                },
            ];
        }
        this.patientLearningTableConfig.isOpenDetailEnable = true;
    }

    initTabs() {
        this.segmentListLearning = [
            new CurafidaSegmentItem({
                name: this.translateService.instant('PLANNED_ADJECTIVE'),
                icon: 'time-outline',
                value: SegmentType.PLANNED,
            }),
            new CurafidaSegmentItem({
                name: this.translateService.instant('ACTIVATED'),
                icon: 'alert-circle-outline',
                value: SegmentType.ACTIVE,
            }),
            new CurafidaSegmentItem({
                name: this.translateService.instant('CLOSED'),
                icon: 'checkmark-circle-outline',
                value: SegmentType.INACTIVE,
            }),
            new CurafidaSegmentItem({
                name: this.translateService.instant('CANCELLED'),
                icon: 'close-circle-outline',
                value: SegmentType.CANCELLED,
            }),
            new CurafidaSegmentItem({
                name: this.translateService.instant('EXPIRED'),
                icon: 'remove-circle-outline',
                value: SegmentType.EXPIRED,
            }),
        ];
        if (this.userRoles.includes(UserRoles.read_therapy)) {
            this.segmentListLearning.push(
                new CurafidaSegmentItem({
                    name: this.translateService.instant('LEARNING.PLAN.PLURAL'),
                    icon: 'clipboard-outline',
                    value: SegmentType.PLAN,
                }),
            );
        }
    }

    async openDetailModal(actionEmitter: ActionEmitter<PatientLearningListItem>) {
        let learningTask;
        if (actionEmitter?.item) {
            learningTask = await this.userExerciseSessionsService.getExerciseSessionOfUser(
                this.user.username,
                Number(actionEmitter.item.id),
            );
        }
        const modal = await this.modalCtrl.create({
            component: PatientLearningDetailComponent,
            cssClass: 'full-width-modal',
            componentProps: {
                learningResource: learningTask ? learningTask : undefined,
                isNew: !actionEmitter?.item,
                isMobile: this.isMobile,
                patient: this.user,
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data) {
            await this.getPatientLearningList({ offset: this.offset, limit: this.limit }, this.searchTerm);
        }
    }

    async getPatientLearningList(value: TableUpdateValue, searchTerm: string) {
        this.isLoading = true;
        this.isLoadingSuccess = false;

        let learningStates;
        let userlearningStates = null;
        if (this.displayedSegmentType === SegmentType.ACTIVE) {
            learningStates = this.activeLearningStates;
        } else if (this.displayedSegmentType === SegmentType.PLANNED) {
            learningStates = this.plannedLearningStates;
        } else if (this.displayedSegmentType === SegmentType.CANCELLED) {
            learningStates = this.cancelLearningStates;
        } else if (this.displayedSegmentType === SegmentType.INACTIVE) {
            learningStates = this.finishedLearningStates;
            userlearningStates = this.finishedLearningStates;
        } else if (this.displayedSegmentType === SegmentType.EXPIRED) {
            learningStates = this.finishedLearningStates;
            userlearningStates = [ExerciseSessionState.NO_SHOW];
        } else if (this.displayedSegmentType === SegmentType.PLAN) {
            this.isLoading = false;
            this.isLoadingSuccess = true;
            return;
        }

        this.offset = value.offset;
        this.searchTerm = searchTerm;
        try {
            const responsibleUserRole = this.user.username === this.loggedInUser.username ? UserRoles.PATIENT : null;
            // this.patientLearningTableConfig.list.items = [];
            const userExerciseSessionsResult = new PaginatedResponse<PatientLearningListItem[]>();
            userExerciseSessionsResult.items = [];
            const userExerciseSessions = (await this.userExerciseSessionsService.getExerciseSessionsOfUserPaginated(
                this.user.username,
                {
                    exerciseSessionStates: learningStates,
                    exerciseSessionUserStates: userlearningStates,
                    exerciseType: ExerciseType.LEARNING,
                    responsibleUserRole: responsibleUserRole,
                    sortBy: ExerciseSessionOfUserSortProperties.DELAYED_TIME,
                    sortOrder: SortOrder.ASC,
                    limit: this.limit,
                    offset: value.offset * this.limit,
                    includeExerciseSessionStateChanges: true,
                    filter: searchTerm,
                },
            )) as PaginatedResponse<ExerciseSessionOfUser[]>;
            for (const userLearning of userExerciseSessions.items) {
                const listItem = userLearning.exerciseSession as PatientLearningListItem;
                let courseInfo;
                let resultCourseInfo: XapiFinishedLessonsDto;
                Promise.all([
                    this.therapyXApiService
                        .getCourseInfo(userLearning.username, userLearning.exerciseSession.id)
                        .then((course) => {
                            courseInfo = course;
                        }),
                    this.therapyXApiService
                        .getFinishedLessions(userLearning.username, listItem.id)
                        .then((finishedLessonsDto) => {
                            resultCourseInfo = finishedLessonsDto;
                        }),
                ]).then(() => {
                    if (resultCourseInfo && courseInfo) {
                        listItem.starValue = {
                            value: resultCourseInfo.numFinishedLessons,
                            max: resultCourseInfo.numTotalLessons,
                        };
                    }
                });

                /*
                 * This condition used to exist to only show the lock and alert icons for the tasks the loggedInUser is responsible for
                 * Also the TableConfig had to be adjusted, because there's 2 different cases and one didn't include the icon slot
                 * if (this.user.username === this.loggedInUser.username)
                 */
                this.setListItemStyle(listItem);

                listItem.startDate = listItem.appointment?.startTime;
                const tableConfigDueDate = this.patientLearningTableConfig.itemSettings.find(
                    (tc) => tc.prop === 'dueDate',
                );
                listItem.dueDate = listItem.appointment?.delayedTime;
                tableConfigDueDate.header = this.translateService.instant('DUE_DATE');
                if (
                    userLearning.exerciseSession.exerciseSessionState === ExerciseSessionState.FINISHED &&
                    this.displayedSegmentType === SegmentType.INACTIVE &&
                    userLearning.exerciseSession?.stateChanges
                ) {
                    const finishedStateChange = userLearning.exerciseSession.stateChanges.find(
                        (sc) => sc.newState === ExerciseSessionState.FINISHED,
                    );
                    listItem.dueDate = finishedStateChange?.created_at;
                    tableConfigDueDate.header = this.translateService.instant('CLOSED_AT');
                }
                listItem.state = PatientLearningListComponent.mapState(listItem.exerciseSessionState);
                // @ts-ignore
                listItem.checkBoxActive = listItem.state !== 'OPEN';
                // @ts-ignore
                listItem.checkBoxDisabled = true;
                if (userLearning.exerciseSession.responsibleUserRole === UserRoles.PATIENT) {
                    listItem.responsibleFullName = `${this.user.lastname}, ${this.user.firstname}`;
                }
                if (listItem.dueDate) {
                    listItem.dueDate = format(parseISO(listItem.dueDate.toString()), 'dd.MM.yyyy \n HH:mm') + ' Uhr';
                }
                userExerciseSessionsResult.items.push(listItem);
            }

            // TODO: replace with backend sorting
            if (this.displayedSegmentType === SegmentType.INACTIVE) {
                userExerciseSessionsResult.items = userExerciseSessionsResult.items.sort((a, b) => {
                    if (new Date(a.finished_at).getTime() > new Date(b.finished_at).getTime()) {
                        return -1;
                    } else if (new Date(a.finished_at).getTime() < new Date(b.finished_at).getTime()) {
                        return 1;
                    }
                    return 0;
                });
            }

            userExerciseSessionsResult.limit = userExerciseSessions.limit;
            userExerciseSessionsResult.count = userExerciseSessions.count;
            userExerciseSessionsResult.total = userExerciseSessions.total;
            userExerciseSessionsResult.offset = userExerciseSessions.offset;
            this.patientLearningTableConfig.list = userExerciseSessionsResult;
            this.isLoadingSuccess = true;
            this.isLoading = false;
        } catch (e) {
            this.isLoadingSuccess = false;
            this.log.error('Error in getPatientLearningList', e);
            await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
            this.patientLearningTableConfig.list = new PaginatedResponse<PatientLearningListItem[]>();
            this.isLoading = false;
        }
    }

    setListItemStyle(listItem: PatientLearningListItem) {
        // Set the lock icon for learning where the processing time period has not yet started
        if (
            this.displayedSegmentType === SegmentType.PLANNED &&
            listItem.appointment?.delayedTime && // Check that the period of time in which the learning can be processed has not yet started
            isFuture(parseISO(listItem.appointment?.startTime))
        ) {
            listItem.iconName = 'lock-closed-outline';
            listItem.iconColor = 'dark';
            listItem.dashedCardBorder = true;
        }
    }

    async setLearningSegmentType(event: SegmentType) {
        this.displayedSegmentType = event;
        this.limit = 10;
        await this.getPatientLearningList({ offset: 0, limit: this.limit }, '');
    }

    async changeLearningStatus(learning: PatientLearningListItem) {
        if (this.activeLearningStates.includes(learning.exerciseSessionState)) {
            try {
                if (learning.exerciseSessionState === ExerciseSessionState.NOT_PLANNED) {
                    await this.exerciseSessionService.postExerciseSessionConfirmPlanning(learning.id);
                }
                if (learning.exerciseSessionState === ExerciseSessionState.PLANNED) {
                    await this.userExerciseSessionsService.postUsersExerciseSessionActivate(
                        this.user.username,
                        learning.id,
                    );
                }
                await this.userExerciseSessionsService.postUsersExerciseSessionFinish(this.user.username, learning.id);
                learning.exerciseSessionState = ExerciseSessionState.FINISHED;
                learning.checkBoxActive = learning.checkBoxDisabled = true;
                await this.getPatientLearningList({ offset: 0 }, this.searchTerm);
                await this.toastService.showToast(
                    this.translateService.instant('FINISH_TASK_SUCCESS'),
                    IonicColor.success,
                );
            } catch (e) {
                this.log.error('Error in changeLearningStatus', e);
                await this.toastService.showToast(
                    this.translateService.instant('FINISH_TASK_FAILURE'),
                    IonicColor.danger,
                );
            }
        }
    }

    openDetailPageForMobile(patientLearningListItem: PatientLearningListItem) {
        const item = new ActionEmitter<PatientLearningListItem>();
        item.item = patientLearningListItem;
        this.openDetailModal(item);
    }

    private async initComponent() {
        this.userRoles = (await this.authService.getRoles()) as UserRoles[];
        this.initTabs();
    }
}
