import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../api';
import { ExerciseTool } from '../entities/exerciseSession/exercise-tool.dto';
import { ExerciseSubType, ExerciseType, PresenceType } from '../entities/exerciseSession';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectInputItem } from '../../common/components/curafida-input/curafida-select-input/curafida-select-input.component';

export interface ExerciseToolModel {
    all: ExerciseTool[];
    selected: ExerciseTool;
}

@Injectable({
    providedIn: 'root',
})
export class ExerciseToolService {
    readonly model$ = new BehaviorSubject<ExerciseToolModel>({ all: [], selected: undefined });

    readonly options$ = this.model$.pipe(map((model) => model.all.map((it) => new SelectInputItem(it.name, it.name))));

    readonly selected$ = this.model$.pipe(map((model) => model.selected));

    get currentSelected() {
        return this.model$.value.selected;
    }

    constructor(protected http: HttpClient) {}

    loadVideoTools(args: {
        exerciseType?: ExerciseType;
        exerciseSubType?: ExerciseSubType;
        presenceType?: PresenceType;
    }): void {
        const url = new URL(`${ApiService.url}exerciseTools`);
        if (args.exerciseSubType) url.searchParams.append('exerciseSubType', args.exerciseSubType);
        if (args.exerciseType) url.searchParams.append('exerciseTypes', args.exerciseType);
        if (args.presenceType) url.searchParams.append('presenceTypes', args.presenceType);
        this.http.get<ExerciseTool[]>(url.toString(), ApiService.options).subscribe((result) =>
            this.model$.next({
                all: result,
                selected: undefined,
            }),
        );
    }

    select(value: string) {
        const current = this.model$.value;
        this.model$.next({ ...current, selected: current.all.find((it) => it.name === value) });
    }
}
