import { Component, OnInit, ViewChild } from '@angular/core';
import { CurafidaSegmentItem } from '../../../../common/entities/curafida-segment.item';
import { SegmentType } from '../../../../common/entities/segment.type';
import { ActionType, ButtonItemAdapterComponent, ItemType, TableConfig } from '../../../../table/entities/table';
import { ExerciseType } from '../../../entities/exerciseSession';
import { StringItemAdapterComponent } from '../../../../table/components/table-adapter/string-item-adapter.component';
import { ExerciseCatalog } from '../../../entities/exercise';
import { ExerciseCatalogComponent } from '../../training/exercise-catalog/exercise-catalog.component';
import { TherapyTemplateListComponent } from '../../therapy-template-list/therapy-template-list.component';
import { TagsAdapterComponent } from '../../table-adapter/tags-adapter.component';

@Component({
    selector: 'lib-task-template-overview',
    templateUrl: './task-template-overview.component.html',
    styleUrls: ['./task-template-overview.component.scss'],
})
export class TaskTemplateOverviewComponent implements OnInit {
    @ViewChild('exerciseCatalogComponent') exerciseCatalogComponent: ExerciseCatalogComponent<ExerciseCatalog[]>;
    @ViewChild('therapyTemplateListComponent') therapyTemplateListComponent: TherapyTemplateListComponent;
    segmentType = SegmentType.TEMPLATE;
    SegmentType = SegmentType;
    ExerciseType = ExerciseType;
    segmentTrainingAdministrationPage: CurafidaSegmentItem<SegmentType>[] = [];
    exerciseListConfig: TableConfig<ExerciseCatalog[]> = new TableConfig();

    constructor() {
        this.exerciseListConfig.itemSettings = [
            {
                id: 'title',
                prop: 'title',
                header: 'Name',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '20%',
                sortOrderWeb: 0,
            },
            {
                id: 'description',
                prop: 'description',
                header: 'Beschreibung',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '27%',
                sortOrderWeb: 1,
            },
            {
                id: 'tag',
                prop: 'tags',
                header: 'Tags',
                type: ItemType.ADAPTER,
                adapter: TagsAdapterComponent,
                width: '15%',
                sortOrderWeb: 1,
            },
            {
                id: 'type',
                prop: 'exerciseSubType',
                header: 'Typ',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '15%',
                sortOrderWeb: 2,
            },
            {
                id: 'responsibleUserRole',
                prop: 'responsibleUserRole',
                header: 'Zuständigkeit',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '15%',
                sortOrderWeb: 3,
            },
            {
                id: 'action_open',
                prop: 'id',
                header: '',
                type: ItemType.ADAPTER,
                adapter: ButtonItemAdapterComponent,
                icon: 'open-outline',
                actionType: ActionType.OPEN_NEW_PAGE,
                width: '8%',
                sortOrderWeb: 4,
            },
        ];
        this.exerciseListConfig.isOpenDetailEnable = true;
    }

    async ngOnInit() {
        this.initTabs();
        if (this.exerciseCatalogComponent) await this.exerciseCatalogComponent.ngOnInit();
        if (this.therapyTemplateListComponent) await this.therapyTemplateListComponent.ngOnInit();
    }

    initTabs() {
        this.segmentTrainingAdministrationPage.push(
            new CurafidaSegmentItem({ name: 'Aufgabenvorlagen', value: SegmentType.EXERCISE }),
        );
        this.segmentTrainingAdministrationPage.push(
            new CurafidaSegmentItem({ name: 'Aufgabenplanvorlagen', value: SegmentType.TEMPLATE }),
        );
        this.segmentTrainingAdministrationPage.push(
            new CurafidaSegmentItem({ name: 'TAG.PLURAL', value: SegmentType.TAGS }),
        );
    }

    async setSegmentType(event: SegmentType) {
        this.segmentType = event;
        if (this.segmentType === SegmentType.EXERCISE) {
            await this.exerciseCatalogComponent.ngOnInit();
        } else if (this.segmentType === SegmentType.TEMPLATE) {
            await this.therapyTemplateListComponent.ngOnInit();
        }
    }
}
