import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { CurafidaInputComponent } from '../../../../common/components/curafida-input/curafida-input';
import { Content } from '../../../entities/content';
import { IXapiCourseInfo } from '../../../entities/xapi/x-api-course-information';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'lib-therapy-learning-input',
    templateUrl: './therapy-learning-content-input.component.html',
    styleUrls: ['./therapy-learning-content-input.component.scss'],
})
export class TherapyLearningContentInputComponent extends CurafidaInputComponent implements OnInit {
    @Input()
    unit: string;

    @Input()
    explanation: string;

    @Input()
    inputType = 'text';

    @Input()
    labelIcon = '';
    @Input()
    labelIconColor = '';
    @Input()
    contents: Content[];
    @Input()
    learningModuleDetail: IXapiCourseInfo;
    @Input()
    finishedLessons: string[] = [];

    constructor(
        @Self()
        @Optional()
        public ngControl: NgControl,
        translateService: TranslateService,
    ) {
        super(ngControl, translateService);
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit();
        if (this.contents) this.formGroup.controls[this.formControlName].setValue('');
    }
}
