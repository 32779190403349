import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Logger, LoggingService } from '../../../logging/logging.service';
import { defaultInputValidator } from '../../validators/curafida-validators';
import { RangeType } from '../../../therapy/entities/training/range.typ';
import { Subscription } from 'rxjs';
import { CommonComponentsModule } from '../common-components.module';

@Component({
    selector: 'lib-range-selector',
    templateUrl: './range-selector.component.html',
    styleUrls: ['./range-selector.component.scss'],
    standalone: true,
    imports: [CommonComponentsModule],
})
export class RangeSelectorComponent implements OnInit, OnDestroy {
    @Input()
    rangeValue: RangeDefinition;
    @Input()
    isVertical = false;
    @Output()
    emitValue: EventEmitter<number> = new EventEmitter<number>();
    SelectorAction = SelectorAction;
    rangeSelectForm: FormGroup;
    pulseValidators: Validators = Validators.compose([
        defaultInputValidator,
        Validators.pattern('^(?:35|3[5-9]|[4-9][0-9]|1[0-9][0-9]|2[0-2][0-9]|230)$'),
    ]);
    seriesValidators: Validators = Validators.compose([
        defaultInputValidator,
        Validators.pattern('^(?:0|[0-9]|[1-9][0-9])$'),
    ]);
    pulseVarianceValidators: Validators = Validators.compose([
        defaultInputValidator,
        Validators.pattern('^(?:0|[0-9]|[1-9][0-9])$'),
    ]);
    durationValidators: Validators = Validators.compose([
        defaultInputValidator,
        Validators.pattern('^(?:0|[1-9]|[1-9][0-9]|[1-9][0-9][0-9]|[1-4][1-9][0-9][0-9]|5000)$'),
    ]);
    intensityValidators: Validators = Validators.compose([
        defaultInputValidator,
        Validators.pattern(
            '^(?:0(?:(\\.|\\,)[0-9])?|[0-9](?:(\\.|\\,)[0-9])?|[0-9][0-9](?:(\\.|\\,)[0-9])?|[0-9][0-9][0-9](?:(\\.|\\,)[0-9])?|[0-4][1-9][0-9][0-9](?:(\\.|\\,)[0-9])?|5000(?:(\\.|\\,)[0-9])?)',
        ),
    ]);
    valueSub: Subscription;
    lowestValueReached: boolean;
    highestValueReached: boolean;
    protected readonly log: Logger;

    constructor(
        private loggingService: LoggingService,
        private formBuilder: FormBuilder,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    ngOnInit(): void {
        if (!this.rangeValue) throw Error('No RangeDefinition given as Input() for RangeSelectorComponent');
        const rangeValue = this.rangeValue.value ? this.rangeValue.value : 0;
        let currentValidator: Validators;
        switch (this.rangeValue.type) {
            case RangeType.DURATION:
                currentValidator = this.durationValidators;
                break;
            case RangeType.INTENSITY:
                currentValidator = this.intensityValidators;
                break;
            case RangeType.PULSE:
                currentValidator = this.pulseValidators;
                break;
            case RangeType.SERIES:
                currentValidator = this.seriesValidators;
                break;
            case RangeType.PULSE_VARIANCE:
                currentValidator = this.pulseVarianceValidators;
                break;
        }
        this.rangeSelectForm = this.formBuilder.group({
            inputValue: new FormControl<string>({ value: rangeValue.toString(), disabled: false }, currentValidator),
        });
        /*
         * The minimum and maximum need to be checked against zero
         * to avoid false positives when they are null or undefined
         */
        if (this.rangeValue.min || this.rangeValue.min === 0) {
            this.rangeSelectForm.controls.inputValue.addValidators(Validators.min(this.rangeValue.min));
        }
        if (this.rangeValue.max || this.rangeValue.max === 0) {
            this.rangeSelectForm.controls.inputValue.addValidators(Validators.max(this.rangeValue.max));
        }
        this.valueSub = this.rangeSelectForm.controls.inputValue.valueChanges.subscribe((newValue) => {
            const valueAsNumber = this.convertToNumber(newValue);
            /* Handle empty strings which get overwritten with zero */
            if (newValue === '') {
                this.rangeSelectForm.controls.inputValue.patchValue('0');
                return;
            }
            /* Handle invalid numbers such as text input which gets overwritten with zero */
            if (!valueAsNumber && valueAsNumber !== 0) {
                this.rangeSelectForm.controls.inputValue.patchValue('0');
                return;
            }
            /*
             * Handle validation against minimum and maximum configurations.
             * These need to be checked against undefined and null
             * to avoid false positives when they are set to explicitly zero
             */
            if (this.rangeValue.min || this.rangeValue.min === 0) {
                if (valueAsNumber < this.rangeValue.min) {
                    this.rangeSelectForm.controls.inputValue.patchValue(this.rangeValue.min.toString());
                    return;
                }
                /* Disable minus button if min is reached */
                this.lowestValueReached = valueAsNumber === this.rangeValue.min;
            }
            if (this.rangeValue.max || this.rangeValue.max === 0) {
                if (valueAsNumber > this.rangeValue.max) {
                    this.rangeSelectForm.controls.inputValue.patchValue(this.rangeValue.max.toString());
                    return;
                }
                /* Disable plus button if max is reached */
                this.highestValueReached = valueAsNumber === this.rangeValue.max;
            }
            this.emitValueToParent();
        });
    }

    private convertToNumber(value: string): number {
        value = value.toString();
        return Number(value.replace(',', '.'));
    }

    private emitValueToParent() {
        if (this.rangeSelectForm.controls.inputValue.invalid) return;
        const formValue = this.convertToNumber(this.rangeSelectForm.controls.inputValue.value);
        this.emitValue.emit(formValue);
    }

    onButtonPress(action: SelectorAction) {
        const formValue = this.convertToNumber(this.rangeSelectForm.controls.inputValue.value);
        /*
         * If the initial (not form value) value for pulse is 0 set form value to minimum and range definition value to 60.
         * Unsure what this is needed for, the parent component should be in charge of setting default values.
         */
        if (this.rangeValue.type === RangeType.PULSE && this.rangeValue.value === 0) {
            this.rangeSelectForm.controls.inputValue.patchValue(this.rangeValue.min.toString());
            this.rangeValue.value = 60;
        }
        if (action === SelectorAction.LOWER) {
            const newValue: string = (formValue - this.rangeValue.range).toString();
            this.rangeSelectForm.controls.inputValue.patchValue(newValue);
        }
        if (action === SelectorAction.UPPER) {
            const newValue: string = (formValue + this.rangeValue.range).toString();
            this.rangeSelectForm.controls.inputValue.patchValue(newValue);
        }
    }

    ngOnDestroy(): void {
        this.valueSub?.unsubscribe();
    }
}

export enum SelectorAction {
    LOWER = 'LOWER',
    UPPER = 'UPPER',
}

export class RangeDefinition {
    value: number;
    range = 1;
    min?: number;
    max?: number;
    unit?: string;
    disabled? = false;
    type?: RangeType;
}
