// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item {
  border: 1px solid var(--ion-color-light);
  margin-bottom: 5px;
  width: 50%;
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./../../libs/user/components/password-change-keycloak/password-change-key-cloak.component.scss"],"names":[],"mappings":"AAAA;EACI,wCAAA;EACA,kBAAA;EACA,UAAA;EACA,iBAAA;AACJ","sourcesContent":["ion-item {\n    border: 1px solid var(--ion-color-light);\n    margin-bottom: 5px;\n    width: 50%;\n    margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
