import { Pipe, PipeTransform } from '@angular/core';
import { TableItem } from '../entities/table';

@Pipe({
    name: 'filterNotes',
})
export class FilterNotesPipe implements PipeTransform {
    transform(itemTable: TableItem[]) {
        if (itemTable) {
            itemTable = itemTable.filter((item) => item.isNotes);
            if (itemTable.length > 0) return itemTable[0];
        }
        return null;
    }
}
