import { Pipe, PipeTransform } from '@angular/core';
import { Content } from '../entities/content';
import { ContentUsage } from '../entities/content/content-usage';

@Pipe({
    name: 'filterLearningSummaryContent',
})
export class FilterLearningSummaryContentPipe implements PipeTransform {
    transform(contents: Content[]): Content[] {
        return contents
            .filter((content) => {
                return content.contentUsage === ContentUsage.ARTICULATE_SUMMARY_CONTENT && content.jsonData?.ordinal;
            })
            .sort((a, b) => {
                if (new Date(a.jsonData.ordinal) < new Date(b.jsonData.ordinal)) {
                    return -1;
                } else if (new Date(a.jsonData.ordinal) > new Date(b.jsonData.ordinal)) {
                    return 1;
                }
                return 0;
            });
    }
}
