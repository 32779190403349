import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ValidatorService } from '../../../../../common/services/validator/validator.service';

@Component({
    selector: 'lib-pulse',
    templateUrl: './pulse.component.html',
    styleUrls: ['./pulse.component.scss'],
})
export class PulseComponent {
    constructor(private formBuilder: FormBuilder) {
        this.numberInputForm = this.formBuilder.group({
            pulse: new FormControl('', ValidatorService.isInteger),
        });
    }
    @Input()
    label: string;

    @Input()
    pulseValue: string;

    @Output()
    changedPulseValue = new EventEmitter<string>();

    @Output()
    validPulseValue = new EventEmitter<boolean>();

    numberInputForm: FormGroup;

    static checkCompleteInput(value: number, valueString: string) {
        if (!value) {
            return valueString.slice(0, valueString.length - 1);
        }
        return valueString;
    }

    emitChangedPulseValue(valueString) {
        const value = Number(valueString);
        this.pulseValue = PulseComponent.checkCompleteInput(value, valueString);
        this.changedPulseValue.emit(this.pulseValue);
        this.validPulseValue.emit(!this.numberInputForm.controls.pulse.valid && this.pulseValue !== undefined);
    }

    checkInputCharacter(event) {
        return /[0-9]/.test(event.key) || event.keyCode === 8;
    }
}
