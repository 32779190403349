import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { defaultInputValidator } from '../../validators/curafida-validators';
import { ErrorMessage, errorMessagesConst } from '../../entities/error-const';

@Component({
    selector: 'text-input-modal',
    templateUrl: './text-input-modal.component.html',
    styleUrls: ['./text-input-modal.component.scss'],
})
export class TextInputModalComponent implements OnInit {
    @Input()
    title = 'Input';
    @Input()
    labelName = 'Text';
    @Input()
    placeholderText: string;
    @Input()
    isMobile: boolean;

    textInputForm: FormGroup;
    formErrors: ErrorMessage[];

    constructor(
        private modalController: ModalController,
        private formBuilder: FormBuilder,
    ) {}

    ngOnInit() {
        this.initTextInputForm();
    }

    initTextInputForm() {
        const inputText = this.placeholderText !== null ? this.placeholderText : '';
        this.textInputForm = this.formBuilder.group({
            inputText: new FormControl({ value: inputText, disabled: false }, [
                Validators.required,
                defaultInputValidator,
            ]),
        });
        this.formErrors = [...errorMessagesConst.filter((i) => i.formType === 'inputText')];
    }

    async dismissModal() {
        await this.modalController.dismiss({ action: 'left' });
    }

    async returnInput() {
        const inputText = this.textInputForm.controls.inputText.value;
        await this.modalController.dismiss({ value: inputText, action: 'right' });
    }

    async emitLeftButton() {
        await this.dismissModal();
    }

    async emitRightButton() {
        await this.returnInput();
    }
}
