import { Component, Input } from '@angular/core';
import { ItemAdapterComponent, TableItem } from '../../entities/table';

@Component({
    template: ` <ion-checkbox [checked]="data[itemSetting.prop]"></ion-checkbox> `,
})
export class CheckBoxItemAdapterComponent implements ItemAdapterComponent {
    @Input() data: any;
    @Input() itemSetting: TableItem;
}
