import { SortOrder } from '../../../common/entities/paginated-response';
import { ExerciseSubType } from '../exerciseSession';

export enum TaskTemplatesSortProperties {
    TITLE = 'TITLE',
    DESCRIPTION = 'DESCRIPTION',
    CREATED_AT = 'CREATED_AT',
    UPDATED_AT = 'UPDATED_AT',
    DURATION = 'DURATION',
    EXERCISE_SESSION_TYPE = 'EXERCISE_SESSION_TYPE',
}

export interface TaskTemplatesSearchParameters {
    textSearchTerm?: string;
    tagUuids?: string[];
    offset?: number;
    limit?: number;
    sortBy?: TaskTemplatesSortProperties;
    sortOrder?: SortOrder;
    exerciseSubType?: ExerciseSubType;
}
