import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconAdapterElement } from '../../../../../table/components/table-adapter/icon-adapter.component';
import { HttpClient } from '@angular/common/http';
import { IconType } from '../../../../../common/entities/icon_type';
import { ApiService } from '../../../../../api';
import { IconFilterItem } from '../../../task/task-overview/task-overview.component';

@Component({
    selector: 'icon-filter-selection',
    templateUrl: './icon-filter-selection.component.html',
    styleUrls: ['./icon-filter-selection.component.scss'],
})
export class IconFilterSelectionComponent {
    readonly IconType = IconType;
    @Output()
    readonly selected$ = new EventEmitter<IconFilterItem[]>();

    constructor(private readonly httpClient: HttpClient) {}

    private _icons: IconAdapterElement[];

    get icons() {
        return this._icons;
    }

    /**
     * Delegate the possible icons.
     */
    @Input()
    set icons(value: IconAdapterElement[]) {
        this._icons = value;
        this.syncSelected();
    }

    private _selected: IconFilterItem[] = [];

    get selected() {
        return this._selected;
    }

    @Input()
    set selected(value: IconFilterItem[]) {
        this._selected = value;
        this.syncSelected();
    }

    /**
     * Delegate a href to fetch the possible icons. Endpoint must return IconAdapterElement[].
     */
    @Input()
    set href(value: string) {
        this.icons = [];
        this.httpClient
            .get<IconAdapterElement[]>(ApiService.url + value, ApiService.options)
            .subscribe((it) => (this.icons = it));
    }

    toggle(icon: IconAdapterElement) {
        if (this.isSelected(icon)) {
            this.selected = this.selected.filter((it) => it.name !== icon.name || it.color !== icon.color);
        } else {
            this.selected.push({ name: icon.name, color: icon.color });
        }
        this.selected$.emit([...this.selected]);
    }

    isSelected(value: IconFilterItem) {
        return this.selected?.some((it) => it.name === value.name && it.color === value.color);
    }

    private syncSelected() {
        if (this.icons?.length > 0) {
            this._selected = this.selected?.filter((it) =>
                this.icons.find((icon) => icon.name === it.name && icon.color === icon.color),
            );
            this.selected$.emit([...this.selected]);
        }
    }
}
