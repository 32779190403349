// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 767px) {
  ion-card {
    width: 85% !important;
    margin-top: 0 !important;
    margin-left: 20px !important;
  }
}
ion-card {
  width: 90% !important;
  margin-top: 0 !important;
  margin-bottom: 10px !important;
}

.timeline-adapter-mobile-width {
  width: 90%;
}

.timeline-button {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  flex: 1 0 0px;
}

.timeline-button-container {
  margin: 0 50px 0 34px;
}

.timeline-button-label {
  line-height: normal;
  white-space: pre-line;
}`, "",{"version":3,"sources":["webpack://./../../libs/common/components/curafida-timeline/curafida-timeline.component.scss"],"names":[],"mappings":"AAAA;EACI;IACI,qBAAA;IACA,wBAAA;IACA,4BAAA;EACN;AACF;AAEA;EACI,qBAAA;EACA,wBAAA;EACA,8BAAA;AAAJ;;AAGA;EACI,UAAA;AAAJ;;AAGA;EACI,2BAAA;EAAA,wBAAA;EAAA,mBAAA;EACA,aAAA;AAAJ;;AAGA;EACI,qBAAA;AAAJ;;AAGA;EACI,mBAAA;EACA,qBAAA;AAAJ","sourcesContent":["@media (max-width: 767px) {\n    ion-card {\n        width: 85% !important;\n        margin-top: 0 !important;\n        margin-left: 20px !important;\n    }\n}\n\nion-card {\n    width: 90% !important;\n    margin-top: 0 !important;\n    margin-bottom: 10px !important;\n}\n\n.timeline-adapter-mobile-width {\n    width: 90%;\n}\n\n.timeline-button {\n    height: fit-content;\n    flex: 1 0 0px;\n}\n\n.timeline-button-container {\n    margin: 0 50px 0 34px;\n}\n\n.timeline-button-label {\n    line-height: normal;\n    white-space: pre-line;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
