import { AfterContentInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ExerciseContentsService } from '../../../therapy/services/exercise-contents';
import { Content } from '../../../therapy/entities/content';
import { ContentFormatType } from '../../../common/entities/content-format-type';
import { Therapy } from '../../../therapy/entities/therapy';
import { TaskResource } from '../../../therapy/components/task/task.resource';

@Component({
    selector: 'lib-kiosk-task-video-modal',
    templateUrl: './kiosk-task-video-modal.component.html',
    styleUrls: ['./kiosk-task-video-modal.component.scss'],
})
export class KioskTaskVideoModalComponent implements OnInit, AfterContentInit {
    @ViewChild('videoplayer') videoplayer: any;
    title: string;
    training: Therapy;
    taskResource: TaskResource;
    content: Content;
    Content = Content;
    exerciseIndex: number;

    constructor(
        private modalCtrl: ModalController,
        private elRef: ElementRef,
        private exerciseContentsService: ExerciseContentsService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.content = this.taskResource.contents[0];
        if (Content.isAudioMimeType(this.content.mimeType) || Content.isVideoMimeType(this.content.mimeType)) {
            this.content.url = await this.exerciseContentsService.getTempDownloadUrl(this.content.uuid);
        } else {
            this.content.url = await this.exerciseContentsService.getObjectURLContentFromUrl(
                this.content.uuid,
                ContentFormatType.MEDIUM_SIZE,
            );
        }
    }

    async dismissModal(): Promise<void> {
        await this.modalCtrl.dismiss({ action: 'finished' });
    }

    ngAfterContentInit(): void {
        setTimeout(() => {
            if (this.videoplayer && this.content.url) {
                this.elRef.nativeElement.querySelector('video').src = this.content.url;
                this.elRef.nativeElement.querySelector('video').type = this.content.mimeType;
                this.elRef.nativeElement.querySelector('video').loop = false;
                this.playVideo();
            }
        }, 1000);
    }

    playVideo(): void {
        this.elRef.nativeElement.querySelector('video').play();
        this.elRef.nativeElement.querySelector('video').addEventListener('ended', async () => {
            await this.modalCtrl.dismiss({ action: 'finished' });
        });
    }
}
