import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
    ProgramArticle,
    ProgramArticleVariant,
    CreateProgramArticleDto,
    CreateProgramArticleVariantDto,
    UpdateProgramArticleDto,
    CreateProgramTemplateArticleVariantDto,
    UpdateProgramTemplateArticleVariantDto,
    UpdateProgramArticleVariantDto,
} from '../../entities/webshop/program-article.entity';
import { ApiService } from '../../../api';

@Injectable({
    providedIn: 'root',
})
export class TherapyWebshopService {
    constructor(private readonly http: HttpClient) {}

    async getArticleOfTherapyTemplate(therapyTemplateId: number): Promise<ProgramArticle> {
        const headers = new HttpHeaders().append('authorization', ApiService.options.headers.get('authorization'));
        return this.http
            .get<ProgramArticle>(`${ApiService.url}webshop/therapy/${therapyTemplateId}/article`, { headers })
            .toPromise();
    }

    async getArticleVariantOfTherapy(therapyId: number): Promise<ProgramArticleVariant> {
        const headers = new HttpHeaders().append('authorization', ApiService.options.headers.get('authorization'));
        return this.http
            .get<ProgramArticleVariant>(`${ApiService.url}webshop/therapy/${therapyId}/articleVariant`, { headers })
            .toPromise();
    }

    async createArticle(createCourseArticleDto: CreateProgramArticleDto): Promise<ProgramArticle> {
        const headers = new HttpHeaders().append('authorization', ApiService.options.headers.get('authorization'));
        const options = { headers, withCredentials: true };
        return this.http
            .post<ProgramArticle>(`${ApiService.url}webshop/therapy/articles`, createCourseArticleDto, options)
            .toPromise();
    }

    async createArticleVariant(
        createCourseArticleVariantDto: CreateProgramArticleVariantDto | CreateProgramTemplateArticleVariantDto,
        articleUuid: string,
    ): Promise<ProgramArticleVariant> {
        const headers = new HttpHeaders().append('authorization', ApiService.options.headers.get('authorization'));
        const options = { headers, withCredentials: true };
        return this.http
            .post<ProgramArticleVariant>(
                `${ApiService.url}webshop/therapy/articles/${articleUuid}/variants`,
                createCourseArticleVariantDto,
                options,
            )
            .toPromise();
    }

    async updateArticleVariant(
        createCourseArticleVariantDto: UpdateProgramTemplateArticleVariantDto | UpdateProgramArticleVariantDto,
        articleUuid: string,
    ): Promise<ProgramArticleVariant> {
        const headers = new HttpHeaders().append('authorization', ApiService.options.headers.get('authorization'));
        const options = { headers, withCredentials: true };
        return this.http
            .put<ProgramArticleVariant>(
                `${ApiService.url}webshop/therapy/articles/${articleUuid}/variants`,
                createCourseArticleVariantDto,
                options,
            )
            .toPromise();
    }

    async updateArticle(saveCourseArticleDto: UpdateProgramArticleDto): Promise<ProgramArticle> {
        const headers = new HttpHeaders().append('authorization', ApiService.options.headers.get('authorization'));
        const options = { headers, withCredentials: true };
        return this.http
            .put<ProgramArticle>(`${ApiService.url}webshop/therapy/articles`, saveCourseArticleDto, options)
            .toPromise();
    }
}
