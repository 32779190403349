// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.task-state-content {
  display: flex;
  align-content: center;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.task-state-icon {
  font-size: 25px;
  margin-right: 3px;
}

.background-primary {
  background: var(--ion-color-primary);
}

.background-danger {
  background: var(--ion-color-danger);
}

.background-success {
  background: var(--ion-color-success);
}

.fill-medium {
  filter: var(--ion-color-medium);
}

.appointment-checkbox {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
}`, "",{"version":3,"sources":["webpack://./../../libs/therapy/components/exercise-session-filter-modal/exercise-session-filter-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,qBAAA;EACA,mBAAA;EACA,0BAAA;EAAA,uBAAA;EAAA,kBAAA;AACJ;;AAEA;EACI,eAAA;EACA,iBAAA;AACJ;;AAEA;EACI,oCAAA;AACJ;;AAEA;EACI,mCAAA;AACJ;;AAEA;EACI,oCAAA;AACJ;;AACA;EACI,+BAAA;AAEJ;;AACA;EACI,aAAA;EACA,sBAAA;EACA,YAAA;EACA,uBAAA;AAEJ","sourcesContent":[".task-state-content {\n    display: flex;\n    align-content: center;\n    align-items: center;\n    width: fit-content;\n}\n\n.task-state-icon {\n    font-size: 25px;\n    margin-right: 3px;\n}\n\n.background-primary {\n    background: var(--ion-color-primary);\n}\n\n.background-danger {\n    background: var(--ion-color-danger);\n}\n\n.background-success {\n    background: var(--ion-color-success);\n}\n.fill-medium {\n    filter: var(--ion-color-medium);\n}\n\n.appointment-checkbox {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    align-items: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
