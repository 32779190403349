import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { Logger } from 'loglevel';
import { CurafidaSession } from '../auth/entities/user/curafida-session';
import { BeforeSessionChangeListener } from '../kc-auth/services/before-session-change-listener';
import { HypermediaResource } from '../hateoas/hateoas.model';
import { SearchParameters } from '../common/entities/search-parameters';
import { Url } from 'url';

@Injectable({
    providedIn: 'root',
})
export class ApiService implements BeforeSessionChangeListener<CurafidaSession> {
    private log: Logger;

    constructor(private httpClient: HttpClient) {}

    async sessionChanged(session: CurafidaSession): Promise<void> {
        if (session) {
            ApiService.setAuthHeader(session.tokenSet.access_token);
            const backendUrlFromAccessToken = this.getBackendUrlFromAccessToken(session?.tokenSet?.access_token);
            if (backendUrlFromAccessToken) {
                session.backendUrl = backendUrlFromAccessToken;
                ApiService.url = backendUrlFromAccessToken;
                ApiService.apiRootResponse = await this.httpClient
                    .get<HypermediaResource>(ApiService.url, ApiService.options)
                    .toPromise();
            }
        } else {
            ApiService.deleteHeader();
        }
    }

    public static options = { headers: new HttpHeaders(), withCredentials: false };

    private static _url: string;

    static get url(): string {
        return this._url;
    }

    private static _apiRootResponse: HypermediaResource;

    static get apiRootResponse(): HypermediaResource {
        return this._apiRootResponse;
    }

    private static set apiRootResponse(value: HypermediaResource) {
        this._apiRootResponse = value;
    }

    private static set url(value: string) {
        if (!value) {
            throw new Error('Given ApiService.url is not valid!');
        }
        if (!value.endsWith('/')) {
            value += '/';
        }
        this._url = value;
    }

    static setAuthHeader(accessToken: string): void {
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', `Bearer ${accessToken}`);
        headers = headers.set('Accept', 'application/json');
        headers = headers.set('content-Type', 'application/json');
        ApiService.options = {
            headers: headers,
            withCredentials: true,
        };
    }

    static deleteHeader(): void {
        ApiService.options = { headers: new HttpHeaders(), withCredentials: false };
    }

    private getBackendUrlFromAccessToken(accessToken: string): string {
        const decoded: any = jwt_decode(accessToken);
        if (!decoded || !decoded.tenant || !decoded.tenant.backend_url) {
            this.log.warn('tenant.backend_url claim is missing in access token');
            return null;
        }
        return decoded.tenant.backend_url;
    }

    static addDefaultSearchParams(url: URL, parameters: SearchParameters) {
        if (parameters.offset > -1) url.searchParams.set('offset', parameters.offset.toString());
        if (parameters.limit > -1) url.searchParams.set('limit', parameters.limit.toString());
        if (parameters.textSearchTerm) url.searchParams.set('textSearchTerm', parameters.textSearchTerm);
        if (parameters.sortOrder) url.searchParams.set('sortOrder', parameters.sortOrder);
        if (parameters.sortBy) url.searchParams.set('sortBy', parameters.sortBy);
        return url;
    }
}
