import { SortOrder } from '../../common/entities/paginated-response';
import { SegmentType } from '../../common/entities/segment.type';
import { TableItem } from './table';

interface paginationOptions {
    offset: number;
    sortBy: string[];
    sortOrder: SortOrder;
    // Für die Korrekte Darstellung der Sortierung im TabellenHeader
    tableColumnId?: string;
}

interface SegmentComposite {
    responsible?: SegmentType;
    segment: SegmentType;
}

export class CurafidaPaginationOptions {
    private _map: Map<string, paginationOptions>;
    private _tableItemSettings: TableItem[];

    constructor(tableItemSettings: TableItem[]) {
        this._map = new Map<string, paginationOptions>();
        this._tableItemSettings = tableItemSettings;
    }

    public get(segment: SegmentComposite) {
        const paginationOptions = this._map.get(`${segment.responsible}_${segment.segment}`);
        this.setTableSortOrder(paginationOptions);

        return paginationOptions;
    }

    public set(segment: SegmentComposite, value: paginationOptions) {
        this._map.set(`${segment.responsible}_${segment.segment}`, value);
    }

    private setTableSortOrder(paginationOptions: paginationOptions) {
        this._tableItemSettings.forEach((itemSetting) => {
            if (paginationOptions.tableColumnId) {
                if (itemSetting.id !== paginationOptions.tableColumnId) {
                    itemSetting.sortOrder = SortOrder.NONE;
                } else {
                    itemSetting.sortOrder = paginationOptions.sortOrder;
                }
            } else {
                if (itemSetting.sortBy !== paginationOptions.sortBy[0]) {
                    itemSetting.sortOrder = SortOrder.NONE;
                } else {
                    itemSetting.sortOrder = paginationOptions.sortOrder;
                }
            }
        });
    }
}
