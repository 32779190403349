import { Component, EventEmitter, Input, Output, Type } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CurafidaAuthService } from '../../../auth/services';
import { Logger, LoggingService } from '../../../logging/logging.service';
import { InfiniteScrollCustomEvent } from '@ionic/angular';
import { TimeLineItemAdapterComponent, TimelineItemTyp, TimelineOutputParams } from './timeline.entity';
import 'rxjs-compat/add/operator/take';
import { CurafidaCommonModule } from '../../curafida-common.module';
import { ToastService } from '../../services/toast-service/toast-service.service';
import { StyleService } from '../../services/style/style.service';
import { CurafidaTimelineDateLabelPipe } from './curafida-timeline-date-label.pipe';
import { TimelineItemIconConfigPipe } from './curafida-timeline-item-icon-config.pipe';
import { addYears, endOfDay, endOfYear, startOfDay, subDays, subYears } from 'date-fns';
import { LazyLoadedList } from '../../lazy-loaded.list';
import { PaginatedResponse, SortOrder } from '../../entities/paginated-response';
import { TimelineIconPathPipe } from './curafida-timeline-icon-path.pipe';
import { TimelineLineDefinitionComponent } from './timeline-item-definition/timeline-line-definition.component';
import { PlanerItemStatePipe } from '../patient-act/care-planer/planer-item-state.pipe';
import { TimelineItemResource } from '../../../journal/entities/planer-item-resource';
import { LoadingComponent } from '../loading/loading';

@Component({
    standalone: true,
    selector: 'curafida-timeline',
    templateUrl: './curafida-timeline.component.html',
    styleUrls: ['./curafida-timeline.component.scss'],
    imports: [
        CurafidaCommonModule,
        TimelineIconPathPipe,
        CurafidaTimelineDateLabelPipe,
        TimelineItemIconConfigPipe,
        TimelineLineDefinitionComponent,
        PlanerItemStatePipe,
        LoadingComponent,
    ],
})
export class CurafidaTimelineComponent<T> {
    readonly today = startOfDay(new Date());
    readonly previousItemsStartDate = startOfDay(subYears(this.today, 5));
    @Input() hasNavigationButton = true;
    @Input() limit: number = 10;
    @Output()
    readonly updateStatics: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output()
    readonly openDetail: EventEmitter<TimelineItemResource> = new EventEmitter<TimelineItemResource>();
    @Output()
    readonly openAdapterDetail: EventEmitter<T> = new EventEmitter<T>();
    @Output()
    readonly updatePreviousItems: EventEmitter<TimelineOutputParams> = new EventEmitter<TimelineOutputParams>();
    @Output()
    readonly updateNextItems: EventEmitter<TimelineOutputParams> = new EventEmitter<TimelineOutputParams>();
    @Output()
    readonly refreshItems: EventEmitter<TimelineOutputParams> = new EventEmitter<TimelineOutputParams>();
    @Output()
    readonly returnToday: EventEmitter<TimelineOutputParams> = new EventEmitter<TimelineOutputParams>();
    readonly previousItemsEndDate = endOfDay(subDays(this.today, 1));
    readonly nextItemsEndDate = endOfYear(addYears(this.today, 5));
    readonly previousList = new LazyLoadedList<T>(this.limit);
    readonly nextList = new LazyLoadedList<T>(this.limit);
    @Input()
    adapter: Type<TimeLineItemAdapterComponent<T>>;
    @Input() scrollUuid: string;
    isMobile: boolean;
    @Input()
    hasCircle = true;
    protected readonly log: Logger;
    protected readonly TimelineItemType = TimelineItemTyp;

    constructor(
        public router: Router,
        protected toastService: ToastService,
        private authService: CurafidaAuthService,
        protected route: ActivatedRoute,
        readonly loggingService: LoggingService,
        public styleService: StyleService,
    ) {
        this.log = loggingService.getLogger(this.constructor.name);
        this.isMobile = this.styleService.isMobile$;
    }

    private _shownPreviousItems = 0;

    get shownPreviousItems(): number {
        return this._shownPreviousItems;
    }

    set shownPreviousItems(value: number) {
        this._shownPreviousItems = value;
    }

    private _isLoading = false;

    get isLoading() {
        return this._isLoading;
    }

    set isLoading(value: boolean) {
        this._isLoading = value;
    }

    private _isLoadingSuccess = false;

    get isLoadingSuccess() {
        return this._isLoadingSuccess;
    }

    set isLoadingSuccess(value: boolean) {
        this._isLoadingSuccess = value;
    }

    get items(): T[] {
        return [...this.previousList.list.items, ...this.nextList.list.items];
    }

    get loggedInUser() {
        return this.authService.getSession().user;
    }

    hasMorePreviousItems() {
        return this.previousList.hasMoreItems();
    }

    hasMoreNextItems() {
        return this.nextList.hasMoreItems();
    }

    hasPreviousItems() {
        return this.previousList.hasItems();
    }

    init(paginatedItems: PaginatedResponse<T[]>) {
        this.isLoading = true;
        this.isLoadingSuccess = false;
        this.nextList.init(paginatedItems);
        this.isLoading = false;
        this.isLoadingSuccess = true;
        if (this.scrollUuid) {
            setTimeout(() => {
                document.getElementById(this.scrollUuid).scrollIntoView({
                    behavior: 'smooth',
                });
            }, 150);
        }
    }

    async fetchPreviousItems(): Promise<void> {
        if (!this.previousList.hasMoreItems()) {
            return;
        }
        this._isLoading = true;
        this._isLoadingSuccess = false;
        const endDate = new Date(endOfDay(subDays(new Date(), 1)));
        this._shownPreviousItems += 4;
        this.updatePreviousItems.emit({
            ...this.previousList.nextParameter(),
            sortOrder: SortOrder.DESC,
            overlapRangeStart: this.previousItemsStartDate.toISOString(),
            overlapRangeEnd: endDate.toISOString(),
        });
    }

    fetchNextItems() {
        if (!this.nextList.hasMoreItems()) {
            return;
        }
        this.updateNextItems.emit({
            ...this.nextList.nextParameter(),
            sortOrder: SortOrder.ASC,
            overlapRangeStart: this.today.toISOString(),
            overlapRangeEnd: this.nextItemsEndDate.toISOString(),
        });
    }

    async loadFutureData(event: InfiniteScrollCustomEvent): Promise<void> {
        this.fetchNextItems();
        await event.target.complete();
    }

    openItemDetail(item: TimelineItemResource, event: MouseEvent): void {
        event.stopPropagation();
        this.openDetail.emit(item);
    }

    openAdapterOutput(item: T) {
        this.openAdapterDetail.emit(item);
    }

    resetPreviousList() {
        this.returnToday.emit();
    }
}
