import {
    Article,
    ArticleTypeEnum,
    ArticleVariant,
    ArticleVariantTypeEnum,
    CreateArticleDto,
    CurrencyEnum,
    DEFAULT_VAT,
    PublishStatusEnum,
} from '../../../common/entities/webshop.model';

export class CreateProgramArticleDto extends CreateArticleDto {
    therapyTemplateId: number;
}

export class UpdateProgramArticleDto extends CreateArticleDto {
    uuid: string;
}

export class UpdateProgramTemplateArticleVariantDto {
    summary: string;
    description: string;
    price: number;
    currency: CurrencyEnum;
    vat: number;
    publishStatus: PublishStatusEnum;
    medicalRequirements: string;
    location: string;
    articleVariantType: ArticleVariantTypeEnum;
    uuid: string;
}

export class UpdateProgramArticleVariantDto extends UpdateProgramTemplateArticleVariantDto {
    seatsMax: number;
    seatsMin: number;
}

export class CreateProgramArticleVariantDto extends CreateArticleDto {
    seatsMax: number;
    seatsMin: number;
    articleUuid: string;
    therapyId: number;
}

export class CreateProgramTemplateArticleVariantDto extends CreateArticleDto {
    articleUuid: string;
}

export class ProgramArticle extends Article {
    articleVariants: ProgramArticleVariant[] = [];
    articleType: ArticleTypeEnum;
    therapyTemplateId?: number;

    // therapyTemplate: TherapyTemplate;

    static createDefault(therapyTemplateId: number): ProgramArticle {
        const programArticle = new ProgramArticle();
        programArticle.therapyTemplateId = therapyTemplateId;
        programArticle.price = 0;
        programArticle.currency = CurrencyEnum.EUR;
        programArticle.vat = DEFAULT_VAT;
        programArticle.publishStatus = PublishStatusEnum.DRAFT;
        programArticle.contents = [];
        return programArticle;
    }
}

export class ProgramArticleVariant extends ArticleVariant {
    article: ProgramArticle;
    articleVariantType: ArticleVariantTypeEnum;

    therapyId: number;
    // therapy: Therapy;
    seatsMax?: number;
    seatsMin?: number;

    get availability(): number {
        return this.seatsAvailable;
    }

    get isAvailable(): boolean {
        return this.seatsAvailable === -1 || this.seatsAvailable > 0;
    }

    static createDefault(therapyId: number, programArticle: ProgramArticle): ProgramArticleVariant {
        const programArticleVariant = new ProgramArticleVariant();
        programArticleVariant.therapyId = therapyId;
        programArticleVariant.summary = programArticle.summary;
        programArticleVariant.description = programArticle.description;
        programArticleVariant.publishStatus = PublishStatusEnum.DRAFT;
        programArticleVariant.price = programArticle.price;
        programArticleVariant.currency = programArticle.currency;
        programArticleVariant.vat = programArticle.vat;
        programArticleVariant.location = programArticle.location;
        programArticleVariant.medicalRequirements = programArticle.medicalRequirements;
        return programArticleVariant;
    }
}
