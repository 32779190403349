import { Component, EventEmitter, Input, OnInit, Output, Type, ViewChild } from '@angular/core';
import { TimeLineItemAdapterComponent } from '../timeline.entity';
import { TimelineItemAdapterHostDirective } from '../timeline-item-adapter-host.directive';

@Component({
    standalone: true,
    selector: 'timeline-line-definition',
    templateUrl: './timeline-line-definition.component.html',
    styleUrls: ['./timeline-line-definition.component.scss'],
    imports: [TimelineItemAdapterHostDirective],
})
export class TimelineLineDefinitionComponent<T> implements OnInit {
    @Input()
    item: T;
    @Input()
    items: T[];
    @Input()
    adapter: Type<TimeLineItemAdapterComponent<T>>;
    @Output()
    actionOutput = new EventEmitter<T>();
    @ViewChild(TimelineItemAdapterHostDirective, { static: true }) itemAdapterHost!: TimelineItemAdapterHostDirective;

    ngOnInit() {
        this.loadComponent();
    }

    loadComponent() {
        if (this.itemAdapterHost) {
            const viewContainerRef = this.itemAdapterHost.viewContainerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent<TimeLineItemAdapterComponent<T>>(this.adapter);
            componentRef.instance.items = this.items;
            componentRef.instance.item = this.item;
            if (componentRef.instance.actionSelected) {
                componentRef.instance.actionSelected.subscribe((value) => {
                    this.actionOutput.emit(value);
                });
            }
        }
    }
}
