import { Component, Input } from '@angular/core';
import { ItemAdapterComponent, TableItem } from '../../entities/table';

@Component({
    template: ` <ion-reorder slot="start"></ion-reorder> `,
})
export class ReorderAdapterComponent implements ItemAdapterComponent {
    @Input() data: { disabled: boolean };
    @Input() itemSetting: TableItem;
}
