import { Pipe, PipeTransform } from '@angular/core';
import { ExerciseSessionParticipant, ExerciseSessionUserState } from '../entities/exerciseSession';

@Pipe({
    name: 'removePatientNoMoreActive',
})
export class RemovePatientNoMoreActivePipe implements PipeTransform {
    transform(exerciseSessionParticipant: ExerciseSessionParticipant[]): ExerciseSessionParticipant[] {
        if (exerciseSessionParticipant) {
            exerciseSessionParticipant = exerciseSessionParticipant.filter(
                (e) => e.state !== ExerciseSessionUserState.REPLACED,
            );
        }
        return exerciseSessionParticipant;
    }
}
