import { ExerciseSessionUserState } from './entities/exerciseSession';
import { ActionInfoText } from '../table/entities/action-menu.item';
import { format } from 'date-fns';
import { ExerciseSessionAppointment } from './entities/appointement';
import { TranslateService } from '@ngx-translate/core';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'stateDetailItems',
})
export class StateDetailItemsPipe implements PipeTransform {
    constructor(private readonly translateService: TranslateService) {}

    transform(
        appointment: ExerciseSessionAppointment,
        typeTranslationKey: string,
        state: ExerciseSessionUserState,
        stateDate: string,
    ): ActionInfoText[] {
        const actionItems: ActionInfoText[] = [];
        const translatedType = this.translateService.instant(typeTranslationKey);

        if (state === ExerciseSessionUserState.FINISHED) {
            actionItems.push(
                new ActionInfoText(
                    this.translateService.instant('STATE_DETAILS.COMPLETED_AT', {
                        translatedType: translatedType,
                        date: format(new Date(stateDate), 'dd.MM.yyyy'),
                    }),
                ),
            );
        }
        if (state === ExerciseSessionUserState.CANCELLED || state === ExerciseSessionUserState.PATIENT_CANCELLED) {
            actionItems.push(
                new ActionInfoText(
                    this.translateService.instant('STATE_DETAILS.CANCELLED_AT', {
                        translatedType: translatedType,
                        date: format(new Date(stateDate), 'dd.MM.yyyy'),
                    }),
                ),
            );
        }
        if (appointment?.startTime) {
            actionItems.push(
                new ActionInfoText(
                    this.translateService.instant('STATE_DETAILS.EARLIEST_START', {
                        date: format(new Date(appointment.startTime), 'dd.MM.yyyy'),
                    }),
                ),
            );
        }
        if (appointment?.delayedTime) {
            actionItems.push(
                new ActionInfoText(
                    this.translateService.instant('STATE_DETAILS.DUE_DATE', {
                        translatedType: translatedType,
                        date: format(new Date(appointment.delayedTime), 'dd.MM.yyyy'),
                    }),
                ),
            );
        }
        if (appointment?.endTime) {
            actionItems.push(
                new ActionInfoText(
                    this.translateService.instant('STATE_DETAILS.LATEST_SUBMISSION_DATE', {
                        date: format(new Date(appointment.endTime), 'dd.MM.yyyy'),
                    }),
                ),
            );
        }
        return actionItems;
    }
}
