// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `hr {
  border: 0;
  border-top: 1px solid gray;
}`, "",{"version":3,"sources":["webpack://./../../libs/therapy/components/training/exercise-content-training-definition/exercise-content-training-definition.component.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,0BAAA;AACJ","sourcesContent":["hr {\n    border: 0;\n    border-top: 1px solid gray;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
