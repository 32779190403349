import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActionType, ItemAdapterComponent, TableItem } from '../../entities/table';

@Component({
    template: `
        <ion-toggle
            [disabled]="itemSetting.disabled || data.deactivateToggle"
            [hidden]="data.isToggleHidden"
            [(ngModel)]="data[itemSetting.prop]"
            (ionChange)="setActionTypeEvent($event)">
        </ion-toggle>
        <div [hidden]="!data.isToggleHidden">{{ itemSetting.label | curafidaTranslate }}</div>
    `,
})
export class ToggleItemAdapterComponent implements ItemAdapterComponent {
    @Input() data: any;
    @Input() itemSetting: TableItem;
    @Output() updateList = new EventEmitter<boolean>();
    @Output() actionSelected = new EventEmitter<{ action: ActionType; mouseEvent: MouseEvent }>();

    setActionTypeEvent(event: MouseEvent) {
        this.actionSelected.emit({ action: this.itemSetting.actionType, mouseEvent: event });
    }
}
