import { Validators } from '@angular/forms';
import { Exercise } from '../entities/exercise';
import { Component, Input } from '@angular/core';
import { defaultInputValidator } from '../../common/validators/curafida-validators';

@Component({
    template: '',
})
export class ExerciseBasePage {
    @Input()
    exercise: Exercise = new Exercise();
    exerciseType: string;
    durationUnit: string;

    requiredValidators: Validators = Validators.compose([defaultInputValidator, Validators.required]);
    validators: Validators = Validators.compose([defaultInputValidator, Validators.minLength(1)]);
    validatorDuration: Validators = Validators.compose([
        defaultInputValidator,
        Validators.required,
        Validators.minLength(1),
        Validators.pattern(/^[1-9]\d*$/),
    ]);
}
