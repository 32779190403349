import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Exercise } from '../../entities/exercise';

@Component({
    selector: 'lib-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent {
    @Input()
    exercises: Exercise[];
    @Input()
    exerciseIndex = 0;

    @Output()
    updateIndex: EventEmitter<number> = new EventEmitter<number>();

    isButtonOutline(exercise: Exercise): string {
        return this.exercises.findIndex((i) => i.id === exercise.id) === this.exerciseIndex ? 'solid' : 'outline';
    }

    isButtonHidden(buttonIndex: number): boolean {
        if (this.exerciseIndex <= 2) {
            return buttonIndex >= 4;
        } else if (this.exerciseIndex >= this.exercises.length - 3) {
            return buttonIndex <= this.exercises.length - 5;
        } else if (this.exercises.length - 3 > this.exerciseIndex || this.exerciseIndex > 3) {
            return this.exerciseIndex - 1 > buttonIndex || this.exerciseIndex + 1 < buttonIndex;
        }
    }

    goToPreviousStep(): void {
        if (this.exerciseIndex > 0) this.exerciseIndex = this.exerciseIndex - 1;
        this.updateIndex.emit(this.exerciseIndex);
    }

    goToNextStep(): void {
        if (this.exerciseIndex < this.exercises.length) this.exerciseIndex = this.exerciseIndex + 1;
        this.updateIndex.emit(this.exerciseIndex);
    }

    changeIndex(index: number): void {
        this.exerciseIndex = index;
        this.updateIndex.emit(this.exerciseIndex);
    }
}
