import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ErrorMessage, errorMessagesConst } from '../../../common/entities/error-const';
import { defaultInputValidator } from '../../../common/validators/curafida-validators';

@Component({
    selector: 'text-input-modal',
    templateUrl: './consultation-settings-modal.component.html',
    styleUrls: ['./consultation-settings-modal.component.scss'],
})
export class ConsultationSettingsModalComponent implements OnInit {
    @Input()
    title = 'Input';
    @Input()
    requiredInputLabel = 'Text';
    @Input()
    optionalInputLabel = 'Text';
    @Input()
    requiredInputDefault: string;
    @Input()
    optionalInputDefault: string;
    @Input()
    isMobile: boolean;

    textInputForm: FormGroup;
    formErrors: ErrorMessage[];

    constructor(
        private modalController: ModalController,
        private formBuilder: FormBuilder,
    ) {}

    ngOnInit() {
        this.initTextInputForm();
    }

    initTextInputForm() {
        const requiredInput = this.requiredInputDefault == null ? '' : this.requiredInputDefault;
        const optionalInput = this.optionalInputDefault == null ? '' : this.optionalInputDefault;
        this.textInputForm = this.formBuilder.group({
            requiredInput: new FormControl({ value: requiredInput, disabled: false }, [
                Validators.required,
                defaultInputValidator,
            ]),
            optionalInput: new FormControl({ value: optionalInput, disabled: false }, defaultInputValidator),
        });
        this.formErrors = [...errorMessagesConst.filter((i) => i.formType === 'message')];
    }

    async dismissModal() {
        await this.modalController.dismiss({ action: 'left' });
    }

    async returnInput() {
        const requiredInput = this.textInputForm.controls.requiredInput.value;
        const optionalInput = this.textInputForm.controls.optionalInput.value;
        await this.modalController.dismiss({ requiredInput, optionalInput, action: 'right' });
    }

    async emitLeftButton() {
        await this.dismissModal();
    }

    async emitRightButton() {
        await this.returnInput();
    }
}
