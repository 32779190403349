import { Injectable } from '@angular/core';
import { CurafidaAuthService } from '../../../auth/services';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ContentFormatType } from '../../entities/content-format-type';
import { HttpClient, HttpDownloadProgressEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Logger, LoggingService, LogLevel } from '../../../logging/logging.service';
import 'rxjs/add/operator/map';
import { ApiService } from '../../../api';
import { File } from '@ionic-native/file/ngx';
import { Capacitor } from '@capacitor/core';
import { extension } from 'mime-types';
import { from, Observable, Subject } from 'rxjs';
import { Content } from '../../../therapy/entities/content';
import { concatMap, filter, map, tap } from 'rxjs/operators';
import { ConfigService } from '../../../config/services';
import { BrowserNavigationService } from '../browser-navigation/browser-navigation.service';
import { FileOpener } from '@capacitor-community/file-opener';

export class DownloadContentProgress {
    content: Content;
    percentage: number; // 0 - 100%

    constructor(content: Content, percentage: number) {
        this.content = content;
        this.percentage = percentage;
    }
}
@Injectable({
    providedIn: 'root',
})
export class FileContentService {
    protected readonly log: Logger;
    private progressEmitter = new Subject<DownloadContentProgress>();
    private notDownloadedFileEmitter = new Subject<Content>();

    constructor(
        private authService: CurafidaAuthService,
        private sanitizer: DomSanitizer,
        private http: HttpClient,
        private readonly loggingService: LoggingService,
        private file: File,
        public configService: ConfigService,
        private browser: BrowserNavigationService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
        this.log.setLevel(LogLevel.DEBUG);
    }

    async createSafeObjectUrlFromUrl(
        url: string,
        accessToken?: string,
        preferredImageFormat?: ContentFormatType,
    ): Promise<SafeUrl> {
        const objectUrl = window.URL.createObjectURL(await this.getBlobFromUrl(url, accessToken, preferredImageFormat));
        return this.sanitizer.bypassSecurityTrustUrl(objectUrl);
    }

    async openObjectURLinNewWindow(url: string, accessToken?: string, preferredImageFormat?: ContentFormatType) {
        try {
            const objectUrl = URL.createObjectURL(await this.getBlobFromUrl(url, accessToken, preferredImageFormat));
            this.log.debug('objectUrl', objectUrl);
            this.browser.openTargetBlank(objectUrl);
        } catch (e) {
            this.log.error(e);
        }
    }

    async getObjectURL(url: string, accessToken?: string, preferredImageFormat?: ContentFormatType) {
        try {
            return URL.createObjectURL(await this.getBlobFromUrl(url, accessToken, preferredImageFormat));
        } catch (e) {
            this.log.error(e);
        }
    }

    /**
     * Download and open a file in the mobile app, since it is not possible to download blobs through the InAppBrowser
     * This method uses cordova-plugin-file to save the file and the capacitor file-opener plugin to immediately open it
     * @param url - the URL to get the blob from
     * @param filename - optional name, must include file extension, recommended empty to take the suggested name from the http response
     */
    async openObjectURLinMobileDevice(url: string, filename?: string) {
        if (!Capacitor.isNativePlatform()) {
            await this.downloadObjectURLinBrowser(url, filename);
        }
        this.log.debug('url to get blob from', url);
        return this.http
            .get(url, { ...ApiService.options, observe: 'response', responseType: 'blob' })
            .map((res) => {
                this.log.debug('http response', res);
                filename = filename ? filename : res.headers.get('content-disposition').split('filename=')[1];
                return {
                    blob: new Blob([res.body], { type: res.headers.get('content-type') }),
                    filename: filename
                        ? filename.replace(' ', '_')
                        : 'undefined.' + extension(res.headers.get('content-type')),
                };
            })
            .subscribe(async (data) => {
                this.log.debug('filename', data.filename);
                this.log.debug('mime type', data.blob.type);
                const next = await this.file.writeFile(this.file.dataDirectory, data.filename, data.blob, {
                    replace: true,
                });
                this.log.debug('file has been written in', next.nativeURL);
                return await FileOpener.open({ filePath: next.nativeURL, contentType: data.blob.type });
            });
    }

    /**
     * Forces a download to the file system on browsers instead of opening the file in another tab or window
     * @param url - the URL to get the blob from
     * @param filename - optional name, must include file extension, recommended empty to take the suggested name from the http response
     */
    async downloadObjectURLinBrowser(url: string, filename?: string) {
        this.log.debug('url to get blob from', url);
        return this.http
            .get(url, { ...ApiService.options, observe: 'response', responseType: 'blob' })
            .map((res) => {
                this.log.debug('http response', res);
                filename = filename ? filename : res.headers.get('content-disposition').split('filename=')[1];
                return {
                    blob: new Blob([res.body], { type: res.headers.get('content-type') }),
                    filename: filename
                        ? filename.replace(' ', '_')
                        : 'undefined.' + extension(res.headers.get('content-type')),
                };
            })
            .subscribe((data) => {
                this.log.debug('filename', data.filename);
                this.log.debug('mime type', data.blob.type);
                const blobURL = window.URL.createObjectURL(data.blob);
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = blobURL;
                a.download = data.filename;
                a.click();
                window.URL.revokeObjectURL(blobURL);
                a.remove();
            });
    }

    getObservableBlobFromUrl(
        url: string,
        accessToken?: string,
        preferredImageFormat?: ContentFormatType,
    ): Observable<Blob> {
        const urlWithParam: URL = new URL(url);
        if (preferredImageFormat) {
            urlWithParam.searchParams.set('preferredImageFormat', preferredImageFormat.toString());
        }
        const bearerToken = accessToken
            ? `Bearer ${accessToken}`
            : `Bearer ${this.authService.getSession().tokenSet.access_token}`;
        if (!accessToken) {
            urlWithParam.searchParams.set('contentDisposition', 'attachment');
        }
        url = urlWithParam.toString();
        return this.http.get(`${url}`, {
            headers: { authorization: bearerToken },
            withCredentials: true,
            responseType: 'blob',
        });
    }

    private async getBlobFromUrl(
        url: string,
        accessToken?: string,
        preferredImageFormat?: ContentFormatType,
        filename?: string,
    ): Promise<Blob> {
        const urlWithParam: URL = new URL(url);
        if (preferredImageFormat) {
            urlWithParam.searchParams.set('preferredImageFormat', preferredImageFormat.toString());
        }
        let bearerToken: string;
        if (await this.authService.isAuthenticated(false, false)) {
            bearerToken = accessToken
                ? `Bearer ${accessToken}`
                : `Bearer ${this.authService.getSession().tokenSet.access_token}`;
        }
        if (!accessToken) {
            urlWithParam.searchParams.set('contentDisposition', 'attachment');
        }
        url = urlWithParam.toString();
        const fetchResult = await fetch(`${url}`, {
            headers: {
                authorization: bearerToken,
                'Content-Disposition': `inline; filename="${filename}"`,
            },
        });
        return await fetchResult.blob();
    }

    /**
     * Cache Files using HttpEvents
     */
    async cacheFilesObservableWithProgress(contents: Content[], counterSeed = 0) {
        const cacheStorage = await this.getCachePublicStorage();
        let counter = counterSeed;
        this.log.debug('contents length', contents.length);
        return from(contents).pipe(
            tap(console),
            concatMap((content: Content) => this.downloaderObservableWithHttpEvents(content, cacheStorage)),
            map(() => {
                counter++;
                return counter;
            }),
        );
    }

    /**
     * https://developer.mozilla.org/en-US/docs/Web/API/StorageManager/estimate
     * Result is given in MB - MegaBytes
     */
    private async readBrowserStorageInfo(): Promise<number> {
        if ('storage' in navigator && 'estimate' in navigator.storage) {
            const storageEstimate = await navigator.storage.estimate();
            this.log.debug('navigator.storage.estimate() => ', storageEstimate);
            if (storageEstimate?.quota && storageEstimate?.usage) {
                const storageEstimateQuotaPercent = ((storageEstimate.usage * 100) / storageEstimate.quota).toFixed(2);
                this.log.debug('storageEstimateQuotaPercent', storageEstimateQuotaPercent);
            }
            return storageEstimate.quota - storageEstimate.usage;
        }
    }

    private formatBytes(bytes: number, decimals: number): FormatBytes {
        if (bytes == 0) return null;
        const k = 1024,
            dm = decimals || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return { value: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)), unit: sizes[i] };
    }

    public async getCachePublicStorage(): Promise<Cache> {
        return window.caches.open('cache-public-' + this.configService?.config?.appDisplayName);
    }

    async filterDownloadedFilesFromCache(contents: Content[]): Promise<Content[]> {
        const notDownloadedFiles: Content[] = [];
        const cache = await this.getCachePublicStorage();
        const requests = await cache.keys();
        // First delete files from Cache that have a wrong Key/Request
        for (const request of requests) {
            const url = request?.url;
            if (!url) {
                this.log.error('URL was not found, Illegal Request/Key from Cache');
                continue;
            }
            if (!url.includes(ApiService.url)) {
                this.log.warn('This request will be deleted, Request-Url does not included API-URL', url);
                await cache.delete(url);
            }
        }
        for (const content of contents) {
            const isCached = !!(await cache.match(this.getKeyForCache(content.uuid)));
            if (!isCached) {
                notDownloadedFiles.push(content);
            }
        }
        return notDownloadedFiles;
    }

    private getKeyForCache(uuid: string) {
        return `${ApiService.url}${uuid}`;
    }

    /**
     * Get Response (from Fetch API) from CacheStorage
     * UUID: Content Uuid used as a key for the CacheStorage
     */
    async getContentResponseFromCache(uuid: string): Promise<Response | undefined> {
        const cacheStorage = await this.getCachePublicStorage();
        this.log.debug(cacheStorage);
        return cacheStorage.match(this.getKeyForCache(uuid));
    }

    /**
     * It checks if there is enough available memory to store the contents locally
     * @param notDownloadedContents - contents that want to be stored
     */
    async hasEnoughMemory(notDownloadedContents: Content[]): Promise<boolean> {
        // TODO: this method must also handle for iOS- Devices 1GiB
        const factorMemorySafety = 500000000; // due to the freeSpaceMemory comes from an estimation
        const freeSpaceMemory = await this.readBrowserStorageInfo();
        this.log.debug('freeSpace Human readable', this.formatBytes(freeSpaceMemory, 4));
        for (const notDownloadedContent of notDownloadedContents) {
            if (notDownloadedContent.byteSize > 1000000000) {
                this.log.debug('bite size larger than 1000000000', notDownloadedContent);
            }
        }
        const requiredMemory = notDownloadedContents.map((x) => Number(x.byteSize))?.reduce((a, b) => a + b);
        this.log.debug('requiredMemory Human readable', this.formatBytes(requiredMemory, 4));
        const ensuredFreeSpace = freeSpaceMemory - factorMemorySafety;
        if (ensuredFreeSpace < requiredMemory) {
            this.log.warn('Not enough memory in device, please free:', requiredMemory - ensuredFreeSpace);
            return false;
        }
        return true;
    }

    downloaderObservableWithHttpEvents(content: Content, cache: Cache) {
        const url = new URL(`${ApiService.url}exerciseContents/${content.uuid}/download`);
        return this.http
            .get(url.toString(), {
                ...ApiService.options,
                observe: 'events',
                responseType: 'blob',
                reportProgress: true,
            })
            .pipe(
                tap(async (e: any) => {
                    if (e.type === HttpEventType.DownloadProgress) {
                        e = e as HttpDownloadProgressEvent;
                        const percentage = (e.loaded / e.total) * 100;
                        const downloaderProgress = new DownloadContentProgress(content, Number(percentage.toFixed(0)));
                        this.getDownloaderProgressEmitter().next(downloaderProgress);
                    }
                }),
                filter((e) => e.type === HttpEventType.Response),
                concatMap(async (e: HttpResponse<Blob>) => {
                    const cacheHeaders = e.headers.keys().map((x) => [x, e.headers.get(x)] as [string, string]);
                    // Add additional metadata to the cache entries
                    cacheHeaders.push(
                        ['x-cached-content-uuid', content.uuid],
                        ['x-cached-timestamp', Date.now().toString()],
                        ['x-cached-origFileName', content.origFileName],
                    );
                    const init: ResponseInit = {
                        status: e.status,
                        headers: cacheHeaders,
                        statusText: e.statusText,
                    };
                    const res = new Response(e.body, init);
                    const key = this.getKeyForCache(content.uuid);
                    try {
                        await cache.put(key, res.clone());
                    } catch (e) {
                        this.log.error('Error Cache Put', e);
                        // Subject to emit values not possible to download
                        this.getNotDownloadedFilesEmitter().next(content);
                    }
                }),
            );
    }

    getDownloaderProgressEmitter() {
        return this.progressEmitter;
    }
    getNotDownloadedFilesEmitter() {
        return this.notDownloadedFileEmitter;
    }
}

interface FormatBytes {
    value: number;
    unit: string;
}
