// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.task-state-content {
  display: flex;
  align-content: center;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.task-state-icon {
  font-size: 25px;
  fill: var(--ion-color-primary);
  margin-right: 6px;
}

.task-state-icon-fill-green {
  font-size: 25px;
  fill: var(--ion-color-success);
  margin-right: 6px;
}

.background-primary {
  background: var(--ion-color-primary);
}

.background-danger {
  background: var(--ion-color-danger);
}

.background-success {
  background: var(--ion-color-success);
}

.fill-medium {
  filter: var(--ion-color-medium);
}

.appointment-checkbox {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
}

.text-area {
  min-height: 100px;
  line-height: normal;
  border: 1px solid;
}

.text-area-disable {
  min-height: 100px;
  line-height: normal;
  border: 1px solid lightgrey;
}

.icon-multiple-col {
  flex: 0 0 auto;
}

.no-left-padding {
  padding-left: 0 !important;
}`, "",{"version":3,"sources":["webpack://./../../libs/therapy/components/task/caregiver-task-preview/caregiver-task-preview.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,qBAAA;EACA,mBAAA;EACA,0BAAA;EAAA,uBAAA;EAAA,kBAAA;AACJ;;AAEA;EACI,eAAA;EACA,8BAAA;EACA,iBAAA;AACJ;;AAEA;EACI,eAAA;EACA,8BAAA;EACA,iBAAA;AACJ;;AAEA;EACI,oCAAA;AACJ;;AAEA;EACI,mCAAA;AACJ;;AAEA;EACI,oCAAA;AACJ;;AAEA;EACI,+BAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,YAAA;EACA,uBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,mBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,mBAAA;EACA,2BAAA;AACJ;;AAEA;EACI,cAAA;AACJ;;AAEA;EACI,0BAAA;AACJ","sourcesContent":[".task-state-content {\n    display: flex;\n    align-content: center;\n    align-items: center;\n    width: fit-content;\n}\n\n.task-state-icon {\n    font-size: 25px;\n    fill: var(--ion-color-primary);\n    margin-right: 6px;\n}\n\n.task-state-icon-fill-green {\n    font-size: 25px;\n    fill: var(--ion-color-success);\n    margin-right: 6px;\n}\n\n.background-primary {\n    background: var(--ion-color-primary);\n}\n\n.background-danger {\n    background: var(--ion-color-danger);\n}\n\n.background-success {\n    background: var(--ion-color-success);\n}\n\n.fill-medium {\n    filter: var(--ion-color-medium);\n}\n\n.appointment-checkbox {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    align-items: flex-start;\n}\n\n.text-area {\n    min-height: 100px;\n    line-height: normal;\n    border: 1px solid;\n}\n\n.text-area-disable {\n    min-height: 100px;\n    line-height: normal;\n    border: 1px solid lightgrey;\n}\n\n.icon-multiple-col {\n    flex: 0 0 auto;\n}\n\n.no-left-padding {\n    padding-left: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
