import { Component } from '@angular/core';
import {
    ActionEmitter,
    ActionType,
    ButtonItemAdapterComponent,
    ItemType,
    TableConfig,
} from '../../../../table/entities/table';
import { ModalController } from '@ionic/angular';
import { ExerciseCommentModalComponent } from '../exercise-comment/exercise-comment-modal.component';
import { FeedbackCommentsService } from '../../../services/feedback-comments';
import { FeedbackComment, FeedbackCommentDto } from '../../../entities/feedback/feedback-comment';
import { ToastService } from '../../../../common/services/toast-service/toast-service.service';
import { IonicColor } from '../../../../common/entities/toast/ionic-color';
import { ModalConfig } from '../../../../common/entities/modal/modal-config';
import { ModalTyp } from '../../../../common/entities/modal/modal-typ';
import { ButtonConfig } from '../../../../common/entities/modal/modal-button';
import { ModalAlertService } from '../../../../common/services/modal';
import { Logger, LoggingService } from '../../../../logging/logging.service';
import { StringItemAdapterComponent } from '../../../../table/components/table-adapter/string-item-adapter.component';

@Component({
    selector: 'lib-exercise-comments',
    templateUrl: './feedback-comments.component.html',
    styleUrls: ['./feedback-comments.component.scss'],
})
export class FeedbackCommentsComponent {
    feedbackCommentsTable: TableConfig<FeedbackComment[]> = new TableConfig<FeedbackComment[]>();
    protected readonly log: Logger;

    constructor(
        private modalController: ModalController,
        private feedBackCommentService: FeedbackCommentsService,
        private toastService: ToastService,
        private modalAlertService: ModalAlertService,
        private loggingService: LoggingService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
        this.feedbackCommentsTable.emptyListLabel = 'TRAINING.COMMENT.ANY_ITEM';
        this.feedbackCommentsTable.itemSettings = [
            {
                prop: 'text',
                header: 'Name',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '40%',
                sortOrderWeb: 1,
            },
            {
                prop: 'flag',
                header: 'Farbe',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '44%',
                sortOrderWeb: 2,
            },
            {
                prop: '',
                header: '',
                adapter: ButtonItemAdapterComponent,
                type: ItemType.ADAPTER,
                actionType: ActionType.UPDATE,
                icon: 'create',
                width: '8%',
                sortOrderWeb: 3,
            },
            {
                prop: '',
                header: '',
                adapter: ButtonItemAdapterComponent,
                type: ItemType.ADAPTER,
                actionType: ActionType.DELETE,
                icon: 'trash',
                color: 'danger',
                width: '8%',
                sortOrderWeb: 4,
            },
        ];
        this.feedbackCommentsTable.isOpenDetailEnable = false;
    }

    async initFeedbackCommentTable(offset = 0, limit = 10) {
        this.feedbackCommentsTable.list = await this.feedBackCommentService.getFeedbackComments(offset * limit, limit);
    }

    async updateComment(comment: FeedbackCommentDto) {
        const modal = await this.modalController.create({
            component: ExerciseCommentModalComponent,
            cssClass: 'modal-create-exercise-comment-css',
            componentProps: {
                comment,
            },
        });
        await modal.present();
        await modal.onDidDismiss();
        await this.initFeedbackCommentTable();
    }

    async setActionOnItem(actionEmitter: ActionEmitter<FeedbackComment>) {
        if (actionEmitter.actionType === ActionType.UPDATE) {
            await this.updateComment(actionEmitter.item);
        }
        if (actionEmitter.actionType === ActionType.DELETE) {
            await this.removeExerciseComment(actionEmitter.item);
        }
    }

    async removeExerciseComment(feedbackComment: FeedbackComment) {
        const modalConfig = new ModalConfig();
        modalConfig.modalTyp = ModalTyp.INFORMATION;
        modalConfig.title = `${feedbackComment.text} löschen`;
        modalConfig.titleIcon = 'warning-outline';
        modalConfig.description = `Die Daten werden unwiderruflich gelöscht.
        Wenn Sie den Übungskommentar „${feedbackComment.text}“ löschen möchten, klicken Sie auf „Löschen“.`;

        modalConfig.buttonRight = new ButtonConfig();
        modalConfig.buttonRight.buttonText = 'Löschen';
        modalConfig.buttonRight.buttonColor = 'danger';
        const action = await this.modalAlertService.showModal(modalConfig);
        if (action && action.action === 'right') {
            try {
                await this.feedBackCommentService.deleteFeedbackComments(feedbackComment.uuid);
                await this.toastService.showToast('Der Kommentar wurde gelöscht', IonicColor.success);
            } catch (err) {
                this.log.error('Error in FeedbackCommentsComponent', err);
                await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
            } finally {
                await this.initFeedbackCommentTable();
            }
        }
    }
}
