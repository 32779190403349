import { ExerciseSessionState } from '../exerciseSession';

export const PlannedState = [
    ExerciseSessionState.PLANNED,
    ExerciseSessionState.ACTIVE,
    ExerciseSessionState.FINISHED,
    ExerciseSessionState.CANCELLED,
    ExerciseSessionState.POSTPONED,
    ExerciseSessionState.PATIENT_CANCELLED,
    ExerciseSessionState.DELETED,
    ExerciseSessionState.NO_SHOW,
];
export const RunningState = [ExerciseSessionState.PLANNED, ExerciseSessionState.ACTIVE];
export const FinishedState = [
    ExerciseSessionState.FINISHED,
    ExerciseSessionState.CANCELLED,
    ExerciseSessionState.POSTPONED,
    ExerciseSessionState.PATIENT_CANCELLED,
    ExerciseSessionState.DELETED,
    ExerciseSessionState.NO_SHOW,
];
export const FinishedFrontendState = [ExerciseSessionState.FINISHED, ExerciseSessionState.NO_SHOW];
export const NotPlannedState = [ExerciseSessionState.NOT_PLANNED, ExerciseSessionState.SCHEDULED];
