import { UserTagCategory } from './user-tag-category.entity';

export class UserTagDto {
    categoryUuid: string;
    label: string;
}

export class UserTag extends UserTagDto {
    uuid: string;
    category: UserTagCategory;
    groupUuid: string;
    userRole?: string;
    isHidden = false;
    isReadonly = false;
    isSelected: boolean;
}

export class UpdateUserTagDto {
    uuid: string;
    label?: string;
    category?: UserTagCategory;
    groupUuid?: string;
    isHidden?: boolean;
    isReadonly?: boolean;
}

export class UserTagAssignmentDto {
    tagUuids: string[] = [];

    constructor(tagUuids: string[]) {
        this.tagUuids = tagUuids;
    }
}
