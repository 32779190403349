import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'handleEmptyValue',
})
export class HandleEmptyValuePipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        if (value === undefined || value === '' || value === null || value === 'Keine Angabe') {
            return '-';
        } else {
            return value;
        }
    }
}
