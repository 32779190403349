// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.state-icon {
  font-size: 25px;
  padding-right: 5px;
}

.state-paragraph {
  align-items: center;
  display: flex;
}

p {
  margin-top: 0 !important;
}

.text-area {
  min-height: 100px;
  line-height: normal;
  border: 1px solid;
}

.text-area-disable {
  min-height: 100px;
  line-height: normal;
  border: 1px solid lightgrey;
}`, "",{"version":3,"sources":["webpack://./../../libs/therapy/components/task/patient-task-preview/patient-task-preview.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,kBAAA;AACJ;;AAEA;EACI,mBAAA;EACA,aAAA;AACJ;;AAEA;EACI,wBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,mBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,mBAAA;EACA,2BAAA;AACJ","sourcesContent":[".state-icon {\n    font-size: 25px;\n    padding-right: 5px;\n}\n\n.state-paragraph {\n    align-items: center;\n    display: flex;\n}\n\np {\n    margin-top: 0 !important;\n}\n\n.text-area {\n    min-height: 100px;\n    line-height: normal;\n    border: 1px solid;\n}\n\n.text-area-disable {\n    min-height: 100px;\n    line-height: normal;\n    border: 1px solid lightgrey;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
