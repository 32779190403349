import { Component, Input, OnInit } from '@angular/core';
import { ToastService } from '../../../common/services/toast-service/toast-service.service';
import { IonicColor } from '../../../common/entities/toast/ionic-color';
import {
    ActionType,
    ButtonItemAdapterComponent,
    ItemType,
    TableConfig,
    TableUpdateValue,
} from '../../../table/entities/table';
import { SortBy, SortOrder } from '../../../common/entities/paginated-response';
import { TherapyTemplate } from '../../entities/therapy/therapy-template';
import { Router } from '@angular/router';
import { ModalAlertService } from '../../../common/services/modal';
import { TherapyTemplatesService } from '../../services/therapy-templates';
import { RoutingSegment } from '../../../common/entities/routing-segment';
import { ViewState } from '../../../common/entities/view';
import { ExerciseType } from '../../entities/exerciseSession';
import { TherapiesService } from '../../services/therapies';
import { Logger, LoggingService } from '../../../logging/logging.service';
import { StringItemAdapterComponent } from '../../../table/components/table-adapter/string-item-adapter.component';

@Component({
    selector: 'lib-therapy-template-list',
    templateUrl: './therapy-template-list.component.html',
    styleUrls: ['./therapy-template-list.component.scss'],
})
export class TherapyTemplateListComponent implements OnInit {
    searchTerm: string;
    refreshTable = false;
    therapyTemplateListConfig: TableConfig<TherapyTemplate[]> = new TableConfig();
    @Input()
    buttonLabel: string;
    @Input()
    anyItemLabel: string;
    @Input()
    exerciseType = ExerciseType.COURSE;
    @Input()
    hideSearch = false;
    protected readonly log: Logger;
    private limit = 10;
    private offset = 0;
    private sortBy = SortBy.TITLE;
    private sortOrder = SortOrder.ASC;

    constructor(
        private therapiesService: TherapiesService,
        private therapyTemplatesService: TherapyTemplatesService,
        private router: Router,
        protected modalAlertService: ModalAlertService,
        protected toastService: ToastService,
        private loggingService: LoggingService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
        this.therapyTemplateListConfig.isOpenDetailEnable = true;
        this.therapyTemplateListConfig.itemSettings = [
            {
                id: 'title',
                prop: 'title',
                header: 'Name',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '44%',
                sortBy: SortBy.TITLE,
                sortOrderWeb: 0,
            },
            {
                id: 'description',
                prop: 'description',
                header: 'Beschreibung',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '48%',
                sortBy: SortBy.DESCRIPTION,
                sortOrderWeb: 1,
            },
            {
                id: 'action_open',
                prop: 'id',
                header: '',
                type: ItemType.ADAPTER,
                adapter: ButtonItemAdapterComponent,
                icon: 'create',
                actionType: ActionType.OPEN_NEW_PAGE,
                width: '8%',
                sortOrderWeb: 2,
            },
        ];
    }

    async ngOnInit() {
        await this.updateTherapyTemplateList(new TableUpdateValue({ offset: this.offset }));
    }

    async updateTherapyTemplateList(value: TableUpdateValue) {
        this.offset = value.offset;
        if (value.limit) this.limit = value.limit;
        await this.refreshTherapyTemplateList(value);
    }

    private async refreshTherapyTemplateList(value: TableUpdateValue) {
        this.refreshTable = false;
        this.sortBy = value?.sortBy as SortBy;
        this.sortOrder = value?.sortOrder;
        try {
            this.therapyTemplateListConfig.list = await this.therapyTemplatesService.getTherapyTemplates({
                offset: this.offset * this.limit,
                limit: this.limit,
                filter: this.searchTerm,
                sortOrder: this.sortOrder,
                sortBy: this.sortBy,
                exerciseType: this.exerciseType,
            });
            this.refreshTable = true;
        } catch (e) {
            this.log.error('Error in refreshTherapyTemplateList', e);
            await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
        }
    }

    async openExercisePage(therapyTemplate?: TherapyTemplate) {
        const id = therapyTemplate ? therapyTemplate.id : 'new';
        if (this.exerciseType === ExerciseType.COURSE) {
            await this.router.navigate([
                RoutingSegment.MEMBER,
                RoutingSegment.ADMINISTRATION,
                RoutingSegment.MEETINGS,
                RoutingSegment.THERAPY_TEMPLATE,
                RoutingSegment.DETAIL,
                id,
                ViewState.EDIT,
            ]);
        }
        if (this.exerciseType === ExerciseType.TASK) {
            await this.router.navigate([
                RoutingSegment.MEMBER,
                RoutingSegment.ADMINISTRATION,
                RoutingSegment.TASK_MANAGEMENT,
                RoutingSegment.TASK_PLAN_TEMPLATES,
                RoutingSegment.DETAIL,
                id,
            ]);
        }
        if (this.exerciseType === ExerciseType.LEARNING) {
            await this.router.navigate([
                RoutingSegment.MEMBER,
                RoutingSegment.ADMINISTRATION,
                RoutingSegment.LEARNING_MANAGEMENT,
                RoutingSegment.LEARNING_PLAN_TEMPLATES,
                RoutingSegment.DETAIL,
                id,
            ]);
        }
        if (this.exerciseType === ExerciseType.MIXED) {
            await this.router.navigate([
                RoutingSegment.MEMBER,
                RoutingSegment.ADMINISTRATION,
                RoutingSegment.PROGRAM_MANAGEMENT,
                RoutingSegment.PROGRAM_TEMPLATES,
                RoutingSegment.DETAIL,
                id,
            ]);
        }
    }
}
