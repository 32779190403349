import { Component, Input } from '@angular/core';
import { Platform } from '@ionic/angular';
import { ExerciseSessionType } from '../../entities/exerciseSession';
import { ItemAdapterComponent, TableItem } from '../../../table/entities/table';

@Component({
    template: `
        <div [ngClass]="{ 'web-width': !isMobile }">
            <div *ngIf="data.exerciseSessionType === ExerciseSessionType.HOMEWORK">
                {{ ExerciseSessionType.HOMEWORK | translate }}
            </div>
            <div *ngIf="data.exerciseSessionType === ExerciseSessionType.GROUP && data[itemSetting.prop] === 1">
                {{ 'SINGLE' | translate }}
            </div>
            <div *ngIf="data.exerciseSessionType === ExerciseSessionType.GROUP && data[itemSetting.prop] !== 1">
                {{ ExerciseSessionType.GROUP | translate }}
            </div>
        </div>
    `,
})
export class ExerciseSessionTypeAdapterComponent implements ItemAdapterComponent {
    ExerciseSessionType = ExerciseSessionType;
    @Input() data: { exerciseSessionType: ExerciseSessionType };
    @Input() itemSetting: TableItem;
    isMobile: boolean;

    constructor(private platform: Platform) {
        if (platform.is('mobile')) this.isMobile = true;
    }
}
