// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-input {
  min-width: 30px;
  border: 1px solid var(--ion-color-primary);
  border-radius: 5px;
  height: 25px;
  --padding-top: 0px;
  --padding-bottom: 0px;
  --padding-start: 0px;
  --padding-end: 0px;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
}

ion-button {
  width: 25px;
  height: 25px;
  padding: 0;
  --padding: 0;
}

.button-small {
  --padding-start: 0.1rem !important;
  --padding-end: 0.1rem !important;
  --color: white;
  margin-top: 0;
  margin-bottom: 0;
}

.vertical-button {
  width: 30px !important;
  margin-left: 0;
  margin-right: 0;
}`, "",{"version":3,"sources":["webpack://./../../libs/common/components/range-selector/range-selector.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,0CAAA;EACA,kBAAA;EACA,YAAA;EACA,kBAAA;EACA,qBAAA;EACA,oBAAA;EACA,kBAAA;EACA,aAAA;EACA,gBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;EACA,UAAA;EACA,YAAA;AACJ;;AAEA;EACI,kCAAA;EACA,gCAAA;EACA,cAAA;EACA,aAAA;EACA,gBAAA;AACJ;;AAEA;EACI,sBAAA;EACA,cAAA;EACA,eAAA;AACJ","sourcesContent":["ion-input {\n    min-width: 30px;\n    border: 1px solid var(--ion-color-primary);\n    border-radius: 5px;\n    height: 25px;\n    --padding-top: 0px;\n    --padding-bottom: 0px;\n    --padding-start: 0px;\n    --padding-end: 0px;\n    margin-top: 0;\n    margin-bottom: 0;\n    text-align: center;\n}\n\nion-button {\n    width: 25px;\n    height: 25px;\n    padding: 0;\n    --padding: 0;\n}\n\n.button-small {\n    --padding-start: 0.1rem !important;\n    --padding-end: 0.1rem !important;\n    --color: white;\n    margin-top: 0;\n    margin-bottom: 0;\n}\n\n.vertical-button {\n    width: 30px !important;\n    margin-left: 0;\n    margin-right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
