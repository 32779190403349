import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { User, UserRoles } from '../../../auth/entities/user';
import { Logger, LoggingService } from '../../../logging/logging.service';
import { ParticipantDto } from '../../../therapy/entities/exerciseSession/participation.dto';
import { TaskResource } from '../../../therapy/components/task/task.resource';
import { ParticipationService } from '../../../therapy/components/task/participation/participation.service';
import { skip, take } from 'rxjs/operators';
import { ExerciseType } from '../../../therapy/entities/exerciseSession';

@Component({
    selector: 'lib-kiosk-appointment-video-modal',
    templateUrl: './kiosk-appointment-video-modal.component.html',
    styleUrls: ['./kiosk-appointment-video-modal.component.scss'],
})
export class KioskAppointmentVideoModalComponent implements OnInit {
    title: string;
    @Input()
    taskResource: TaskResource;
    @Input()
    loggedInUser: User;
    caregiver: ParticipantDto;
    private readonly log: Logger;

    constructor(
        private modalCtrl: ModalController,
        private loggingService: LoggingService,
        private participationService: ParticipationService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    async ngOnInit(): Promise<void> {
        this.title = this.taskResource.title;
        this.participationService.init(this.taskResource);
        this.participationService.model$.pipe(skip(1), take(1)).subscribe((participants) => {
            this.taskResource.participants = participants.taskParticipation;
            this.caregiver = this.taskResource.participants.allParticipants.filter(
                (i) => i.role === UserRoles.CAREGIVER,
            )[0];
        });
    }

    async dismissModal(): Promise<void> {
        await this.modalCtrl.dismiss();
    }

    protected readonly ExerciseType = ExerciseType;
}
