import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { User, UserRoles } from '../../../auth/entities/user';
import { ItemType, TableConfig, TableItem, TableUpdateValue } from '../../../table/entities/table';
import { ToastService } from '../../../common/services/toast-service/toast-service.service';
import { IonicColor } from '../../../common/entities/toast/ionic-color';
import { UsersService } from '../../services/user';
import { SupervisorService } from '../../services/supervisor';
import { PaginatedResponse } from '../../../common/entities/paginated-response';
import { StringItemAdapterComponent } from '../../../table/components/table-adapter/string-item-adapter.component';
import { CheckBoxItemAdapterComponent } from '../../../table/components/table-adapter/checkbox-item-adapter.component';
import { CurafidaAuthService } from '../../../auth/services';
import { Logger, LoggingService } from '../../../logging/logging.service';
import { TagsAdapterComponent } from '../../../therapy/components/table-adapter/tags-adapter.component';
import { UserTagListModalComponent } from '../user-tag-list-modal/user-tag-list-modal.component';
import { UserTag } from '../../entities/user-tag/user-tag.entity';

export class SelectableUser extends User {
    selected = false;
    constructor(usermame?: string) {
        super();
        this.username = usermame ? usermame : this.username;
    }
}

@Component({
    selector: 'lib-user-list-modal',
    templateUrl: './user-list-modal.component.html',
    styleUrls: ['./user-list-modal.component.scss'],
})
export class UserListModalComponent implements OnInit {
    title: string;
    selectedUsers: User[] = [] as SelectableUser[];
    userListConfig: TableConfig<SelectableUser[]> = new TableConfig();
    isMultipleChoice = false;
    role: string;
    showSelectedUser = true;
    anyItem: string;
    concernedUser: string;
    limit = 10;
    tableItems: TableItem[];

    isLoading = true;
    isLoadingSuccess = false;
    loggedInUser: User;
    addToSelectedUsers: SelectableUser[] = [];
    searchTerm: string;
    protected readonly log: Logger;
    initialSelectedUsers: SelectableUser[] = [];
    changeSelectedUsers: boolean = false;
    finalSelectedUsers: User[] = [] as SelectableUser[];
    tags: UserTag[];

    constructor(
        protected modalCtrl: ModalController,
        private userService: UsersService,
        protected toastService: ToastService,
        private supervisorService: SupervisorService,
        private authService: CurafidaAuthService,
        private loggingService: LoggingService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
        this.userListConfig.isOpenDetailEnable = true;
        this.userListConfig.itemSettings = [
            {
                id: 'selected',
                prop: 'selected',
                header: '',
                adapter: CheckBoxItemAdapterComponent,
                type: ItemType.ADAPTER,
                width: '8%',
                sortOrderWeb: 0,
            },
            {
                id: 'firstname',
                prop: 'firstname',
                header: 'Vorname',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '15%',
                sortOrderWeb: 1,
            },
            {
                id: 'lastname',
                prop: 'lastname',
                header: 'Nachname',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '15%',
                sortOrderWeb: 2,
            },
            {
                id: 'tags',
                prop: 'tags',
                header: 'TAG.PLURAL',
                adapter: TagsAdapterComponent,
                type: ItemType.ADAPTER,
                width: '62%',
                sortOrderWeb: 3,
            },
        ];
        this.loggedInUser = this.authService.getSession().user;
        this.tags = [];
    }

    async ngOnInit(): Promise<void> {
        if (this.tableItems) this.userListConfig.itemSettings = this.tableItems;
        if (!this.isMultipleChoice && this.selectedUsers?.length > 1) {
            throw new Error(
                'If the single choice option is set, there cannot be more than one selected user as an input.',
            );
        }
        this.initialSelectedUsers = [...this.selectedUsers] as SelectableUser[];
        this.finalSelectedUsers = [...this.initialSelectedUsers];
        await this.initUserList({ offset: 0, limit: this.limit });
    }

    async initUserList(value: TableUpdateValue): Promise<void> {
        this.isLoading = true;
        this.isLoadingSuccess = false;
        try {
            this.userListConfig.list.items = [];
            if (this.role === UserRoles.SUPERVISOR && this.concernedUser) {
                const supervisors = await this.supervisorService.getSupervisorOfPatient(this.concernedUser);
                for (const supervisor of supervisors) {
                    const supervisorUser = await this.userService.getUser(supervisor.username);
                    this.userListConfig.list.items.push(supervisorUser as SelectableUser);
                }
                this.userListConfig.list.count = this.userListConfig.list.items.length;
                this.userListConfig.list.limit = this.userListConfig.list.items.length;
                this.userListConfig.list.offset = value.offset;
                this.userListConfig.list.total = this.userListConfig.list.items.length;
            } else if (this.loggedInUser.roles.includes(UserRoles.SUPERVISOR) && this.role === UserRoles.PATIENT) {
                this.userListConfig.list = (await this.supervisorService.getSupervisedPatient(
                    this.loggedInUser.username,
                )) as PaginatedResponse<SelectableUser[]>;
            } else {
                this.userListConfig.list = (await this.userService.getUsers({
                    offset: value.offset * value.limit,
                    limit: value.limit,
                    role: this.role,
                    disabled: false,
                    filter: this.searchTerm,
                    withTags: true,
                    tagUuids: this.tags?.map((tag) => tag.uuid),
                })) as PaginatedResponse<SelectableUser[]>;
            }
            this.userListConfig.isOpenDetailEnable = true;
            for (const user of this.userListConfig.list.items) {
                user.selected = !!(
                    this.addToSelectedUsers?.find((u) => u.username === user.username) ||
                    this.selectedUsers?.find((u) => u.username === user.username)
                );
            }
            if (this.showSelectedUser === false) {
                const responseLength = this.userListConfig.list.items.length;
                this.userListConfig.list.items = this.userListConfig.list.items.filter(
                    (user) => !this.selectedUsers.some((selectedUser) => selectedUser.username === user.username),
                );
                const removedItemsCount = responseLength - this.userListConfig.list.items.length;
                this.userListConfig.list.count = this.userListConfig.list.items.length;
                this.userListConfig.list.total = this.userListConfig.list.total - removedItemsCount;
            }
            this.isLoading = false;
            this.isLoadingSuccess = true;
        } catch (err) {
            this.log.error('Error in initUserList', err);
            await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
            this.isLoading = false;
            this.isLoadingSuccess = false;
        }
    }

    async dismissModal(): Promise<void> {
        await this.modalCtrl.dismiss();
    }

    onActionOnItem(clickedUser: SelectableUser): void {
        if (!clickedUser) {
            return;
        }
        clickedUser.selected = !clickedUser.selected;

        if (clickedUser.selected) {
            this.handleSelection(clickedUser);
        } else {
            this.handleDeselection(clickedUser);
        }

        this.updateSelectedUsersChange();
    }

    private handleSelection(clickedUser: SelectableUser): void {
        if (!this.isMultipleChoice) {
            this.finalSelectedUsers = [clickedUser];
            this.addToSelectedUsers = [clickedUser];
            for (const user of this.userListConfig.list.items) {
                if (clickedUser.username !== user.username) user.selected = false;
            }
        } else {
            if (!this.finalSelectedUsers.some((user) => user.username === clickedUser.username)) {
                this.finalSelectedUsers.push(clickedUser);
            }
            this.addToSelectedUsers.push(clickedUser);
        }
    }

    private handleDeselection(clickedUser: SelectableUser): void {
        if (!this.isMultipleChoice) {
            this.addToSelectedUsers = [];
            this.finalSelectedUsers = [];
        } else {
            this.finalSelectedUsers = this.finalSelectedUsers.filter((u) => u.username !== clickedUser.username);
            this.addToSelectedUsers = this.addToSelectedUsers.filter((u) => u.username !== clickedUser.username);
        }
    }

    private updateSelectedUsersChange(): void {
        this.changeSelectedUsers = !(
            this.initialSelectedUsers.length === this.finalSelectedUsers.length &&
            this.initialSelectedUsers.every((initialUser) =>
                this.finalSelectedUsers.find((finalUser) => finalUser.username === initialUser.username),
            )
        );
    }

    async returnSelectedUsers(): Promise<void> {
        const result: User[] = [];
        const sendingList = this.showSelectedUser ? this.finalSelectedUsers : this.addToSelectedUsers;
        if (sendingList) {
            for (const selectedUser of sendingList as SelectableUser[]) {
                delete selectedUser.selected;
                result.push(selectedUser);
            }
        }
        await this.modalCtrl.dismiss(result);
    }

    async openFilterModal(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: UserTagListModalComponent,
            cssClass: 'modal-tag-list-css',
            componentProps: {
                title: 'Filter',
                tags: this.tags,
                userRoles: this.role,
                dismissText: 'Speichern',
                clearSelectedTagText: 'Verwerfen',
                enableFilterOptions: true,
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data) {
            this.tags = data as UserTag[];
            await this.initUserList({ offset: 0, limit: this.limit });
        }
    }
}
