import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Tag } from '../../../../entities/tag/tag.entity';
import { TagCategory } from '../../../../entities/tag/tag-category.entity';
import { ExerciseType } from '../../../../entities/exerciseSession';
import { TagsService } from '../../../../services/tags';

@Component({
    selector: 'tag-selection',
    templateUrl: './tag-selection.component.html',
    styleUrls: ['./tag-selection.component.scss'],
})
export class TagSelectionComponent {
    @Output()
    readonly selected$ = new EventEmitter<Tag[]>();
    @Input()
    isMobile: boolean;

    constructor(private readonly tagsService: TagsService) {}

    private _tagCategories: TagCategory[];

    get tagCategories(): TagCategory[] {
        return this._tagCategories;
    }

    @Input()
    set tagCategories(value: TagCategory[]) {
        this._tagCategories = value;
        this.syncSelected();
    }

    get tags() {
        return this._tagCategories?.flatMap((it) => it.tags);
    }

    private _selected: Tag[] = [];

    get selected() {
        return this._selected;
    }

    @Input()
    set selected(value: Tag[]) {
        this._selected = value;
        this.syncSelected();
    }

    @Input()
    set exerciseType(value: ExerciseType) {
        this.tagCategories = [];
        if (value) {
            this.tagsService.getTagCategories(value).then((it) => (this.tagCategories = it));
        }
    }

    toggle(tag: Tag): void {
        const index = this.selected.findIndex((it) => tag.uuid === it.uuid);
        if (index < 0) {
            this.selected.push(tag);
        } else {
            this.selected.splice(index, 1);
        }
        this.selected$.emit([...this.selected]);
    }

    isSelected(value: Tag) {
        return Boolean(this.selected?.some((it) => it.uuid === value.uuid));
    }

    private syncSelected() {
        if (this.tags?.length > 0) {
            this._selected = this.selected?.filter((it) => this.tags.find((tag) => tag.uuid === it.uuid));
            this.selected$.emit([...this.selected]);
        }
    }
}
