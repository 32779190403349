import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../config/services';
import { ApiService } from '../../api';
import * as buildinfo from 'preval-build-info';
import { Logger, LoggingService } from '../../logging/logging.service';
import { Platform } from '@ionic/angular';
import { HTTP } from '@ionic-native/http/ngx';

@Injectable({
    providedIn: 'root',
})
export class VersionService {
    frontendVersion: string;
    backendVersion: string;
    appStoreVersion: string;
    protected readonly log: Logger;

    constructor(
        private readonly http: HttpClient,
        private readonly configService: ConfigService,
        private loggingService: LoggingService,
        private platform: Platform,
        private cordovaHttp: HTTP,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
        // this.log.setLevel(LogLevel.DEBUG);
    }

    async setBackendVersion() {
        const url = ApiService.url;
        if (url) {
            const versionEndpoint = `${ApiService.url}_version`;
            try {
                this.backendVersion = await this.http.get(versionEndpoint, { responseType: 'text' }).toPromise();
            } catch (e) {
                // this.log.warn('Backend version not fetched')
            }
        }
    }

    async getVersionString(): Promise<string> {
        this.frontendVersion = this.configService.config.appVersion;
        let versionString = `Version:`;
        if (!this.frontendVersion && !this.backendVersion) {
            return `${versionString} unknown`;
        }
        if (this.frontendVersion) {
            versionString = `${versionString} App v${this.frontendVersion}`;
        }
        if (!this.backendVersion) {
            await this.setBackendVersion();
        }
        if (this.backendVersion) {
            versionString = `${versionString} API v${this.backendVersion}`;
        }
        return versionString;
    }

    async getBackendVersionString(): Promise<string> {
        let versionString = ``;
        if (!this.backendVersion) {
            await this.setBackendVersion();
        }
        if (this.backendVersion) {
            versionString = `${this.backendVersion}`;
        }
        return versionString;
    }

    async getFrontendVersionString(): Promise<string> {
        return this.configService.config.appVersion;
    }

    /**
     * Build information is generated on npm postinstall.
     */
    getBuildInfo() {
        return (buildinfo as any)?.default;
    }

    /**
     * Fetch the latest app version from the Apple App Store or Google Play Store
     * It calls up the website of the app in the store and selects the version from its HTML code with a CSS class selector
     * @return string the version in semantic versioning format (MAJOR.MINOR.PATCH)
     */
    async getLatestStoreVersion(): Promise<void> {
        this.log.debug('Fetching store version');
        let url: URL;
        let versionSearchRegExp: RegExp;
        if (!this.platform.is('ios') && !this.platform.is('android')) {
            this.log.warn('Did not check for app store version because platform is not mobile');
        } else if (this.platform.is('ios') && this.configService.config.iosAppStoreId) {
            this.log.debug('Platform is iOS, checking Apple App Store');
            url = new URL(`https://apps.apple.com/de/app/id${this.configService.config.iosAppStoreId}`);
            // Hard coded RegExp for the Apple App Store, in the format of "X.X.X/"
            versionSearchRegExp = new RegExp(
                '"(0|[1-9]\\d*)\\.(0|[1-9]\\d*)\\.(0|[1-9]\\d*)(?:-((?:0|[1-9]\\d*|\\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\\.(?:0|[1-9]\\d*|\\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\\+([0-9a-zA-Z-]+(?:\\.[0-9a-zA-Z-]+)*))?\\\\"',
                'g',
            );
        } else if (this.platform.is('android') && this.configService.config.androidPlayStoreId) {
            this.log.debug('Platform is Android, checking Google Play Store');
            url = new URL(
                `https://play.google.com/store/apps/details?id=${this.configService.config.androidPlayStoreId}`,
            );
            // Hard coded RegExp for the Google Play Store, in the format of "X.X.X"
            versionSearchRegExp = new RegExp(
                '"(0|[1-9]\\d*)\\.(0|[1-9]\\d*)\\.(0|[1-9]\\d*)(?:-((?:0|[1-9]\\d*|\\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\\.(?:0|[1-9]\\d*|\\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\\+([0-9a-zA-Z-]+(?:\\.[0-9a-zA-Z-]+)*))?"',
                'g',
            );
        } else {
            this.log.warn('Could not find app in the store, missing app ID for current platform');
        }
        if (url && versionSearchRegExp) {
            try {
                // The Cordova Http Client is used in order to circumvent CORS restrictions
                // Fetch the store website as a text response, which will contain its entire HTML code in string format
                const response = await this.cordovaHttp.get(url.toString(), { responseType: 'text' }, null);
                // Search for the parts of the string that match the version regular expression and extract them
                const matchingStrings = response.data.match(versionSearchRegExp);
                // The first element of the array should be the current version
                // Remove the starting and ending quotation characters from the matching string
                let versionString = matchingStrings[0].split('"')[1];
                // In the case of iOS we also need to remove a backslash at the end of the string
                // For Android this does not change the string
                versionString = versionString.split('\\')[0];
                this.log.debug('Found version text in store:', versionString);
                // Check if it matches the semantic versioning regular expression
                // See https://semver.org/#is-there-a-suggested-regular-expression-regex-to-check-a-semver-string
                const semVerRegExp = new RegExp(
                    '^(0|[1-9]\\d*)\\.(0|[1-9]\\d*)\\.(0|[1-9]\\d*)(?:-((?:0|[1-9]\\d*|\\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\\.(?:0|[1-9]\\d*|\\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\\+([0-9a-zA-Z-]+(?:\\.[0-9a-zA-Z-]+)*))?$',
                );
                if (semVerRegExp.test(versionString)) {
                    this.appStoreVersion = versionString;
                    this.log.debug('Saved store version as', this.appStoreVersion);
                } else {
                    this.log.warn(
                        'Could not find version information in the store, extracted version did not match semantic version format',
                    );
                }
            } catch (e) {
                this.log.warn('Unable to fetch app store version', e);
            }
        }
    }

    /**
     * Compares the installed app version to the latest version available in the Apple App Store / Google Play Store
     * @return boolean
     */
    async appNeedsUpdate(): Promise<boolean> {
        this.frontendVersion = this.configService.config.appVersion;
        await this.getLatestStoreVersion();
        if (this.frontendVersion && this.appStoreVersion) {
            this.log.debug('Comparing local version', this.frontendVersion, 'to store version', this.appStoreVersion);
            const splitFrontendVersion = this.frontendVersion.split('.');
            const splitStoreVersion = this.appStoreVersion.split('.');
            // Iterate on major, minor and patch versions in that order
            for (const [index, frontendVersion] of splitFrontendVersion.entries()) {
                // If the local version is higher than the store version, end the method and return false (no update available)
                if (Number(frontendVersion) > Number(splitStoreVersion[index])) {
                    this.log.debug('Local version is higher than store version, app does not need update');
                    return false;
                }
                // If the local version is lower than the store version, end the method and return true (update available)
                if (Number(frontendVersion) < Number(splitStoreVersion[index])) {
                    this.log.debug('Local version is lower than store version, app needs update');
                    return true;
                }
            }
            // If the iteration is over and it has not returned a value, then both versions are equal (no update available)
            this.log.debug('Local version and store version are equal, app does not need update');
            return false;
        } else {
            // If either of the versions is unknown, return false (no update available)
            this.log.warn(
                'Could not compare local version to store version.',
                '\nLocal version:',
                this.frontendVersion,
                '\nStore version:',
                this.appStoreVersion,
            );
            return false;
        }
    }
}
