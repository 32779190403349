import { NgModule } from '@angular/core';
import { UserComponentsModule } from './components/user-components.module';
import { CurafidaCommonModule } from '../common/curafida-common.module';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { MyMedaxModule } from '../my-medax/my-medax.module';
import { TableModule } from '../table/table.module';
import { FullNamePipe } from './full-name.pipe';

@NgModule({
    declarations: [FullNamePipe],
    imports: [
        CurafidaCommonModule,
        CommonModule,
        IonicModule,
        ReactiveFormsModule,
        FormsModule,
        TranslateModule,
        RouterModule,
        MyMedaxModule,
        TableModule,
    ],
    exports: [UserComponentsModule, FullNamePipe],
    providers: [FullNamePipe],
})
export class UserModule {}
