import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UsersService } from '../../services/user';
import { ToastService } from '../../../common/services/toast-service/toast-service.service';
import { User } from '../../../auth/entities/user';
import { IonicColor } from '../../../common/entities/toast/ionic-color';

@Component({
    selector: 'lib-send-verification-email',
    templateUrl: './send-verification-email.component.html',
    styleUrls: ['./send-verification-email.component.scss'],
})
export class SendVerificationEmailComponent {
    @Input() user: User;
    @Input() isMobile = false;

    constructor(
        public route: ActivatedRoute,
        private usersService: UsersService,
        protected toastService: ToastService,
    ) {}

    async sendVerificationEmail() {
        // TODO: disable when the email is still verified
        try {
            await this.usersService.sendUpdatePasswordActionEmail(this.user.username);
            await this.toastService.showToast('E-Mail erfolgreich gesendet', IonicColor.success);
        } catch (e) {
            if (e.status === 429) {
                await this.toastService.showToast(
                    'Es wurde bereits eine E-Mail zur Verifizierung versandt. ' +
                        'Das Versenden einer neuen E-Mail ist erst zu einem späteren Zeitpunkt möglich.',
                    IonicColor.danger,
                );
            } else {
                await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
            }
        }
    }
}
