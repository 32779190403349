import { Component } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { NgForOf, NgIf } from '@angular/common';
import { TranslationModule } from '../../../translation/translation.module';
import { CommonComponentsModule } from '../../../common/components/common-components.module';

@Component({
    standalone: true,
    selector: 'lib-kiosk-feedback-borg-modal',
    templateUrl: './kiosk-feedback-borg-modal.component.html',
    styleUrls: ['./kiosk-feedback-borg-modal.component.scss'],
    imports: [IonicModule, NgIf, TranslationModule, NgForOf, CommonComponentsModule],
})
export class KioskFeedbackBorgModalComponent {
    selectedBorgLevel: number;
    borgLevels: number[] = [...Array(11).keys()];

    constructor(private modalCtrl: ModalController) {}

    async dismissModal() {
        await this.modalCtrl.dismiss(this.selectedBorgLevel);
    }

    selectBorg(borg: number) {
        this.selectedBorgLevel = borg;
    }
}
