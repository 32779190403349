import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
    ExerciseSessionCalendarEvent,
    ExerciseSessionDto,
    ExerciseSessionState,
    ExerciseSubType,
    ExerciseToolSetting,
} from '../../../entities/exerciseSession';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { User, UserRoles } from '../../../../auth/entities/user';
import { ItemType } from '../../../../table/entities/table';
import { ToastService } from '../../../../common/services/toast-service/toast-service.service';
import { ExerciseSessionsService } from '../../../services/exercise-sessions';
import { IonicColor } from '../../../../common/entities/toast/ionic-color';
import { UserExerciseSessionsService } from '../../../services/user-exercise-sessions';
import { ModalController, NavController, PopoverController } from '@ionic/angular';
import { CurafidaAuthService } from '../../../../auth/services';
import { ExerciseSessionUserResult } from '../../../entities/exerciseSession/exercise-session-user-result';
import { QuestionnaireTemplate } from '../../../../my-medax/entities/questionnaire-template';
import { ExercisesService } from '../../../services/exercises';
import { UserListModalComponent } from '../../../../user/components/user-list-modal/user-list-modal.component';
import { ExerciseSessionAppointmentDto } from '../../../entities/appointement';
import { MimeTypeCurafida } from '../../../../common/entities/mime.type';
import { MyMedaxService } from '../../../../my-medax/services/my-medax.service';
import { Logger, LoggingService } from '../../../../logging/logging.service';
import { StringItemAdapterComponent } from '../../../../table/components/table-adapter/string-item-adapter.component';
import { CheckBoxItemAdapterComponent } from '../../../../table/components/table-adapter/checkbox-item-adapter.component';
import { Subscription } from 'rxjs';
import { addMinutes, differenceInMinutes, isAfter, isBefore, isPast } from 'date-fns';
import { SortBy, SortOrder } from '../../../../common/entities/paginated-response';
import { UntilDestroy } from '@ngneat/until-destroy';
import * as duration from 'duration-fns';
import { toString } from 'duration-fns';
import { ExerciseToolService } from '../../../services/exercise-tool.service';
import { catchError, filter, take } from 'rxjs/operators';
import { MyMedaxQuestionnaireSubmission } from '../../../../my-medax/entities/my-medax-questionnaire-submission';
import { ParticipationForm } from '../participation/participation.form';
import { ParticipationService } from '../participation/participation.service';
import { SelectInputItem } from '../../../../common/components/curafida-input/curafida-select-input/curafida-select-input.component';
import { Content } from '../../../entities/content';
import { TaskTemplateListModalComponent } from '../task-templates-list/task-template-list-modal.component';
import { TaskActionLinkName, TaskResource } from '../task.resource';
import { TaskTemplateResource } from '../../../entities/exercise/task-template.resource';
import { TranslateService } from '@ngx-translate/core';
import { CurafidaDateInputComponent } from '../../../../common/components/curafida-input/curafida-date-input/curafida-date-input.component';
import { FullNamePipe } from '../../../../user/full-name.pipe';
import { TaskService } from '../../../services/task/task.service';
import { ContentUsage } from '../../../entities/content/content-usage';
import { TaskTemplateService } from '../../../services/task-template/task-template.service';
import { HttpErrorResponse } from '@angular/common/http';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'task-form',
    templateUrl: './task-form.component.html',
    styleUrls: ['./task-form.component.scss'],
})
export class TaskFormComponent implements OnInit, OnDestroy {
    @ViewChild('calendarStartTimeDateInput') calendarStartTimeDateInput: CurafidaDateInputComponent;
    @ViewChild('calendarEndTimeDateInput') calendarEndTimeDateInput: CurafidaDateInputComponent;
    readonly subscriptions: Subscription[] = [];

    readonly exerciseSubType = ExerciseSubType;

    @Input() task: TaskResource;
    @Input() isMobile = false;
    @Input() isNew = false;
    @Input() patient: User;
    userRoleOfBetreuer = UserRoles.CAREGIVER;
    taskResult: ExerciseSessionUserResult;
    patientTaskForm: FormGroup;
    exerciseSubTypeList: { value: string; label: string }[] = [
        {
            value: 'MISSION',
            label: 'MISSION',
        },
        { value: 'QUESTIONNAIRE', label: 'QUESTIONNAIRE' },
        {
            value: 'FREETEXT',
            label: 'FREETEXT',
        },
        { value: 'VIDEO_CONFERENCE', label: 'VIDEO_CONFERENCE' },
        { value: 'LEARNING', label: 'LEARNING' },
    ];
    @Input() editCalendar: false;
    isEditEnabled = false;
    title = 'TASK.SINGULAR';
    subtitle = '';
    finishedTaskStates = [ExerciseSessionState.FINISHED, ExerciseSessionState.CANCELLED];
    selectedTaskTemplate: TaskTemplateResource;
    exerciseSessionState = ExerciseSessionState;
    form: QuestionnaireTemplate;
    loggedInUser: User;
    hideActions = false;
    fromMyMedax = false;
    myMedaxQuestionnaireSubmissions: MyMedaxQuestionnaireSubmission[];
    userRoles: UserRoles[] = [];
    UserRoles = UserRoles;
    isCheckBoxChecked = false;
    isButtonDisable = false;
    hasCalendarEvent: boolean;
    hasAppointment = false;
    calendarEventMaxDate: string;
    calendarEventMinDate: string;
    isLoadingFinished = false;
    participantsForm = new ParticipationForm(this.formBuilder);
    noQuestionnaireContent: boolean;
    appointment: ExerciseSessionAppointmentDto;
    hasRoundedMinutes = true;
    minutesStep = 5;
    ExerciseSubType = ExerciseSubType;
    protected readonly log: Logger;
    private responsible: User;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private toastService: ToastService,
        private authService: CurafidaAuthService,
        private route: ActivatedRoute,
        private exercisesService: ExercisesService,
        private exerciseSessionService: ExerciseSessionsService,
        private userExerciseSessionsService: UserExerciseSessionsService,
        private taskService: TaskService,
        private myMedaxService: MyMedaxService,
        private navCtrl: NavController,
        private modalCtrl: ModalController,
        private loggingService: LoggingService,
        private translate: TranslateService,
        readonly exerciseToolService: ExerciseToolService,
        readonly participationService: ParticipationService,
        readonly popoverController: PopoverController,
        readonly fullNamePipe: FullNamePipe,
        private taskTemplateService: TaskTemplateService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    get responsibleSelect(): SelectInputItem[] {
        return this.task?.exerciseSubType === ExerciseSubType.VIDEO_CONFERENCE ||
            this.selectedTaskTemplate?.exerciseSubType === ExerciseSubType.VIDEO_CONFERENCE
            ? [
                  {
                      value: this.userRoleOfBetreuer,
                      label: `USER.${this.userRoleOfBetreuer.toString()}.SINGULAR`,
                  },
                  {
                      value: UserRoles.SUPERVISOR,
                      label: `USER.${UserRoles.SUPERVISOR.toString()}.SINGULAR`,
                  },
                  {
                      value: UserRoles.PATIENT,
                      label: `USER.${UserRoles.PATIENT.toString()}.SINGULAR`,
                  },
              ]
            : [
                  {
                      value: UserRoles.PATIENT,
                      label: `USER.${UserRoles.PATIENT.toString()}.SINGULAR`,
                  },
                  {
                      value: this.userRoleOfBetreuer,
                      label: `USER.${this.userRoleOfBetreuer.toString()}.SINGULAR`,
                  },
                  {
                      value: UserRoles.SUPERVISOR,
                      label: `USER.${UserRoles.SUPERVISOR.toString()}.SINGULAR`,
                  },
                  { value: UserRoles.ANALYST, label: `USER.${UserRoles.ANALYST.toString()}.SINGULAR` },
              ];
    }

    async ngOnInit(): Promise<void> {
        if (this.isNew) {
            this.title = 'TASK.ADD';
        }
        this.loggedInUser = this.authService.getSession()?.user;
        this.subscriptions.push(
            this.route.queryParams.subscribe((params) => {
                // if this page was called after a redirection from mymedax a timeout is called
                // to give the async update of the exerciseSessionState some time
                if (params && params.fromMyMedax && params.fromMyMedax === 'true') {
                    setTimeout(async () => {
                        this.router.navigate([], { queryParams: { fromMyMedax: null }, queryParamsHandling: 'merge' });
                    }, 1000);
                }
            }),
            this.participationService.model$.subscribe((it) => {
                if (it.taskParticipation) {
                    const form = new ParticipationForm(this.formBuilder);
                    form.apply(it.taskParticipation);
                    this.participantsForm = form;
                }
            }),
        );
        await this.initComponent();
        if (this.task?._links) this.participationService.init(this.task);
    }

    onAppointmentChanged(exerciseSessionAppointment: ExerciseSessionAppointmentDto): void {
        this.appointment = exerciseSessionAppointment;
        this.patientTaskForm.controls['appointment'].patchValue(this.appointment);
        this.calendarEventMinDate = exerciseSessionAppointment.startTime;
        this.calendarEventMaxDate = exerciseSessionAppointment.endTime;
        this.onStartCalendarEventChange(exerciseSessionAppointment.startTime);
    }

    updateVideoSystem(value: string): void {
        this.exerciseToolService.select(value);
        const it = this.exerciseToolService.currentSelected;
        if (it?.config?.isUrlEnabled) {
            this.patientTaskForm.controls.url.enable();
        } else {
            this.patientTaskForm.controls.url.disable();
        }
        this.checkForm();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((it) => it.unsubscribe());
    }

    cancelEdit(): void {
        if (this.isEditEnabled) {
            this.toggleEdit();
            this.initTaskForm();
        }
    }

    toggleEdit(): void {
        this.isEditEnabled = !this.isEditEnabled;
        this.checkForm();
        Object.keys(this.patientTaskForm.controls)
            .filter((control) => !['xapiContent', 'learningLessonDetail', 'url', 'exerciseSubType'].includes(control))
            .forEach((it) =>
                this.isEditEnabled ? this.patientTaskForm.get(it).enable() : this.patientTaskForm.get(it).disable(),
            );
    }

    async openTaskTemplateModal(): Promise<void> {
        if (this.patientTaskForm.controls.taskTemplateTitle.disabled) return;

        const modal = await this.modalCtrl.create({
            component: TaskTemplateListModalComponent,
            cssClass: 'full-width-modal',
            componentProps: {
                title: this.translate.instant('TASK.TEMPLATE.ITEM.SELECT'),
                searchString: this.translate.instant('TASK.TEMPLATE.ITEM.SEARCH'),
                selectedTaskTemplate: this.selectedTaskTemplate,
                anyItem: 'ANY_HOMEWORK_TEMPLATE',
                hasTags: true,
                hasFilter: true,
                titleColName: this.translate.instant('NAME'),
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data) {
            this.selectedTaskTemplate = await this.taskTemplateService.getTaskTemplateById(data.id);

            this.patientTaskForm.controls.taskTemplateTitle.patchValue(this.selectedTaskTemplate.title);
            this.patientTaskForm.controls.title.patchValue(this.selectedTaskTemplate.title);
            this.patientTaskForm.controls.description.patchValue(this.selectedTaskTemplate.description);
            this.patientTaskForm.controls.exerciseDuration.patchValue(
                duration.toMinutes(duration.parse(this.selectedTaskTemplate?.duration)).toString(),
            );
            this.onDurationChange(this.patientTaskForm.controls.exerciseDuration.value);
            this.patientTaskForm.controls.exerciseSessionDuration.patchValue(
                duration.toMinutes(duration.parse(this.selectedTaskTemplate?.duration)).toString(),
            );
            await this.initQuestionnaire();
            this.patientTaskForm.controls.exerciseSubType.patchValue(this.selectedTaskTemplate.exerciseSubType);
            if (this.selectedTaskTemplate.notifyOnStartTime !== undefined) {
                this.patientTaskForm.controls.notifyOnStartTime.patchValue(this.selectedTaskTemplate.notifyOnStartTime);
            }
            if (this.selectedTaskTemplate.notifyOnDelayedTime !== undefined) {
                this.patientTaskForm.controls.notifyOnDelayedTime.patchValue(
                    this.selectedTaskTemplate.notifyOnDelayedTime,
                );
            }
            if (this.selectedTaskTemplate.notifyOnEndTime !== undefined) {
                this.patientTaskForm.controls.notifyOnEndTime.patchValue(this.selectedTaskTemplate.notifyOnEndTime);
            }
            this.patientTaskForm.controls.isAutoFinished.patchValue(this.selectedTaskTemplate.isAutoFinished);
            if (this.selectedTaskTemplate.exerciseSubType === ExerciseSubType.QUESTIONNAIRE) {
                const formContent = this.selectedTaskTemplate.contents
                    ? this.selectedTaskTemplate.contents.find((c) => c.mimeType === MimeTypeCurafida.MY_MEDAX)
                    : null;
                const questionnaireTemplate = formContent?.jsonData as QuestionnaireTemplate;
                if (questionnaireTemplate?.isImpermanent) {
                    this.patientTaskForm.controls.responsibleRole.disable();
                } else {
                    this.patientTaskForm.controls.responsibleRole.enable();
                }
            } else {
                this.patientTaskForm.controls.responsibleRole.enable();
            }
            this.patientTaskForm.controls.responsibleRole.patchValue(this.selectedTaskTemplate.responsibleUserRole);
            if (this.selectedTaskTemplate.responsibleUserRole === UserRoles.PATIENT) {
                this.patientTaskForm.controls.responsible.disable();
                this.patientTaskForm.controls.responsible.patchValue(
                    `${this.patient.lastname}, ${this.patient.firstname}`,
                );
                this.responsible = this.patient;
            }
            if (this.form) {
                this.patientTaskForm.controls.form.patchValue(this.form.title);
            }
            if (
                this.selectedTaskTemplate.exerciseSubType === ExerciseSubType.VIDEO_CONFERENCE &&
                this.selectedTaskTemplate.exerciseToolSettings.enabledTools.length > 0
            ) {
                this.exerciseToolService.options$
                    .pipe(
                        filter((it) => it?.length > 0),
                        take(1),
                    )
                    .subscribe(() => {
                        this.updateVideoSystem(this.selectedTaskTemplate.exerciseToolSettings.enabledTools[0].name);
                        this.patientTaskForm.controls.system.patchValue(
                            this.selectedTaskTemplate.exerciseToolSettings.enabledTools[0].name,
                        );
                        this.patientTaskForm.controls.url.patchValue(
                            this.selectedTaskTemplate.exerciseToolSettings.enabledTools[0].config?.url,
                        );
                    });
            }
            if (this.selectedTaskTemplate.exerciseSubType === ExerciseSubType.LEARNING) {
                this.patientTaskForm.controls.responsible.disable();
                this.patientTaskForm.controls.responsible.patchValue(
                    `${this.patient.lastname}, ${this.patient.firstname}`,
                );
                this.responsible = this.patient;
            }
        }
    }

    async openUserModal(): Promise<void> {
        if (this.patientTaskForm.controls.responsible.disabled) return;
        const responsibleRole = this.patientTaskForm.controls.responsibleRole.value;
        if (responsibleRole === UserRoles.PATIENT) return;
        const tableItems = [
            {
                id: 'selected',
                prop: 'selected',
                name: '',
                adapter: CheckBoxItemAdapterComponent,
                type: ItemType.ADAPTER,
                width: '10%',
                sortOrderWeb: 0,
            },
            {
                id: 'lastname',
                prop: 'lastname',
                header: 'LAST_NAME',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '30%',
                sortOrderWeb: 1,
            },
            {
                id: 'firstname',
                prop: 'firstname',
                header: 'FIRST_NAME',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '30%',
                sortOrderWeb: 2,
            },
            {
                id: 'username',
                prop: 'username',
                name: 'USERNAME',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '30%',
                sortOrderWeb: 3,
            },
        ];

        //const users = this.task?.responsible ? [new User(this.task.responsible)] : [];
        const users = this.task?.responsibility.person?.username
            ? [new User(this.task?.responsibility.person?.username)]
            : [];
        let noItemsText = 'ANY_USER';
        let modalTitle = 'USER.CAREGIVER.CHOOSE';
        if (responsibleRole === UserRoles.SUPERVISOR) {
            noItemsText =
                'Diesem Patienten sind keine Co-Betreuer zugeordnet. Co-Betreuer können einem Patient über ' +
                'die Stammdatenverwaltung zugeordnet werden.';
            modalTitle = 'USER.SUPERVISOR.CHOOSE';
        }
        const modal = await this.modalCtrl.create({
            component: UserListModalComponent,
            cssClass: 'full-width-modal',
            showBackdrop: true,
            backdropDismiss: false,
            componentProps: {
                title: modalTitle,
                selectedUsers: users,
                isMultipleChoice: false,
                role: responsibleRole,
                tableItems,
                anyItem: noItemsText,
                concernedUser: this.patient.username,
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data) {
            if (data[0]) {
                this.responsible = data[0];
                const participationForm = new ParticipationForm(
                    this.formBuilder,
                    this.participantsForm.group.controls.caregivers.value,
                    this.participantsForm.group.controls.supervisors.value,
                );
                if (responsibleRole === UserRoles.CAREGIVER) {
                    participationForm.pushCaregiver(this.responsible.username);
                    this.participantsForm = participationForm;
                } else if (responsibleRole === UserRoles.SUPERVISOR) {
                    participationForm.pushSupervisor(this.responsible.username);
                    this.participantsForm = participationForm;
                }
                if (this.task?.responsibility?.person) {
                    this.task.responsibility.person.username = this.responsible.username;
                }
                this.patientTaskForm.controls.responsible.patchValue(
                    `${this.responsible.firstname} ${this.responsible.lastname}`,
                );
            } else {
                this.responsible = null;
                const participationForm = new ParticipationForm(
                    this.formBuilder,
                    this.participantsForm.group.controls.caregivers.value,
                    this.participantsForm.group.controls.supervisors.value,
                );
                this.patientTaskForm.controls.responsible.patchValue('');
            }
            this.patientTaskForm.controls.responsible.markAsDirty();
        }
    }

    updateResponsibleControl(): void {
        if (this.patientTaskForm.controls.responsibleRole.value === UserRoles.PATIENT) {
            this.patientTaskForm.controls.responsible.disable();
            this.patientTaskForm.controls.responsible.patchValue(`${this.patient.lastname}, ${this.patient.firstname}`);
            this.responsible = this.patient;
        } else {
            if (this.task) this.task.responsibility.person.username = null;
            this.responsible = null;
            this.patientTaskForm.controls.responsible.patchValue(``);
            this.patientTaskForm.controls.responsible.markAsDirty();
            this.patientTaskForm.controls.responsible.enable();
            if (this.patientTaskForm.controls.responsibleRole.value === UserRoles.CAREGIVER) {
                this.patientTaskForm.controls.responsible.patchValue(this.translate.instant('TASK.RESPONSIBLE_ROLES'));
            }
            if (this.patientTaskForm.controls.responsibleRole.value === UserRoles.SUPERVISOR) {
                this.patientTaskForm.controls.responsible.patchValue(
                    this.translate.instant('TASK.RESPONSIBLE_SUPERVISORS'),
                );
            }
        }
    }

    async savePatientTask(): Promise<void> {
        this.isButtonDisable = true;
        const newPatientTask = new ExerciseSessionDto();

        newPatientTask.exercise_id = this.selectedTaskTemplate.id;
        newPatientTask.exerciseSubType = this.patientTaskForm.controls.exerciseSubType.value;
        newPatientTask.title = this.patientTaskForm.controls.title.value;
        newPatientTask.description = this.patientTaskForm.controls.description.value;
        newPatientTask.duration = toString({
            minutes: Number(this.patientTaskForm.controls.exerciseSessionDuration.value),
        });
        newPatientTask.responsible = this.responsible ? this.responsible.username : null;
        newPatientTask.needsSchedule = this.hasAppointment && !!this.appointment;
        newPatientTask.autoNoShow = this.hasAppointment && !!this.appointment;
        newPatientTask.notifyOnStartTime = this.patientTaskForm.controls.notifyOnStartTime.value;
        newPatientTask.notifyOnDelayedTime = this.patientTaskForm.controls.notifyOnDelayedTime.value;
        newPatientTask.notifyOnEndTime = this.patientTaskForm.controls.notifyOnEndTime.value;
        newPatientTask.isAutoFinished = this.patientTaskForm.controls.isAutoFinished.value;
        newPatientTask.concernedUsername = this.patient.username;
        if (newPatientTask.exerciseSubType === ExerciseSubType.VIDEO_CONFERENCE) {
            newPatientTask.exerciseToolSettings = {
                enabledTools: [
                    new ExerciseToolSetting(this.selectedTaskTemplate.exerciseToolSettings.enabledTools[0].name),
                ],
            };
            if (this.patientTaskForm.controls.url.value) {
                newPatientTask.exerciseToolSettings.enabledTools[0].config = {
                    url: this.patientTaskForm.controls.url.value,
                };
            }
        }
        try {
            const created = await this.taskService.createTask(this.patient.username, newPatientTask);
            if (this.isParticipationFormShown() && this.participantsForm.group.controls.isActivated.value) {
                this.participationService.create(created, this.participantsForm.toDto()).subscribe({
                    error: (response: HttpErrorResponse) =>
                        this.toastService.showToast(response.error.message, IonicColor.danger),
                });
            }
            if (this.hasAppointment) {
                // This method cannot be called by supervisors.
                try {
                    await this.exerciseSessionService.putTaskAppointment(created, this.appointment);
                } catch (e) {
                    await this.exerciseSessionService.deleteExerciseSession(created.id);
                    await this.toastService.showToast('APPOINTMENT_CREATED_FAILURE_MESSAGE', IonicColor.danger);
                    return this.dismissModal();
                }
            }
            try {
                // TODO: @deprecated in newer backend versions (still needed in API v7.36.1, see Ticket #2571)
                if (
                    created.exerciseSessionState === ExerciseSessionState.SCHEDULED ||
                    created.exerciseSessionState === ExerciseSessionState.NOT_PLANNED
                ) {
                    // TODO: move this step to if (newPatientTask.needsSchedule)
                    if (newPatientTask.needsSchedule) {
                        await this.exerciseSessionService.postTaskAction(created, TaskActionLinkName.CONFIRM_PLANNING);
                    }
                    if (!newPatientTask.needsSchedule && newPatientTask.autoActivation) {
                        await this.exerciseSessionService.postTaskAction(
                            created,
                            TaskActionLinkName.RESPONSIBLE_ACTIVATE,
                        );
                    }
                }
            } catch (e) {
                await this.exerciseSessionService.deleteExerciseSession(created.id);
                this.log.error('Error in savePatientTask', e);
                await this.toastService.showToast('APPOINTMENT_CREATED_FAILURE_MESSAGE', IonicColor.danger);
                return this.dismissModal();
            }
            if (this.hasCalendarEvent) {
                try {
                    const esce = new ExerciseSessionCalendarEvent(
                        created.id,
                        this.patientTaskForm.controls.calendarStartTime.value,
                        this.patientTaskForm.controls.calendarEndTime.value,
                    );
                    await this.userExerciseSessionsService.updateExerciseSessionCalendarEvent(
                        this.patient.username,
                        created.id,
                        esce,
                    );
                } catch (e) {
                    this.log.error('Error in add calendarEvent', e);
                    await this.toastService.showToast('APPOINTMENT_CREATED_FAILURE_MESSAGE', IonicColor.danger);
                    return this.dismissModal();
                }
            }
        } catch (e) {
            this.log.error('Error in savePatientTask', e);
            this.isButtonDisable = false;
            await this.toastService.showToast('SAVE_TASK_FAILURE_MESSAGE', IonicColor.danger);
        } finally {
            await this.toastService.showToast('SAVE_TASK_SUCCESS_MESSAGE', IonicColor.success);
            await this.dismissModal();
        }
    }

    async initMyMedaxQuestionnaireSubmissions() {
        try {
            const submissions = await this.myMedaxService.getQuestionnaireSubmissionsByUser(
                this.patient.username,
                0,
                null,
                SortBy.UPDATED_AT,
                SortOrder.DESC,
            );
            if (submissions.items?.length > 0) {
                const versioningUuid = submissions.items.find(
                    (x) => x.pdfContent === this.taskResult.contentUuid,
                ).submissionVersioningUuid;
                this.myMedaxQuestionnaireSubmissions = submissions?.items?.filter(
                    (x) => x.submissionVersioningUuid === versioningUuid,
                );
                this.myMedaxQuestionnaireSubmissions.sort((a, b) => {
                    if (isBefore(new Date(a.created_at), new Date(b.created_at))) {
                        return 1;
                    } else if (isAfter(new Date(a.created_at), new Date(b.created_at))) {
                        return -1;
                    }
                    return 0;
                });
            }
        } catch (e) {
            this.log.error('Error in initMyMedaxQuestionnaireSubmissions', e);
            await this.toastService.showToast('ERROR_LOADING_FORM', IonicColor.danger);
        }
    }

    onHasAppointmentClicked(value: any): void {
        this.hasAppointment = value.detail.checked;
        if (this.hasAppointment) {
            this.patientTaskForm.controls['appointment'].setValidators(Validators.required);
            return;
        }
        this.calendarEventMinDate = undefined;
        this.calendarEventMaxDate = undefined;
        this.patientTaskForm.controls['appointment'].removeValidators(Validators.required);
    }

    async dismissModal(): Promise<void> {
        const queryParams: Params = { fromMyMedax: null, taskId: null };
        await this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams,
            queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
        await this.modalCtrl.dismiss('true');
    }

    onHasCalendarEventClicked(value: any): void {
        this.hasCalendarEvent = value.detail.checked;
        if (value.detail.checked) {
            const calendarStartTime = this.task?.calendarEvent?.startTime
                ? this.task.calendarEvent.startTime
                : new Date().toISOString();
            this.calendarEventMinDate = this.calendarEventMinDate ?? new Date().toISOString();
            /* Programmatically trigger the adjustment when the start time changes */
            this.onStartCalendarEventChange(calendarStartTime);
            this.patientTaskForm.controls['calendarStartTime'].patchValue(calendarStartTime);
            this.patientTaskForm.controls['calendarStartTime'].setValidators(Validators.required);
            this.patientTaskForm.controls['calendarStartTime'].updateValueAndValidity();
            this.patientTaskForm.controls['calendarEndTime'].setValidators(Validators.required);
            this.patientTaskForm.controls['calendarEndTime'].updateValueAndValidity();
            this.patientTaskForm.controls['exerciseSessionDuration'].setValidators(Validators.required);
            this.patientTaskForm.controls['exerciseSessionDuration'].addValidators(Validators.min(1));
            this.patientTaskForm.controls['exerciseSessionDuration'].updateValueAndValidity();
        } else {
            this.calendarEventMinDate = undefined;
            this.patientTaskForm.controls['calendarStartTime'].setValidators([]);
            this.patientTaskForm.controls['calendarStartTime'].updateValueAndValidity();
            this.patientTaskForm.controls['calendarEndTime'].setValidators([]);
            this.patientTaskForm.controls['calendarEndTime'].updateValueAndValidity();
            this.patientTaskForm.controls['exerciseSessionDuration'].setValidators([]);
            this.patientTaskForm.controls['exerciseSessionDuration'].updateValueAndValidity();
        }
    }

    onStartCalendarEventChange(value: string): void {
        const newValueAsDate = new Date(value);
        let currentStartTime = new Date(this.patientTaskForm.controls.calendarStartTime.value);
        let currentEndTime = new Date(this.patientTaskForm.controls.calendarEndTime.value);
        /* Only use the new value if it is not in the past */
        if (!isPast(newValueAsDate)) {
            this.patientTaskForm.controls.calendarStartTime.patchValue(value);
            currentStartTime = newValueAsDate;
        }
        /* If the task has a pre-defined duration, set the end time accordingly */
        if (this.patientTaskForm.controls.exerciseSessionDuration.value) {
            this.patientTaskForm.controls.calendarEndTime.patchValue(
                addMinutes(
                    currentStartTime,
                    Number(this.patientTaskForm.controls.exerciseSessionDuration.value),
                ).toISOString(),
            );
            currentEndTime = new Date(this.patientTaskForm.controls.calendarEndTime.value);
        }
        /* If the start time is after the end time, adjust the end time and add some minutes (they can't be equal) */
        if (isAfter(currentStartTime, currentEndTime)) {
            this.patientTaskForm.controls.calendarEndTime.patchValue(
                addMinutes(new Date(this.patientTaskForm.controls.calendarStartTime.value), 5).toISOString(),
            );
        }
    }

    async onEndCalendarEventChange(value: string): Promise<void> {
        const newValueAsDate = new Date(value);
        const currentStartTime = new Date(this.patientTaskForm.controls.calendarStartTime.value);
        let currentEndTime = new Date(this.patientTaskForm.controls.calendarEndTime.value);
        let newDuration = toString({ minutes: differenceInMinutes(newValueAsDate, currentStartTime) });
        /* Only use the new value if the new duration will be greater than or equal to one minute */
        if (duration.toMinutes(duration.parse(newDuration)) >= 1) {
            currentEndTime = newValueAsDate;
        }
        /* If the duration will be lower than one minute, adjust the end time with the original duration */
        if (duration.toMinutes(duration.parse(newDuration)) < 1) {
            currentEndTime = addMinutes(
                currentStartTime,
                Number(this.patientTaskForm.controls.exerciseSessionDuration.value),
            );
            /* Re-calculate the new duration with the adjustments */
            newDuration = toString({ minutes: differenceInMinutes(currentEndTime, currentStartTime) });
            await this.toastService.showToast('APPOINTMENT_DURATION_MESSAGE', IonicColor.danger);
        }
        /* Finally set both the new duration and the new end time in the FormControls */
        this.patientTaskForm.controls.calendarEndTime.patchValue(currentEndTime.toISOString());
        this.patientTaskForm.controls.exerciseSessionDuration.patchValue(
            duration.toMinutes(duration.parse(newDuration)).toString(),
        );
    }

    onDurationChange(value: string): void {
        this.patientTaskForm.controls.calendarEndTime.patchValue(
            addMinutes(new Date(this.patientTaskForm.controls.calendarStartTime.value), Number(value)).toISOString(),
        );
    }

    checkForm(): void {
        if (this.patientTaskForm.controls.exerciseSubType.value === ExerciseSubType.VIDEO_CONFERENCE) {
            if (!this.patientTaskForm.get('system').enabled) {
                this.exerciseToolService.loadVideoTools({ exerciseSubType: ExerciseSubType.VIDEO_CONFERENCE });
            }
            this.patientTaskForm.controls.system.addValidators(Validators.required);
            this.patientTaskForm.get('system').enable();
        } else {
            this.patientTaskForm.get('system').disable();
            this.patientTaskForm.get('url').disable();
        }
        this.noQuestionnaireContent = this.hasOnlyNoQuestionnaireAndLearningContent(
            this.selectedTaskTemplate?.contents,
        );
    }

    async updateTask(): Promise<void> {
        try {
            if (!this.hasCalendarEvent) {
                this.taskService
                    .deleteTaskCalendarEvent(this.task)
                    ?.pipe(
                        catchError(() =>
                            this.toastService.showToast('TASK.CALENDAR_EVENT.DELETE_ERROR_MESSAGE', IonicColor.danger),
                        ),
                    )
                    .subscribe();
            } else {
                await this.updateCalendarEvent();
            }

            if (!this.hasAppointment) {
                this.taskService
                    .deleteTaskAppointment(this.task)
                    ?.pipe(
                        catchError(() =>
                            this.toastService.showToast('TASK.APPOINTMENT.DELETE_ERROR_MESSAGE', IonicColor.danger),
                        ),
                    )
                    .subscribe();
            } else {
                await this.updateAppointment();
            }

            if (this.participantsForm.hasChanged) {
                await this.updateParticipants();
            }
            if (this.patientTaskForm.controls.responsible.dirty) await this.updateResponsible(this.responsible);
        } finally {
            this.dismissModal();
        }
    }

    async updateResponsible(responsible: User): Promise<void> {
        const newResponsibleUsername = this.responsible?.username ? this.responsible.username : '';
        this.taskService.updateResponsible(this.task, { username: newResponsibleUsername });
    }

    async updateParticipants(): Promise<void> {
        if (this.participantsForm.hasChanged) {
            if (this.isParticipationFormShown() && this.participantsForm.group.controls.isActivated.value) {
                this.participationService.create(this.task, this.participantsForm.toDto()).subscribe({
                    error: (response: HttpErrorResponse) =>
                        this.toastService.showToast(response.error.message, IonicColor.danger),
                });
            }
        }
    }

    async updateAppointment(): Promise<void> {
        try {
            await this.exerciseSessionService.putTaskAppointment(this.task, this.appointment);
        } catch (e) {
            this.log.error('Error in update appointment', e);
            await this.toastService.showToast('TASK.APPOINTMENT.SET_ERROR_MESSAGE', IonicColor.danger);
        }
    }

    async updateCalendarEvent(): Promise<void> {
        try {
            const esce = new ExerciseSessionCalendarEvent(
                Number(this.task.id),
                this.patientTaskForm.controls.calendarStartTime.value,
                this.patientTaskForm.controls.calendarEndTime.value,
            );
            await this.userExerciseSessionsService.updateExerciseSessionCalendarEvent(
                this.patient.username,
                Number(this.task.id),
                esce,
            );
        } catch (e) {
            this.log.error('Error in add calendarEvent', e);
            await this.toastService.showToast('APPOINTMENT_CREATED_FAILURE_MESSAGE', IonicColor.danger);
        }
    }

    isParticipationFormShown(): boolean {
        return Boolean(
            this.patientTaskForm.controls.hasCalendarEvent.enabled &&
                this.patientTaskForm.controls.hasCalendarEvent.value &&
                this.patientTaskForm.controls.calendarStartTime.value &&
                this.patientTaskForm.controls.calendarEndTime.value,
        );
    }

    hasOnlyNoQuestionnaireAndLearningContent(contents: Content[]) {
        if (!contents) return false;
        contents = contents.filter((i) => {
            return (
                i.mimeType !== 'application/vnd.my-medax-questionnaire-template+json' &&
                i.contentUsage !== ContentUsage.ARTICULATE_SUMMARY_CONTENT &&
                i.contentUsage !== ContentUsage.ARTICULATE_CONTENT
            );
        });
        return contents.length > 0;
    }

    setMinutesStepAndRoundedMinutes() {
        this.minutesStep = 5;
        this.hasRoundedMinutes = true;
        this.calendarStartTimeDateInput.config.minutesInterval = 5;
        this.calendarStartTimeDateInput.minuteStep = 5;
        this.calendarStartTimeDateInput.roundedMinutes = true;
        // this.calendarStartTimeDateInput.initDatePickerConfig();

        this.calendarEndTimeDateInput.config.minutesInterval = 5;
        this.calendarEndTimeDateInput.minuteStep = 5;
        this.calendarEndTimeDateInput.roundedMinutes = true;
        // this.calendarEndTimeDateInput.initDatePickerConfig();
    }

    changeCalendarInputConfig() {
        this.hasRoundedMinutes = false;
        this.minutesStep = 1;
    }

    private async initQuestionnaire() {
        if (!this.selectedTaskTemplate) return;
        if (!this.selectedTaskTemplate.contents) {
            this.selectedTaskTemplate.contents = await this.exercisesService.getContentsFromExercise(
                this.selectedTaskTemplate.id,
            );
        }
        if (
            this.selectedTaskTemplate.contents.length > 0 &&
            this.selectedTaskTemplate.contents[0].mimeType === MimeTypeCurafida.MY_MEDAX
        ) {
            this.form = this.selectedTaskTemplate.contents[0].jsonData as QuestionnaireTemplate;
            if (this.form.isImpermanent) {
                this.hideActions = this.loggedInUser.username !== this.patient.username;
            }
        } else {
            this.form = null;
        }
    }

    private async initComponent() {
        if (!this.task) {
            this.isNew = true;
        } else {
            this.task = await this.taskService.fetchTask(this.task.id);
        }
        this.userRoles = (await this.authService.getRoles()) as UserRoles[];
        if (this.isNew && !this.userRoles.includes(UserRoles.manage_therapy)) {
            this.navCtrl.back();
        }
        if (this.isNew) {
            this.isEditEnabled = true;
            this.hasAppointment = false;
        } else {
            this.isEditEnabled = false;
            this.taskResult = this.task.exerciseSessionUserResults?.find((x) => x.content.mimeType.includes('pdf'));
            this.selectedTaskTemplate = this.task.taskTemplate;
            await this.initQuestionnaire();
            if (this.taskResult?.contentUuid) await this.initMyMedaxQuestionnaireSubmissions();
        }

        if (this.editCalendar) {
            this.isEditEnabled = true;
        }
        this.initTaskForm();
        if (this.task) this.isCheckBoxChecked = this.finishedTaskStates.includes(this.task.exerciseSessionState);
    }

    private initTaskForm() {
        const title = this.task ? this.task?.title : '';
        const description = this.task ? this.task?.description : '';
        const exerciseDuration = this.task?.duration
            ? duration.toMinutes(duration.parse(this.task?.duration)).toString()
            : null;
        let exerciseSessionDuration: number;
        if (this.task?.calendarEvent?.startTime && this.task?.calendarEvent?.endTime) {
            exerciseSessionDuration = differenceInMinutes(
                new Date(this.task.calendarEvent.endTime),
                new Date(this.task.calendarEvent.startTime),
            );
        } else {
            exerciseSessionDuration = this.task?.duration
                ? duration.toMinutes(duration.parse(this.task?.duration))
                : null;
        }
        const responsibleRole = this.task ? this.task?.responsibility.role : null;
        const calendarStartTime = this.task?.calendarEvent?.startTime
            ? this.task.calendarEvent.startTime
            : new Date().toISOString();
        const calendarEndTime = this.task?.calendarEvent?.endTime
            ? this.task.calendarEvent.endTime
            : exerciseDuration
              ? addMinutes(new Date(), Number(exerciseDuration)).toISOString()
              : new Date().toISOString();
        if (this.task?.calendarEvent) this.hasCalendarEvent = true;
        let taskTemplateTitle = this.selectedTaskTemplate ? this.selectedTaskTemplate.title : '';
        if (this.task?.responsibility?.person) {
            this.task.responsibleFullName = this.fullNamePipe.transform({
                firstname: this.task.responsibility.person.firstname,
                lastname: this.task.responsibility.person.lastname,
            });
        }
        let responsible = this.task?.responsibleFullName ? `${this.task.responsibleFullName}` : '';
        if (!responsible && responsibleRole === UserRoles.PATIENT) {
            responsible = `${this.patient.lastname}, ${this.patient.firstname}`;
        }
        if (this.task?.appointment) {
            this.appointment = {
                startTime: this.task.appointment.startTime,
                delayedTime: this.task.appointment.delayedTime,
                endTime: this.task.appointment.endTime,
            };
        }
        if (this.task?.appointment?.startTime) {
            this.hasAppointment = true;
        }
        let system: string, url: string;
        if (this.task?.exerciseSubType === ExerciseSubType.VIDEO_CONFERENCE) {
            const tool = this.task?.exerciseToolSettings?.enabledTools[0];
            system = tool?.name;
            url = tool?.config?.url;
        }

        const form = this.form ? this.form.title : '';
        if (this.selectedTaskTemplate) taskTemplateTitle = this.selectedTaskTemplate.title;

        const exerciseSubType = this.task?.exerciseSubType ? this.task.exerciseSubType : null;
        const notifyOnStartTime = this.task ? this.task.notifyOnStartTime : true;
        const notifyOnDelayedTime = this.task ? this.task.notifyOnDelayedTime : true;
        const notifyOnEndTime = this.task ? this.task.notifyOnEndTime : true;
        const isAutoFinished = this.task ? this.task.isAutoFinished : true;
        this.patientTaskForm = this.formBuilder.group({
            taskTemplateTitle: new FormControl(
                {
                    value: taskTemplateTitle,
                    disabled: !this.isNew,
                },
                Validators.required,
            ),
            title: new FormControl(
                {
                    value: title,
                    disabled: !this.isNew,
                },
                [Validators.required, Validators.maxLength(255)],
            ),
            description: new FormControl({ value: description, disabled: !this.isNew }),
            exerciseSubType: new FormControl({ value: exerciseSubType, disabled: true }, Validators.required),
            hasCalendarEvent: new FormControl({ value: this.hasCalendarEvent, disabled: !this.isEditEnabled }),
            calendarStartTime: new FormControl({ value: calendarStartTime, disabled: !this.isEditEnabled }),
            calendarEndTime: new FormControl({ value: calendarEndTime, disabled: !this.isEditEnabled }),
            form: new FormControl({ value: form, disabled: true }),
            responsibleRole: new FormControl({ value: responsibleRole, disabled: true }, Validators.required),
            responsible: new FormControl({ value: responsible, disabled: !this.isEditEnabled }),
            exerciseDuration: new FormControl({ value: exerciseDuration, disabled: true }),
            exerciseSessionDuration: new FormControl({ value: exerciseSessionDuration, disabled: !this.isEditEnabled }),
            hasAppointment: new FormControl({ value: this.hasAppointment, disabled: !this.isEditEnabled }),
            system: new FormControl({ value: system, disabled: true }),
            url: new FormControl({ value: url, disabled: true }),
            notifyOnStartTime: new FormControl({ value: notifyOnStartTime, disabled: !this.isEditEnabled }),
            notifyOnDelayedTime: new FormControl({ value: notifyOnDelayedTime, disabled: !this.isEditEnabled }),
            notifyOnEndTime: new FormControl({ value: notifyOnEndTime, disabled: !this.isEditEnabled }),
            isAutoFinished: new FormControl({ value: isAutoFinished, disabled: !this.isEditEnabled }),
            learningLessonDetail: new FormControl({ value: '', disabled: true }),
            appointment: new FormControl<ExerciseSessionAppointmentDto>({
                value: this.appointment,
                disabled: !this.isEditEnabled,
            }),
        });
        this.checkForm();
        if (!this.isNew) {
            this.patientTaskForm.controls.hasCalendarEvent.enable();
            this.patientTaskForm.controls.calendarStartTime.enable();
            this.patientTaskForm.controls.calendarEndTime.enable();
            this.patientTaskForm.controls.exerciseSessionDuration.enable();
            this.patientTaskForm.controls.responsible.enable();
        }
        if (responsibleRole === UserRoles.PATIENT) {
            this.patientTaskForm.controls.responsible.disable();
        }
        if (responsibleRole === UserRoles.PATIENT) {
            this.patientTaskForm.controls.responsible.disable();
        }
        this.subscriptions.push(
            this.exerciseToolService.options$
                .pipe(
                    filter((it) => it?.length > 0),
                    take(1),
                )
                .subscribe(() => {
                    if (
                        this.task?.exerciseSubType === ExerciseSubType.VIDEO_CONFERENCE &&
                        this.task.exerciseToolSettings?.enabledTools?.length > 0
                    ) {
                        this.updateVideoSystem(this.selectedTaskTemplate.exerciseToolSettings.enabledTools[0].name);
                        this.patientTaskForm.controls.system.patchValue(
                            this.selectedTaskTemplate.exerciseToolSettings.enabledTools[0].name,
                        );
                        this.patientTaskForm.controls.url.patchValue(
                            this.selectedTaskTemplate.exerciseToolSettings.enabledTools[0].config?.url,
                        );
                    }
                }),
        );
        this.isLoadingFinished = true;
    }
}
