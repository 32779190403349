import { Component, Input } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Exercise } from '../../../entities/exercise';
import { ItemType, TableConfig, TableUpdateValue } from '../../../../table/entities/table';
import { ExercisesService } from '../../../services/exercises';
import { PaginatedResponse, SortBy, SortOrder } from '../../../../common/entities/paginated-response';
import { ToastService } from '../../../../common/services/toast-service/toast-service.service';
import { IonicColor } from '../../../../common/entities/toast/ionic-color';
import { TagListModalComponent } from '../../modal/tag-list-modal/tag-list-modal.component';
import { Tag } from '../../../entities/tag/tag.entity';
import { ExerciseType } from '../../../entities/exerciseSession';
import { Logger, LoggingService } from '../../../../logging/logging.service';
import { ExerciseSessionTypeAdapterComponent } from '../../table-adapter/exercise-session-type-adapter.component';
import { TagsAdapterComponent } from '../../table-adapter/tags-adapter.component';
import { StringItemAdapterComponent } from '../../../../table/components/table-adapter/string-item-adapter.component';
import { DurationItemAdapterComponent } from '../../../../table/components/table-adapter/duration-item-adapter.component';
import { CheckBoxItemAdapterComponent } from '../../../../table/components/table-adapter/checkbox-item-adapter.component';

@Component({
    selector: 'lib-exercise-list-checkbox',
    templateUrl: './exercise-list-checkbox.component.html',
    styleUrls: ['./exercise-list-checkbox.component.scss'],
})
export class ExerciseListCheckboxComponent {
    @Input()
    filterExerciseString = 'Filtern';
    @Input()
    searchExerciseString = 'Übungen suchen';
    @Input()
    exerciseType = ExerciseType.TRAINING;
    title: string;
    exercisesSelected: PaginatedResponse<Exercise[]> = new PaginatedResponse<Exercise[]>();
    exerciseListTableConfig: TableConfig<Exercise[]> = new TableConfig();
    selectedExerciseLocalList: Exercise[] = [];
    saveButtonText = 'Auswahl bestätigen';
    limit = 10;
    searchTerm: string;
    filterTags: Tag[] = [];
    protected readonly log: Logger;

    constructor(
        protected modalCtrl: ModalController,
        private exercisesService: ExercisesService,
        protected toastService: ToastService,
        private params: NavParams,
        private loggingService: LoggingService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
        this.exerciseListTableConfig.emptyListLabel = 'ANY_EXERCISE';
        this.exerciseListTableConfig.isOpenDetailEnable = true;
        this.exerciseListTableConfig.itemSettings = [
            {
                id: 'selected',
                prop: 'selected',
                header: '',
                adapter: CheckBoxItemAdapterComponent,
                type: ItemType.ADAPTER,
                width: '8%',
                sortOrderWeb: 0,
            },
            {
                id: 'title',
                prop: 'title',
                header: 'Name',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '32%',
                sortOrderWeb: 1,
            },
            {
                id: 'categoryId',
                prop: 'tagsArray',
                header: 'Tags',
                type: ItemType.ADAPTER,
                adapter: TagsAdapterComponent,
                width: '20%',
                sortOrderWeb: 2,
            },
            {
                id: 'duration',
                prop: 'duration',
                header: 'Dauer',
                adapter: DurationItemAdapterComponent,
                type: ItemType.ADAPTER,
                unit: 'minutes',
                width: '20%',
                sortOrderWeb: 3,
            },
            {
                id: 'maxGroupSize',
                prop: 'maxGroupSize',
                header: 'Typ',
                type: ItemType.ADAPTER,
                adapter: ExerciseSessionTypeAdapterComponent,
                width: '20%',
                sortOrderWeb: 4,
            },
        ];
    }

    ionViewDidEnter() {
        this.initExerciseList();
    }

    async getExercisesList(value: TableUpdateValue, searchTerm: string) {
        this.limit = value.limit;
        try {
            const tagsList = this.filterTags ? this.filterTags.map((tag) => tag.uuid) : [];

            this.exerciseListTableConfig.list = await this.exercisesService.getExercises(
                value.offset * value.limit,
                value.limit,
                searchTerm,
                SortOrder.ASC,
                SortBy.TITLE,
                this.exerciseType,
                false,
                false,
                true,
                tagsList,
                this.exercisesSelected.items.map((e) => e.id.toString()),
            );
        } catch (err) {
            this.log.error('Error in getExercisesList', err);
            await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
        }
    }

    async setFilter() {
        const modal = await this.modalCtrl.create({
            component: TagListModalComponent,
            cssClass: 'modal-list',
            componentProps: {
                title: 'Filter',
                exerciseTags: this.filterTags,
                exerciseType: this.exerciseType,
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data) {
            this.filterTags = data;
            await this.getExercisesList({ offset: 0, limit: this.limit }, this.searchTerm);
        }
    }

    async onActivate(event) {
        this.exerciseListTableConfig.list.items.map((item) => {
            if (item.id === event.id) {
                item.selected = !item.selected;
            }
        });

        if (this.selectedExerciseLocalList.find((user) => user.id === event.id)) {
            const index = this.selectedExerciseLocalList.findIndex((user) => user.id === event.id);
            this.selectedExerciseLocalList.splice(index, 1);
        } else {
            this.selectedExerciseLocalList.push(event);
        }
    }

    passExercise() {
        const exerciseToAdd = this.exerciseListTableConfig.list.items.filter((exercise) => {
            return exercise.selected;
        });
        this.modalCtrl.dismiss({
            reload: true,
            exercises: exerciseToAdd,
        });
    }

    private async initExerciseList() {
        this.title = await this.params.get('title');
        if (this.params.get('exercises')) {
            this.exercisesSelected = this.params.get('exercises');
            await this.getExercisesList({ offset: 0, limit: this.limit }, this.searchTerm);
        }
    }
}
