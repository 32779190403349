import { AfterViewChecked, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ComponentType, TutorialDefinition } from '../../entities/tutorial-slide';
import { CommentMeasurements } from '../../entities/examination-states';
import { Subject } from 'rxjs';
import { Ecg3ChannelValues } from '../../entities/bluetooth-models/online-data-structure';
import { SwiperComponent } from 'swiper/angular';

@Component({
    selector: 'lib-tutorial-slider',
    templateUrl: './tutorial-slider.component.html',
    styleUrls: ['./tutorial-slider.component.scss'],
})
export class TutorialSliderComponent implements OnInit, AfterViewChecked {
    @ViewChild('swiper') swiper: SwiperComponent;

    @Input()
    slideshow: TutorialDefinition;

    @Input()
    autoplay: boolean;
    @Input()
    hasCounter: boolean;
    @Input()
    maxCounter: number;
    @Input()
    counter: number;

    @Input()
    temperature: string;
    @Input()
    temperatureComment: CommentMeasurements;
    @Input()
    spo2: string;
    @Input()
    spo2Comment: CommentMeasurements;
    @Input()
    heartRate: string;
    @Input()
    pulseComment: CommentMeasurements;

    @Input()
    channels$: Subject<Ecg3ChannelValues>;

    config;
    isBeginning = true;
    isEnd = false;
    currentIndex = 0;
    isTutorialChecked = false;
    ComponentType = ComponentType;

    async ngOnInit() {
        if (this.autoplay) {
            this.config = {
                initialSlide: 0,
                slidesPerView: 1,
                effect: 'flip',
                centeredSlides: true,
                centeredSlidesBounds: true,
                autoplay: {
                    delay: 10000,
                },
                pagination: true,
            };
        } else {
            this.config = {
                initialSlide: 0,
                slidesPerView: 1,
                effect: 'flip',
                centeredSlides: true,
                centeredSlidesBounds: true,
                pagination: true,
            };
        }
    }

    ngAfterViewChecked(): void {
        this.swiper.swiperRef.update(); // This will update slide after view checked
        if (!this.slideshow?.hasBorder) {
            const borderStyle = document.getElementById('border-tutorial');
            borderStyle.style.border = 'none';
        }
    }

    backStep() {
        this.swiper.swiperRef.slidePrev(500);
    }

    fowardStep() {
        this.swiper.swiperRef.slideNext(500);
    }

    /* dismissModal() {
        this.modalCtrl.dismiss(
            {
                isTutorialChecked: this.isTutorialChecked,
            }
        );
    } */

    async slideChanged() {
        const index = this.swiper.swiperRef.activeIndex;
        this.currentIndex = index;
        if (index === 0) {
            this.isBeginning = true;
            this.isEnd = false;
            this.swiper.swiperRef.allowSlidePrev = false;
            this.swiper.swiperRef.allowSlideNext = true;
        } else if (index === this.slideshow.list.length - 1) {
            this.isEnd = true;
            this.isBeginning = false;
            this.swiper.swiperRef.allowSlidePrev = true;
            this.swiper.swiperRef.allowSlideNext = false;
        } else {
            this.isBeginning = false;
            this.isEnd = false;
            this.swiper.swiperRef.allowSlidePrev = true;
            this.swiper.swiperRef.allowSlideNext = true;
        }
    }
}
