import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CheckboxChangeEventDetail, ModalController } from '@ionic/angular';
import { UntilDestroy } from '@ngneat/until-destroy';
import { format, parseISO } from 'date-fns';
import { FileItem } from 'ng2-file-upload';
import { Observable, of } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { debounceTime } from 'rxjs/operators';
import { ApiService } from '../../../api';
import {
    AccountType,
    AnnouncementAssignableUserRoles,
    BasicAssignableUserRole,
    ChatAssignableUserRoles,
    KnowledgeAssignableUserRoles,
    LoginUserWithoutPasswordDto,
    NotesAssignableUserRoles,
    PlainUserDto,
    UpdateUserDto,
    User,
    UserRoles,
} from '../../../auth/entities/user';
import { MaritalStatus } from '../../../auth/entities/user/marital-status';
import {
    UserCustomProperty,
    UserCustomPropertyDto,
    UserCustomPropertySchema,
} from '../../../auth/entities/user/user-custom-property';
import { CurafidaAuthService } from '../../../auth/services';
import { InputMode } from '../../../common/components/curafida-input/curafida-text-input/curafida-text-input.component';
import { UserFormComponent } from '../../../common/components/user-form.component';
import { ICardActionButton } from '../../../common/entities/card-action-button.interface';
import { ButtonConfig } from '../../../common/entities/modal/modal-button';
import { ModalConfig } from '../../../common/entities/modal/modal-config';
import { ModalTyp } from '../../../common/entities/modal/modal-typ';
import { PaginatedResponse } from '../../../common/entities/paginated-response';
import { RoutingSegment } from '../../../common/entities/routing-segment';
import { IonicColor } from '../../../common/entities/toast/ionic-color';
import { UPLOAD_MIME_TYPE_WHITELIST } from '../../../common/entities/white-list-mime-type';
import { FileContentService } from '../../../common/services/content/file-content.service';
import { LoadingService } from '../../../common/services/loading/loading.service';
import { ModalAlertService } from '../../../common/services/modal';
import { StyleService } from '../../../common/services/style/style.service';
import { ToastService } from '../../../common/services/toast-service/toast-service.service';
import {
    defaultInputValidator,
    isEMail,
    noWhitespaceValidator,
    phoneValidators,
    repeatValidationValidator,
    requiredUserNameValidators,
    requiredValidators,
} from '../../../common/validators/curafida-validators';
import { ConfigService } from '../../../config/services';
import { RoutingService } from '../../../config/services/routing.service';
import { HypermediaResource } from '../../../hateoas/hateoas.model';
import { Logger, LoggingService } from '../../../logging/logging.service';
import { CheckBoxItemAdapterComponent } from '../../../table/components/table-adapter/checkbox-item-adapter.component';
import { StringItemAdapterComponent } from '../../../table/components/table-adapter/string-item-adapter.component';
import {
    ActionEmitter,
    ActionType,
    ButtonItemAdapterComponent,
    ItemType,
    TableConfig,
    TableItem,
} from '../../../table/entities/table';
import { Content } from '../../../therapy/entities/content';
import { Group, GroupCategory, GroupLevel } from '../../entities/group';
import { GroupListItem } from '../../entities/group-list.item';
import { GroupService } from '../../services/group';
import { SupervisorService } from '../../services/supervisor';
import { UsersService } from '../../services/user';
import { UserCustomPropertyService } from '../../services/user-custom-property.service';
import { GroupListModalComponent } from '../group-list-modal/group-list-modal.component';
import { RoleListModalComponent } from '../role-list-modal/role-list-modal.component';
import { academicTitleOptions } from './academic-title.options';
import { UserMainInfoConfig } from './entities/user-main-info-config';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'user-main-info',
    templateUrl: './user-main-info.component.html',
    styleUrls: ['./user-main-info.component.scss'],
})
export class UserMainInfoComponent extends UserFormComponent implements OnInit {
    readonly academicTitleOptions = academicTitleOptions();

    patientDossier$: Observable<HypermediaResource>;

    @Input()
    config: UserMainInfoConfig = new UserMainInfoConfig();

    @Output()
    updateTitle: EventEmitter<{ firstName: string; lastName: string; birthDate: string }> = new EventEmitter<{
        firstName: string;
        lastName: string;
        birthDate: string;
    }>();
    usernameSuggestion: Subscription;
    existingUsernameSubscription: Subscription;
    maritalStatus = [
        { value: MaritalStatus.SINGLE, label: 'Ledig' },
        { value: MaritalStatus.MARRIED, label: 'Verheiratet' },
        { value: MaritalStatus.DIVORCED, label: 'Geschieden' },
        { value: MaritalStatus.WIDOWED, label: 'Verwitwet' },
        { value: MaritalStatus.CIVIL_PARTNERSHIP, label: 'In einer Lebenspartnerschaft' },
        { value: MaritalStatus.UNKNOWN, label: 'NONE' },
    ];
    usePseudonym = [
        { value: false, label: 'Deaktiviert' },
        {
            value: true,
            label: 'Aktiviert für Formulare',
            subtitle:
                '(Anstelle von personenbezogenen Daten wird ausschließlich das Pseudonym in Formularen verwendet)',
        },
    ];
    pseudonymDefinition = [
        { value: true, label: 'Identisch zu Benutzer-ID' },
        { value: false, label: 'Manuelle Eingabe' },
    ];
    isEditEnabled = false;
    queryParamsSubscription: Subscription;
    isPatientForConsultation: boolean;
    isPatientForScreening: boolean;
    isPatientForOrder: boolean;
    userForm: FormGroup;
    userFormBackup: FormGroup;
    isNewUser: boolean;
    userMemberships: string[] = [];
    RoutingSegment = RoutingSegment;
    UserRoles = UserRoles;
    AccountType = AccountType;
    accountTypeList = [
        { value: AccountType.LOGIN_USER, label: 'ACCOUNT_TYPE.LOGIN_USER' },
        { value: AccountType.PLAIN_USER, label: 'ACCOUNT_TYPE.PLAIN_USER' },
    ];
    originallyPlainUser: boolean;
    originallyHadOtpConfigured: boolean;
    otpConfigurationList = [
        { value: false, label: '2FA.DEACTIVATED' },
        { value: true, label: '2FA.ACTIVATED' },
    ];

    groupListConfig: TableConfig<GroupListItem[]> = new TableConfig<GroupListItem[]>();
    roleListConfig: TableConfig<{ role: string }[]> = new TableConfig<{ role: string }[]>();
    onlyOneGroupAvailable: boolean;
    isGroupManager: boolean;
    isRoleTableInit = false;
    InputMode = InputMode;
    // This flag exists to avoid sending the pseudonym in the updateUserDto if it has not been changed from the original pseudonym
    // Otherwise the backend responds with an error, because the pseudonym already exists #4189
    // Not relevant for new users
    updatePseudonym = false;
    customPropertySchemas: UserCustomPropertySchema[] = [];
    groupActionButtons: ICardActionButton[] = [];
    rolesActionButtons: ICardActionButton[] = [];
    uploadMineTypeWhitelist = UPLOAD_MIME_TYPE_WHITELIST.join();
    protected readonly log: Logger;

    constructor(
        protected modalCtrl: ModalController,
        public router: Router,
        private modalAlertService: ModalAlertService,
        public route: ActivatedRoute,
        protected formBuilder: FormBuilder,
        private styleService: StyleService,
        protected usersService: UsersService,
        protected supervisorService: SupervisorService,
        protected groupService: GroupService,
        private toastService: ToastService,
        private authService: CurafidaAuthService,
        public configService: ConfigService,
        private loadingService: LoadingService,
        private loggingService: LoggingService,
        private location: Location,
        public routingService: RoutingService,
        private readonly httpClient: HttpClient,
        private readonly fileContentService: FileContentService,
        private readonly userCustomPropertyService: UserCustomPropertyService,
    ) {
        super();
        this.log = this.loggingService.getLogger(this.constructor.name);
        this.groupActionButtons.push({
            icon: 'add',
            id: 'add-group',
            isDisabled: false,
            isHidden: false,
            isIconButton: this.isMobile,
            title: 'USER.ASSIGN_GROUP',
        });
        this.rolesActionButtons.push({
            icon: 'add',
            id: 'add-roles',
            isDisabled: false,
            isHidden: false,
            isIconButton: this.isMobile,
            title: 'USER.ASSIGN_ROLES',
        });
        this.isNewUser = this.route.snapshot.paramMap.get('username') === 'new';
    }

    async ngOnInit(): Promise<void> {
        this.loggedInUser = this.authService.getSession().user;
        this.isGroupManager = this.usersService.isUserGroupManager(this.loggedInUser);
        this.isMobile = this.styleService.isMobile$;
        this.queryParamsSubscription = this.route.queryParams.subscribe((params) => {
            if (params) {
                if (params.isPatientForConsultation) {
                    this.isPatientForConsultation = true;
                } else if (params.isPatientForScreening) {
                    this.isPatientForScreening = true;
                } else if (params.isPatientForOrder) {
                    this.isPatientForOrder = true;
                }
            }
        });
        this.onlyOneGroupAvailable =
            this.loggedInUser.groups?.length === 1 && this.usersService.isUserMemberOfLowestLevel(this.loggedInUser);
        await this.initUserForm();
        if (this.route.snapshot.paramMap.get('username') === 'new') {
            this.user = new User();
            this.isEditEnabled = true;

            // TODO: write custom async validator
            this.usernameSuggestion = this.usersService
                .getUsernameSuggestion(this.userForm, 'firstName', 'lastName')
                .subscribe(async (value) => {
                    this.userForm.patchValue({ username: value });
                });

            // TODO: switchMap cancel requests
            this.existingUsernameSubscription = this.userForm.controls.username.valueChanges
                .pipe(debounceTime(400))
                .subscribe(async (value) => {
                    if (this.userForm.controls.username.dirty && this.userForm.controls.username.valid) {
                        const usernameCheck = await this.usersService.isUsername(value);
                        if (usernameCheck.isUsername) {
                            this.userForm.controls.username.setErrors({ incorrect: true });
                        }
                    }
                });
            this.user.roles = this.getUserRolesFromConfig();
            this.user.accountType = this.user.roles.includes(UserRoles.PATIENT)
                ? AccountType.PLAIN_USER
                : AccountType.LOGIN_USER;
            this.onChangeAccountType(this.user.accountType);
        }

        if (this.configService.config.features.organisation?.enabled) {
            await this.initGroupsConfig();
        }

        if (
            this.loggedInUser.roles.includes(UserRoles.manage_user) ||
            this.loggedInUser.roles.includes(UserRoles.create_patient) ||
            this.loggedInUser.roles.includes(UserRoles.create_supervised_patient)
        ) {
            await this.initRolesConfig();
        }

        const isPatientCareManagementActive = Boolean(
            (this.configService.config.features.user.caregiver || this.configService.config.features.user.supervisor) &&
                this.user?.roles?.includes(UserRoles.PATIENT) &&
                !this.isProfilePage() &&
                !this.isNewUser &&
                this.config.patientCareManagement,
        );
        if (isPatientCareManagementActive) {
            this.patientDossier$ = this.httpClient.get<HypermediaResource>(
                `${ApiService.url}patients/${this.user.username}/dossier`,
                ApiService.options,
            );
        } else {
            this.patientDossier$ = of({ _links: {} });
        }
    }

    hasRightToModifyUser(): boolean {
        if (this.user?.roles?.includes(UserRoles.PATIENT) || this.readUserRoleFromURL() === UserRoles.PATIENT) {
            return this.loggedInUser.roles.includes(UserRoles.manage_patient);
        }
        return this.loggedInUser.roles.includes(UserRoles.manage_user);
    }

    /**
     * Returns false for new users or if updating another user. True if updating the own user details.
     */
    isOwnDetails(): boolean {
        return this.user?.username === this.loggedInUser?.username;
    }

    async initGroupList(): Promise<void> {
        // If it is an existing user, show the group of which it is member
        if (!this.isNewUser) {
            try {
                this.groupListConfig.list = (await this.usersService.getGroupsOfUser(
                    this.user.username,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    false,
                )) as PaginatedResponse<GroupListItem[]>;
                // Set extra attributes to show in the table
                for (const group of this.groupListConfig.list.items) {
                    const groupPath = group.path.split('/');
                    const groupLevel = this.groupService.getGroupLevel(group.path);
                    if (groupLevel === GroupLevel.TENANT || groupLevel === GroupLevel.ORGANIZATION) {
                        group.parentName = '-';
                        if (groupLevel === GroupLevel.TENANT) group.category = GroupCategory.TENANT;
                    } else {
                        group.parentName = groupPath[groupPath.length - 2];
                    }
                    group.titleName =
                        group.parentName === '-' ? `${group.name}` : `${group.name} (${group.parentName})`;
                    this.userMemberships.push(group.path);
                }
            } catch (e) {
                this.log.error('Error in initGroupList', e);
                await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
            }
            // If it's a new user, show the loggedInUser group by default
        } else {
            try {
                this.groupListConfig.list = (await this.usersService.getGroupsOfUser(
                    this.loggedInUser.username,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    false,
                    GroupLevel.INSTITUTION,
                )) as PaginatedResponse<GroupListItem[]>;
                // If it's only one group, show by default
                // Empty the list if there's more than one group
                if (this.groupListConfig.list.items.length !== 1) {
                    this.groupListConfig.list.items = [];
                    this.groupListConfig.list.total =
                        this.groupListConfig.list.count =
                        this.groupListConfig.list.limit =
                            0;
                }
                for (const group of this.groupListConfig.list.items) {
                    const groupPath = group.path.split('/');
                    const groupLevel = this.groupService.getGroupLevel(group.path);
                    if (groupLevel === GroupLevel.TENANT || groupLevel === GroupLevel.ORGANIZATION) {
                        group.parentName = '-';
                        if (groupLevel === GroupLevel.TENANT) group.category = GroupCategory.TENANT;
                    } else {
                        group.parentName = groupPath[groupPath.length - 2];
                    }
                    group.titleName =
                        group.parentName === '-' ? `${group.name}` : `${group.name} (${group.parentName})`;
                    this.userMemberships.push(group.path);
                }
            } catch (e) {
                this.log.error('Error in initGroupList', e);
                await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
            }
        }
    }

    async initRoleList(): Promise<void> {
        this.isRoleTableInit = false;
        if (this.user.roles) {
            this.roleListConfig.list.items = [];
            try {
                for (const role of this.user.roles) {
                    if (Object.keys(BasicAssignableUserRole).find((e) => role.toString() === e.toString())) {
                        this.roleListConfig.list.items.push({ role: role });
                    }
                    if (
                        this.configService.config.features?.announcement?.enabled &&
                        Object.keys(AnnouncementAssignableUserRoles).find((e) => role.toString() === e.toString())
                    ) {
                        this.roleListConfig.list.items.push({ role: role });
                    }
                    if (
                        this.configService.config.features?.chat?.enabled &&
                        Object.keys(ChatAssignableUserRoles).find((e) => role.toString() === e.toString())
                    ) {
                        this.roleListConfig.list.items.push({ role: role });
                    }
                    if (
                        this.configService.config.features?.note?.enabled &&
                        Object.keys(NotesAssignableUserRoles).find((e) => role.toString() === e.toString())
                    ) {
                        this.roleListConfig.list.items.push({ role: role });
                    }
                    if (
                        this.configService.config.features?.knowledge?.enabled &&
                        Object.keys(KnowledgeAssignableUserRoles).find((e) => role.toString() === e.toString())
                    ) {
                        this.roleListConfig.list.items.push({ role: role });
                    }
                }
            } catch (e) {
                this.log.error('Error in initRoleList', e);
                await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
            } finally {
                this.roleListConfig.list.total =
                    this.roleListConfig.list.limit =
                    this.roleListConfig.list.count =
                        this.roleListConfig.list.items.length;
                this.roleListConfig.list.offset = 0;
                this.isRoleTableInit = true;
            }
        } else {
            this.roleListConfig.list.total =
                this.roleListConfig.list.limit =
                this.roleListConfig.list.count =
                    this.roleListConfig.list.items.length;
            this.roleListConfig.list.offset = 0;
            this.isRoleTableInit = true;
        }
    }

    readValuesFromForm(): void {
        this.user.title = this.userForm.controls.title.value;
        this.user.username = this.userForm.controls.username.value;
        this.user.firstname = this.userForm.value.firstName;
        this.user.lastname = this.userForm.value.lastName;
        // This flag is set to avoid sending the pseudonym in the updateUserDto if it has not been changed from the original pseudonym
        // Otherwise the backend responds with an error, because the pseudonym already exists #4189
        // Not relevant for new users
        if (this.user.pseudonym !== this.userForm.value.pseudonym) {
            this.updatePseudonym = true;
        }
        this.user.pseudonym = this.userForm.value.pseudonym === '' ? null : this.userForm.value.pseudonym;
        this.user.usePseudonym = this.user.pseudonym?.length >= 3 ? this.userForm.value.usePseudonym : false;
        this.user.email = this.userForm.value.email;
        this.user.accountType = this.userForm.value.accountType;
        this.user.birthdate = this.userForm.value.birthDate ? this.userForm.value.birthDate : null;
        this.user.maritalStatus = this.userForm.value.maritalStatus;
        this.user.streetAddress = this.userForm.value.streetAddress;
        this.user.postalCode = this.userForm.value.postalCode;
        this.user.city = this.userForm.value.city;
        this.user.addressAddition = this.userForm.value.addressAddition;
        this.user.country = this.userForm.value.country;
        this.user.nationality = this.userForm.value.nationality;
        this.user.phone = this.userForm.value.phone;
        this.user.mobilePhone = this.userForm.value.mobilePhone;
        this.user.gender = this.userForm.value.gender;
        if (this.userForm.value.contactCategory) this.user.contactCategory = this.userForm.value.contactCategory;
        if (this.userForm.value.healthInsurer) this.user.healthInsurer = this.userForm.value.healthInsurer;
        if (this.userForm.value.healthInsurerType) this.user.healthInsurerType = this.userForm.value.healthInsurerType;
        if (this.userForm.value.healthInsuranceNumber) {
            this.user.healthInsuranceNumber = this.userForm.value.healthInsuranceNumber;
        }
        this.user.generalPractitionerName = this.userForm.value.generalPractitionerName;
        this.user.generalPractitionerPhone = this.userForm.value.generalPractitionerPhone;
        if (this.userForm.value.isEmployed) this.user.isEmployed = this.userForm.value.isEmployed === 1;
        if (this.userForm.value.job) this.user.job = this.userForm.value.job;
        if (this.userForm.value.company) this.user.company = this.userForm.value.company;
        if (this.userForm.value.patientNumber) this.user.patientNumber = this.userForm.value.patientNumber;
        if (this.userForm.value.pseudonymDefinition as boolean) {
            this.user.pseudonym = this.userForm.value.patientNumber;
        } else {
            this.user.pseudonym = this.userForm.value.pseudonym;
        }
    }

    async saveUserInformation(): Promise<void> {
        if (this.userForm.valid) {
            this.readValuesFromForm();
            if (this.isNewUser) {
                await this.saveNewUser();
            } else {
                await this.updateExistingUser();
            }
            this.userFormBackup = this.createBackupForm();
        } else {
            await this.toastService.showToast(ToastService.formMessage, IonicColor.danger);
        }
    }

    getUserAccountType(): AccountType {
        const roles = this.user?.roles ? this.user.roles : this.getUserRolesFromConfig();
        return this.user?.accountType
            ? this.user.accountType
            : roles.includes(UserRoles.PATIENT)
              ? AccountType.PLAIN_USER
              : AccountType.LOGIN_USER;
    }

    async getUserHasOtpConfiguredOrRequired(): Promise<boolean> {
        if (!this.user?.username || this.user?.accountType !== AccountType.LOGIN_USER) {
            return false;
        }
        return await this.usersService.getUserHasOtpConfiguredOrRequired(this.user.username);
    }

    async setRequireOtpConfiguration(username: string): Promise<void> {
        await this.usersService.setRequireOtpConfiguration(username, true, !this.user.emailVerified);
    }

    resetUserForm() {
        Object.keys(this.userFormBackup.controls).forEach((controlName) => {
            const control = this.userFormBackup.controls[controlName];
            this.userForm.get(controlName).setValue(control.value);
        });
    }

    createBackupForm(): FormGroup {
        const backupForm = new FormGroup({});
        Object.keys(this.userForm.controls).forEach((controlName) => {
            const control = this.userForm.controls[controlName];
            backupForm.addControl(controlName, new FormControl(control.value));
        });
        return backupForm;
    }

    async cancelEdit(): Promise<void> {
        if (this.isEditEnabled) {
            this.toggleEdit();
            this.resetUserForm();
        }
    }

    async initUserForm(): Promise<void> {
        this.isEditEnabled = this.user == null;
        const userName = this.user?.username || '';
        const accountType = this.getUserAccountType();
        this.originallyPlainUser = accountType === AccountType.PLAIN_USER;
        const pvsId = this.user?.pvsId || '';
        const title = this.user?.title || '';
        const firstName = this.user?.firstname || '';
        const lastName = this.user?.lastname || '';
        const birthDate = this.user?.birthdate;
        const pseudonym = this.user?.pseudonym;
        const usePseudonym = pseudonym?.length >= 3 ? this.user.usePseudonym : false;
        const maritalStatus = this.user?.maritalStatus;
        const gender = this.user?.gender;
        const streetAddress = this.user?.streetAddress || '';
        const postalCode = this.user?.postalCode || '';
        const city = this.user?.city || '';
        const addressAddition = this.user?.addressAddition || '';
        const country = this.user?.country || '';
        const email = this.user?.email || '';
        const phone = this.user?.phone || '';
        const mobilePhone = this.user?.mobilePhone || '';
        const healthInsurer = this.user?.healthInsurer || '';
        const healthInsuranceNumber = this.user?.healthInsuranceNumber || '';
        const healthInsurerType = this.user?.healthInsurerType || '';
        const generalPractitionerName = this.user?.generalPractitionerName || '';
        const generalPractitionerPhone = this.user?.generalPractitionerPhone || '';
        const nationality = this.user?.nationality || '';
        const isEmployed = this.user == null || this.user.isEmployed == null ? '' : this.user.isEmployed ? 1 : 2;
        const company = this.user?.company || '';
        const contactCategory = this.user?.contactCategory || '';
        const job = this.user?.job || '';
        const createdAt =
            this.user == null ? format(new Date(), 'dd.MM.yyyy') : format(parseISO(this.user.created_at), 'dd.MM.yyyy');
        const hasOtpConfigured = await this.getUserHasOtpConfiguredOrRequired();
        const patientNumber = this.user?.patientNumber ? this.user.patientNumber : null;
        const pseudonymDefinition =
            this.user?.pseudonym === this.user?.patientNumber?.toString() || this.user?.pseudonym === '';
        this.originallyHadOtpConfigured = hasOtpConfigured;
        this.userForm = this.formBuilder.group({
            username: new FormControl(
                {
                    value: userName,
                    disabled: true,
                },
                requiredValidators,
            ),
            accountType: new FormControl({
                value: accountType,
                disabled: !this.isEditEnabled || accountType === AccountType.LOGIN_USER || !this.hasRightToModifyUser(),
            }),
            hasOtpConfigured: new FormControl({
                value: hasOtpConfigured,
                disabled:
                    hasOtpConfigured ||
                    !this.isEditEnabled ||
                    accountType !== AccountType.LOGIN_USER ||
                    !this.hasRightToModifyUser(),
            }),
            pvsId: new FormControl({ value: pvsId, disabled: !this.isEditEnabled }, defaultInputValidator),
            title: new FormControl({ value: title, disabled: !this.isEditEnabled }, defaultInputValidator),
            firstName: new FormControl({ value: firstName, disabled: !this.isEditEnabled }, requiredUserNameValidators),
            lastName: new FormControl({ value: lastName, disabled: !this.isEditEnabled }, requiredUserNameValidators),
            pseudonym: new FormControl({ value: pseudonym, disabled: true }, [
                Validators.minLength(3),
                Validators.pattern(/^([A-Z]|[0-9]|_|-|\.){3,50}$/i),
            ]),
            usePseudonym: new FormControl({ value: usePseudonym, disabled: !this.isEditEnabled }),
            birthDate: new FormControl({ value: birthDate, disabled: !this.isEditEnabled }, defaultInputValidator),
            maritalStatus: new FormControl(
                { value: maritalStatus, disabled: !this.isEditEnabled },
                defaultInputValidator,
            ),
            gender: new FormControl({ value: gender, disabled: !this.isEditEnabled }, defaultInputValidator),
            streetAddress: new FormControl(
                { value: streetAddress, disabled: !this.isEditEnabled },
                defaultInputValidator,
            ),
            postalCode: new FormControl({ value: postalCode, disabled: !this.isEditEnabled }, defaultInputValidator),
            city: new FormControl({ value: city, disabled: !this.isEditEnabled }, defaultInputValidator),
            country: new FormControl({ value: country, disabled: !this.isEditEnabled }, defaultInputValidator),
            nationality: new FormControl({ value: nationality, disabled: !this.isEditEnabled }, defaultInputValidator),
            addressAddition: new FormControl({ value: addressAddition, disabled: !this.isEditEnabled }, [
                defaultInputValidator,
            ]),
            email: new FormControl({ value: email, disabled: !this.isEditEnabled }, [Validators.email, isEMail]),
            phone: new FormControl({ value: phone, disabled: !this.isEditEnabled }, phoneValidators),
            mobilePhone: new FormControl(
                {
                    value: mobilePhone,
                    disabled: !this.isEditEnabled,
                },
                phoneValidators,
            ),
            healthInsurer: new FormControl(
                { value: healthInsurer, disabled: !this.isEditEnabled },
                defaultInputValidator,
            ),
            healthInsuranceNumber: new FormControl(
                {
                    value: healthInsuranceNumber,
                    disabled: !this.isEditEnabled,
                },
                defaultInputValidator,
            ),
            healthInsurerType: new FormControl(
                {
                    value: healthInsurerType,
                    disabled: !this.isEditEnabled,
                },
                defaultInputValidator,
            ),
            generalPractitionerName: new FormControl(
                {
                    value: generalPractitionerName,
                    disabled: !this.isEditEnabled,
                },
                defaultInputValidator,
            ),
            generalPractitionerPhone: new FormControl(
                {
                    value: generalPractitionerPhone,
                    disabled: !this.isEditEnabled,
                },
                defaultInputValidator,
            ),
            isEmployed: new FormControl({ value: isEmployed, disabled: !this.isEditEnabled }, defaultInputValidator),
            company: new FormControl({ value: company, disabled: !this.isEditEnabled }, defaultInputValidator),
            job: new FormControl({ value: job, disabled: !this.isEditEnabled }, defaultInputValidator),
            contactCategory: new FormControl(
                { value: contactCategory, disabled: !this.isEditEnabled },
                defaultInputValidator,
            ),
            createdAt: new FormControl({
                value: createdAt,
                disabled: true,
            }),
            patientNumber: new FormControl({
                value: patientNumber,
                disabled: true,
            }),
            pseudonymDefinition: new FormControl({
                value: pseudonymDefinition,
                disabled: true,
            }),
        });
        if (accountType === AccountType.LOGIN_USER) {
            this.userForm.controls.email.addValidators(Validators.required);
        }
        this.userForm.controls.username.setValidators(
            Validators.compose([
                defaultInputValidator,
                noWhitespaceValidator,
                Validators.minLength(5),
                Validators.maxLength(50),
                Validators.required,
                Validators.pattern(/^([A-Z]|[0-9]|_|-|\.){5,50}$/i),
            ]),
        );
        await this.initCustomPropsFormControls();
        this.userFormBackup = this.createBackupForm();
    }

    buildUpdateUserDto(): UpdateUserDto {
        const updateUserDto = new UpdateUserDto();
        updateUserDto.title = this.userForm.value.title;
        updateUserDto.firstname = this.userForm.value.firstName;
        updateUserDto.lastname = this.userForm.value.lastName;
        // This flag is used to avoid sending the pseudonym in the updateUserDto if it has not been changed from the original pseudonym
        // Otherwise the backend responds with an error, because the pseudonym already exists #4189
        // Not relevant for new users
        if (this.updatePseudonym) {
            updateUserDto.pseudonym = this.userForm.value.pseudonym === '' ? null : this.userForm.value.pseudonym;
            this.updatePseudonym = false;
        }
        if (this.userForm.value.pseudonymDefinition) {
            updateUserDto.usePseudonym = true;
            updateUserDto.pseudonym = this.user.patientNumber.toString();
        } else {
            updateUserDto.usePseudonym =
                this.userForm.value.pseudonym?.length >= 3 ? this.userForm.value.usePseudonym : false;
        }
        updateUserDto.birthdate = this.userForm.value.birthDate ? this.userForm.value.birthDate : null;
        updateUserDto.maritalStatus = this.userForm.value.maritalStatus;
        if (this.userForm.value.disabled === 'true') {
            updateUserDto.disabled = true;
        } else if (this.userForm.value.disabled === 'false') {
            updateUserDto.disabled = false;
        }
        updateUserDto.nationality = this.userForm.value.nationality;
        updateUserDto.streetAddress = this.userForm.value.streetAddress;
        updateUserDto.postalCode = this.userForm.value.postalCode;
        updateUserDto.city = this.userForm.value.city;
        updateUserDto.gender = this.userForm.value.gender;
        updateUserDto.addressAddition = this.userForm.value.addressAddition;
        updateUserDto.country = this.userForm.value.country;
        updateUserDto.phone = this.userForm.value.phone;
        updateUserDto.mobilePhone = this.userForm.value.mobilePhone;
        updateUserDto.gender = this.userForm.value.gender;
        if (this.userForm.value.contactCategory) updateUserDto.contactCategory = this.userForm.value.contactCategory;
        if (this.userForm.value.healthInsurer) updateUserDto.healthInsurer = this.userForm.value.healthInsurer;
        if (this.userForm.value.healthInsurerType) {
            updateUserDto.healthInsurerType = this.userForm.value.healthInsurerType;
        }
        if (this.userForm.value.healthInsuranceNumber) {
            updateUserDto.healthInsuranceNumber = this.userForm.value.healthInsuranceNumber;
        }
        updateUserDto.generalPractitionerName = this.userForm.value.generalPractitionerName;
        updateUserDto.generalPractitionerPhone = this.userForm.value.generalPractitionerPhone;
        if (this.userForm.controls.isEmployed.valid) updateUserDto.isEmployed = this.userForm.value.isEmployed === 1;
        if (this.userForm.value.job) updateUserDto.job = this.userForm.value.job;
        if (this.userForm.value.company) updateUserDto.company = this.userForm.value.company;

        const customProps = this.readCustomPropsFromForm();
        if (customProps?.length > 0) updateUserDto.customPropertyDtos = customProps;

        return updateUserDto;
    }

    buildLoginUserWithoutPasswordDto(): LoginUserWithoutPasswordDto {
        const loginUserDto = new LoginUserWithoutPasswordDto();
        loginUserDto.title = this.userForm.controls.title.value;
        loginUserDto.username = this.userForm.controls.username.value;
        loginUserDto.firstname = this.userForm.value.firstName;
        loginUserDto.lastname = this.userForm.value.lastName;
        loginUserDto.pseudonym = this.userForm.value.pseudonym === '' ? null : this.userForm.value.pseudonym;
        loginUserDto.usePseudonym = loginUserDto.pseudonym?.length >= 3 ? this.userForm.value.usePseudonym : false;
        loginUserDto.email = this.userForm.value.email;
        loginUserDto.accountType = AccountType.LOGIN_USER;
        loginUserDto.birthdate = this.userForm.value.birthDate ? this.userForm.value.birthDate : null;
        loginUserDto.maritalStatus = this.userForm.value.maritalStatus;
        loginUserDto.streetAddress = this.userForm.value.streetAddress;
        loginUserDto.postalCode = this.userForm.value.postalCode;
        loginUserDto.city = this.userForm.value.city;
        loginUserDto.addressAddition = this.userForm.value.addressAddition;
        loginUserDto.country = this.userForm.value.country;
        loginUserDto.nationality = this.userForm.value.nationality;
        loginUserDto.phone = this.userForm.value.phone;
        loginUserDto.mobilePhone = this.userForm.value.mobilePhone;
        loginUserDto.gender = this.userForm.value.gender;
        if (this.userForm.value.contactCategory) loginUserDto.contactCategory = this.userForm.value.contactCategory;
        if (this.userForm.value.healthInsurer) loginUserDto.healthInsurer = this.userForm.value.healthInsurer;
        if (this.userForm.value.healthInsurerType) {
            loginUserDto.healthInsurerType = this.userForm.value.healthInsurerType;
        }
        if (this.userForm.value.healthInsuranceNumber) {
            loginUserDto.healthInsuranceNumber = this.userForm.value.healthInsuranceNumber;
        }
        loginUserDto.generalPractitionerName = this.userForm.value.generalPractitionerName;
        loginUserDto.generalPractitionerPhone = this.userForm.value.generalPractitionerPhone;
        if (this.userForm.value.isEmployed) loginUserDto.isEmployed = this.userForm.value.isEmployed === 1;
        if (this.userForm.value.job) loginUserDto.job = this.userForm.value.job;
        if (this.userForm.value.company) loginUserDto.company = this.userForm.value.company;

        const customProps = this.readCustomPropsFromForm();
        if (customProps?.length > 0) loginUserDto.customPropertyDtos = customProps;

        return loginUserDto;
    }

    buildPlainUserDto(): PlainUserDto {
        const plainUserDto = new PlainUserDto();
        plainUserDto.accountType = AccountType.PLAIN_USER;
        plainUserDto.title = this.userForm.controls.title.value;
        plainUserDto.username = this.userForm.controls.username.value;
        plainUserDto.firstname = this.userForm.value.firstName;
        plainUserDto.lastname = this.userForm.value.lastName;
        plainUserDto.pseudonym = this.userForm.value.pseudonym === '' ? null : this.userForm.value.pseudonym;
        plainUserDto.usePseudonym =
            this.userForm.value.pseudonym?.length >= 3 ? this.userForm.value.usePseudonym : false;
        plainUserDto.birthdate = this.userForm.value.birthDate ? this.userForm.value.birthDate : null;
        plainUserDto.maritalStatus = this.userForm.value.maritalStatus;
        plainUserDto.streetAddress = this.userForm.value.streetAddress;
        plainUserDto.postalCode = this.userForm.value.postalCode;
        plainUserDto.city = this.userForm.value.city;
        plainUserDto.addressAddition = this.userForm.value.addressAddition;
        plainUserDto.country = this.userForm.value.country;
        plainUserDto.nationality = this.userForm.value.nationality;
        plainUserDto.phone = this.userForm.value.phone;
        plainUserDto.mobilePhone = this.userForm.value.mobilePhone;
        plainUserDto.gender = this.userForm.value.gender;
        if (this.userForm.value.contactCategory) plainUserDto.contactCategory = this.userForm.value.contactCategory;
        if (this.userForm.value.healthInsurer) plainUserDto.healthInsurer = this.userForm.value.healthInsurer;
        if (this.userForm.value.healthInsurerType) {
            plainUserDto.healthInsurerType = this.userForm.value.healthInsurerType;
        }
        if (this.userForm.value.healthInsuranceNumber) {
            plainUserDto.healthInsuranceNumber = this.userForm.value.healthInsuranceNumber;
        }
        plainUserDto.generalPractitionerName = this.userForm.value.generalPractitionerName;
        plainUserDto.generalPractitionerPhone = this.userForm.value.generalPractitionerPhone;
        if (this.userForm.value.isEmployed) plainUserDto.isEmployed = this.userForm.value.isEmployed === 1;
        if (this.userForm.value.job) plainUserDto.job = this.userForm.value.job;
        if (this.userForm.value.company) plainUserDto.company = this.userForm.value.company;

        const customProps = this.readCustomPropsFromForm();
        if (customProps?.length > 0) plainUserDto.customPropertyDtos = customProps;
        return plainUserDto;
    }

    toggleEdit(): void {
        this.isEditEnabled = !this.isEditEnabled;
        const firstNameInput = this.userForm.get('firstName');
        const titleInput = this.userForm.get('title');
        const lastNameInput = this.userForm.get('lastName');
        const pseudonymInput = this.userForm.get('pseudonym');
        const usePseudonym = this.userForm.get('usePseudonym');
        const genderInput = this.userForm.get('gender');
        const birthDateInput = this.userForm.get('birthDate');
        const maritalStatusInput = this.userForm.get('maritalStatus');
        const streetAddressInput = this.userForm.get('streetAddress');
        const postalCodeInput = this.userForm.get('postalCode');
        const cityInput = this.userForm.get('city');
        const addressAdditionInput = this.userForm.get('addressAddition');
        const countryInput = this.userForm.get('country');
        const email = this.userForm.get('email');
        const phoneInput = this.userForm.get('phone');
        const mobilePhoneInput = this.userForm.get('mobilePhone');
        const nationalityInput = this.userForm.get('nationality');
        const healthInsurerInput = this.userForm.get('healthInsurer');
        const healthInsuranceNumberInput = this.userForm.get('healthInsuranceNumber');
        const healthInsurerTypeInput = this.userForm.get('healthInsurerType');
        const generalPractitionerNameInput = this.userForm.get('generalPractitionerName');
        const generalPractitionerPhoneInput = this.userForm.get('generalPractitionerPhone');
        const isEmployedInput = this.userForm.get('isEmployed');
        const companyInput = this.userForm.get('company');
        const jobInput = this.userForm.get('job');
        const contactCategoryInput = this.userForm.get('contactCategory');
        const hasOtpConfigured = this.userForm.get('hasOtpConfigured');
        const accountType = this.userForm.get('accountType');
        const pseudonymDefinition = this.userForm.get('pseudonymDefinition');

        if (!this.isProfilePage()) {
            for (const schema of this.customPropertySchemas.flatMap((s) => s.propertySchemas)) {
                if (!(this.isProfilePage() && !schema.userWritable)) {
                    const formControlName = schema.getFormControlName();
                    const control = this.userForm.get(formControlName);
                    if (control) {
                        if (this.isEditEnabled) {
                            control?.enable();
                            if (schema.validationEnabled) {
                                this.userForm.get(schema.getRepeatValidationCheckBoxFormControlName())?.enable();
                                this.userForm.get(schema.getRepeatValidationInputFormControlName())?.enable();
                            }
                        } else {
                            control?.disable();
                            if (schema.validationEnabled) {
                                this.userForm.get(schema.getRepeatValidationCheckBoxFormControlName())?.disable();
                                this.userForm.get(schema.getRepeatValidationInputFormControlName())?.disable();
                            }
                        }
                    }
                }
            }
        }

        this.isEditEnabled ? titleInput.enable() : titleInput.disable();
        this.isEditEnabled ? firstNameInput.enable() : firstNameInput.disable();
        this.isEditEnabled ? lastNameInput.enable() : lastNameInput.disable();
        if (usePseudonym.value) {
            this.isEditEnabled ? pseudonymDefinition.enable() : pseudonymDefinition.disable();
            if (pseudonymDefinition.value) {
                pseudonymInput.disable();
            } else {
                this.isEditEnabled ? pseudonymInput.enable() : pseudonymInput.disable();
            }
        } else {
            pseudonymInput.disable();
            pseudonymDefinition.disable();
        }
        this.isEditEnabled ? usePseudonym.enable() : usePseudonym.disable();
        this.isEditEnabled ? genderInput.enable() : genderInput.disable();
        this.isEditEnabled ? birthDateInput.enable() : birthDateInput.disable();
        this.isEditEnabled ? maritalStatusInput.enable() : maritalStatusInput.disable();
        this.isEditEnabled && this.user.accountType === AccountType.PLAIN_USER ? email.enable() : email.disable();
        this.isEditEnabled ? streetAddressInput.enable() : streetAddressInput.disable();
        this.isEditEnabled ? postalCodeInput.enable() : postalCodeInput.disable();
        this.isEditEnabled ? cityInput.enable() : cityInput.disable();
        this.isEditEnabled ? addressAdditionInput.enable() : addressAdditionInput.disable();
        this.isEditEnabled ? countryInput.enable() : countryInput.disable();
        this.isEditEnabled ? phoneInput.enable() : phoneInput.disable();
        this.isEditEnabled ? mobilePhoneInput.enable() : mobilePhoneInput.disable();
        this.isEditEnabled ? healthInsurerInput.enable() : healthInsurerInput.disable();
        this.isEditEnabled ? healthInsuranceNumberInput.enable() : healthInsuranceNumberInput.disable();
        this.isEditEnabled ? healthInsurerTypeInput.enable() : healthInsurerTypeInput.disable();
        this.isEditEnabled ? generalPractitionerNameInput.enable() : generalPractitionerNameInput.disable();
        this.isEditEnabled ? generalPractitionerPhoneInput.enable() : generalPractitionerPhoneInput.disable();
        this.isEditEnabled ? isEmployedInput.enable() : isEmployedInput.disable();
        this.isEditEnabled ? companyInput.enable() : companyInput.disable();
        this.isEditEnabled ? jobInput.enable() : jobInput.disable();
        this.isEditEnabled ? nationalityInput.enable() : nationalityInput.disable();
        this.isEditEnabled ? phoneInput.enable() : phoneInput.disable();
        this.isEditEnabled ? contactCategoryInput.enable() : contactCategoryInput.disable();
        if (
            this.isEditEnabled &&
            this.user.accountType !== AccountType.LOGIN_USER &&
            this.user.roles.includes(UserRoles.PATIENT) &&
            this.hasRightToModifyUser()
        ) {
            accountType.enable();
        } else {
            accountType.disable();
        }
        if (
            this.isEditEnabled &&
            this.user.accountType === AccountType.LOGIN_USER &&
            !this.originallyHadOtpConfigured &&
            this.hasRightToModifyUser()
        ) {
            hasOtpConfigured.enable();
        } else {
            hasOtpConfigured.disable();
        }
    }

    async changeGroup(): Promise<void> {
        const selectedGroup: Group[] = [];
        const tableItems: TableItem[] = [
            {
                id: 'selected',
                prop: 'selected',
                header: '',
                adapter: CheckBoxItemAdapterComponent,
                type: ItemType.ADAPTER,
                width: '8%',
                sortOrderWeb: 0,
            },
            {
                id: 'name',
                prop: 'name',
                header: 'Name',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '30%',
                sortOrderWeb: 1,
            },
            {
                id: 'parentName',
                prop: 'parentName',
                header: 'Organisation',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '30%',
                sortOrderWeb: 2,
            },
            {
                id: 'category',
                prop: 'category',
                header: 'Kategorie',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '32%',
                sortOrderWeb: 3,
            },
        ];
        if (this.groupListConfig.list.items.length && this.groupListConfig.list.items.length > 0) {
            for (const group of this.groupListConfig.list.items) {
                const newGroup = new Group();
                newGroup.uuid = group.uuid;
                selectedGroup.push(newGroup);
            }
        }
        // TODO: Allow more group to select but only one to select
        const modal = await this.modalCtrl.create({
            component: GroupListModalComponent,
            cssClass: 'modal-standard-ionic',
            componentProps: {
                title: 'Gruppe wählen',
                selectedGroups: selectedGroup,
                isMultipleChoice: false,
                onlyGroupsWithMembership: true,
                groupLevel: GroupLevel.INSTITUTION,
                tableItems,
                showSelectedGroup: true,
                searchString: 'Gruppe suchen',
                anyItem: 'ANY_GROUP',
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data && data.length > 0) {
            if (!this.isNewUser) {
                try {
                    for (const group of data) {
                        await this.usersService.assignUserToGroup(this.user.username, group.path);
                    }
                    await this.toastService.showToast(ToastService.changeSavedMessage, IonicColor.success);
                } catch (err) {
                    this.log.error('Error in changeGroup', err);
                    await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
                    // If an error is caught while trying to assign user to a group, then do not change the group in the UI list
                    return;
                }
            }
            // Change UI list with the chosen group
            this.userMemberships = [];
            this.groupListConfig.list.items = [];
            this.groupListConfig.list.total =
                this.groupListConfig.list.count =
                this.groupListConfig.list.limit =
                    data.length;
            for (const group of data) {
                const groupPath = group.path.split('/');
                const groupLevel = this.groupService.getGroupLevel(group.path);
                if (groupLevel === GroupLevel.TENANT || groupLevel === GroupLevel.ORGANIZATION) {
                    group.parentName = '-';
                    if (groupLevel === GroupLevel.TENANT) group.category = GroupCategory.TENANT;
                } else {
                    group.parentName = groupPath[groupPath.length - 2];
                }
                group.titleName = group.parentName === '-' ? `${group.name}` : `${group.name} (${group.parentName})`;
                this.groupListConfig.list.items.push(group);
                this.userMemberships.push(group.path);
            }
        }
    }

    async changeRole(): Promise<void> {
        const selectedRole: { name: string }[] = [];
        if (this.roleListConfig.list.items) {
            for (const role of this.roleListConfig.list.items) {
                const newRole = { name: role.role };
                selectedRole.push(newRole);
            }
        }
        const modal = await this.modalCtrl.create({
            component: RoleListModalComponent,
            cssClass: 'full-width-modal',
            componentProps: {
                alreadyAssignedUserRoles: selectedRole,
                isMultipleChoice: true,
                onlyGroupsWithMembership: true,
                showSelectedGroup: true,
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data) {
            this.log.debug('data', data);
            try {
                await this.changeUserRoles(data, this.roleListConfig.list.items, this.isNewUser);
                await this.toastService.showToast(ToastService.changeSavedMessage, IonicColor.success);
            } catch (e) {
                await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
                this.log.error('Error in changeRole', e);
            } finally {
                this.roleListConfig.list.items = [];
                this.roleListConfig.list.items = data;
                this.roleListConfig.list.total =
                    this.roleListConfig.list.limit =
                    this.roleListConfig.list.count =
                        this.roleListConfig.list.items.length;
                this.roleListConfig.list.offset = 0;
            }
        }
    }

    async changeUserRoles(
        newRolesList: { role: string }[],
        previousRoleList: { role: string }[],
        isNewUser: boolean,
    ): Promise<void> {
        const rolesToAdd: BasicAssignableUserRole[] = [];
        const rolesToRemove: BasicAssignableUserRole[] = [];
        for (const previousRole of previousRoleList) {
            if (!newRolesList.find((i) => i.role === previousRole.role)) {
                if (!isNewUser) {
                    rolesToRemove.push(previousRole.role as BasicAssignableUserRole);
                }
            }
        }
        for (const newRole of newRolesList) {
            if (!previousRoleList.find((i) => i.role === newRole.role)) {
                if (!isNewUser) {
                    rolesToAdd.push(newRole.role as BasicAssignableUserRole);
                }
            }
        }
        if (!isNewUser) {
            if (rolesToAdd.length > 0) {
                await this.usersService.assignRolesToUser(this.user.username, { roleNames: rolesToAdd });
            }
            if (rolesToRemove.length > 0) {
                await this.usersService.removeRolesFromUser(this.user.username, { roleNames: rolesToRemove });
            }
        }
    }

    changePseudonymActivation(value: boolean): void {
        this.userForm.controls.usePseudonym.setValue(value);
        this.userForm.markAsDirty();
        if (value) {
            this.userForm.controls.pseudonymDefinition.enable();
            if (this.userForm.controls.pseudonymDefinition.value) {
                this.userForm.controls.pseudonym.setValue(this.user.patientNumber);
                this.userForm.controls.pseudonym.disable();
                this.userForm.markAsDirty();
            } else {
                this.userForm.controls.pseudonym.enable();
                this.userForm.markAsDirty();
            }
        } else {
            this.userForm.controls.pseudonym.disable();
            this.userForm.controls.pseudonymDefinition.disable();
            this.userForm.markAsDirty();
        }
    }

    changePseudonymDefinition(value: boolean): void {
        this.userForm.controls.pseudonymDefinition.setValue(value);
        if (value) {
            this.userForm.controls.pseudonym.setValue(this.user.patientNumber);
            this.userForm.controls.pseudonym.disable();
            this.userForm.markAsDirty();
        } else {
            this.userForm.controls.pseudonym.enable();
        }
    }

    onChangeAccountType(value: AccountType): void {
        if (!this.isEditEnabled && !this.hasRightToModifyUser()) return;
        this.userForm.patchValue({ accountType: value });
        if (value === AccountType.LOGIN_USER) {
            this.userForm.controls.email.addValidators(Validators.required);
            if (!this.originallyHadOtpConfigured) this.userForm.controls.hasOtpConfigured.enable();
        }
        if (value === AccountType.PLAIN_USER) {
            this.userForm.controls.email.removeValidators(Validators.required);
            this.userForm.controls.hasOtpConfigured.disable();
            this.userForm.controls.hasOtpConfigured.patchValue(false);
        }
        this.userForm.markAsDirty();
        this.userForm.controls.email.updateValueAndValidity();
        this.userForm.controls.hasOtpConfigured.updateValueAndValidity();
    }

    async onChangeOtpRequirement(value: boolean): Promise<void> {
        if (value === true) {
            const modalConfig = new ModalConfig();
            modalConfig.modalTyp = ModalTyp.INFORMATION;
            modalConfig.title = `2FA aktivieren`;
            modalConfig.titleIcon = 'warning-outline';
            modalConfig.description =
                'Wenn Sie die 2FA aktivieren, ist sie für dieses Benutzerkonto nachträglich nicht deaktivierbar. Möchten Sie die 2FA aktivieren?';

            modalConfig.buttonRight = new ButtonConfig();
            modalConfig.buttonRight.buttonText = 'Aktivieren';
            modalConfig.buttonRight.buttonColor = 'primary';
            const action = await this.modalAlertService.showModal(modalConfig);
            if (action && action.action === 'left') {
                this.userForm.controls.hasOtpConfigured.reset(false);
            }
        }
    }

    isProfilePage(): boolean {
        return this.getLocation().includes(RoutingSegment.PROFILE);
    }

    async navigateToDetailPage(username: string): Promise<void> {
        if (this.readUserRoleFromURL() === UserRoles.PATIENT) {
            await this.navigateWithPatientInParams(username);
            return;
        }
        await this.routingService.openUserDetails(this.readUserRoleFromURL(), username, true);
    }

    /**
     * Navigates back to consultation creation, screening creation or order creation based on query params
     * Otherwise navigates to patient detail page
     * @param username
     */
    async navigateWithPatientInParams(username: string): Promise<void> {
        if (this.isPatientForConsultation) {
            await this.router.navigate([RoutingSegment.MEMBER, RoutingSegment.CONSULTATION_MANAGEMENT, 'new'], {
                queryParams: { patient: username },
            });
        } else if (this.isPatientForScreening) {
            await this.router.navigate([RoutingSegment.MEMBER, RoutingSegment.SCREENING_MANAGEMENT, 'new'], {
                queryParams: { patient: username },
            });
        } else if (this.isPatientForOrder) {
            await this.router.navigate([RoutingSegment.MEMBER, RoutingSegment.ORDER_MANAGEMENT, 'new'], {
                queryParams: { patient: username },
            });
        } else {
            await this.routingService.openUserDetails(this.readUserRoleFromURL(), username, true);
        }
    }

    getLocation(): string {
        // can be longer that router.url, but should always include it
        return decodeURIComponent(this.location.path());
    }

    public async handleActionOfCustomPropertyContentTable(
        actionEmitter: ActionEmitter<Content>,
        schema: UserCustomPropertySchema,
        username: string,
    ) {
        const contentUuid = actionEmitter?.item?.uuid;
        if (actionEmitter.actionType === ActionType.DELETE) {
            const modalConfig = new ModalConfig();
            modalConfig.modalTyp = ModalTyp.INFORMATION;
            modalConfig.title = `Datei löschen`;
            modalConfig.titleIcon = 'warning-outline';
            modalConfig.description = 'Sind Sie sicher, dass Sie diese Datei löschen möchten?';
            modalConfig.buttonRight = new ButtonConfig();
            modalConfig.buttonRight.buttonText = 'Löschen';
            modalConfig.buttonRight.buttonColor = 'danger';
            const action = await this.modalAlertService.showModal(modalConfig);
            if (action && action.action === 'right') {
                try {
                    this.userCustomPropertyService.deleteUserCustomPropertyFile$(username, contentUuid).subscribe({
                        complete: () => {
                            this.user.customProperties = this.user.customProperties.map((ucp) => {
                                ucp.contents = ucp.contents.filter((x) => x.uuid !== contentUuid);
                                return ucp;
                            });
                            this.initCustomPropTableConfig(schema);
                            this.toggleEdit();
                        },
                    });
                    await this.toastService.showToast(ToastService.changeSavedMessage, IonicColor.success);
                } catch (e) {
                    this.log.error('Error in deleteContent', e);
                    await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
                }
            }
        } else if (actionEmitter.actionType === ActionType.PREVIEW) {
            await this.userCustomPropertyService.openUserCustomPropertyFile(username, actionEmitter?.item?.uuid);
        }
    }

    uploadFileToCustomProperty($event: FileItem, schema: UserCustomPropertySchema) {
        this.userCustomPropertyService
            .uploadUserCustomPropertyFile(this.user.username, schema.uuid, [$event._file])
            .subscribe({
                next: (ucp: UserCustomProperty) => {
                    const ucpIdx = this.user.customProperties.findIndex((x) => x.schemaId === schema.uuid);
                    if (ucpIdx >= 0) {
                        this.user.customProperties[ucpIdx] = ucp;
                    } else {
                        this.user.customProperties.push(ucp);
                    }
                    this.initCustomPropTableConfig(schema);
                    this.toggleEdit();
                },
            });
    }

    isCustomPropsTableHidden(schema: UserCustomPropertySchema) {
        const customProp = this.user.customProperties?.find((x) => x.schemaId === schema.uuid);
        return !(customProp?.contents?.length > 0);
    }

    public initCustomPropTableConfig(schema: UserCustomPropertySchema): TableConfig<Content[]> {
        const contentListConfig = new TableConfig<Content[]>();
        contentListConfig.itemSettings = [
            {
                id: 'description',
                prop: 'origFileName',
                header: 'Dateiname',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '85%',
                sortOrderWeb: 0,
            },
        ];
        if (this.isEditEnabled) {
            contentListConfig.itemSettings.push({
                prop: 'uuid',
                id: 'action_delete',
                header: '',
                type: ItemType.ADAPTER,
                adapter: ButtonItemAdapterComponent,
                icon: 'close',
                actionType: ActionType.DELETE,
                width: '15%',
                color: 'danger',
                sortOrderWeb: 1,
            });
        } else {
            contentListConfig.itemSettings.push({
                prop: 'uuid',
                id: 'action_preview',
                header: '',
                type: ItemType.ADAPTER,
                adapter: ButtonItemAdapterComponent,
                icon: 'download-outline',
                actionType: ActionType.PREVIEW,
                width: '8%',
                sortOrderWeb: 1,
                disabled: false,
            });
        }
        const customProp = this.user?.customProperties?.find((p) => p.schemaId === schema.uuid);
        contentListConfig.list = PaginatedResponse.init(customProp?.contents);
        return contentListConfig;
    }

    private async initRolesConfig() {
        this.roleListConfig.emptyListLabel = 'USER.ROLLE.ANY_ASSIGN';
        this.roleListConfig.itemSettings = [
            {
                id: 'role',
                prop: 'role',
                header: 'Name',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '40%',
                sortOrderWeb: 0,
            },
        ];
        await this.initRoleList();
    }

    private async initGroupsConfig() {
        this.groupListConfig.emptyListLabel = 'USER.GROUP.ANY';
        this.groupListConfig.itemSettings = [
            {
                id: 'name',
                prop: 'titleName',
                header: 'Name',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '40%',
                sortOrderMobile: 0,
                isMobileBold: true,
            },
            {
                id: 'name',
                prop: 'name',
                header: 'Name',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '40%',
                sortOrderWeb: 0,
            },
            {
                id: 'parentName',
                prop: 'parentName',
                header: 'Organisation',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '25%',
                sortOrderWeb: 1,
            },
            {
                id: 'category',
                prop: 'category',
                header: 'Kategorie',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '35%',
                sortOrderWeb: 2,
                sortOrderMobile: 1,
            },
        ];
        await this.initGroupList();
    }

    private async saveNewUser() {
        try {
            const userRoles = [];
            for (const role of this.user.roles) {
                userRoles.push({ role });
            }
            let newUser: User;
            if (
                this.userForm.value.accountType !== AccountType.LOGIN_USER &&
                this.readUserRoleFromURL() === UserRoles.PATIENT
            ) {
                if (this.loggedInUser.roles.includes(UserRoles.SUPERVISOR)) {
                    newUser = await this.supervisorService.postSupervisedPlainPatient(
                        this.loggedInUser.username,
                        this.buildPlainUserDto(),
                        this.userMemberships,
                    );
                } else {
                    newUser = await this.usersService.postPlainUser(this.buildPlainUserDto(), this.userMemberships);
                }
            } else {
                if (this.loggedInUser.roles.includes(UserRoles.SUPERVISOR)) {
                    newUser = await this.supervisorService.postSupervisedLoginPatient(
                        this.loggedInUser.username,
                        this.buildLoginUserWithoutPasswordDto(),
                        this.userMemberships,
                    );
                } else {
                    /*
                     * postLoginPatient() requires the logged in user to have the role "create_patient"
                     * meanwhile postLoginUser() requires the logged in user to have the role "create_user"
                     * which is why both endpoints are separate from one another.
                     * For more info see ionic-common#7868, curafida-entwicklungsideen#106 and nest-common!346
                     */
                    if (this.readUserRoleFromURL() === UserRoles.PATIENT) {
                        newUser = await this.usersService.postLoginPatient(
                            this.buildLoginUserWithoutPasswordDto(),
                            this.userMemberships,
                            true,
                            this.userForm.controls.hasOtpConfigured.value,
                        );
                    } else {
                        newUser = await this.usersService.postLoginUser(
                            this.buildLoginUserWithoutPasswordDto(),
                            [this.readUserRoleFromURL()],
                            this.userMemberships,
                            true,
                            this.userForm.controls.hasOtpConfigured.value,
                        );
                    }
                }
            }
            this.isNewUser = false;
            await this.toastService.showToast(ToastService.changeSavedMessage, IonicColor.success);
            this.loadingService.resetCountLoadingModal();
            await this.navigateToDetailPage(newUser?.username);
        } catch (e) {
            this.log.error('Error in saveUserInformation', e);
            await this.handleErrorUserInfo(e);
        }
    }

    private async updateExistingUser() {
        try {
            let updatedUser: User;
            const requireOtpConfiguration: boolean =
                !this.originallyHadOtpConfigured &&
                this.userForm.controls.hasOtpConfigured.value &&
                this.hasRightToModifyUser();
            if (this.originallyPlainUser && this.userForm.value.accountType === AccountType.LOGIN_USER) {
                await this.usersService.upgradeToLoginPatient(
                    this.user.username,
                    { email: this.userForm.controls.email.value },
                    true,
                    requireOtpConfiguration,
                );
            } else if (requireOtpConfiguration) {
                await this.setRequireOtpConfiguration(this.user.username);
            }
            if (this.user.roles.includes(UserRoles.PATIENT)) {
                updatedUser = await this.usersService.updatePatient(this.buildUpdateUserDto(), this.user.username);
            } else {
                updatedUser = await this.usersService.putUser(this.buildUpdateUserDto(), this.user.username);
            }
            this.updateTitle.emit({
                firstName: updatedUser.firstname,
                lastName: updatedUser.lastname,
                birthDate: updatedUser.birthdate,
            });
            this.user = await this.usersService.getUser(updatedUser.username);
            if (this.userForm.controls.pseudonym) {
                this.userForm.controls.usePseudonym.setValue(this.user.usePseudonym);
            }
            this.toggleEdit();
            await this.toastService.showToast(ToastService.changeSavedMessage, IonicColor.success);
            if (this.readUserRoleFromURL() === UserRoles.PATIENT && this.config.navigateOnEdit) {
                await this.navigateWithPatientInParams(updatedUser.username);
            }
        } catch (e) {
            this.log.error('Error in saveUserInformation', e);
            await this.handleErrorUserInfo(e);
        }
    }

    private getUserRolesFromConfig(): UserRoles[] {
        return [this.readUserRoleFromURL()];
    }

    private readUserRoleFromURL(): UserRoles {
        const url = this.getLocation();
        if (url.includes(UserRoles.CAREGIVER.toLocaleLowerCase())) {
            return UserRoles.CAREGIVER;
        } else if (url.includes(UserRoles.USER_MANAGER.toLocaleLowerCase())) {
            return UserRoles.USER_MANAGER;
        } else if (url.includes(UserRoles.CATALOG_MANAGER.toLocaleLowerCase())) {
            return UserRoles.CATALOG_MANAGER;
        } else if (url.includes(UserRoles.PATIENT.toLocaleLowerCase())) {
            return UserRoles.PATIENT;
        } else if (url.includes(UserRoles.SUPERVISOR.toLocaleLowerCase())) {
            return UserRoles.SUPERVISOR;
        } else if (url.includes(UserRoles.ANALYST.toLocaleLowerCase())) {
            return UserRoles.ANALYST;
        } else if (url.includes(UserRoles.KNOWLEDGE_MANAGER.toLocaleLowerCase())) {
            return UserRoles.KNOWLEDGE_MANAGER;
        }
    }

    private async initCustomPropsFormControls() {
        const roles = this.isProfilePage() ? this.user.roles : this.getUserRolesFromConfig();
        // TODO: change if group changes when registering a new user
        const schemas = await this.usersService.getUserCustomPropertySchemasByGroup(this.loggedInUser.groups[0], roles);
        this.customPropertySchemas = schemas?.filter((s) => !s.propertyGroupUuid) || [];
        if (this.viewerIsPatient() || this.isOwnDetails()) {
            this.customPropertySchemas = this.customPropertySchemas?.filter((s) => s.userReadable) || [];
        }
        for (const customPropertySchema of this.customPropertySchemas) {
            customPropertySchema.propertySchemas = schemas.filter(
                (s) => s?.propertyGroupUuid === customPropertySchema?.uuid,
            );
        }

        for (const propertyGroup of this.customPropertySchemas) {
            for (const schema of propertyGroup.propertySchemas) {
                const customProp = this.user?.customProperties?.find((p) => p.schemaId === schema.uuid);
                const initialValue = customProp?.value || '';
                const customPropertyFormControlName = schema.getFormControlName();
                this.userForm.addControl(
                    customPropertyFormControlName,
                    new FormControl(
                        {
                            value: initialValue,
                            disabled: !this.isEditEnabled,
                        },
                        schema.required ? requiredValidators : null,
                    ),
                );
                if (schema.validationEnabled) {
                    const validationRepeatCheckboxFormControlName = schema.getRepeatValidationCheckBoxFormControlName();
                    this.userForm.addControl(
                        validationRepeatCheckboxFormControlName,
                        new FormControl({
                            value: customProp?.validated || false,
                            disabled: !this.isEditEnabled,
                        }),
                    );
                    const validationRepeatInputFormControlName = schema.getRepeatValidationInputFormControlName();
                    this.userForm.addControl(
                        validationRepeatInputFormControlName,
                        new FormControl({
                            value: customProp?.validated ? customProp?.value : '',
                            disabled: !this.isEditEnabled,
                        }),
                    );
                    this.userForm.addValidators([
                        repeatValidationValidator(
                            customPropertyFormControlName,
                            validationRepeatCheckboxFormControlName,
                            validationRepeatInputFormControlName,
                        ),
                    ]);
                }
            }
        }
    }

    onRepeatValidationCheckboxChanged(
        event: { detail: CheckboxChangeEventDetail<boolean> },
        schema: UserCustomPropertySchema,
    ) {
        // Reset repeat validation input to empty string if validation checkbox is unchecked
        if (!event?.detail?.checked) {
            this.userForm.get(schema.getRepeatValidationInputFormControlName()).setValue('');
        }
    }

    private viewerIsPatient(): boolean {
        return this.loggedInUser.roles.includes(UserRoles.PATIENT);
    }

    private readCustomPropsFromForm(): UserCustomPropertyDto[] {
        const customProps = [];
        let schemas = this.customPropertySchemas?.flatMap((s) => s.propertySchemas) || [];
        if (this.viewerIsPatient() || this.isOwnDetails()) {
            schemas = schemas.filter((s) => s.userWritable);
        }
        for (const schema of schemas) {
            const formControlName = schema.getFormControlName();
            const userCustomPropertyDto = new UserCustomPropertyDto(
                schema.uuid,
                this.userForm.controls[formControlName].value,
            );
            if (userCustomPropertyDto.value !== undefined) {
                if (schema.validationEnabled) {
                    const validationCheckboxFormControl = this.userForm.get(
                        schema.getRepeatValidationCheckBoxFormControlName(),
                    );
                    const validationInputFormControl = this.userForm.get(
                        schema.getRepeatValidationInputFormControlName(),
                    );
                    userCustomPropertyDto.validated =
                        validationCheckboxFormControl.getRawValue() === true &&
                        userCustomPropertyDto.value === validationInputFormControl?.value;
                }
                // if this is an update, value existed beforehand and had an uuid
                if (this.user?.customProperties?.length > 0) {
                    const uuid = this.user.customProperties.find((p) => p.schemaId === schema.uuid)?.uuid;
                    if (uuid) userCustomPropertyDto.uuid = uuid;
                }
                // only save relevant changes (modified data) to backend
                if (!userCustomPropertyDto.isPristine()) customProps.push(userCustomPropertyDto);
            }
        }
        return customProps;
    }

    private async handleErrorUserInfo(e: any) {
        let errorMsg: string;
        if (e?.error?.message?.length) errorMsg = e.error?.message[0];
        if (errorMsg.includes('Pseudonym')) {
            await this.toastService.showToast('Pseudonym ist bereits vergeben oder nicht erlaubt.', IonicColor.danger);
        } else {
            await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
            await this.ngOnInit();
        }
    }
}
