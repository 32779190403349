import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActionType, ItemAdapterComponent, TableItem } from '../../entities/table';

@Component({
    template: `
        <ion-button
            *ngIf="isShown()"
            color="{{ itemSetting.color }}"
            (click)="setActionTypeEvent($event)"
            [id]="itemSetting.id"
            [disabled]="itemSetting.disabled || data.disabled || data[itemSetting.disabledProp]"
            fill="{{ itemSetting.fill || 'solid' }}">
            <ion-icon
                *ngIf="itemSetting.icon"
                id="icon_{{ itemSetting.id }}"
                style="height: 30px; margin: 3px;"
                name="{{ itemSetting.icon }}"></ion-icon>
            <span *ngIf="data[itemSetting.label]"> {{ data[itemSetting.label] | curafidaTranslate }}</span>
        </ion-button>
    `,
    styles: ['ion-button{ height: 32px}'],
})
export class ButtonItemAdapterComponent implements ItemAdapterComponent {
    @Input() data: any;
    @Input() itemSetting: TableItem;
    @Output() updateList = new EventEmitter<boolean>();
    @Output() actionSelected = new EventEmitter<{ action: ActionType; mouseEvent: MouseEvent }>();

    setActionTypeEvent(event: MouseEvent) {
        if (this.itemSetting.disabled || this.data.disabled || this.data[this.itemSetting.disabledProp]) return;
        this.actionSelected.emit({ action: this.itemSetting.actionType, mouseEvent: event });
    }

    isShown() {
        return this.isDeleteActivated() || this.isPopoverWithActions() || !this.isSpecificAction();
    }

    private isDeleteActivated() {
        return Boolean(this.itemSetting.actionType === ActionType.DELETE && !this.data.hideDeleteButton);
    }

    private isPopoverWithActionsProperty() {
        return Boolean(this.itemSetting.actionType === ActionType.POPOVER && this.data.actions);
    }

    private isPopoverWithActions() {
        return this.isPopoverWithActionsProperty() && this.data.actions.length > 0;
    }

    private isSpecificAction() {
        return this.itemSetting.actionType === ActionType.DELETE || this.isPopoverWithActionsProperty();
    }
}
