import { Pipe, PipeTransform } from '@angular/core';
import { TableConfig } from '../../table/entities/table';
import { MiaPatient } from '../entities/mia-patient';

@Pipe({
    name: 'filterPatients',
})
export class FilterPatientsPipe implements PipeTransform {
    transform(patients: TableConfig<MiaPatient[]>, searchTerm: string): TableConfig<MiaPatient[]> {
        const copyOfPatientList = new TableConfig<MiaPatient[]>();
        copyOfPatientList.list.items = patients.list.items;
        copyOfPatientList.list.count = patients.list.count;
        copyOfPatientList.list.total = patients.list.total;
        copyOfPatientList.list.limit = patients.list.limit;
        copyOfPatientList.list.offset = patients.list.offset;
        copyOfPatientList.itemSettings = patients.itemSettings;
        copyOfPatientList.isOpenDetailEnable = patients.isOpenDetailEnable;
        copyOfPatientList.hideHeader = patients.hideHeader;
        copyOfPatientList.isReorderDisable = patients.isReorderDisable;
        if (!searchTerm || searchTerm.length === 0) {
            return copyOfPatientList;
        }
        let miaPatients: MiaPatient[] = copyOfPatientList.list.items;
        searchTerm = searchTerm.replace(/[\s,]*$/, '');
        const searchTerms = searchTerm.split(/[\s,]+/);
        miaPatients = miaPatients.filter((patient) => {
            const patientDisplayName = patient?.patientDisplayName;
            // Ignore "," (colon)
            const nameTerms = patient?.name?.split(/[\s,]+/);
            const name = patient?.name?.replace(',', '');
            if (!patientDisplayName && !name) {
                console.error('It is not possible to have a Patient without name or displayName');
                return;
            }
            if (patientDisplayName && !name) {
                return patientDisplayName.toLowerCase().includes(searchTerm.toLowerCase());
            }
            if (name && !patientDisplayName) {
                return name.toLowerCase().includes(searchTerm.toLowerCase());
            }
            if (searchTerms?.length <= 1) {
                return (
                    patientDisplayName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    name.toLowerCase().includes(searchTerm.toLowerCase())
                );
            } else {
                return (
                    patientDisplayName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    this.filterTermsOfArrayWithOtherArray(nameTerms, searchTerms)
                );
            }
        });
        copyOfPatientList.list.items = miaPatients;
        return copyOfPatientList;
    }

    private filterTermsOfArrayWithOtherArray(nameTerms: string[], searchTerms: string[]): boolean {
        if (!nameTerms || !searchTerms || nameTerms.length < searchTerms.length) {
            return false;
        }
        const matchedTerms = [];
        try {
            for (let i = 0; i < searchTerms.length; i++) {
                const searchTerm = searchTerms[i];
                if (searchTerm === '') {
                    continue;
                }
                for (let j = 0; j < nameTerms.length; j++) {
                    const name = nameTerms[j];
                    if (name?.toLowerCase() === searchTerm && searchTerms.length > 0) {
                        matchedTerms.push(Match.complete);
                        break;
                    }
                    if (name?.toLowerCase().includes(searchTerm)) {
                        matchedTerms.push(Match.partial);
                        break;
                    } else {
                        matchedTerms.push(Match.noMatch);
                    }
                }
            }
            // TODO Check if this applies for all cases. It doesn't seem 100% generic
            return (
                (!!matchedTerms.find((match) => match === Match.complete) &&
                    !!matchedTerms.find((match) => match === Match.partial)) ||
                matchedTerms.filter((match) => match === Match.complete).length === searchTerms.length
            );
        } catch (e) {
            console.error('Error', e);
        }
    }
}

enum Match {
    complete = 'complete',
    partial = 'partial',
    noMatch = 'noMatch',
}
