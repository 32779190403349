import { Component, Input } from '@angular/core';
import { ItemAdapterComponent, TableItem } from '../../entities/table';

@Component({
    template: `
        <lib-star-processing
            [tableInput]="data[itemSetting.prop]"
            class="full-width"></lib-star-processing>
    `,
})
export class StarProcessingAdapterComponent implements ItemAdapterComponent {
    @Input() data: { disabled: boolean };
    @Input() itemSetting: TableItem;
}
