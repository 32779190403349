// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-select::part(icon) {
  color: #fff !important;
}`, "",{"version":3,"sources":["webpack://./../../libs/common/components/curafida-input/curafida-select-input/curafida-select-input.component.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;AACJ","sourcesContent":["ion-select::part(icon) {\n    color: #fff !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
