// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icons {
  display: grid;
  gap: 0.25rem 2rem;
  grid-auto-columns: fit-content(1rem);
  grid-auto-flow: column;
  grid-template-rows: 1fr 1fr 1fr;
  overflow-x: auto;
}
.icons .checkbox-item {
  gap: 0.5rem;
  flex: 0 1 2.5rem;
  display: flex;
  align-items: center;
}
.icons .checkbox-item ion-checkbox {
  margin: 0;
}
.icons .checkbox-item .checkbox-icon {
  width: 1.75rem;
  height: 1.75rem;
}`, "",{"version":3,"sources":["webpack://./../../libs/therapy/components/modal/task-filter-modal/icon-selection/icon-filter-selection.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,iBAAA;EACA,oCAAA;EACA,sBAAA;EACA,+BAAA;EACA,gBAAA;AACJ;AACI;EACI,WAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;AACR;AACQ;EACI,SAAA;AACZ;AAEQ;EACI,cAAA;EACA,eAAA;AAAZ","sourcesContent":[".icons {\n    display: grid;\n    gap: 0.25rem 2rem;\n    grid-auto-columns: fit-content(1rem);\n    grid-auto-flow: column;\n    grid-template-rows: 1fr 1fr 1fr;\n    overflow-x: auto;\n\n    .checkbox-item {\n        gap: 0.5rem;\n        flex: 0 1 2.5rem;\n        display: flex;\n        align-items: center;\n\n        ion-checkbox {\n            margin: 0;\n        }\n\n        .checkbox-icon {\n            width: 1.75rem;\n            height: 1.75rem;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
