import { Pipe, PipeTransform } from '@angular/core';
import { Content } from '../entities/content';
import { ContentUsage } from '../entities/content/content-usage';

@Pipe({
    name: 'hideLearningContents',
})
export class HideLearningContentsPipe implements PipeTransform {
    transform(contents: Content[]): Content[] {
        if (contents) {
            contents = contents.filter((it) => {
                if (
                    it.contentUsage !== ContentUsage.ARTICULATE_CONTENT &&
                    it.contentUsage !== ContentUsage.ARTICULATE_SUMMARY_CONTENT
                ) {
                    return it;
                }
            });
        }
        return contents;
    }
}
