import { Component, OnInit } from '@angular/core';
import { SplashScreenStateService } from '../../services/splash-screen-state.service';

/**
 * https://vaiz10.medium.com/creating-a-splash-screen-in-angular-for-loading-all-the-data-at-startup-b0b91d9d9f93
 */

@Component({
    selector: 'lib-splash',
    templateUrl: './splash.component.html',
    styleUrls: ['./splash.component.scss'],
})
export class SplashComponent implements OnInit {
    // The screen starts with the maximum opacity
    public opacityChange = 1;
    public splashTransition;
    // First access the splash is visible
    public showSplash = true;
    readonly ANIMATION_DURATION = 0.5;

    constructor(private splashScreenStateService: SplashScreenStateService) {}

    ngOnInit(): void {
        // Somewhere the stop method has been invoked
        this.splashScreenStateService.subscribe((res) => {
            if (res) {
                this.showSplashAnimation();
            } else {
                this.hideSplashAnimation();
            }
        });
    }

    private showSplashAnimation() {
        // Setting the transition
        this.splashTransition = `opacity ${this.ANIMATION_DURATION}s`;
        this.opacityChange = 1;
        this.showSplash = true;
    }

    private hideSplashAnimation() {
        // Setting the transition
        this.splashTransition = `opacity ${this.ANIMATION_DURATION}s`;
        this.opacityChange = 0;
        setTimeout(() => {
            // After the transition is ended the showSplash will be hided
            this.showSplash = false;
        }, 500);
    }
}
