import { ActionType, ButtonItemAdapterComponent, ItemType, TableItem } from '../../../table/entities/table';
import { CheckBoxItemAdapterComponent } from '../../../table/components/table-adapter/checkbox-item-adapter.component';
import { StringItemAdapterComponent } from '../../../table/components/table-adapter/string-item-adapter.component';
import { SortBy } from '../../../common/entities/paginated-response';
import { TagsAdapterComponent } from '../../../therapy/components/table-adapter/tags-adapter.component';
import { UserRoles } from '../../../auth/entities/user';

export function careManagementItemSettingsConfig(managedRole: UserRoles): TableItem[] {
    return [
        {
            id: `${managedRole}_nameOfUser`,
            prop: 'nameOfUser',
            header: 'nameOfUser',
            type: ItemType.ADAPTER,
            adapter: StringItemAdapterComponent,
            width: '15%',
            sortOrderMobile: 0,
            isMobileBold: true,
        },
        {
            id: `${managedRole}_firstname`,
            prop: 'firstname',
            header: 'FIRST_NAME',
            type: ItemType.ADAPTER,
            adapter: StringItemAdapterComponent,
            width: '15%',
            sortBy: SortBy.FIRSTNAME,
            sortOrderWeb: 0,
        },
        {
            id: `${managedRole}_lastname`,
            prop: 'lastname',
            header: 'LAST_NAME',
            type: ItemType.ADAPTER,
            adapter: StringItemAdapterComponent,
            width: '15%',
            sortBy: SortBy.LASTNAME,
            sortOrderWeb: 1,
        },
        {
            id: `${managedRole}_contactCategory`,
            prop: 'contactCategory',
            header: 'CATEGORY',
            type: ItemType.ADAPTER,
            adapter: StringItemAdapterComponent,
            width: '15%',
            sortOrderWeb: 2,
            sortOrderMobile: 1,
        },
        {
            id: `${managedRole}_email`,
            prop: 'email',
            header: 'EMAIL',
            type: ItemType.ADAPTER,
            adapter: StringItemAdapterComponent,
            width: '20%',
            sortBy: SortBy.EMAIL,
            sortOrderWeb: 3,
            sortOrderMobile: 2,
        },
        {
            id: `${managedRole}_phone`,
            prop: 'phone',
            header: 'PHONE',
            type: ItemType.ADAPTER,
            adapter: StringItemAdapterComponent,
            width: '27%',
            sortOrderWeb: 4,
            sortOrderMobile: 3,
        },
        {
            id: `${managedRole}_action_delete`,
            prop: 'id',
            header: '',
            type: ItemType.ADAPTER,
            adapter: ButtonItemAdapterComponent,
            icon: 'close-outline',
            color: 'danger',
            actionType: ActionType.DELETE,
            width: '8%',
            sortOrderWeb: 5,
            isHidden: true,
        },
    ];
}

export const tableItemsConfig: TableItem[] = [
    {
        id: 'selected',
        prop: 'selected',
        header: '',
        adapter: CheckBoxItemAdapterComponent,
        type: ItemType.ADAPTER,
        width: '10%',
        sortOrderWeb: 0,
    },
    {
        id: 'firstname',
        prop: 'firstname',
        header: 'FIRST_NAME',
        type: ItemType.ADAPTER,
        adapter: StringItemAdapterComponent,
        width: '15%',
        sortOrderWeb: 1,
    },
    {
        id: 'lastname',
        prop: 'lastname',
        header: 'LAST_NAME',
        type: ItemType.ADAPTER,
        adapter: StringItemAdapterComponent,
        width: '20%',
        sortOrderWeb: 2,
    },
    {
        id: 'contactCategory',
        prop: 'contactCategory',
        header: 'CATEGORY',
        type: ItemType.ADAPTER,
        adapter: StringItemAdapterComponent,
        width: '25%',
        sortOrderWeb: 3,
    },
    {
        id: 'tag',
        prop: 'tags',
        header: 'TAG.PLURAL',
        type: ItemType.ADAPTER,
        adapter: TagsAdapterComponent,
        width: '30%',
        sortOrderWeb: 3,
    },
];
