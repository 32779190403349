export class UserMainInfoConfig {
    contact?: boolean;
    address?: boolean;
    tags?: boolean;
    username?: boolean;
    pseudonym?: boolean;
    accountInfo?: boolean;
    navigateOnEdit?: boolean;
    patientCareManagement?: boolean;

    constructor(config?: UserMainInfoConfig) {
        this.contact = config?.contact ?? true;
        this.address = config?.address ?? true;
        this.tags = config?.tags ?? true;
        this.username = config?.username ?? true;
        this.pseudonym = config?.pseudonym ?? true;
        this.accountInfo = config?.accountInfo ?? true;
        this.navigateOnEdit = config?.navigateOnEdit ?? true;
        this.patientCareManagement = config?.patientCareManagement ?? true;
    }
}
