import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    CreateExerciseSessionDto,
    ExerciseSession,
    ExerciseSessionDto,
    ExerciseType,
} from '../../entities/exerciseSession';
import { ExerciseSessionAppointment, ExerciseSessionAppointmentDto } from '../../entities/appointement';
import { PaginatedResponse, SortBy, SortOrder } from '../../../common/entities/paginated-response';
import { ApiService } from '../../../api';
import { JitsiService } from '../../../video-conference/jitsi/jitsi.service';
import { HypermediaResource } from '../../../hateoas/hateoas.model';
import { NoAuthorizationPipe } from '../../../hateoas/authorization.pipe';
import { TranslateService } from '@ngx-translate/core';
import { TaskActionLinkName } from '../../components/task/task.resource';
import { ExerciseSessionEndpointFilterOption } from '../../entities/exerciseSession/exercise-session-end-point-filter-option';

@Injectable({
    providedIn: 'root',
})
export class ExerciseSessionsService {
    constructor(
        protected http: HttpClient,
        private jitsiService: JitsiService,
        private readonly noAuthorizationPipe: NoAuthorizationPipe,
        private readonly translateService: TranslateService,
    ) {}

    getExerciseSessions(args?: {
        offset?: number;
        exerciseType: ExerciseType;
        sortOrder?: SortOrder;
        limit?: number;
        sortBy?: SortBy;
        options?: ExerciseSessionEndpointFilterOption;
    }): Promise<PaginatedResponse<ExerciseSession[]>> {
        const url = new URL(`${ApiService.url}exerciseSessions`);

        if (args.offset) url.searchParams.set('offset', args.offset.toString());
        if (args.limit) url.searchParams.set('limit', args.limit.toString());
        if (args.options.therapyId) url.searchParams.set('therapyId', args.options.therapyId.toString());
        if (args.options.exerciseSessionState) {
            url.searchParams.set('exerciseSessionState', args.options.exerciseSessionState.toString());
        }
        if (args.sortBy) url.searchParams.set('sortBy', args.sortBy.toString());
        if (args.sortOrder) url.searchParams.set('sortOrder', args.sortOrder.toString());
        if (args.exerciseType) url.searchParams.set('exerciseType', args.exerciseType.toString());
        if (args.options.responsible) url.searchParams.set('responsible', args.options.responsible.toString());
        if (args.options.includeParticipants) {
            url.searchParams.set('includeParticipants', args.options.includeParticipants.toString());
        }
        if (args.options.includeStateChanges) {
            url.searchParams.set('includeStateChanges', args.options.includeStateChanges.toString());
        }
        if (args.options.exerciseSessionStates) {
            for (const state of args.options.exerciseSessionStates) {
                url.searchParams.append('exerciseSessionStates', state.toString());
            }
        }
        if (args.options.exerciseSessionUserStates) {
            for (const state of args.options.exerciseSessionUserStates) {
                url.searchParams.append('exerciseSessionUserStates', state.toString());
            }
        }
        if (args.options.startTimeBefore) {
            url.searchParams.set(
                'appointmentFilterProperties[startTimeBefore]',
                args.options.startTimeBefore.toString(),
            );
        }
        if (args.options.startTimeAfter) {
            url.searchParams.set('appointmentFilterProperties[startTimeAfter]', args.options.startTimeAfter.toString());
        }
        if (args.options.delayedTimeBefore) {
            url.searchParams.set(
                'appointmentFilterProperties[delayedTimeBefore]',
                args.options.delayedTimeBefore.toString(),
            );
        }
        if (args.options.delayedTimeAfter) {
            url.searchParams.set(
                'appointmentFilterProperties[delayedTimeAfter]',
                args.options.delayedTimeAfter.toString(),
            );
        }
        if (args.options.endTimeBefore) {
            url.searchParams.set('appointmentFilterProperties[endTimeBefore]', args.options.endTimeBefore.toString());
        }
        if (args.options.endTimeAfter) {
            url.searchParams.set('appointmentFilterProperties[endTimeAfter]', args.options.endTimeAfter.toString());
        }
        if (args.options.isGroup) url.searchParams.set('isGroup', args.options.isGroup.toString());
        return this.http.get<PaginatedResponse<ExerciseSession[]>>(url.toString(), ApiService.options).toPromise();
    }

    postExerciseSession(
        exerciseSessionDto: ExerciseSessionDto,
        usernamesPatients?: string[],
        usernamesCaregivers?: string[],
    ): Promise<ExerciseSession> {
        const url = `${ApiService.url}exerciseSessions`;
        const createExerciseSessionDto: CreateExerciseSessionDto = {
            ...exerciseSessionDto,
            usernamesPatients: usernamesPatients,
            usernamesCaregivers: usernamesCaregivers,
        };
        return this.http.post<ExerciseSession>(url, createExerciseSessionDto, ApiService.options).toPromise();
    }

    getExerciseSession(exerciseSessionId: number): Promise<ExerciseSession> {
        const url = `${ApiService.url}exerciseSessions/${exerciseSessionId}`;
        return this.http.get<ExerciseSession>(url, ApiService.options).toPromise();
    }

    assignPatientToExerciseSession(exerciseSession_id: number, username: string, ignoreGroupSize?: boolean) {
        let url = `${ApiService.url}exerciseSessions/${exerciseSession_id}/users/${username}`;

        // build query param string
        let queryParams = '';
        if (ignoreGroupSize) {
            queryParams = `${queryParams}ignoreGroupSize=${ignoreGroupSize}&`;
        }

        // check if query params are set, if so ...
        if (queryParams.length > 0) {
            // ... remove the last char '&' and append the query param string to the url
            queryParams = queryParams.substring(0, queryParams.length - 1);
            url = url + '?' + queryParams;
        }
        return this.http.put(url, null, ApiService.options).toPromise();
    }

    removePatientFromExerciseSession(exerciseSession_id: number, username: string) {
        const url = `${ApiService.url}exerciseSessions/${exerciseSession_id}/users/${username}`;
        return this.http.delete(url, ApiService.options).toPromise();
    }

    putTaskAppointment(
        taskResource: HypermediaResource,
        appointment: ExerciseSessionAppointmentDto,
    ): Promise<ExerciseSessionAppointment> {
        if (this.noAuthorizationPipe.transform(taskResource, 'appointment', 'write')) {
            throw new Error(this.translateService.instant('FORBIDDEN'));
        }
        const url = new URL(taskResource._links.appointment.href, ApiService.url);
        return this.http.put<ExerciseSessionAppointment>(url.toString(), appointment, ApiService.options).toPromise();
    }

    putAppointmentToExerciseSession(
        exerciseSession_id: number,
        appointment: ExerciseSessionAppointmentDto,
    ): Promise<ExerciseSessionAppointment> {
        return this.http
            .put<ExerciseSessionAppointment>(
                `${ApiService.url}exerciseSessions/${exerciseSession_id}/exerciseSessionAppointment`,
                appointment,
                ApiService.options,
            )
            .toPromise();
    }

    assignTherapistToExerciseSession(exerciseSession_id: number, username: string) {
        const url = `${ApiService.url}exerciseSessions/${exerciseSession_id}/therapists/${username}`;
        return this.http.put(url, '', ApiService.options).toPromise();
    }

    removeTherapistToExerciseSession(exerciseSession_id: number, username: string) {
        const url = `${ApiService.url}exerciseSessions/${exerciseSession_id}/therapists/${username}`;
        return this.http.delete(url, ApiService.options).toPromise();
    }

    postTaskAction(taskResource: HypermediaResource, taskAction: TaskActionLinkName) {
        if (this.noAuthorizationPipe.transform(taskResource, taskAction, 'write')) {
            throw new Error(this.translateService.instant('FORBIDDEN'));
        }
        const url = new URL(taskResource._links[taskAction].href, ApiService.url);
        return this.http.post<ExerciseSessionAppointment>(url.toString(), '', ApiService.options).toPromise();
    }

    postExerciseSessionConfirmPlanning(exerciseSessionId: number) {
        const url = `${ApiService.url}exerciseSessions/${exerciseSessionId}/confirmPlanning`;
        return this.http.post(url, '', ApiService.options).toPromise();
    }

    postExerciseSessionPostpone(exerciseSessionId: number) {
        const url = `${ApiService.url}exerciseSessions/${exerciseSessionId}/postpone`;
        return this.http.post(url, '', ApiService.options).toPromise();
    }

    postExerciseSessionCancel(exerciseSessionId: number) {
        const url = `${ApiService.url}exerciseSessions/${exerciseSessionId}/cancel`;
        return this.http.post(url, '', ApiService.options).toPromise();
    }

    postExerciseSessionActivate(exerciseSessionId: number) {
        const url = `${ApiService.url}exerciseSessions/${exerciseSessionId}/activate`;
        return this.http.post(url, '', ApiService.options).toPromise();
    }

    postExerciseSessionFinish(exerciseSessionId: number) {
        const url = `${ApiService.url}exerciseSessions/${exerciseSessionId}/finish`;
        return this.http.post(url, '', ApiService.options).toPromise();
    }

    deleteExerciseSession(exerciseSessionId: number) {
        const url = `${ApiService.url}exerciseSessions/${exerciseSessionId}`;
        return this.http.delete(url, ApiService.options).toPromise();
    }
}
