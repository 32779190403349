import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../api';
import { UserCustomProperty } from '../../auth/entities/user/user-custom-property';
import { CurafidaAuthService } from '../../auth/services';
import { FileContentService } from '../../common/services/content/file-content.service';
import { Content } from '../../therapy/entities/content';

@Injectable({
    providedIn: 'root',
})
export class UserCustomPropertyService {
    constructor(
        private readonly http: HttpClient,
        private readonly fileContentService: FileContentService,
        private readonly curafidaAuthService: CurafidaAuthService,
    ) {}

    uploadUserCustomPropertyFile(
        username: string,
        userCustomPropSchemaUuid: string,
        files: File[],
    ): Observable<UserCustomProperty> {
        if (!files || files.length < 1) {
            return;
        }
        const url = `users/${username}/userCustomPropertySchemas/${userCustomPropSchemaUuid}/files`;
        const headers = new HttpHeaders().append('authorization', ApiService.options.headers.get('authorization'));
        headers.append('content-type', 'multipart/form-data');
        const multipartOptions = { headers, withCredentials: true };
        const formData = new FormData();
        files.forEach((file) => formData.append('files', file));
        return this.http.post<UserCustomProperty>(url.toString(), formData, multipartOptions);
    }

    async openUserCustomPropertyFile(username: string, contentUuid: string): Promise<void> {
        const accessToken = this.curafidaAuthService.getSession()?.tokenSet?.access_token;
        const url = new URL(`users/${username}/userCustomProperty/files/${contentUuid}`, ApiService.url);
        return this.fileContentService.openObjectURLinNewWindow(url.toString(), accessToken);
    }

    deleteUserCustomPropertyFile$(username: string, contentUuid: string): Observable<Content> {
        const url = `users/${username}/userCustomProperty/files/${contentUuid}`;
        return this.http.delete<Content>(url);
    }
}
