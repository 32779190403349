// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-width {
  min-height: 10em;
  min-width: 10em;
}

.modal-width {
  min-height: 30em;
  min-width: 30em;
}

.web-width {
  min-height: 20em;
  min-width: 30em;
}`, "",{"version":3,"sources":["webpack://./../../libs/therapy/components/training/therapy-pdf-viewer/therapy-pdf-viewer.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,eAAA;AACJ;;AAEA;EACI,gBAAA;EACA,eAAA;AACJ;;AAEA;EACI,gBAAA;EACA,eAAA;AACJ","sourcesContent":[".mobile-width {\n    min-height: 10em;\n    min-width: 10em;\n}\n\n.modal-width {\n    min-height: 30em;\n    min-width: 30em;\n}\n\n.web-width {\n    min-height: 20em;\n    min-width: 30em;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
