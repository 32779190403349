import { Pipe, PipeTransform } from '@angular/core';
import { ActionButton, ActionItemType, ActionMenuItem } from '../../../table/entities/action-menu.item';
import { ActionType } from '../../../table/entities/table';
import { TaskResource } from './task.resource';
import { ExerciseSubType } from '../../entities/exerciseSession';
import { TranslateService } from '@ngx-translate/core';
import { AuthorizationPipe } from '../../../hateoas/authorization.pipe';

@Pipe({
    name: 'taskActions',
})
export class TaskActionsPipe implements PipeTransform {
    constructor(
        private readonly translate: TranslateService,
        private readonly authorizationPipe: AuthorizationPipe,
    ) {}

    transform(taskResource: TaskResource): ActionMenuItem[] {
        const items: ActionMenuItem[] = [];

        if (!taskResource) {
            return items;
        }

        if (
            taskResource.exerciseSessionUserResults?.length > 0 &&
            taskResource.exerciseSubType === ExerciseSubType.QUESTIONNAIRE
        ) {
            items.push(
                new ActionButton(ActionItemType.BUTTON, this.translate.instant('PREVIOUS_VERSION'), ActionType.MODAL),
            );
        }
        if (
            this.authorizationPipe.transform(taskResource, 'appointment', 'write') ||
            this.authorizationPipe.transform(taskResource, 'calendarEvent', 'write') ||
            this.authorizationPipe.transform(taskResource, 'participants', 'write')
        ) {
            items.push(new ActionButton(ActionItemType.BUTTON, this.translate.instant('EDIT'), ActionType.UPDATE));
        }
        if (this.authorizationPipe.transform(taskResource, 'responsibleCancel', 'write')) {
            items.push(
                new ActionButton(ActionItemType.BUTTON, this.translate.instant('CANCEL_TASK'), ActionType.CANCELED),
            );
        }
        return items;
    }
}
