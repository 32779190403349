import { Group } from '../../user/entities/group';

export class OrderSettings {
    groupUuid: string;
    group: Group;
    ordersEnabled: boolean;
    justifyingIndicationEnabled: boolean;
    orderProvidedServicesEnabled: boolean;
    created_at: string;
    updated_at: string;
    orderProvidedServices: ProvidedService[];
}

export class UpdateOrderSettingsDto {
    ordersEnabled?: boolean;
    justifyingIndicationEnabled?: boolean;
    orderProvidedServicesEnabled?: boolean;
}

export class CreateProvidedServiceDto {
    name: string;
    description?: string;
    isProviderService?: boolean;
    isClientService?: boolean;
}

export class ProvidedService extends CreateProvidedServiceDto {
    uuid: string;
    groupUuid: string;
    group?: Group;
    created_at: string;
    updated_at: string;
}

export class UpdateProvidedServiceDto {
    uuid: string;
    name?: string;
    description?: string;
    isProviderService?: boolean;
    isClientService?: boolean;
}
