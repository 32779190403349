// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kiosk-training-exercise-info {
  z-index: 100;
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.show-white-space {
  white-space: pre-wrap;
}

.dismiss-button {
  --background: #b6bbc3;
  --color: black;
  --border-color: black;
  --border-style: solid;
  --border-width: 1px;
}`, "",{"version":3,"sources":["webpack://./../../libs/kiosk/components/kiosk-training-video-modal/kiosk-training-video-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,kBAAA;EACA,YAAA;EACA,UAAA;AACJ;;AAEA;EACI,qBAAA;AACJ;;AAEA;EACI,qBAAA;EACA,cAAA;EACA,qBAAA;EACA,qBAAA;EACA,mBAAA;AACJ","sourcesContent":[".kiosk-training-exercise-info {\n    z-index: 100;\n    position: absolute;\n    bottom: 20px;\n    left: 20px;\n}\n\n.show-white-space {\n    white-space: pre-wrap;\n}\n\n.dismiss-button {\n    --background: #b6bbc3;\n    --color: black;\n    --border-color: black;\n    --border-style: solid;\n    --border-width: 1px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
