// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-label {
  font-size: 14px !important;
}

.segment-xtra-wide {
  width: 85%;
}

ion-button {
  height: 32px;
  font-size: 14px;
}

.curafida-search-input ion-input {
  --padding-top: 0px;
  --padding-bottom: 20px;
  font-size: 14px !important;
}

.curafida-search-input ion-label {
  margin-top: 0;
  margin-bottom: 15px;
}

.curafida-search-input {
  border: 2px var(--ion-color-light) solid;
  border-radius: 7px;
  height: 32px;
  --padding-start: 10px;
}

.mobile {
  width: 50%;
}

.web {
  width: 75%;
}

.extended-search-bar {
  margin-left: 0;
  width: 100%;
}
.extended-search-bar .web {
  width: 100%;
}

.standard-search-bar {
  min-width: 30%;
}`, "",{"version":3,"sources":["webpack://./../../libs/common/components/curafida-search-and-button/curafida-search-and-button.component.scss"],"names":[],"mappings":"AAAA;EACI,0BAAA;AACJ;;AAEA;EACI,UAAA;AACJ;;AAEA;EACI,YAAA;EACA,eAAA;AACJ;;AAEA;EACI,kBAAA;EACA,sBAAA;EACA,0BAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;AACJ;;AAEA;EACI,wCAAA;EACA,kBAAA;EACA,YAAA;EACA,qBAAA;AACJ;;AAEA;EACI,UAAA;AACJ;;AAEA;EACI,UAAA;AACJ;;AAEA;EACI,cAAA;EACA,WAAA;AACJ;AAAI;EACI,WAAA;AAER;;AAEA;EACI,cAAA;AACJ","sourcesContent":["ion-label {\n    font-size: 14px !important;\n}\n\n.segment-xtra-wide {\n    width: 85%;\n}\n\nion-button {\n    height: 32px;\n    font-size: 14px;\n}\n\n.curafida-search-input ion-input {\n    --padding-top: 0px;\n    --padding-bottom: 20px;\n    font-size: 14px !important;\n}\n\n.curafida-search-input ion-label {\n    margin-top: 0;\n    margin-bottom: 15px;\n}\n\n.curafida-search-input {\n    border: 2px var(--ion-color-light) solid;\n    border-radius: 7px;\n    height: 32px;\n    --padding-start: 10px;\n}\n\n.mobile {\n    width: 50%;\n}\n\n.web {\n    width: 75%;\n}\n\n.extended-search-bar {\n    margin-left: 0;\n    width: 100%;\n    .web {\n        width: 100%;\n    }\n}\n\n.standard-search-bar {\n    min-width: 30%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
