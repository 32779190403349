// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-label-text-input {
  flex-basis: 10%;
  min-width: 5vw !important;
  max-width: 15vw;
}

.item-input-modal-width {
  margin-bottom: 5px;
  width: 80%;
}`, "",{"version":3,"sources":["webpack://./../../libs/common/components/text-input-modal/text-input-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,yBAAA;EACA,eAAA;AACJ;;AAEA;EACI,kBAAA;EACA,UAAA;AACJ","sourcesContent":[".item-label-text-input {\n    flex-basis: 10%;\n    min-width: 5vw !important;\n    max-width: 15vw;\n}\n\n.item-input-modal-width {\n    margin-bottom: 5px;\n    width: 80%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
