import { isAfter, parseISO } from 'date-fns';

export class ExerciseSessionAppointmentDto {
    constructor(startTime?: string, endTime?: string, delayedTime?: string) {
        this.startTime = startTime;
        this.endTime = endTime;
        if (startTime && endTime && isAfter(parseISO(startTime), parseISO(endTime))) {
            throw new Error('Start time cannot be set after end time.');
        }
        this.delayedTime = delayedTime;
    }

    startTime: string;
    delayedTime: string;
    endTime: string;
}
