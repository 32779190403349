// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item {
  border: 1px solid var(--ion-color-light);
  margin-bottom: 5px;
  width: 50%;
  margin-left: 10px;
}

.item-label-user-info {
  flex-basis: 20%;
  min-width: 14em !important;
  -webkit-padding-start: 0 !important;
          padding-inline-start: 0 !important;
  max-width: 8em;
}`, "",{"version":3,"sources":["webpack://./../../libs/therapy/components/exercise/exercise-contents/exercise-contents.component.scss"],"names":[],"mappings":"AAAA;EACI,wCAAA;EACA,kBAAA;EACA,UAAA;EACA,iBAAA;AACJ;;AAEA;EACI,eAAA;EACA,0BAAA;EACA,mCAAA;UAAA,kCAAA;EACA,cAAA;AACJ","sourcesContent":["ion-item {\n    border: 1px solid var(--ion-color-light);\n    margin-bottom: 5px;\n    width: 50%;\n    margin-left: 10px;\n}\n\n.item-label-user-info {\n    flex-basis: 20%;\n    min-width: 14em !important;\n    padding-inline-start: 0 !important;\n    max-width: 8em;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
