import { Pipe, PipeTransform } from '@angular/core';
import { TaskResponsibility } from '../task.resource';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'responsibleName',
    pure: true,
})
export class ResponsibleNamePipe implements PipeTransform {
    constructor(private readonly translateService: TranslateService) {}
    transform(responsibility: TaskResponsibility): string {
        if (!responsibility) {
            return '-';
        }
        const translatedRole = this.translateService.instant(`USER.${responsibility.role.toString()}.SINGULAR`);
        return responsibility.person && responsibility.role !== 'PATIENT'
            ? `${translatedRole}\n${responsibility.person.lastname}, ${responsibility.person.firstname}`
            : translatedRole;
    }
}
