import { Group } from '../../user/entities/group';

export class ConsultationSettings {
    groupUuid: string;
    group: Group;
    consultationsEnabled: boolean;
    consultationProvidedServicesEnabled: boolean;
    created_at: string;
    updated_at: string;
    consultationProvidedServices: ProvidedService[];
}

export class UpdateConsultationSettingsDto {
    consultationsEnabled?: boolean;
    consultationProvidedServicesEnabled?: boolean;
}

export class CreateProvidedServiceDto {
    name: string;
    description?: string;
    isProviderService?: boolean;
    isClientService?: boolean;
}

export class ProvidedService extends CreateProvidedServiceDto {
    uuid: string;
    groupUuid: string;
    group?: Group;
    created_at: string;
    updated_at: string;
}

export class UpdateProvidedServiceDto {
    uuid: string;
    name?: string;
    description?: string;
    isProviderService?: boolean;
    isClientService?: boolean;
}
