import { Component, HostListener, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, FormGroup, NgControl } from '@angular/forms';
import { Subscription } from 'rxjs/internal/Subscription';
import { Platform, PopoverController } from '@ionic/angular';
import { DateTimePickerComponent } from '../../../common/components/date-picker/date-time-picker/date-time-picker.component';
import { StyleService } from '../../../common/services/style/style.service';
import { DatePipe } from '@angular/common';
import { UntilDestroy } from '@ngneat/until-destroy';
@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'curafida-calendar-time-planer',
    templateUrl: './calendar-time-planer.component.html',
    styleUrls: ['./calendar-time-planer.component.scss'],
})
export class CalendarTimePlanerComponent implements ControlValueAccessor, OnInit {
    isMobile: boolean;
    platformSubscription: Subscription;
    showAppointment = false;
    patientVisibility = [
        { value: 'standard', label: 'Standard' },
        { value: 'private', label: 'Privat' },
    ];
    @Input()
    formGroup: FormGroup;
    private changed = new Array<(value: FormGroup) => void>();
    private touched = new Array<() => void>();

    constructor(
        private platform: Platform,
        private styleService: StyleService,
        private popoverController: PopoverController,
        private datePipe: DatePipe,
        @Optional() @Self() public ngControl: NgControl,
    ) {
        if (this.ngControl != null) {
            // Setting the value accessor directly (instead of using
            // the providers) to avoid running into a circular import.
            this.ngControl.valueAccessor = this;
        }
    }

    get value(): FormGroup {
        return this.formGroup;
    }

    set value(value: FormGroup) {
        if (this.formGroup !== value) {
            this.formGroup = value;
            this.changed.forEach((f) => f(value));
        }
    }

    touch() {
        this.touched.forEach((f) => f());
    }

    writeValue(value: FormGroup) {
        this.formGroup = value;
    }

    @HostListener('onChange', ['$event.detail'])
    registerOnChange(fn: (value: FormGroup) => void) {
        this.changed.push(fn);
    }

    registerOnTouched(fn: () => void) {
        this.touched.push(fn);
    }

    ngOnInit(): void {
        this.isMobile = true;
    }

    async openDatePicker(formControlName: string) {
        const date = new Date();
        const popoverElement = await this.popoverController.create({
            component: DateTimePickerComponent,
            cssClass: 'modal-date-picker',
            componentProps: {
                date,
                presentation: 'date-time',
            },
        });
        await popoverElement.present();
        await popoverElement.onDidDismiss().then((pickedDate) => {
            if (pickedDate !== null && pickedDate?.data) {
                this.formGroup.controls[formControlName].setValue(
                    this.datePipe.transform(pickedDate.data, 'dd.MM.yyyy HH:mm') + ' Uhr',
                );
            }
        });
    }

    setStartDate() {
        this.openDatePicker('startTime');
    }

    setEndDate() {
        this.openDatePicker('endTime');
    }
}
