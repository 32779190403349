import { Pipe, PipeTransform } from '@angular/core';
import { DateFormat } from '../entities/table';
import { format } from 'date-fns';

@Pipe({
    name: 'DateTransform',
})
export class DateTransformPipe implements PipeTransform {
    transform(value: any, dateFormat: DateFormat): any {
        if (dateFormat && value !== '-') {
            if (dateFormat === DateFormat.DATE) {
                return format(new Date(value), 'dd.MM.yyyy');
            }
            if (dateFormat === DateFormat.DATE_AND_HOUR) {
                return format(new Date(value), 'dd.MM.yyyy') + ', ' + format(new Date(value), 'HH:mm') + ' Uhr';
            }
            if (dateFormat === DateFormat.DATE_AND_HOUR_2_LINES) {
                return format(new Date(value), 'dd.MM.yyyy') + '\n' + format(new Date(value), 'HH:mm') + ' Uhr';
            }
        } else {
            return value;
        }
    }
}
