import { Component, OnInit } from '@angular/core';
import { Logger, LoggingService } from '../../../../logging/logging.service';
import { Subscription } from 'rxjs';
import { Platform } from '@ionic/angular';
import { StyleService } from '../../../../common/services/style/style.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'concept-manual',
    templateUrl: './concept-main.component.html',
    styleUrls: ['./concept-main.component.scss'],
})
export class ConceptMainComponent implements OnInit {
    text: any;
    conceptList: ManualMainList[] = [];
    isMobile = false;
    protected readonly log: Logger;

    constructor(
        private loggingService: LoggingService,
        private styleService: StyleService,
    ) {
        this.isMobile = this.styleService.isMobile$;
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    async ngOnInit(): Promise<void> {
        fetch('assets/extra/manual.json')
            .then((resp) => resp.text())
            .then((text) => (this.conceptList = JSON.parse(this.replaceTextUrlsWithHtmlLinks(text))))
            .catch((err) => this.log.error(err));
    }

    /**
     * Replace all links starting with https:// with a HTML link element.
     */
    private replaceTextUrlsWithHtmlLinks(text: string): string {
        return text.replaceAll(/https:\/\/?[^\s\"]+/g, '<a href=\\"$&\\" target=\\"_blank\\">$&</a>');
    }

    getColor(color: string): string {
        if (color) {
            return `var(--ion-color-${color})`;
        } else {
            return 'black';
        }
    }
}

export class ManualMainList {
    type: string;
    title: string;
    subtitle: string;
    mainDetails: ManualItem[];
    phases: ManualPhase[];
    isOpen: boolean;
}

export class ManualPhase {
    title: string;
    items: ManualItem[];
}

export class ManualItem {
    label: ItemDefinition;
    content: ItemDefinition;
}

export class ItemDefinition {
    text: string;
    bold: boolean;
    color: string;
    italic: boolean;
}
