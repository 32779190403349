import { Content } from '../../therapy/entities/content';
import { Therapy } from '../../therapy/entities/therapy';
import { TherapyTemplate } from '../../therapy/entities/therapy/therapy-template';

export enum CurrencyEnum {
    EUR = 'EUR',
}

export const DEFAULT_VAT = 0.19;

export enum PublishStatusEnum {
    DRAFT = 'DRAFT',
    PUBLISHED = 'PUBLISHED',
}

export const PUBLISH_STATUS_LABELS = new Map<PublishStatusEnum, string>([
    [PublishStatusEnum.DRAFT, 'Deaktiviert'],
    [PublishStatusEnum.PUBLISHED, 'Freigegeben'],
]);

export enum ArticleTypeEnum {
    PROGRAM_ARTICLE = 'PROGRAM_ARTICLE',
}

export enum ArticleVariantTypeEnum {
    PROGRAM_ARTICLE_VARIANT = 'PROGRAM_ARTICLE_VARIANT',
    PROGRAM_TEMPLATE_ARTICLE_VARIANT = 'PROGRAM_TEMPLATE_ARTICLE_VARIANT',
}

export class CreateArticleDto {
    summary?: string;
    description?: string;
    price?: number;
    currency?: CurrencyEnum;
    vat: number;
    publishStatus?: PublishStatusEnum;
    medicalRequirements?: string;
    location?: string;
    articleVariantType: ArticleVariantTypeEnum;
}

export abstract class BaseArticle extends CreateArticleDto {
    uuid: string;
    title: string;
    latestRegisterDate: string;
    seatsAvailable: number;
}

export abstract class Article extends BaseArticle {
    articleType: ArticleTypeEnum;
    articleVariants: ArticleVariant[];
    contents: Content[];
    buyingArticleVariant: ArticleVariant;
    therapyTemplate: TherapyTemplate;
}

export abstract class ArticleVariant extends BaseArticle {
    articleNo: number;
    articleVariantType: ArticleVariantTypeEnum;
    article: Article;
    articleUuid: string;
    therapy: Therapy;

    abstract get availability(): number;
}
