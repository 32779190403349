import { LogbookEntry } from '../../logbook/entities/logbook-entry';

export enum GroupCategory {
    TENANT = 'TENANT',
    ORGANIZATION = 'ORGANIZATION',
    CENTRAL_FACILITY = 'CENTRAL_FACILITY',
    COOPERATION_FACILITY = 'COOPERATION_FACILITY',
    NO_CATEGORY = 'NO_CATEGORY',
}

export enum GroupLevel {
    TENANT = 'TENANT',
    ORGANIZATION = 'ORGANIZATION',
    INSTITUTION = 'INSTITUTION',
}

export class CreateGroupDto {
    name: string;
    // Parent is uuid from parent group
    parent: string;
    shortName?: string;
    category: GroupCategory;
}

export class Group extends CreateGroupDto {
    uuid: string;
    path: string;
    subGroups?: Group[];
    events?: LogbookEntry[];
}

export class UpdateGroupDto {
    uuid: string;
    name?: string;
    // Parent is uuid from parent group
    parent?: string;
    shortName?: string;
    category?: GroupCategory;
}

export class User_Group {
    uuid: string;
    username: string;
    group_uuid: string;
}
