import { Component, Input } from '@angular/core';
import { ItemAdapterComponent, TableItem } from '../../entities/table';

@Component({
    template: `
        <div *ngIf="isReorderDisable">
            <ion-reorder slot="start"></ion-reorder>
        </div>
        <div *ngIf="!isReorderDisable">
            {{ rowIndex + 1 }}
        </div>
    `,
})
export class NumberAndReorderAdapterComponent implements ItemAdapterComponent {
    @Input() data: boolean;
    @Input() itemSetting: TableItem;
    @Input() rowIndex: number;
    @Input() isReorderDisable: boolean;
}
