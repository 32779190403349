import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../../api';
import { TherapySession, TherapySessionState } from '../../entities/therapy-session/therapy-session';
import { PaginatedResponse, SortOrder } from '../../../common/entities/paginated-response';

@Injectable({
    providedIn: 'root',
})
export class TherapySessionsService {
    constructor(protected http: HttpClient) {}

    async getTherapySessions(
        offset?: number,
        limit?: number,
        sortOrder: SortOrder = null,
        state?: TherapySessionState,
        earliestStart?: string,
        latestEnd?: string,
        therapyId?: number,
        therapyIsLocked?: boolean,
        onlyNewestPerTherapy?: boolean,
        therapyEndIsAfter?: string,
    ): Promise<PaginatedResponse<TherapySession[]>> {
        const url = new URL(`${ApiService.url}therapySessions`);
        if (offset) url.searchParams.set('offset', offset.toString());
        if (limit) url.searchParams.set('limit', limit.toString());
        if (sortOrder) url.searchParams.set('sortOrder', sortOrder);
        if (therapyId) url.searchParams.set('therapyId', therapyId.toString());
        if (state) url.searchParams.set('state', state);
        if (earliestStart) url.searchParams.set('earliestStart', earliestStart);
        if (latestEnd) url.searchParams.set('latestEnd', latestEnd);
        if (therapyIsLocked) url.searchParams.set('therapyIsLocked', therapyIsLocked.toString());
        if (onlyNewestPerTherapy) url.searchParams.set('onlyNewestPerTherapy', onlyNewestPerTherapy.toString());
        if (therapyEndIsAfter) url.searchParams.set('therapyEndIsAfter', therapyEndIsAfter);

        return this.http.get<PaginatedResponse<TherapySession[]>>(url.toString(), ApiService.options).toPromise();
    }
}
