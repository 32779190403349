import { DateFormat, ItemType, TableConfig } from '../../../table/entities/table';
import { Therapy } from './therapy';
import { ExerciseSessionOfUser } from '../exerciseSession';
import { StringItemAdapterComponent } from '../../../table/components/table-adapter/string-item-adapter.component';
import {
    IconAdapterComponent,
    IconAdapterElement,
} from '../../../table/components/table-adapter/icon-adapter.component';
import { UserRoles } from '../../../auth/entities/user';

class PlanDefinition {
    title: string;
    start: string;
    end: string;
    responsibleRole: UserRoles | string;
    icons: IconAdapterElement[];
}

export class GenericPlan {
    therapyId: number;
    title = '';
    planTableConfig: TableConfig<PlanDefinition[]> = new TableConfig<PlanDefinition[]>();

    constructor(therapy: Therapy, emptyLabel?: string) {
        this.therapyId = therapy.id;
        this.title = therapy.title;
        this.initTable(emptyLabel);
        const exerciseSessionOfUsers: ExerciseSessionOfUser[] = [];
        for (const template of therapy?.exercises) {
            for (const exerciseSessionsOfUser of template?.exerciseSessionsOfUser) {
                exerciseSessionOfUsers.push(exerciseSessionsOfUser);
            }
        }
        for (const task of exerciseSessionOfUsers) {
            const planDefinition = new PlanDefinition();
            planDefinition.title = task.exerciseSession.title;
            planDefinition.start = '-';
            if (task.exerciseSession.appointment?.startTime) {
                planDefinition.start = task.exerciseSession.appointment?.startTime;
            }
            planDefinition.end = '-';
            if (task.exerciseSession.appointment?.endTime) {
                planDefinition.end = task.exerciseSession.appointment?.endTime;
            }
            planDefinition.responsibleRole = task.exerciseSession.responsibleUserRole;
            this.planTableConfig.list.items.push(planDefinition);
        }
        this.planTableConfig.list.limit = exerciseSessionOfUsers.length;
        this.planTableConfig.list.count = exerciseSessionOfUsers.length;
        this.planTableConfig.list.total = exerciseSessionOfUsers.length;
        this.planTableConfig.list.offset = 0;
    }

    private initTable(emptyLabel?: string) {
        this.planTableConfig.emptyListLabel = emptyLabel;
        this.planTableConfig.itemSettings = [
            {
                id: 'start',
                prop: 'start',
                header: 'Start',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '15%',
                format: DateFormat.DATE,
                sortOrderWeb: 0,
                sortOrderMobile: 1,
                showColNameOnMobile: true,
            },
            {
                id: 'end',
                prop: 'end',
                header: 'Ende',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                format: DateFormat.DATE,
                width: '15%',
                sortOrderWeb: 1,
                sortOrderMobile: 2,
                showColNameOnMobile: true,
            },
            {
                id: 'title',
                prop: 'title',
                header: 'Aufgabe',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '42%',
                sortOrderWeb: 1,
                sortOrderMobile: 0,
                isMobileBold: true,
            },
            {
                id: 'responsibleRole',
                prop: 'responsibleRole',
                header: 'Zuständig',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '24%',
                sortOrderWeb: 2,
            },
            {
                id: 'icon',
                prop: 'icons',
                header: '',
                type: ItemType.ADAPTER,
                adapter: IconAdapterComponent,
                width: '4%',
                sortOrderWeb: 3,
            },
        ];
        this.planTableConfig.list.items = [];
    }
}
