// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `curafida-table {
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./../../libs/therapy/components/modal/task-preconditions-config/task-preconditions-config-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ","sourcesContent":["curafida-table {\n    overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
