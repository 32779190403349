import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { User, UserRoles } from '../../../../auth/entities/user';
import { ExerciseType } from '../../../entities/exerciseSession';
import { Logger, LoggingService } from '../../../../logging/logging.service';
import { ToastService } from '../../../../common/services/toast-service/toast-service.service';
import { CurafidaAuthService } from '../../../../auth/services';
import { StyleService } from '../../../../common/services/style/style.service';
import { ModalController, Platform } from '@ionic/angular';
import { ModalAlertService } from '../../../../common/services/modal';
import { Therapy, TherapyDtoBuilder } from '../../../entities/therapy';
import { TherapiesService } from '../../../services/therapies';
import { TherapyTemplate } from '../../../entities/therapy/therapy-template';
import { TherapyTemplateListModalComponent } from '../../modal/therapy-template-list-modal/therapy-template-list-modal.component';
import { addDays, differenceInCalendarDays } from 'date-fns';
import { TherapyTemplatesService } from '../../../services/therapy-templates';
import * as duration from 'duration-fns';

@Component({
    selector: 'lib-create-program-learning-plan',
    templateUrl: './create-program-learning-plan.component.html',
    styleUrls: ['./create-program-learning-plan.component.scss'],
})
export class CreateProgramLearningPlanComponent implements OnInit {
    @Input()
    task: Therapy;
    @Input()
    program: Therapy;
    @Input()
    isMobile = false;

    programLearningForm: FormGroup;
    isEditEnabled = false;
    title: string;
    subtitle = '';
    loggedInUser: User;
    fromMyMedax = false;
    userRoles: UserRoles[] = [];
    UserRoles = UserRoles;
    isButtonDisable = false;
    selectedLearningTemplate: TherapyTemplate;
    protected readonly log: Logger;

    constructor(
        private formBuilder: FormBuilder,
        private toastService: ToastService,
        private authService: CurafidaAuthService,
        private styleService: StyleService,
        private loggingService: LoggingService,
        private platform: Platform,
        private modalAlertService: ModalAlertService,
        private modalCtrl: ModalController,
        private therapiesService: TherapiesService,
        private therapyTemplatesService: TherapyTemplatesService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    async ngOnInit() {
        this.loggedInUser = this.authService.getSession()?.user;
        await this.initPage();
    }

    async initPage() {
        await this.initForm();
        this.toggleEdit();
    }

    async initForm() {
        const taskTemplateTitle = this.task?.templateId ? null : null;
        const title = this.task ? this.task?.title : '';
        const startDate = this.task ? this.task?.startDate : '';
        const endDate = this.task ? this.task?.endDate : '';
        const duration = this.task ? differenceInCalendarDays(this.task?.startDate, this.task?.endDate) : '';

        this.programLearningForm = this.formBuilder.group({
            taskTemplateTitle: new FormControl(
                { value: taskTemplateTitle, disabled: !this.isEditEnabled },
                Validators.required,
            ),
            title: new FormControl({ value: title, disabled: !this.isEditEnabled }, [
                Validators.required,
                Validators.maxLength(255),
            ]),
            startDate: new FormControl({ value: startDate, disabled: !this.isEditEnabled }, Validators.required),
            duration: new FormControl({ value: duration, disabled: !this.isEditEnabled }, Validators.required),
            endDate: new FormControl({ value: endDate, disabled: !this.isEditEnabled }, Validators.required),
        });
    }

    async openTaskTemplateModal() {
        if (this.programLearningForm.controls.taskTemplateTitle.disabled) return;
        const modal = await this.modalCtrl.create({
            component: TherapyTemplateListModalComponent,
            cssClass: '',
            componentProps: {
                title: 'Lernplanvorlage wählen',
                searchString: 'Lernplanvorlage suchen',
                selectedTherapyTemplates: [],
                isMultipleChoice: false,
                anyItem: 'Keine Lernplanvorlagen vorhanden',
                exerciseType: ExerciseType.LEARNING,
                showOldSelectedExercise: true,
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data && data[0]) {
            this.selectedLearningTemplate = data[0];
            this.programLearningForm.controls.taskTemplateTitle.patchValue(this.selectedLearningTemplate.title);
            this.programLearningForm.controls.title.patchValue(this.selectedLearningTemplate.title);

            let maxDuration = 0;
            this.selectedLearningTemplate = await this.therapyTemplatesService.getTherapyTemplateId(data[0].id);
            this.selectedLearningTemplate.therapyTemplateExercises.map((exercise) => {
                if (duration.toDays(exercise.endOffset) > maxDuration) {
                    maxDuration = duration.toDays(exercise.endOffset);
                }
            });
            this.programLearningForm.controls.taskTemplateTitle.patchValue(this.selectedLearningTemplate.title);
            this.programLearningForm.controls.title.patchValue(this.selectedLearningTemplate.title);
            this.programLearningForm.controls.startDate.patchValue(new Date());
            this.programLearningForm.controls.duration.patchValue(maxDuration);
            this.programLearningForm.controls.endDate.patchValue(addDays(new Date(), maxDuration));
        }
    }

    cancelEdit() {
        if (this.isEditEnabled) {
            this.toggleEdit();
        }
    }

    toggleEdit() {
        this.isEditEnabled = !this.isEditEnabled;
        const taskTemplateTitle = this.programLearningForm.get('taskTemplateTitle');
        const title = this.programLearningForm.get('title');
        this.isEditEnabled ? taskTemplateTitle.enable() : taskTemplateTitle.disable();
        this.isEditEnabled ? title.enable() : title.disable();
    }

    async dismissModal() {
        await this.modalCtrl.dismiss('true');
    }

    async saveProgramTask() {
        const newLearningPlan = await this.therapiesService.copyTherapiesFromTemplate(
            this.selectedLearningTemplate.id,
            this.program.participants.map((i) => i.username),
            null,
            this.program.startDate,
            this.program.endDate,
            this.program.id,
        );
        const newTherapyDto = new TherapyDtoBuilder().copyFromTherapy(newLearningPlan[0]).build();
        newTherapyDto.title = this.programLearningForm.controls.title.value;
        newTherapyDto.startDate = this.program.startDate;
        newTherapyDto.endDate = this.program.endDate;
        await this.therapiesService.updateTherapy(newLearningPlan[0].id, newTherapyDto);
        await this.dismissModal();
    }
}
