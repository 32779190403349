import { NgModule } from '@angular/core';
import { CommonComponentsModule } from './components/common-components.module';
import { CommonModuleRoutingModule } from './common-module-routing.module';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { CurafidaDoubleTextInputComponent } from './components/curafida-input/curafida-double-text-input/curafida-double-text-input.component';
import { AutosizeDirective } from './components/curafida-input/curafida-text-area/autosize-directive';
import { TableComponentsModule } from '../table/components/table-components.module';
import { SwiperModule } from 'swiper/angular';

@NgModule({
    declarations: [CurafidaDoubleTextInputComponent, AutosizeDirective],
    imports: [SwiperModule, CommonModuleRoutingModule, TableComponentsModule, CommonComponentsModule],
    providers: [InAppBrowser],
    exports: [CommonComponentsModule, CurafidaDoubleTextInputComponent],
})
export class CurafidaCommonModule {}
