import { Component, Input } from '@angular/core';
import { ItemAdapterComponent, TableItem } from '../../../table/entities/table';
import { FeedbackFlag } from '../../entities/feedback/feedback-comment';

@Component({
    template: `
        <div
            *ngIf="data[itemSetting.prop]"
            class="flex-container-column">
            {{ data[itemSetting.prop].text }}
            <ion-icon
                *ngIf="data[itemSetting.prop].flag !== FeedbackFlag.NONE"
                class="flag-icon"
                [ngClass]="{
                    red: data[itemSetting.prop].flag === FeedbackFlag.RED,
                    yellow: data[itemSetting.prop].flag === FeedbackFlag.YELLOW,
                    green: data[itemSetting.prop].flag === FeedbackFlag.GREEN
                }"
                name="flag"></ion-icon>
        </div>
    `,
})
export class CommentFeedbackAdapterComponent implements ItemAdapterComponent {
    @Input() data: string[];
    @Input() itemSetting: TableItem;

    FeedbackFlag = FeedbackFlag;
}
