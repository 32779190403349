// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selection-footer {
  margin: 12px;
  text-align: left;
  opacity: 0.5;
  font-size: 14px;
  letter-spacing: 0.06em;
  display: block;
  height: 3rem;
}

.line-separator {
  margin-top: 0;
  min-height: 1px !important;
}`, "",{"version":3,"sources":["webpack://./../../libs/table/components/curafida-popover-selection/curafida-popover-selection.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,gBAAA;EACA,YAAA;EACA,eAAA;EACA,sBAAA;EACA,cAAA;EACA,YAAA;AACJ;;AAEA;EACI,aAAA;EACA,0BAAA;AACJ","sourcesContent":[".selection-footer {\n    margin: 12px;\n    text-align: left;\n    opacity: 0.5;\n    font-size: 14px;\n    letter-spacing: 0.06em;\n    display: block;\n    height: 3rem;\n}\n\n.line-separator {\n    margin-top: 0;\n    min-height: 1px !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
