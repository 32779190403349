// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 901px) {
  .login-logo {
    max-width: 50%;
    display: initial;
    margin: auto;
    padding-top: 50px;
  }
}
@media (max-width: 1374px) and (min-width: 751px) {
  .login-logo {
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 750px) {
  .login-logo {
    max-width: 100%;
  }
}
h4 {
  margin: 10px;
}

.home-title {
  font-weight: bolder;
}`, "",{"version":3,"sources":["webpack://./../../libs/table/components/star-processing/star-processing.component.scss"],"names":[],"mappings":"AACA;EACI;IACI,cAAA;IACA,gBAAA;IACA,YAAA;IACA,iBAAA;EAAN;AACF;AAKA;EACI;IACI,cAAA;IACA,iBAAA;IACA,kBAAA;EAHN;AACF;AAOA;EACI;IACI,eAAA;EALN;AACF;AAQA;EACI,YAAA;AANJ;;AASA;EACI,mBAAA;AANJ","sourcesContent":["//browser\n@media (min-width: 901px) {\n    .login-logo {\n        max-width: 50%;\n        display: initial;\n        margin: auto;\n        padding-top: 50px;\n        //margin: 20px;\n    }\n}\n\n//tablet\n@media (max-width: 1374px) and (min-width: 751px) {\n    .login-logo {\n        max-width: 60%;\n        margin-left: auto;\n        margin-right: auto;\n    }\n}\n\n//mobile\n@media (max-width: 750px) {\n    .login-logo {\n        max-width: 100%;\n    }\n}\n\nh4 {\n    margin: 10px;\n}\n\n.home-title {\n    font-weight: bolder;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
