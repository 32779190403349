import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from '../../../config/services';
import { CurafidaAuthService } from '../../../auth/services';
import { NgIf } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'curafida-loading',
    templateUrl: './loading.html',
    styleUrls: ['./loading.scss'],
    standalone: true,
    imports: [NgIf, IonicModule, TranslateModule],
})
export class LoadingComponent implements OnInit {
    timeout;
    activateBackButton = false;
    @Input()
    showActionButton = false;

    @Input()
    showBackButton = false;
    @Input()
    showActionButtonOnTimeout = false;

    backToHomeButtonText = 'BACK_HOME';

    @Input()
    actionButtonText = 'Erneut laden';
    @Input()
    timeoutMs = 300000;
    @Input()
    showEmptyResult = false;
    @Input()
    emptyResultText: string;
    @Output()
    timeout$ = new EventEmitter<boolean>();
    @Output()
    actionButtonClicked = new EventEmitter<boolean>();

    constructor(
        private router: Router,
        private configService: ConfigService,
        private authService: CurafidaAuthService,
    ) {}

    private _showSpinner = true;

    get showSpinner(): boolean {
        return this._showSpinner;
    }

    @Input()
    set showSpinner(value: boolean) {
        clearTimeout(this.timeout);
        this._showSpinner = value;
        if (this._showSpinner) this.ngOnInit();
    }

    async ngOnInit() {
        await this.initBackButton();

        this.timeout = setTimeout(() => {
            this.activateBackButton = true;
            this.showActionButton = this.showActionButtonOnTimeout;
            this.showSpinner = false;
            this.timeout$.emit(true);
        }, this.timeoutMs);
    }

    actionButtonClick() {
        this.actionButtonClicked.emit(true);
    }

    backToHome() {
        this.router.navigate(['']);
    }

    private async initBackButton() {
        const rootUrl = await this.getRootUrl();
        let currentUrl = this.router.routerState.snapshot.url;
        currentUrl = currentUrl.startsWith('/') ? currentUrl.slice(1) : currentUrl;
        const isRoot = currentUrl === rootUrl;
        if (isRoot) {
            if (this.showBackButton) this.showActionButtonOnTimeout = false;
            this.backToHomeButtonText = 'Erneut laden';
        }
    }

    private async getRootUrl() {
        const userRoles = (await this.authService.getRoles()) as string[];
        const rootUrls = this.configService.config.rootUrls;
        const rootUrl = rootUrls.find((u) => userRoles?.includes(u.role))?.url;
        if (rootUrl) {
            return rootUrl.slice(1);
        } else {
            return rootUrl;
        }
    }
}
