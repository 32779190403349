import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';

@Component({
    selector: 'curafida-line-chart',
    templateUrl: './curafida-line-chart.component.html',
    styleUrls: ['./curafida-line-chart.component.scss'],
})
export class CurafidaLineChartComponent implements OnInit {
    @Input()
    public lineChartData: ChartDataset[] = [];
    @Input()
    public lineChartLabels: string[] = [];
    public lineChartOptions: ChartOptions & { annotation: any } = {
        backgroundColor: 'transparent',
        borderColor: getComputedStyle(document.body).getPropertyValue('--ion-color-primary'),
        responsive: true,
        locale: 'de',
        scales: {
            // We use this empty structure as a placeholder for dynamic theming.
            x: {},
            y: {},
        },
        elements: {
            point: {
                backgroundColor: getComputedStyle(document.body).getPropertyValue('--ion-color-primary'),
                hoverBackgroundColor: getComputedStyle(document.body).getPropertyValue('--ion-color-primary'),
                borderColor: '',
                hoverBorderColor: '',
            },
        },
        annotation: {
            annotations: [
                {
                    type: 'line',
                    mode: 'vertical',
                    scaleID: 'x-axis-0',
                    value: 'March',
                    borderColor: 'orange',
                    borderWidth: 2,
                    label: {
                        enabled: true,
                        fontColor: 'orange',
                        content: 'LineAnno',
                    },
                },
            ],
        },
        // TODO: disable when there is more than one charset
        plugins: {
            tooltip: {
                callbacks: {
                    title: (context) => {
                        // Format => 'Mittwoch 30.8.2023, 11:34'
                        const formattedTitle = format(new Date(context[0].raw['x']), 'eeee d.M.yyyy, HH:mm', {
                            locale: de,
                        });
                        return formattedTitle;
                    },
                },
            },
            legend: {
                display: true,
                onClick: null,
            },
        },
    };

    public lineChartLegend = true;
    public lineChartType: ChartType = 'line';
    @ViewChild(BaseChartDirective, { static: false }) chart: BaseChartDirective;
    @Input()
    yAxes: any;
    @Input()
    xAxes: any;

    @Input()
    set update(value) {
        if (value) this.chart.update();
    }

    ngOnInit(): void {
        if (this.yAxes) this.lineChartOptions.scales.x = this.xAxes;
        if (this.xAxes) this.lineChartOptions.scales.y = this.yAxes;
    }
}
