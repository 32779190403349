// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kiosk-training-exercise-info {
  z-index: 100;
  position: absolute;
  bottom: 20px;
  left: 20px;
}`, "",{"version":3,"sources":["webpack://./../../libs/kiosk/components/kiosk-task-video-modal/kiosk-task-video-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,kBAAA;EACA,YAAA;EACA,UAAA;AACJ","sourcesContent":[".kiosk-training-exercise-info {\n    z-index: 100;\n    position: absolute;\n    bottom: 20px;\n    left: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
