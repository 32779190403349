import { NgModule } from '@angular/core';
import { RemovePatientNoMoreActivePipe } from './removePatientNoMoreActive';
import { LearningActionsPipe } from './learning-actions.pipe';
import { HideLearningContentsPipe } from './hide-learning-contents.pipe';
import { FilterLearningSummaryContentPipe } from './filter-learning-summary-contents.pipe';
import { HideQuestionnaireContentsPipe } from './hide-questionnaire-contents.pipe';

@NgModule({
    declarations: [
        RemovePatientNoMoreActivePipe,
        LearningActionsPipe,
        HideLearningContentsPipe,
        FilterLearningSummaryContentPipe,
        HideQuestionnaireContentsPipe,
    ],
    imports: [],
    exports: [
        RemovePatientNoMoreActivePipe,
        LearningActionsPipe,
        HideLearningContentsPipe,
        FilterLearningSummaryContentPipe,
        HideQuestionnaireContentsPipe,
    ],
    providers: [LearningActionsPipe, HideLearningContentsPipe, FilterLearningSummaryContentPipe],
})
export class PipesTherapyModule {}
