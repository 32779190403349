import { Component, Input, OnInit } from '@angular/core';
import { User, UserRoles } from '../../../../auth/entities/user';
import { ExerciseType } from '../../../entities/exerciseSession';
import { Logger, LoggingService } from '../../../../logging/logging.service';
import { ModalController } from '@ionic/angular';
import { TherapiesService } from '../../../services/therapies';
import { UsersTherapiesService } from '../../../services/users-therapies';
import { ToastService } from '../../../../common/services/toast-service/toast-service.service';
import { ModalAlertService } from '../../../../common/services/modal';
import { Therapy, TherapyState } from '../../../entities/therapy';
import { IonicColor } from '../../../../common/entities/toast/ionic-color';
import {
    ActionType,
    ButtonItemAdapterComponent,
    DateFormat,
    ItemType,
    TableConfig,
} from '../../../../table/entities/table';
import { StringItemAdapterComponent } from '../../../../table/components/table-adapter/string-item-adapter.component';
import { PaginatedResponse, SortBy } from '../../../../common/entities/paginated-response';
import { ModalTyp } from '../../../../common/entities/modal/modal-typ';
import { ModalConfig } from '../../../../common/entities/modal/modal-config';
import { ButtonConfig } from '../../../../common/entities/modal/modal-button';
import { CurafidaSegmentItem } from '../../../../common/entities/curafida-segment.item';
import { RoutingSegment } from '../../../../common/entities/routing-segment';
import { Router } from '@angular/router';
import { CopyTemplateModalComponent } from '../../copy-template-modal/copy-template-modal.component';
import { TrainingService } from '../../../services/training/training.service';

@Component({
    selector: 'lib-program-patient-list',
    templateUrl: './program-patient-list.component.html',
    styleUrls: ['./program-patient-list.component.scss'],
})
export class ProgramPatientListComponent implements OnInit {
    isLoading = true;
    isLoadingSuccess = false;
    @Input()
    user: User;
    @Input()
    userRoles: UserRoles[] = [];
    UserRoles = UserRoles;
    programTableConfig: TableConfig<Therapy[]> = new TableConfig<Therapy[]>();
    therapyState: TherapyState = TherapyState.RUNNING;
    segments: CurafidaSegmentItem<TherapyState>[] = [];
    addProgramButton: string;
    protected readonly log: Logger;

    constructor(
        private modalCtrl: ModalController,
        private therapiesService: TherapiesService,
        private userTherapiesService: UsersTherapiesService,
        private toastService: ToastService,
        private modalAlertService: ModalAlertService,
        private loggingService: LoggingService,
        private router: Router,
        private trainingService: TrainingService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    async ngOnInit() {
        this.segments.push(new CurafidaSegmentItem({ name: 'SEGMENT.ACTIVE', value: TherapyState.RUNNING }));
        this.segments.push(new CurafidaSegmentItem({ name: 'SEGMENT.INACTIVE', value: TherapyState.DONE }));
        this.therapyState = this.segments[0].value;
        await this.fetchPrograms(this.therapyState);
        this.addProgramButton = this.userRoles.includes(UserRoles.manage_therapy) ? 'add' : '';
    }

    async fetchPrograms(therapyState: TherapyState = TherapyState.RUNNING) {
        this.initProgramTableConfig();
        this.isLoading = true;
        this.isLoadingSuccess = false;
        try {
            const programs = await this.userTherapiesService.getUserTherapies({
                username: this.user.username,
                therapyState: therapyState,
                exerciseType: ExerciseType.MIXED,
            });
            programs.limit = programs.count;
            this.programTableConfig.list = programs;
            this.isLoading = false;
            this.isLoadingSuccess = true;
        } catch (e) {
            this.log.error('Error in ngOnInit', e);
            await this.toastService.showToast('Fehler beim Abrufen der Pläne.', IonicColor.danger);
            this.isLoading = false;
            this.isLoadingSuccess = true;
        }
    }

    initProgramTableConfig() {
        this.programTableConfig.list = new PaginatedResponse<Therapy[]>();
        this.programTableConfig.list.items = [];
        this.programTableConfig.emptyListLabel = 'PROGRAM.ANY_ITEM';
        this.programTableConfig.itemSettings = [
            {
                id: 'start',
                prop: 'created_at',
                header: 'Hinzugefügt\nam',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                format: DateFormat.DATE_AND_HOUR_2_LINES,
                width: '20%',
                sortBy: SortBy.TITLE,
                sortOrderWeb: 0,
            },
            {
                id: 'title',
                prop: 'title',
                header: 'Name',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '24%',
                sortBy: SortBy.TITLE,
                sortOrderWeb: 0,
            },
            {
                id: 'description',
                prop: 'description',
                header: 'Beschreibung',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '56%',
                sortOrderWeb: 1,
            },
        ];
        if (this.userRoles.includes(UserRoles.read_therapy)) {
            this.programTableConfig.itemSettings.push({
                id: 'action_open',
                prop: '',
                header: '',
                type: ItemType.ADAPTER,
                adapter: ButtonItemAdapterComponent,
                icon: 'open-outline',
                color: 'primary',
                actionType: ActionType.OPEN_NEW_PAGE,
                width: '8%',
                sortOrderWeb: 3,
            });
            this.programTableConfig.itemSettings.find((i) => i.id === 'description').width = '48%';
        }
        if (this.userRoles.includes(UserRoles.manage_therapy) && this.therapyState === TherapyState.RUNNING) {
            this.programTableConfig.itemSettings.push({
                id: 'action_remove',
                prop: 'id',
                header: '',
                type: ItemType.ADAPTER,
                adapter: ButtonItemAdapterComponent,
                icon: 'close-outline',
                color: 'danger',
                actionType: ActionType.REMOVE,
                width: '8%',
                sortOrderWeb: 3,
            });
            this.programTableConfig.itemSettings.find((i) => i.id === 'description').width = '40%';
        }
    }

    async addNewProgramToOnePatient() {
        this.isLoading = true;
        this.isLoadingSuccess = false;
        const selectPlan = 'PROGRAM.TEMPLATE.SELECT';
        const anyPlan = 'PROGRAM.TEMPLATE.NONE';
        const templateName = 'PROGRAM.TEMPLATE.SINGULAR';
        const modal = await this.modalCtrl.create({
            component: CopyTemplateModalComponent,
            cssClass: 'full-width-modal',
            componentProps: {
                user: this.user,
                title: selectPlan,
                templateName,
                selectedTherapyTemplates: [],
                isMultipleChoice: true,
                anyItem: anyPlan,
                exerciseType: ExerciseType.MIXED,
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data) {
            await this.fetchPrograms(this.therapyState);
        }

        this.isLoading = false;
        this.isLoadingSuccess = true;
    }

    async buttonClickAction(event: { actionType: ActionType; item: Therapy }) {
        if (event.actionType === ActionType.REMOVE) {
            await this.showRemovePrompt(event.item);
        } else if (event.actionType === ActionType.OPEN_NEW_PAGE) {
            await this.openDetailPage(event.item.id.toString());
        }
    }

    async showRemovePrompt(programm: Therapy) {
        const modalConfig = new ModalConfig();
        modalConfig.modalTyp = ModalTyp.INFORMATION;
        modalConfig.title = 'Programm entfernen';
        modalConfig.titleIcon = 'warning-outline';
        modalConfig.description =
            'Durch das Entfernen des Programms werden alle zugehörigen noch anstehenden zugehörigen Elemente ' +
            'entfernt. Sind Sie sicher, dass Sie fortfahren möchten?';

        modalConfig.buttonRight = new ButtonConfig();
        modalConfig.buttonRight.buttonText = 'Entfernen';
        modalConfig.buttonRight.buttonColor = 'danger';
        const action = await this.modalAlertService.showModal(modalConfig);
        if (action && action.action === 'right') {
            try {
                await this.therapiesService.deleteTherapy(programm.id);
                await this.toastService.showToast('Programm erfolgreich entfernt.', IonicColor.success);
                await this.fetchPrograms(this.therapyState);
            } catch (e) {
                this.log.error('Failed to remove plan', e);
                await this.toastService.showToast('Fehler beim Löschen des Programms.', IonicColor.danger);
            }
            this.trainingService.therapySaved();
        }
    }

    async openDetailPage(programId: string) {
        await this.router.navigate([
            RoutingSegment.MEMBER,
            RoutingSegment.PATIENT_MANAGEMENT,
            RoutingSegment.PROGRAM_MANAGEMENT,
            RoutingSegment.DETAIL,
            programId,
        ]);
    }

    async segmentChange(therapyState: TherapyState) {
        this.therapyState = therapyState;
        await this.fetchPrograms(therapyState);
    }
}
