// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-md::after {
  background-image: none;
}

.header-ios::after {
  background-image: none;
}

ion-header {
  background: white !important;
}`, "",{"version":3,"sources":["webpack://./../../libs/therapy/components/course/alternative-appointment/alternative-appointment.component.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;AACJ;;AAEA;EACI,sBAAA;AACJ;;AAEA;EACI,4BAAA;AACJ","sourcesContent":[".header-md::after {\n    background-image: none;\n}\n\n.header-ios::after {\n    background-image: none;\n}\n\nion-header {\n    background: white !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
