import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from '../../../../common/services/toast-service/toast-service.service';
import { IonicColor } from '../../../../common/entities/toast/ionic-color';
import { isEndDateAfterStartDate } from '../../../../common/validators/curafida-validators';
import { SelectableItem } from '../../../../common/components/checkbox-list-modal/checkbox-list-modal.component';
import { ExerciseType } from '../../../entities/exerciseSession';
import { TaskPreconditionsConfigModalComponent } from '../task-preconditions-config/task-preconditions-config-modal.component';

@Component({
    selector: 'lib-start-end-day-modal',
    templateUrl: './therapy-task-template-config-modal.component.html',
    styleUrls: ['./therapy-task-template-config-modal.component.scss'],
})
export class TherapyTaskTemplateConfigModalComponent implements OnInit {
    protected readonly ExerciseType = ExerciseType;
    title: string;
    start: number;
    end: number;
    exerciseType = ExerciseType.TASK;

    @Input()
    preconditionOptions: SelectableItem[];
    @Input()
    selectedPreconditions: SelectableItem[];

    startEndDayForm: FormGroup;

    constructor(
        protected modalCtrl: ModalController,
        private formBuilder: FormBuilder,
        private toastService: ToastService,
        private params: NavParams,
    ) {}

    async ngOnInit() {
        this.title = await this.params.get('title');
        this.start = await this.params.get('start');
        this.end = await this.params.get('end');
        await this.initStartEndDayForm();
    }

    async initStartEndDayForm() {
        const start = this.start ? this.start : '';
        const end = this.end ? this.end : '';
        this.startEndDayForm = this.formBuilder.group(
            {
                start: new FormControl(start, Validators.pattern('(^$|^[1-9][0-9]*$)')),
                end: new FormControl(end, Validators.pattern('(^$|^[1-9][0-9]*$)')),
            },
            { validators: isEndDateAfterStartDate },
        );
    }

    async dismissModal() {
        await this.modalCtrl.dismiss();
    }

    async returnSelected() {
        const start = this.startEndDayForm.controls.start.value;
        const end = this.startEndDayForm.controls.end.value;
        if (start !== '' && end !== '' && Number(start) > Number(end)) {
            await this.toastService.showToast('Das Ende darf nicht vor dem Start liegen.', IonicColor.danger);
            await this.modalCtrl.dismiss();
        }
        await this.modalCtrl.dismiss({ start, end, selectedPreconditions: this.selectedPreconditions });
    }

    async openPreconditionsModal() {
        const modal = await this.modalCtrl.create({
            component: TaskPreconditionsConfigModalComponent,
            cssClass: 'full-width-modal',
            componentProps: {
                preconditionOptions: this.preconditionOptions,
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data) {
            this.selectedPreconditions = data.map((it) =>
                this.preconditionOptions.find((option) => option.name === it.name),
            );
        }
    }

    deselectPrecondition(precondition: SelectableItem) {
        this.selectedPreconditions = this.selectedPreconditions.filter((it) => it.name !== precondition.name);
    }
}
