import { Component, OnInit, ViewChild } from '@angular/core';
import { ExerciseType } from '../../../entities/exerciseSession';
import { TherapyTemplateListComponent } from '../../therapy-template-list/therapy-template-list.component';

@Component({
    selector: 'lib-program-template-overview',
    templateUrl: './program-template-overview.component.html',
    styleUrls: ['./program-template-overview.component.scss'],
})
export class ProgramTemplateOverviewComponent implements OnInit {
    @ViewChild(TherapyTemplateListComponent) therapyTemplateListComponent: TherapyTemplateListComponent;
    ExerciseType = ExerciseType;

    async ngOnInit() {
        if (this.therapyTemplateListComponent) await this.therapyTemplateListComponent.ngOnInit();
    }
}
