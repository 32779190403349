// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p {
  font-size: 1rem !important;
}`, "",{"version":3,"sources":["webpack://./../../libs/common/components/range-selector-group/range-selector-group.component.scss"],"names":[],"mappings":"AAAA;EACI,0BAAA;AACJ","sourcesContent":["p {\n    font-size: 1rem !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
