import { Component, Input } from '@angular/core';
import { User } from '../../auth/entities/user';

@Component({
    template: '',
})
export abstract class UserFormComponent {
    isMobile = false;

    boolean = [
        { value: true, label: 'YES' },
        { value: false, label: 'NO' },
    ];

    @Input()
    user: User;
    loggedInUser: User;
}
