export enum ActionType {
    UPDATE = 'UPDATE',
    REMOVE = 'REMOVE',
    DELETE = 'DELETE',
    OPEN_NEW_PAGE = 'OPEN_NEW_PAGE',
    MODAL = 'MODAL',
    PREVIEW = 'PREVIEW',
    START = 'START',
    SEND = 'SEND',
    TRACKING = 'TRACKING',
    UNTRACKING = 'UNTRACKING',
    READ = 'READ',
    AUDITED = 'AUDITED',
    CANCELED = 'CANCELED',
    MOVED = 'MOVED',
    POPOVER = 'POPOVER',
    OPEN_PATIENT_PAGE = 'OPEN_PATIENT_PAGE',
    REPORT_TO_LATER = 'REPORT_TO_LATER',
    FILL_IN_FORM = 'FILL_IN_FORM',
}
