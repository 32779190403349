import { Component, Input } from '@angular/core';
import { ExerciseType } from '../../../therapy/entities/exerciseSession';
import { Logger, LoggingService } from '../../../logging/logging.service';
import { TaskResource } from '../../../therapy/components/task/task.resource';
import { MeetingResource } from '../../../meeting/resources/meeting.resource';
import { VideoConferenceService } from '../../services/video-conference.service';
import { MeetingReducedResource } from '../../../meeting/resources/meeting-reduced.resource';

@Component({
    selector: 'lib-join-video-conference',
    templateUrl: './join-video-conference.component.html',
    styleUrls: ['./join-video-conference.component.scss'],
})
export class JoinVideoConferenceComponent {
    @Input()
    resource: TaskResource | MeetingResource | MeetingReducedResource;
    @Input()
    exerciseType: ExerciseType;

    @Input()
    /**
     * Use kiosk specific look.
     */
    isKioskMode = false;

    private readonly log: Logger;

    constructor(
        private loggingService: LoggingService,
        public videoConferenceService: VideoConferenceService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }
}
