// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-main {
  display: flex;
  flex-direction: row;
  padding: 5px 10px 5px 10px;
  position: relative;
  min-height: 50px;
}

.name-container {
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  width: 100px;
}

.cardText {
  font-weight: 400;
}

.tagPill {
  border: 1px solid #0096c8;
  border-radius: 5px;
  padding: 2px 5px 2px 5px;
  margin: 2px 4px 2px 4px;
}

.contentCenter {
  display: flex;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./../../libs/user/components/patient-card/patient-card.component.scss"],"names":[],"mappings":"AAEA;EAEI,aAAA;EACA,mBAAA;EACA,0BAAA;EACA,kBAAA;EACA,gBAAA;AAFJ;;AAKA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,YAAA;AAFJ;;AAKA;EACI,gBAAA;AAFJ;;AAKA;EACI,yBAAA;EACA,kBAAA;EACA,wBAAA;EACA,uBAAA;AAFJ;;AASA;EACI,aAAA;EACA,sBAAA;AANJ","sourcesContent":["//top right bottom left\n\n.card-main {\n    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);\n    display: flex;\n    flex-direction: row;\n    padding: 5px 10px 5px 10px;\n    position: relative;\n    min-height: 50px;\n}\n\n.name-container {\n    display: flex;\n    flex-direction: column;\n    padding-right: 10px;\n    width: 100px;\n}\n\n.cardText {\n    font-weight: 400;\n}\n\n.tagPill {\n    border: 1px solid #0096c8;\n    border-radius: 5px;\n    padding: 2px 5px 2px 5px;\n    margin: 2px 4px 2px 4px;\n\n    // overflow: hidden;\n    // text-overflow: ellipsis;\n    // max-width: inherit;\n}\n\n.contentCenter {\n    display: flex;\n    flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
