// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lang-item-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100% !important;
  align-items: center;
}

.img-scale {
  width: 2.5rem;
  height: auto;
}

.highlight {
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./../../libs/common/components/language-selection/language-selection.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,sBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,aAAA;EACA,YAAA;AACJ;;AAEA;EACI,iBAAA;AACJ","sourcesContent":[".lang-item-container {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    width: 100% !important;\n    align-items: center;\n}\n\n.img-scale {\n    width: 2.5rem;\n    height: auto;\n}\n\n.highlight {\n    font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
