import { Pipe, PipeTransform } from '@angular/core';
import * as duration from 'duration-fns';
import { UNITS_META_MAP } from 'duration-fns/dist/lib/units';

@Pipe({
    name: 'duration',
})
export class DurationPipe implements PipeTransform {
    transform(value: any, unit: string | keyof typeof UNITS_META_MAP): string {
        const parsedValue = duration.parse(value);
        return duration.toUnit(parsedValue, unit as keyof typeof UNITS_META_MAP).toString();
    }
}
