import { Component, Input, OnInit } from '@angular/core';
import { ItemAdapterComponent, TableItem } from '../../../table/entities/table';
import { FeedbackFlag } from '../../entities/feedback/feedback-comment';

@Component({
    template: `
        <ion-icon
            *ngIf="hasGreenFlag"
            class="flag-icon green"
            name="flag"></ion-icon>
        <ion-icon
            *ngIf="hasYellowFlag"
            class="flag-icon yellow"
            name="flag"></ion-icon>
        <ion-icon
            *ngIf="hasRedFlag"
            class="flag-icon red"
            name="flag"></ion-icon>
        <span *ngIf="data[this.itemSetting.prop]?.length === 0">-</span>
    `,
})
export class FlagFeedbackAdapterComponent implements ItemAdapterComponent, OnInit {
    @Input() data: string[];
    @Input() itemSetting: TableItem;
    isMobile: boolean;
    hasRedFlag = false;
    hasYellowFlag = false;
    hasGreenFlag = false;
    disabled = false;

    ngOnInit(): void {
        this.initFlagList();
    }

    initFlagList() {
        if (this.data[this.itemSetting.prop]) {
            if (this.data[this.itemSetting.prop].find((f) => f === FeedbackFlag.GREEN)) this.hasGreenFlag = true;
            if (this.data[this.itemSetting.prop].find((f) => f === FeedbackFlag.YELLOW)) this.hasYellowFlag = true;
            if (this.data[this.itemSetting.prop].find((f) => f === FeedbackFlag.RED)) this.hasRedFlag = true;
        }
    }
}
