import { Component, Input, OnInit } from '@angular/core';
import { ExerciseContentsService } from '../../../services/exercise-contents';
import { Content } from '../../../entities/content';
import { StyleService } from '../../../../common/services/style/style.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'therapy-pdf-viewer',
    templateUrl: './therapy-pdf-viewer.component.html',
    styleUrls: ['./therapy-pdf-viewer.component.scss'],
})
export class TherapyPdfViewerComponent implements OnInit {
    @Input()
    content: Content;
    @Input()
    isModal: boolean;
    pdfSrc: any;
    isComponentInit = false;
    isMobile = false;
    private tempBlob: Blob;

    constructor(
        private exerciseContentsService: ExerciseContentsService,
        private styleService: StyleService,
    ) {
        this.isMobile = this.styleService.isMobile$;
    }

    loadPDFFile() {
        this.tempBlob = null;
        //Fetching Data File
        this.exerciseContentsService.getObservableContent(this.content).subscribe((retFileData: any) => {
            this.tempBlob = new Blob([retFileData], { type: this.content.mimeType });
            const fileReader = new FileReader();
            fileReader.onload = () => {
                this.pdfSrc = new Uint8Array(fileReader.result as ArrayBuffer);
            };
            fileReader.readAsArrayBuffer(this.tempBlob);
            this.isComponentInit = true;
        });
    }

    async downloadFile() {
        await this.exerciseContentsService.downloadObjectURLinNewWindow(this.content);
    }

    ngOnInit(): void {
        this.loadPDFFile();
    }
}
