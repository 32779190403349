import { AfterContentInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ExerciseSessionOfUser } from '../../../therapy/entities/exerciseSession';
import { ExerciseContentsService } from '../../../therapy/services/exercise-contents';
import { Content, ContentMetaDataType } from '../../../therapy/entities/content';
import { ContentFormatType } from '../../../common/entities/content-format-type';
import { KioskActionElementModalComponent } from '../kiosk-action-element-modal/kiosk-action-element-modal.component';
import { ButtonsModalDefinition, KioskActionButton } from '../../entities/buttons-modal-definition';
import { TranslateService } from '@ngx-translate/core';
import { TherapySession } from '../../../therapy/entities/therapy-session/therapy-session';
import { TherapyWithTherapySessions } from '../../../therapy/entities/therapy';
import { EffortUnit, FeedbackCommentUnit } from '../../../therapy/entities/exerciseGoal/exerciseGoalDto';
import { FeedBackDto } from '../../../therapy/entities/feedback';
import { KioskFeedbackCommentModalComponent } from '../kiosk-feedback-comment-modal/kiosk-feedback-comment-modal.component';
import { KioskFeedbackBorgModalComponent } from '../kiosk-feedback-borg-modal/kiosk-feedback-borg-modal.component';
import { TrainingContent, TrainingExercise } from '../../../therapy/entities/training/training';

@Component({
    selector: 'lib-kiosk-training-video-modal',
    templateUrl: './kiosk-training-video-modal.component.html',
    styleUrls: ['./kiosk-training-video-modal.component.scss'],
})
export class KioskTrainingVideoModalComponent implements OnInit, AfterContentInit {
    @ViewChild('videoplayer') videoplayer: any;
    title: string;
    training: TherapyWithTherapySessions;
    therapySession: TherapySession;
    exerciseSessionOfUser: ExerciseSessionOfUser;
    content: Content;
    Content = Content;
    exerciseIndex: number;
    exerciseDescription: string;
    showDescription: boolean = false;
    descriptionButtonText: string;
    finishModal: HTMLIonModalElement;
    showButtonsAndDescription: boolean = true;
    finishButtonDisabled: boolean;

    constructor(
        private modalCtrl: ModalController,
        private params: NavParams,
        private elRef: ElementRef,
        private exerciseContentsService: ExerciseContentsService,
        private translate: TranslateService,
    ) {}

    async ngOnInit() {
        this.exerciseSessionOfUser = this.params.get('exercise');
        this.training = this.params.get('training');
        this.exerciseIndex = this.params.get('exerciseIndex');
        const trainingExercise = new TrainingExercise();
        trainingExercise.title = this.exerciseSessionOfUser.exerciseSession.exercise.title;
        trainingExercise.id = this.exerciseSessionOfUser.exerciseSession.exercise.id;
        trainingExercise.exerciseSessionId = this.exerciseSessionOfUser.exerciseSession.id;
        trainingExercise.state = this.exerciseSessionOfUser.exerciseSession.exerciseSessionState;
        trainingExercise.order = this.exerciseSessionOfUser.exerciseSession.therapyExercise.order;
        await this.setExerciseContent(
            this.exerciseSessionOfUser.exerciseSession.exercise.contents.filter(
                (i) => i?.jsonData !== null && i?.jsonData?.contentMetaDataType !== ContentMetaDataType.THUMBNAIL,
            ),
            trainingExercise,
        );
        this.content = trainingExercise.contents[0];
        if (Content.isAudioMimeType(this.content.mimeType) || Content.isVideoMimeType(this.content.mimeType)) {
            this.content.url = await this.exerciseContentsService.getTempDownloadUrl(this.content.uuid);
        } else {
            this.content.url = await this.exerciseContentsService.getObjectURLContentFromUrl(
                this.content.uuid,
                ContentFormatType.MEDIUM_SIZE,
            );
        }
        const contentToConsider = trainingExercise.contents.find((content) => content.name === 'Zu Beachten');
        if (contentToConsider) {
            this.exerciseDescription = contentToConsider.description;
        }
        this.setDescriptionButtonText();
    }

    async dismissModal() {
        this.elRef.nativeElement.querySelector('video')?.pause();
        const buttonsModalDefinition = new ButtonsModalDefinition();
        buttonsModalDefinition.title = 'KIOSK.TRAINING.EXERCISE_ACTION_TITLE';
        buttonsModalDefinition.actionElements = [];

        let button = new KioskActionButton();
        button.title = 'KIOSK.TRAINING.CONTINUE_EXERCISE';
        button.description = 'KIOSK.TRAINING.CONTINUE_EXERCISE_DESCRIPTION';
        button.icon = 'checkmark-circle';
        button.iconColor = 'success';
        button.action = 'back';
        buttonsModalDefinition.actionElements.push(button);

        button = new KioskActionButton();
        button.title = 'KIOSK.TRAINING.LEAVE_EXERCISE';
        button.description = 'KIOSK.TRAINING.LEAVE_EXERCISE_DESCRIPTION';
        button.icon = 'close-circle';
        button.iconColor = 'danger';
        button.action = 'nextExercise';
        buttonsModalDefinition.actionElements.push(button);

        const modal = await this.modalCtrl.create({
            component: KioskActionElementModalComponent,
            cssClass: 'kiosk-modal',
            backdropDismiss: false,
            componentProps: {
                buttonsModalDefinition: buttonsModalDefinition,
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data.action === 'back') {
            this.elRef.nativeElement.querySelector('video').play();
        }
        if (data.action === 'nextExercise') {
            await this.modalCtrl.dismiss({ action: 'cancelExercise' });
        }
    }

    async ngAfterContentInit() {
        setTimeout(() => {
            if (this.videoplayer && this.content.url) {
                this.elRef.nativeElement.querySelector('video').src = this.content.url;
                this.elRef.nativeElement.querySelector('video').type = this.content.mimeType;
                this.elRef.nativeElement.querySelector('video').loop = false;
                this.playVideo();
            }
        }, 1000);
    }

    playVideo() {
        this.finishButtonDisabled = false;
        this.elRef.nativeElement.querySelector('video').play();
        this.elRef.nativeElement.querySelector('video').onended = async () => {
            this.displayFinishModal();
        };
    }

    async displayFinishModal() {
        this.finishButtonDisabled = true;
        this.elRef.nativeElement.querySelector('video').pause();
        const buttonsModalDefinition = new ButtonsModalDefinition();
        if (
            this.exerciseSessionOfUser.exerciseGoal.series &&
            this.exerciseSessionOfUser.exerciseGoal.duration &&
            this.exerciseSessionOfUser.exerciseGoal.durationUnit
        ) {
            buttonsModalDefinition.title = this.translate.instant(
                `KIOSK.TRAINING.CHANGE_EXERCISE_ACTION_TITLE_DETAILS`,
                {
                    series: this.exerciseSessionOfUser.exerciseGoal.series,
                    duration: this.exerciseSessionOfUser.exerciseGoal.duration,
                    durationUnit: this.translate.instant(this.exerciseSessionOfUser.exerciseGoal.durationUnit),
                },
            );
        } else {
            buttonsModalDefinition.title = this.translate.instant(`KIOSK.TRAINING.CHANGE_EXERCISE_ACTION_TITLE`);
        }
        buttonsModalDefinition.actionElements = [];
        let button = new KioskActionButton();
        button.title = 'YES';
        button.description = 'KIOSK.TRAINING.NEXT_EXERCISE_DESCRIPTION';
        button.icon = 'checkmark-circle';
        button.iconColor = 'success';
        button.action = 'nextExercise';
        buttonsModalDefinition.actionElements.push(button);

        button = new KioskActionButton();
        button.title = 'REPEAT';
        button.description = 'KIOSK.TRAINING.REPEAT_EXERCISE_DESCRIPTION';
        button.icon = 'reload-circle';
        button.iconColor = 'success';
        button.action = 'repeat';
        buttonsModalDefinition.actionElements.push(button);

        this.finishModal = await this.modalCtrl.create({
            component: KioskActionElementModalComponent,
            cssClass: 'kiosk-modal',
            backdropDismiss: false,
            componentProps: {
                exercise: this.exerciseSessionOfUser,
                buttonsModalDefinition: buttonsModalDefinition,
            },
        });
        await this.finishModal.present();
        const { data } = await this.finishModal.onDidDismiss();
        if (data.action === 'repeat') {
            this.elRef.nativeElement.querySelector('video').load();
            this.playVideo();
        }
        if (data.action === 'nextExercise') {
            this.showButtonsAndDescription = false;
            if (
                this.exerciseSessionOfUser.exerciseGoal.effortUnit === EffortUnit.BORG_0_TO_10 ||
                this.exerciseSessionOfUser.exerciseGoal.feedbackCommentUnit === FeedbackCommentUnit.FEEDBACK_COMMENT
            ) {
                const feedBack = new FeedBackDto();
                if (this.exerciseSessionOfUser.exerciseGoal.effortUnit === EffortUnit.BORG_0_TO_10) {
                    const feedBackModal = await this.modalCtrl.create({
                        component: KioskFeedbackBorgModalComponent,
                        cssClass: 'feedback-borg-modal',
                        backdropDismiss: false,
                        componentProps: {},
                    });
                    await feedBackModal.present();
                    const { data } = await feedBackModal.onDidDismiss();
                    if (data !== null && data !== undefined) {
                        feedBack.borg = data;
                    }
                }
                if (
                    this.exerciseSessionOfUser.exerciseGoal.feedbackCommentUnit === FeedbackCommentUnit.FEEDBACK_COMMENT
                ) {
                    const feedBackModal = await this.modalCtrl.create({
                        component: KioskFeedbackCommentModalComponent,
                        id: 'KioskFeedbackModalComponent',
                        cssClass: 'feedback-modal',
                        backdropDismiss: false,
                        componentProps: {},
                    });
                    await feedBackModal.present();
                    const { data } = await feedBackModal.onDidDismiss();
                    if (data) {
                        feedBack.feedbackComment = data;
                    }
                }
                await this.modalCtrl.dismiss({ action: 'nextExercise', feedBack: feedBack });
            } else await this.modalCtrl.dismiss({ action: 'nextExercise' });
        }
    }

    private async setExerciseContent(contents: Content[], autoTrainingExercise: TrainingExercise) {
        autoTrainingExercise.contents = [];
        for (const content of contents) {
            const autoTrainingContent = new TrainingContent();
            autoTrainingContent.uuid = content.uuid;
            autoTrainingContent.name = content.jsonData.name;
            autoTrainingContent.order = content.jsonData.order;
            autoTrainingContent.description = content.description;
            autoTrainingContent.mimeType = content.mimeType;
            autoTrainingExercise.contents.push(autoTrainingContent);
        }
        autoTrainingExercise.contents.sort((a, b) => {
            if (a?.order < b?.order) {
                return -1;
            } else if (a?.order > b?.order) {
                return 1;
            }
            return 0;
        });
    }

    switchShowDescription() {
        this.showDescription = !this.showDescription;
        this.setDescriptionButtonText();
    }

    setDescriptionButtonText() {
        this.descriptionButtonText = this.showDescription
            ? 'KIOSK.TRAINING.HIDE_DETAILS'
            : 'KIOSK.TRAINING.SHOW_DETAILS';
    }
}
