import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Tag } from '../../../entities/tag/tag.entity';

@Component({
    selector: 'lib-tag-modal',
    templateUrl: './tag-modal.component.html',
    styleUrls: ['./tag-modal.component.scss'],
})
export class TagModalComponent implements OnInit {
    title: string = 'TAG.ACTION.MODIFY';
    tagForm: FormGroup;
    tag: Tag;

    constructor(
        private modalController: ModalController,
        private formBuilder: FormBuilder,
    ) {}

    ngOnInit() {
        const tagLabel = this.tag == null ? '' : this.tag.label;
        this.tagForm = this.formBuilder.group({
            name: new FormControl({ value: tagLabel, disabled: false }, [Validators.required, Validators.minLength(1)]),
        });
        const control = this.tagForm.get('name');
        const initialValue = this.tag.label;
        control.valueChanges.subscribe((value) => {
            if (value != initialValue) control.markAsDirty();
            else control.markAsPristine();
        });
    }

    async dismissModal() {
        await this.modalController.dismiss();
    }

    async saveModal() {
        this.tag.label = this.tagForm.controls.name.value;
        await this.modalController.dismiss({
            tag: this.tag,
        });
    }
}
