// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flex-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: center;
  padding: 0;
  margin: 0;
  list-style: none;
}

.borg-button {
  width: 23%;
  height: 200px;
  margin-top: 10px;
  padding: 5px;
  text-align: center;
  --padding-start: 10%;
  --padding-end: 10%;
}

.selected {
  --border-width: 5px;
  --padding-start: 9%;
  --padding-end: 9%;
}

ion-button {
  --border-width: 1px;
  --padding-top: 10px;
  --padding-bottom: 10px;
  font-size: 20px;
}`, "",{"version":3,"sources":["webpack://./../../libs/kiosk/components/kiosk-feedback-comment-modal/kiosk-feedback-comment-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,qBAAA;EAEA,UAAA;EACA,SAAA;EACA,gBAAA;AAAJ;;AAGE;EACE,UAAA;EACA,aAAA;EACA,gBAAA;EACA,YAAA;EACA,kBAAA;EACA,oBAAA;EACA,kBAAA;AAAJ;;AAGE;EACE,mBAAA;EACA,mBAAA;EACA,iBAAA;AAAJ;;AAGE;EACE,mBAAA;EACA,mBAAA;EACA,sBAAA;EACA,eAAA;AAAJ","sourcesContent":[".flex-container {\n    display: flex;\n    flex-flow: row wrap;\n    justify-content: center;\n    align-content: center;\n    \n    padding: 0;\n    margin: 0;\n    list-style: none;\n  }\n  \n  .borg-button {\n    width: 23%;\n    height: 200px;\n    margin-top: 10px;\n    padding: 5px;\n    text-align: center;\n    --padding-start: 10%;\n    --padding-end: 10%;\n  }\n\n  .selected{\n    --border-width: 5px;\n    --padding-start: 9%;\n    --padding-end: 9%;\n  }\n\n  ion-button {\n    --border-width: 1px;\n    --padding-top: 10px;\n    --padding-bottom: 10px;\n    font-size: 20px\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
