import { Component, EventEmitter, HostListener, Input, Optional, Output, Self, ViewChild } from '@angular/core';
import { ConfigService } from '../../../config/services';
import { FormBuilder, FormControl, FormGroup, NgControl, Validators } from '@angular/forms';
import {
    ArticleVariantTypeEnum,
    PUBLISH_STATUS_LABELS,
    PublishStatusEnum,
} from '../../../common/entities/webshop.model';
import { SelectInputItem } from '../../../common/components/curafida-input/curafida-select-input/curafida-select-input.component';
import { isEurPrice } from '../../../common/validators/curafida-validators';
import { InputMode } from '../../../common/components/curafida-input/curafida-text-input/curafida-text-input.component';
import {
    CreateProgramArticleDto,
    CreateProgramArticleVariantDto,
    CreateProgramTemplateArticleVariantDto,
    ProgramArticle,
    UpdateProgramArticleDto,
    UpdateProgramArticleVariantDto,
    UpdateProgramTemplateArticleVariantDto,
} from '../../entities/webshop/program-article.entity';
import { TherapyWebshopService } from '../../services/webshop/therapy-webshop.service';
import { TherapyTemplate } from '../../entities/therapy/therapy-template';
import { formatCurrency } from '@angular/common';
import { Content } from '../../entities/content';
import { UpdateWebshopContentDto, WebshopContentMetadata } from '../../../shop/entities/webshop-content.model';
import { WebshopContentService } from '../../../shop/services/webshop-content.service';
import { ExerciseType } from '../../entities/exerciseSession';
import { CurafidaTextAreaComponent } from '../../../common/components/curafida-input/curafida-text-area/curafida-text-area.component';
import { TherapyTemplatesService } from '../../services/therapy-templates';

@Component({
    selector: 'lib-template-shop-form',
    templateUrl: './template-shop-form.component.html',
    styleUrls: ['./template-shop-form.component.scss'],
})
export class TemplateShopFormComponent {
    @ViewChild('descriptionInput') descriptionInput: CurafidaTextAreaComponent;
    isMobile: boolean;
    @Input()
    therapyTemplate: TherapyTemplate;

    @Input()
    therapyForm: FormGroup;
    @Input()
    isNew: boolean;
    @Input()
    isUploadingMediaFile = false;
    @Input()
    isEditEnabled: boolean;
    @Input()
    isNewArticle: boolean;

    @Output()
    toggleEmit = new EventEmitter();
    @Output()
    cancelEmit = new EventEmitter();
    @Output()
    updateEmit = new EventEmitter();
    @Output()
    createEmit = new EventEmitter();

    publishStatusLabels = Array.from(PUBLISH_STATUS_LABELS.entries(), SelectInputItem.fromKeyValuePair);

    articleCosts = [
        { value: 'FREE', label: 'Kostenfrei' },
        {
            value: 'COST',
            label: 'Selbstzahler',
        },
    ];
    article: ProgramArticle;
    InputMode = InputMode;

    // ngModel item
    articleForm: FormGroup;

    private changed = new Array<(value: FormGroup) => void>();
    private touched = new Array<() => void>();

    constructor(
        public configService: ConfigService,
        private therapyWebshopService: TherapyWebshopService,
        private formBuilder: FormBuilder,
        // Retrieve the dependency only from the local injector,
        // not from parent or ancestors.
        @Self()
        // We want to be able to use the component without a form,
        // so we mark the dependency as optional.
        @Optional()
        public ngControl: NgControl,
        private webshopContentService: WebshopContentService,
        private therapyTemplatesService: TherapyTemplatesService,
    ) {
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    get value(): FormGroup {
        return this.articleForm;
    }

    set value(value: FormGroup) {
        if (!this.articleForm) {
            this.articleForm = value;
            this.changed.forEach((f) => f(value));
        }
    }

    touch(): void {
        this.touched.forEach((f) => f());
    }

    writeValue(value: FormGroup): void {
        this.articleForm = value;
    }

    @HostListener('onChange', ['$event.detail'])
    registerOnChange(fn: (value: FormGroup) => void): void {
        this.changed.push(fn);
    }

    registerOnTouched(fn: () => void): void {
        this.touched.push(fn);
    }

    saveNewTemplate(): void {
        this.createEmit.emit();
    }

    cancelEdit(): void {
        this.cancelEmit.emit();
    }

    emitToggleEdit(): void {
        this.toggleEmit.emit();
    }

    toggleEdit(isEditEnabled: boolean): void {
        const publishStatus = this.articleForm.get('publishStatus');
        const uploadedMediaFile = this.articleForm.get('uploadedMediaFile');
        const summary = this.articleForm.get('summary');
        const description = this.articleForm.get('description');
        const price = this.articleForm.get('price');
        const currency = this.articleForm.get('currency');
        const vat = this.articleForm.get('vat');
        const medicalRequirements = this.articleForm.get('medicalRequirements');
        const location = this.articleForm.get('location');
        const costType = this.articleForm.get('costType');
        if (this.therapyTemplate?.disabled) {
            this.articleForm.controls.publishStatus.disable();
        } else {
            isEditEnabled ? publishStatus.enable() : publishStatus.disable();
        }
        isEditEnabled ? uploadedMediaFile.enable() : uploadedMediaFile.disable();
        isEditEnabled ? summary.enable() : summary.disable();
        isEditEnabled ? description.enable() : description.disable();
        isEditEnabled ? price.enable() : price.disable();
        isEditEnabled ? currency.enable() : currency.disable();
        isEditEnabled ? vat.enable() : vat.disable();
        isEditEnabled ? medicalRequirements.enable() : medicalRequirements.disable();
        isEditEnabled ? location.enable() : location.disable();
        isEditEnabled ? costType.enable() : costType.disable();
    }

    updateTemplate(): void {
        this.updateEmit.emit();
    }

    async createArticle(id: number): Promise<ProgramArticle> {
        const article = await this.therapyWebshopService.createArticle(this.createProgramArticleDtoEntity(id));
        await this.initProgramArticleCard(this.therapyTemplate);
        return article;
    }

    async cancelEditArticle(): Promise<void> {
        await this.initProgramArticleCard(this.therapyTemplate);
    }

    async updateArticle(): Promise<void> {
        await this.therapyWebshopService.updateArticle(this.updateProgramArticleDtoEntity());

        await this.initProgramArticleCard(this.therapyTemplate);
        this.articleForm.disable();
    }

    updateProgramArticleDtoEntity(): UpdateProgramArticleDto {
        const updateProgramArticleDto = new UpdateProgramArticleDto();

        updateProgramArticleDto.summary = this.articleForm.controls.summary.value;
        updateProgramArticleDto.description = this.articleForm.controls.description.value;
        if (this.articleForm.controls.costType.value === this.articleCosts[0].value) {
            updateProgramArticleDto.price = 0;
        } else {
            updateProgramArticleDto.price = Number(this.articleForm.controls.price.value.replace(',', '.'));
        }
        updateProgramArticleDto.currency = this.articleForm.controls.currency.value;
        updateProgramArticleDto.vat = Number.parseInt(this.articleForm.controls.vat.value as any, 10) / 100;
        updateProgramArticleDto.publishStatus = this.articleForm.controls.publishStatus.value;
        updateProgramArticleDto.medicalRequirements = this.articleForm.controls.medicalRequirements.value;
        updateProgramArticleDto.location = this.articleForm.controls.location.value;
        updateProgramArticleDto.uuid = this.article.uuid;

        return updateProgramArticleDto;
    }

    async initProgramArticleCard(therapyTemplate: TherapyTemplate): Promise<FormGroup> {
        this.isNewArticle = false;
        try {
            this.article = await this.therapyWebshopService.getArticleOfTherapyTemplate(therapyTemplate.id);
        } catch (e) {
            this.isNewArticle = true;
            this.article = ProgramArticle.createDefault(therapyTemplate.id);
        }
        return this.initArticleForm();
    }

    createNewArticleVariant(): CreateProgramArticleVariantDto | CreateProgramTemplateArticleVariantDto {
        if (this.therapyTemplate.exerciseType === ExerciseType.MIXED) {
            return this.createProgramTemplateArticleVariantDtoEntity();
        } else {
            return this.createProgramArticleVariantDtoEntity();
        }
    }

    updateNewArticleVariant(): UpdateProgramArticleVariantDto | UpdateProgramTemplateArticleVariantDto {
        if (this.therapyTemplate.exerciseType === ExerciseType.MIXED) {
            return this.updateProgramTemplateArticleVariantDtoEntity();
        } else {
            return this.updateProgramArticleVariantDtoEntity();
        }
    }

    async initArticleForm(): Promise<FormGroup> {
        let costType;
        if (this.article.price > 0) {
            costType = this.articleCosts[1].value;
        } else {
            costType = this.articleCosts[0].value;
        }
        this.articleForm = this.formBuilder.group({
            publishStatus: new FormControl({ value: this.article?.publishStatus, disabled: !this.isEditEnabled }, [
                Validators.required,
            ]),
            uploadedMediaFile: new FormControl({ value: '', disabled: this.isNewArticle || !this.isEditEnabled }),
            summary: new FormControl({ value: this.article?.summary, disabled: !this.isEditEnabled }, [
                Validators.maxLength(255),
                Validators.required,
            ]),
            description: new FormControl({ value: this.article?.description, disabled: !this.isEditEnabled }, [
                Validators.required,
            ]),
            price: new FormControl({ value: this.formatEurPrice(), disabled: !this.isEditEnabled }, [
                Validators.required,
                Validators.min(0),
                Validators.max(999.99),
                isEurPrice,
            ]),
            currency: new FormControl({ value: this.article.currency, disabled: !this.isEditEnabled }, [
                Validators.required,
            ]),
            costType: new FormControl({ value: costType, disabled: !this.isEditEnabled }, [Validators.required]),
            vat: new FormControl({ value: Math.trunc(this.article?.vat * 100), disabled: !this.isEditEnabled }, [
                Validators.required,
                Validators.pattern(/^\d+$/),
                Validators.min(0),
                Validators.max(100),
            ]),
            medicalRequirements: new FormControl({
                value: this.article?.medicalRequirements,
                disabled: !this.isEditEnabled,
            }),
            location: new FormControl({ value: this.article?.location, disabled: !this.isEditEnabled }, [
                Validators.maxLength(255),
            ]),
        });
        if (this.therapyTemplate?.disabled) {
            this.articleForm.controls.publishStatus.disable();
        }
        if (this.article?.publishStatus === PublishStatusEnum.DRAFT) {
            this.articleForm.controls.description.removeValidators(Validators.required);
        }

        return this.articleForm;
    }

    changeCostType(value: string): void {
        if (value === 'FREE') {
            this.articleForm.controls.price.clearValidators();
            this.articleForm.controls.price.markAsDirty();
            this.articleForm.controls.price.setValue(null);
        } else {
            this.articleForm.controls.price.clearValidators();
            this.articleForm.controls.price.addValidators(Validators.required);
            this.articleForm.controls.price.addValidators(Validators.min(1));
            this.articleForm.controls.price.addValidators(Validators.max(999));
            this.articleForm.controls.price.addValidators(isEurPrice);
            this.articleForm.controls.price.setValue(1);
        }
        this.articleForm.patchValue({ costType: value });
        this.articleForm.markAsDirty();
    }

    changeDescriptionRequiredStatus(): void {
        if (this.articleForm.controls.publishStatus.value === PublishStatusEnum.DRAFT) {
            this.articleForm.controls.description.removeValidators(Validators.required);
        } else if (this.articleForm.controls.publishStatus.value === PublishStatusEnum.PUBLISHED) {
            this.articleForm.controls.description.addValidators(Validators.required);
        }
        this.articleForm.controls.description.updateValueAndValidity();
        this.descriptionInput.updateLabel();
    }

    private updateProgramTemplateArticleVariantDtoEntity(): UpdateProgramTemplateArticleVariantDto {
        const updateProgramTemplateArticleVariantDto = new UpdateProgramTemplateArticleVariantDto();
        updateProgramTemplateArticleVariantDto.summary = this.articleForm.controls.summary.value;
        updateProgramTemplateArticleVariantDto.description = this.articleForm.controls.description.value;
        if (this.articleForm.controls.costType.value === this.articleCosts[0].value) {
            updateProgramTemplateArticleVariantDto.price = 0;
        } else {
            updateProgramTemplateArticleVariantDto.price = Number(
                this.articleForm.controls.price.value.replace(',', '.'),
            );
        }
        updateProgramTemplateArticleVariantDto.currency = this.articleForm.controls.currency.value;
        updateProgramTemplateArticleVariantDto.vat =
            Number.parseInt(this.articleForm.controls.vat.value as any, 10) / 100;
        updateProgramTemplateArticleVariantDto.publishStatus = this.articleForm.controls.publishStatus.value;
        updateProgramTemplateArticleVariantDto.medicalRequirements =
            this.articleForm.controls.medicalRequirements.value;
        updateProgramTemplateArticleVariantDto.location = this.articleForm.controls.location.value;
        updateProgramTemplateArticleVariantDto.articleVariantType = this.article.articleVariants[0]?.articleVariantType;
        updateProgramTemplateArticleVariantDto.uuid = this.article.articleVariants[0]?.uuid;
        return updateProgramTemplateArticleVariantDto;
    }

    private updateProgramArticleVariantDtoEntity(): UpdateProgramArticleVariantDto {
        const updateProgramArticleVariantDto = new UpdateProgramArticleVariantDto();

        updateProgramArticleVariantDto.summary = this.articleForm.controls.summary.value;
        updateProgramArticleVariantDto.description = this.articleForm.controls.description.value;
        if (this.articleForm.controls.costType.value === this.articleCosts[0].value) {
            updateProgramArticleVariantDto.price = 0;
        } else {
            updateProgramArticleVariantDto.price = Number(this.articleForm.controls.price.value.replace(',', '.'));
        }
        updateProgramArticleVariantDto.currency = this.articleForm.controls.currency.value;
        updateProgramArticleVariantDto.vat = Number.parseInt(this.articleForm.controls.vat.value as any, 10) / 100;
        updateProgramArticleVariantDto.publishStatus = this.articleForm.controls.publishStatus.value;
        updateProgramArticleVariantDto.medicalRequirements = this.articleForm.controls.medicalRequirements.value;
        updateProgramArticleVariantDto.location = this.articleForm.controls.location.value;
        updateProgramArticleVariantDto.articleVariantType = this.article.articleVariants[0]?.articleVariantType;
        updateProgramArticleVariantDto.seatsMax = this.articleForm.controls.seatsMax.value;
        updateProgramArticleVariantDto.seatsMin = this.articleForm.controls.seatsMin.value;
        updateProgramArticleVariantDto.uuid = this.article.articleVariants[0]?.uuid;

        return updateProgramArticleVariantDto;
    }

    private createProgramArticleVariantDtoEntity(): CreateProgramArticleVariantDto {
        const createProgramArticleVariantDto = new CreateProgramArticleVariantDto();

        createProgramArticleVariantDto.summary = this.articleForm.controls.summary.value;
        createProgramArticleVariantDto.description = this.articleForm.controls.description.value;
        if (this.articleForm.controls.costType.value === this.articleCosts[0].value) {
            createProgramArticleVariantDto.price = 0;
        } else {
            createProgramArticleVariantDto.price = Number(this.articleForm.controls.price.value.replace(',', '.'));
        }
        createProgramArticleVariantDto.currency = this.articleForm.controls.currency.value;
        createProgramArticleVariantDto.vat = Number.parseInt(this.articleForm.controls.vat.value as any, 10) / 100;
        createProgramArticleVariantDto.publishStatus = this.articleForm.controls.publishStatus.value;
        createProgramArticleVariantDto.medicalRequirements = this.articleForm.controls.medicalRequirements.value;
        createProgramArticleVariantDto.location = this.articleForm.controls.location.value;
        createProgramArticleVariantDto.articleVariantType = ArticleVariantTypeEnum.PROGRAM_ARTICLE_VARIANT;
        createProgramArticleVariantDto.articleUuid = this.articleForm.controls.articleUuid.value;
        createProgramArticleVariantDto.seatsMax = this.articleForm.controls.seatsMax.value;
        createProgramArticleVariantDto.seatsMin = this.articleForm.controls.seatsMin.value;
        createProgramArticleVariantDto.therapyId = this.articleForm.controls.therapyId.value;

        return createProgramArticleVariantDto;
    }

    private createProgramTemplateArticleVariantDtoEntity(): CreateProgramTemplateArticleVariantDto {
        const createProgramTemplateArticleVariantDto = new CreateProgramTemplateArticleVariantDto();

        createProgramTemplateArticleVariantDto.summary = this.articleForm.controls.summary.value;
        createProgramTemplateArticleVariantDto.description = this.articleForm.controls.description.value;
        if (this.articleForm.controls.costType.value === this.articleCosts[0].value) {
            createProgramTemplateArticleVariantDto.price = 0;
        } else {
            createProgramTemplateArticleVariantDto.price = Number(
                this.articleForm.controls.price.value.replace(',', '.'),
            );
        }
        createProgramTemplateArticleVariantDto.currency = this.articleForm.controls.currency.value;
        createProgramTemplateArticleVariantDto.vat =
            Number.parseInt(this.articleForm.controls.vat.value as any, 10) / 100;
        createProgramTemplateArticleVariantDto.publishStatus = this.articleForm.controls.publishStatus.value;
        createProgramTemplateArticleVariantDto.medicalRequirements =
            this.articleForm.controls.medicalRequirements.value;
        createProgramTemplateArticleVariantDto.location = this.articleForm.controls.location.value;
        createProgramTemplateArticleVariantDto.articleVariantType =
            ArticleVariantTypeEnum.PROGRAM_TEMPLATE_ARTICLE_VARIANT;
        createProgramTemplateArticleVariantDto.articleUuid = this.article.uuid;

        return createProgramTemplateArticleVariantDto;
    }

    private createProgramArticleDtoEntity(therapyTemplateId: number): CreateProgramArticleDto {
        const createProgramArticleDto = new CreateProgramArticleDto();

        createProgramArticleDto.summary = this.articleForm.controls.summary.value;
        createProgramArticleDto.description = this.articleForm.controls.description.value;
        if (this.articleForm.controls.costType.value === this.articleCosts[0].value) {
            createProgramArticleDto.price = 0;
        } else {
            createProgramArticleDto.price = Number(this.articleForm.controls.price.value.replace(',', '.'));
        }
        createProgramArticleDto.currency = this.articleForm.controls.currency.value;
        createProgramArticleDto.vat = Number.parseInt(this.articleForm.controls.vat.value as any, 10) / 100;
        createProgramArticleDto.publishStatus = this.articleForm.controls.publishStatus.value;
        createProgramArticleDto.medicalRequirements = this.articleForm.controls.medicalRequirements.value;
        createProgramArticleDto.location = this.articleForm.controls.location.value;
        createProgramArticleDto.therapyTemplateId = therapyTemplateId;

        return createProgramArticleDto;
    }

    private async updateContentPosition(content: Content, position: number): Promise<Content> {
        const updateDto = new UpdateWebshopContentDto();
        updateDto.jsonData = new WebshopContentMetadata(position);
        return this.therapyTemplatesService.updateContent(content.uuid, updateDto, this.article?.uuid);
    }

    private formatEurPrice(): string {
        return formatCurrency(this.article?.price, 'de-DE', '', this.article?.currency, '0.2-2');
    }
}
