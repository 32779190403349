// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.appointment-checkbox {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
}`, "",{"version":3,"sources":["webpack://./../../libs/therapy/components/calendar-time-planer/calendar-time-planer.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,YAAA;EACA,uBAAA;AACJ","sourcesContent":[".appointment-checkbox {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    align-items: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
