import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Content } from '../../../therapy/entities/content';
import { Chat } from '../../../chat/entities/chat';
import { ChatMessage } from '../../../chat/entities/chat-message';
import { ChatService } from '../../../chat/services/chat.service';
import { ChatNotificationType } from '../../../chat/entities/chat-notification-type';
import { ChatNotification } from '../../../chat/entities/chat-notification';

@Component({
    selector: 'lib-kiosk-notifications-modal',
    templateUrl: './kiosk-notifications-modal.component.html',
    styleUrls: ['./kiosk-notifications-modal.component.scss'],
})
export class KioskNotificationsModalComponent implements OnInit {
    title: string;
    chat: Chat;
    messageIndex = 0;
    Content = Content;
    private chatNotifications: ChatNotification[];

    constructor(
        private modalCtrl: ModalController,
        private params: NavParams,
        private chatService: ChatService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.chat = this.params.get('chat');

        this.chat.messages = this.chat.messages.sort((a, b) => {
            if (new Date(a.created_at).getTime() > new Date(b.created_at).getTime()) {
                return -1;
            } else if (new Date(a.created_at).getTime() < new Date(b.created_at).getTime()) {
                return 1;
            }
            // a must be equal to b
            return 0;
        });

        this.chatNotifications = await this.chatService.getChatNotifications$(this.chat.uuid).toPromise();
        this.chatNotifications = this.chatNotifications.filter((i) => i.type === ChatNotificationType.NEW_MESSAGE);
        const oldestUnreadMessageIndex = this.chat.messages.findIndex(
            (message) => message.uuid === this.chatNotifications[0].chatMessageUuid,
        );
        if (oldestUnreadMessageIndex !== -1) {
            const messagesToMarkAsRead = this.chat.messages.slice(0, oldestUnreadMessageIndex + 1);

            await Promise.all(
                messagesToMarkAsRead.map(async (message) => {
                    await this.chatService.putReadConfirmation(this.chat.uuid, message.uuid);
                }),
            );
        }
    }

    async dismissModal(): Promise<void> {
        await this.modalCtrl.dismiss();
    }

    async getPreviousMessage(): Promise<void> {
        this.messageIndex--;
    }

    async getNextMessage(): Promise<void> {
        this.messageIndex++;
    }

    async downloadAttachment(chatMessage: ChatMessage): Promise<void> {
        await this.chatService.openAttachmentObjectURLinNewWindow(chatMessage.chatUuid, chatMessage.uuid);
    }
}
