import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { Network } from '@ionic-native/network/ngx';
import { Platform, ToastController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../../api';
import { ToastService } from '../../../common/services/toast-service/toast-service.service';
import { IonicColor } from '../../../common/entities/toast/ionic-color';
import { LoggingService } from '../../../logging/logging.service';
import { Logger } from 'loglevel';
export enum ConnectionStatus {
    NONE,
    UNKNOWN,
    BACKEND_REACHABLE,
    BACKEND_UNREACHABLE,
    ONLINE,
    OFFLINE,
}

@Injectable({
    providedIn: 'root',
})
export class NetworkService {
    private onlineStatus: BehaviorSubject<ConnectionStatus> = new BehaviorSubject(ConnectionStatus.UNKNOWN);
    private backendReachableStatus: BehaviorSubject<ConnectionStatus> = new BehaviorSubject(ConnectionStatus.UNKNOWN);
    protected log: Logger;
    constructor(
        private network: Network,
        private toastController: ToastController,
        private plt: Platform,
        protected http: HttpClient,
        public toastService: ToastService,
        public loggingService: LoggingService,
    ) {
        this.plt.ready().then(() => {
            if (this.plt.is('cordova')) {
                this.initializeNetworkEventsForCordova();
            } else {
                this.initializeNetworkEventsForBrowser();
            }
            const onlineStatus = this.network.type !== 'none' ? ConnectionStatus.ONLINE : ConnectionStatus.OFFLINE;
            this.onlineStatus.next(onlineStatus);
            this.log = this.loggingService.getLogger(this.constructor.name);
        });
    }

    public initializeNetworkEventsForCordova() {
        this.network.onDisconnect().subscribe(() => {
            this.updateNetworkStatus(ConnectionStatus.OFFLINE);
        });
        this.network.onConnect().subscribe(() => {
            this.updateNetworkStatus(ConnectionStatus.ONLINE);
        });
    }

    public onNetworkChange(): Observable<ConnectionStatus> {
        return this.onlineStatus.asObservable();
    }

    public getCurrentNetworkStatus(): ConnectionStatus {
        return this.onlineStatus.getValue();
    }

    public isCurrentNetworkOnline(): boolean {
        return this.getCurrentNetworkStatus() === ConnectionStatus.ONLINE;
    }

    public getCurrentNetworkType() {
        return this.network.type;
    }

    public isInternetConnectionAvailable() {
        if (
            this.network.type === this.network.Connection.NONE ||
            this.network.type === this.network.Connection.UNKNOWN
        ) {
            return false;
        }
        return true;
    }

    // TODO: It should also be proved
    async updateBackendReachableStatus(): Promise<ConnectionStatus> {
        try {
            const response = await this.http
                .get(`${ApiService.url}`, {
                    responseType: 'text',
                })
                .toPromise();
            if (response.toLowerCase() === 'mona' || response.toLowerCase() === 'mia') {
                this.backendReachableStatus.next(ConnectionStatus.BACKEND_REACHABLE);
            } else {
                this.backendReachableStatus.next(ConnectionStatus.BACKEND_UNREACHABLE);
            }
        } catch (error) {
            this.backendReachableStatus.next(ConnectionStatus.BACKEND_UNREACHABLE);
        }
        return this.backendReachableStatus.getValue();
    }

    private initializeNetworkEventsForBrowser() {
        fromEvent(window, 'offline').subscribe(() => {
            this.updateNetworkStatus(ConnectionStatus.OFFLINE);
        });
        fromEvent(window, 'online').subscribe(() => {
            this.updateNetworkStatus(ConnectionStatus.ONLINE);
        });
    }

    private async updateNetworkStatus(status: ConnectionStatus) {
        this.onlineStatus.next(status);
        const connection = status === ConnectionStatus.OFFLINE ? 'Offline' : 'Online';
        if (connection === 'Offline') {
            await this.toastService.showToast(
                `Sie befinden sich aktuell im ${connection}-Modus.`,
                IonicColor.medium,
                4000,
            );
        }
    }

    private getConnectionType(): string {
        if (this.plt.is('cordova')) {
            this.log.debug('Download', this.network.downlinkMax);
            return this.network.type;
        }
        // Experimental Technology, not compatible with all kind of browser https://developer.mozilla.org/en-US/docs/Web/API/Navigator/connection
        /*const connection = window.navigator['connection'] as NetworkInformation;
        return connection.effectiveType;*/
    }

    hasLowNetworkQuality(slowConnection: string = '2g') {
        if (!this.plt.is('cordova')) {
            return false; // Experimental Technology, not compatible with all kind of browser https://developer.mozilla.org/en-US/docs/Web/API/Navigator/connection
        }
        const connectionType = this.getConnectionType();
        return slowConnection === connectionType;
    }
}

/**
 * NetworkInformation is still an experimental technology. Type is still missing
 * https://stackoverflow.com/questions/69673842/typescript-networkinformation-type-does-not-contain-all-properties
 */
// http://wicg.github.io/netinfo/#connection-types
type ConnectionType = 'bluetooth' | 'cellular' | 'ethernet' | 'mixed' | 'none' | 'other' | 'unknown' | 'wifi' | 'wimax';

// http://wicg.github.io/netinfo/#effectiveconnectiontype-enum
type EffectiveConnectionType = '2g' | '3g' | '4g' | 'slow-2g';

// http://wicg.github.io/netinfo/#dom-megabit
type Megabit = number;
// http://wicg.github.io/netinfo/#dom-millisecond
type Millisecond = number;

// http://wicg.github.io/netinfo/#networkinformation-interface
interface NetworkInformation extends EventTarget {
    // http://wicg.github.io/netinfo/#type-attribute
    readonly type?: ConnectionType;
    // http://wicg.github.io/netinfo/#effectivetype-attribute
    readonly effectiveType?: EffectiveConnectionType;
    // http://wicg.github.io/netinfo/#downlinkmax-attribute
    readonly downlinkMax?: Megabit;
    // http://wicg.github.io/netinfo/#downlink-attribute
    readonly downlink?: Megabit;
    // http://wicg.github.io/netinfo/#rtt-attribute
    readonly rtt?: Millisecond;
    // http://wicg.github.io/netinfo/#savedata-attribute
    readonly saveData?: boolean;
    // http://wicg.github.io/netinfo/#handling-changes-to-the-underlying-connection
    onchange?: EventListener;
}
