import { AfterContentInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { AnnouncementService } from '../../../announcement/services/announcement.service';
import { Content } from '../../../therapy/entities/content';
import { ContentFormatType } from '../../../common/entities/content-format-type';
import { ButtonsModalDefinition, KioskActionButton } from '../../entities/buttons-modal-definition';
import { KioskActionElementModalComponent } from '../kiosk-action-element-modal/kiosk-action-element-modal.component';
import { NgForOf, NgIf } from '@angular/common';
import { TranslationModule } from '../../../translation/translation.module';
import { CommonComponentsModule } from '../../../common/components/common-components.module';

@Component({
    standalone: true,
    selector: 'lib-kiosk-announcement-content-modal',
    templateUrl: './kiosk-announcement-content-modal.component.html',
    imports: [IonicModule, NgIf, TranslationModule, NgForOf, CommonComponentsModule],
})
export class KioskAnnouncementContentModalComponent implements OnInit, AfterContentInit {
    @ViewChild('videoplayer') videoplayer: ElementRef<HTMLVideoElement>;
    @Input() content: Content;
    contentURL: string;
    isVideoContent: boolean = false;
    finalModal: HTMLIonModalElement;

    constructor(
        private modalCtrl: ModalController,
        private announcementService: AnnouncementService,
    ) {}
    async ngOnInit() {
        if (Content.isAudioMimeType(this.content.mimeType) || Content.isVideoMimeType(this.content.mimeType)) {
            this.isVideoContent = true;
        }
    }

    async ngAfterContentInit() {
        if (!this.contentURL) await this.loadContentURL();
        if (this.videoplayer && this.contentURL) {
            const videoElement = this.videoplayer.nativeElement;
            videoElement.src = this.contentURL;
            videoElement.loop = false;
            this.createModal();
            this.playVideo();
        }
    }

    async dismissModal() {
        await this.modalCtrl.dismiss();
    }

    async loadContentURL() {
        try {
            this.contentURL = await this.announcementService.getContentURL(this.content, ContentFormatType.ORIGINAL);
        } catch (error) {
            console.error('Error loading content URL:', error);
        }
    }

    async createModal() {
        const buttonsModalDefinition = new ButtonsModalDefinition();
        buttonsModalDefinition.title = 'KIOSK.ANNOUNCEMENT.CONTENT_ACTION_TITLE';

        buttonsModalDefinition.actionElements = [];

        let button = new KioskActionButton();
        button.title = 'YES';
        button.description = 'KIOSK.ANNOUNCEMENT.FINISH_CONTENT';
        button.icon = 'checkmark-circle';
        button.iconColor = 'success';
        button.action = 'close';
        buttonsModalDefinition.actionElements.push(button);

        button = new KioskActionButton();
        button.title = 'REPEAT';
        button.description = 'KIOSK.ANNOUNCEMENT.REPEAT_CONTENT';
        button.icon = 'reload-circle';
        button.iconColor = 'success';
        button.action = 'repeat';
        buttonsModalDefinition.actionElements.push(button);

        this.finalModal = await this.modalCtrl.create({
            component: KioskActionElementModalComponent,
            cssClass: 'kiosk-modal',
            backdropDismiss: false,
            showBackdrop: true,
            componentProps: {
                buttonsModalDefinition: buttonsModalDefinition,
            },
        });
    }

    playVideo() {
        this.videoplayer.nativeElement.play();
        this.videoplayer.nativeElement.addEventListener('ended', async () => {
            await this.finalModal.present();
            const { data } = await this.finalModal.onDidDismiss();
            if (data.action === 'repeat') {
                await this.createModal();
                this.playVideo();
            }
            if (data.action === 'close') this.dismissModal();
        });
    }
}
