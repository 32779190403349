import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ExerciseToolSetting } from '../../therapy/entities/exerciseSession';
import { CurafidaAuthService } from '../../auth/services';
import { map } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

export interface MedflexModel {
    setting: ExerciseToolSetting;
    lastNavigationUrl: string;
}

const initialState: MedflexModel = {
    setting: undefined,
    lastNavigationUrl: undefined,
};

@Injectable({
    providedIn: 'root',
})
export class MedflexService {
    readonly model$ = new BehaviorSubject<MedflexModel>({ ...initialState });
    readonly setting$ = this.model$.pipe(map((it) => it.setting));
    readonly joinUrl$ = this.setting$.pipe(
        map((it) => this.sanitizer.bypassSecurityTrustResourceUrl(it.config.joinUrl)),
    );

    constructor(
        readonly route: Router,
        readonly authService: CurafidaAuthService,
        private readonly sanitizer: DomSanitizer,
    ) {}

    get current(): MedflexModel {
        return this.model$.value;
    }

    rememberUrl(url: string): void {
        this.model$.value.lastNavigationUrl = url;
    }
}
