import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ExerciseSession, ExerciseSessionOfUser } from '../entities/exerciseSession';
import { ActionButton, ActionItemType, ActionMenuItem } from '../../table/entities/action-menu.item';
import { ActionType } from '../../table/entities/table';

@Pipe({
    name: 'learningActions',
})
export class LearningActionsPipe implements PipeTransform {
    constructor(private readonly translate: TranslateService) {}

    transform(exerciseSessionOfUser: ExerciseSessionOfUser): ActionMenuItem[] {
        const items: ActionMenuItem[] = [];
        if (!exerciseSessionOfUser) {
            return items;
        }
        items.push(new ActionButton(ActionItemType.BUTTON, this.translate.instant('EDIT'), ActionType.UPDATE));
        if (!ExerciseSession.finishedStates.includes(exerciseSessionOfUser.exerciseSession.exerciseSessionState)) {
            items.push(
                new ActionButton(
                    ActionItemType.BUTTON,
                    this.translate.instant('LEARNING.ACTION_CANCEL'),
                    ActionType.CANCELED,
                ),
            );
        }
        return items;
    }
}
