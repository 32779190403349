import { Pipe, PipeTransform } from '@angular/core';
import { Content } from '../../therapy/entities/content';

@Pipe({
    standalone: true,
    name: 'contentTypeIconSrc',
    pure: true,
})
export class ContentTypeIconSrcPipe implements PipeTransform {
    transform(content: Content): string {
        if (Content.isPNGMimeType(content?.mimeType)) {
            return 'assets/imgs/png.svg';
        } else if (Content.isJPGMimeType(content?.mimeType)) {
            return 'assets/imgs/jpg.svg';
        } else if (Content.isPDFMimeType(content?.mimeType)) {
            return 'assets/imgs/pdf.svg';
        } else if (Content.isAudioMimeType(content?.mimeType)) {
            return 'assets/imgs/audio.svg';
        } else if (Content.isVideoMimeType(content?.mimeType)) {
            return 'assets/imgs/video.svg';
        } else if (
            !Content.isVideoMimeType(content?.mimeType) &&
            !Content.isAudioMimeType(content?.mimeType) &&
            !Content.isAudioMimeType(content?.mimeType) &&
            !Content.isPDFMimeType(content?.mimeType) &&
            !Content.isJPGMimeType(content?.mimeType) &&
            !Content.isPNGMimeType(content?.mimeType)
        ) {
            return 'assets/imgs/picture.svg';
        }
        return '';
    }
}
