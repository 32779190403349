import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ToastService } from '../../../../common/services/toast-service/toast-service.service';
import { ExercisesService } from '../../../services/exercises';
import { ExerciseContentsService } from '../../../services/exercise-contents';
import { Content } from '../../../entities/content';

@Component({
    selector: 'lib-content-overview-modal',
    templateUrl: './content-overview-modal.component.html',
    styleUrls: ['./content-overview-modal.component.scss'],
})
export class ContentOverviewModalComponent implements OnInit {
    title: string;
    content: Content;
    Content = Content;

    constructor(
        private modalCtrl: ModalController,
        private exercisesService: ExercisesService,
        private toastService: ToastService,
        public exerciseContentsService: ExerciseContentsService,
        private params: NavParams,
    ) {}

    async ngOnInit() {
        this.content = await this.params.get('content');
        this.title = this.content.origFileName;
        this.content.url = await this.exerciseContentsService.getObjectURLContentFromUrl(this.content.uuid);
    }

    async dismissModal() {
        await this.modalCtrl.dismiss();
    }
}
