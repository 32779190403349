import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import {
    ExerciseSessionOfUser,
    ExerciseSessionState,
    ExerciseSessionUserState,
} from '../../../../entities/exerciseSession';
import { User } from '../../../../../auth/entities/user';
import { UserExerciseSessionsService } from '../../../../services/user-exercise-sessions';
import { ModalController } from '@ionic/angular';
import { CurafidaAuthService } from '../../../../../auth/services';
import { ExerciseSessionUserResult } from '../../../../entities/exerciseSession/exercise-session-user-result';
import { Exercise } from '../../../../entities/exercise';
import { QuestionnaireTemplate } from '../../../../../my-medax/entities/questionnaire-template';
import { ExercisesService } from '../../../../services/exercises';
import { MimeTypeCurafida } from '../../../../../common/entities/mime.type';
import { TherapyXApiService } from '../../../../services/therapy-xapi/therapy-x-api.service';
import { IXapiCourseInfo } from '../../../../entities/xapi/x-api-course-information';
import { XapiContentMimeType } from '../../../../entities/xapi/xapi-content-mime-type.enum';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Logger, LoggingService } from '../../../../../logging/logging.service';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { isFuture, startOfDay } from 'date-fns';
import { PanelStyle } from '../../../../../common/entities/panel.style';
import Timeout = NodeJS.Timeout;

@Component({
    selector: 'lib-patient-learning-preview',
    templateUrl: './patient-learning-preview.component.html',
    styleUrls: ['./patient-learning-preview.component.scss'],
})
export class PatientLearningPreviewComponent implements OnInit, OnDestroy {
    exerciseSession_id;
    exerciseSessionOfUser: ExerciseSessionOfUser;
    isMobile = false;
    patient: User;
    exerciseSessionUserResult: ExerciseSessionUserResult;
    title = 'Lerneinheit';
    selectedTaskTemplate: Exercise;
    form: QuestionnaireTemplate;
    loggedInUser: User;
    isStartTimeInFuture = false;
    isArticulateModule = false;
    articulateLaunchUrl: string = null;
    finishedLessons: string[] = [];
    isButtonDisable = false;
    xapiCourseInfo: IXapiCourseInfo;
    ExerciseSessionState = ExerciseSessionState;
    protected readonly log: Logger;
    protected readonly PanelStyle = PanelStyle;
    protected readonly ExerciseSessionUserState = ExerciseSessionUserState;
    private updateInterval: Timeout;
    private inFullScreenMode = false;

    constructor(
        private authService: CurafidaAuthService,
        private exercisesService: ExercisesService,
        private userExerciseSessionsService: UserExerciseSessionsService,
        private modalCtrl: ModalController,
        private therapyXApiService: TherapyXApiService,
        private iab: InAppBrowser,
        private loggingService: LoggingService,
        private so: ScreenOrientation,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    @HostListener('document:fullscreenchange', ['$event'])
    @HostListener('document:webkitfullscreenchange', ['$event'])
    @HostListener('document:mozfullscreenchange', ['$event'])
    @HostListener('document:MSFullscreenChange', ['$event'])
    onFullScreenChange(): void {
        if (this.inFullScreenMode) {
            this.so.lock(this.so.ORIENTATIONS.PORTRAIT_PRIMARY);
        } else {
            this.so.unlock();
        }
        this.inFullScreenMode = !this.inFullScreenMode;
    }

    ngOnDestroy(): void {
        if (this.updateInterval) {
            clearInterval(this.updateInterval);
        }
    }

    ngOnInit(): void {
        this.loggedInUser = this.authService.getSession()?.user;
        this.initPage();
    }

    async initPage(): Promise<void> {
        this.exerciseSessionOfUser = await this.userExerciseSessionsService.getExerciseSessionOfUser(
            this.patient.username,
            this.exerciseSession_id,
        );
        this.exerciseSessionUserResult = this.exerciseSessionOfUser.exerciseSessionUserResults[0];
        this.isArticulateModule = this.exerciseSessionOfUser.exerciseSession.exercise.contents.some(
            (c) => c.mimeType === XapiContentMimeType.COURSE,
        );
        if (this.isArticulateModule) {
            this.therapyXApiService
                .getLaunchUrl(this.patient.username, this.exerciseSessionOfUser.exerciseSession.id)
                .then((launchUrl) => {
                    this.articulateLaunchUrl = launchUrl.url;
                });
        }
        this.selectedTaskTemplate = await this.userExerciseSessionsService.getExerciseOfUserExerciseSession(
            this.patient.username,
            this.exerciseSessionOfUser.exerciseSession.id,
        );
        await this.initComponent();
        this.xapiCourseInfo = await this.therapyXApiService.getCourseInfo(
            this.patient.username,
            this.exerciseSessionOfUser.exerciseSession.id,
        );
        this.xapiCourseInfo.lessons.sort((xl1, xl2) => {
            return xl1?.ordinal - xl2?.ordinal;
        });
        this.finishedLessons =
            (
                await this.therapyXApiService.getFinishedLessions(
                    this.patient.username,
                    this.exerciseSessionOfUser.exerciseSession.id,
                )
            )?.lessonIds ?? [];
        let startDate = startOfDay(new Date()).toISOString();
        if (this.exerciseSessionOfUser.exerciseSession?.appointment) {
            startDate = this.exerciseSessionOfUser.exerciseSession.appointment?.startTime;
        }
        if (startDate) this.isStartTimeInFuture = startDate !== '' ? isFuture(new Date(startDate)) : false;
    }

    async initComponent(): Promise<void> {
        if (!this.selectedTaskTemplate) return;
        if (!this.selectedTaskTemplate.contents) {
            this.selectedTaskTemplate.contents = await this.exercisesService.getContentsFromExercise(
                this.selectedTaskTemplate.id,
            );
        }
        if (
            this.selectedTaskTemplate.contents.length > 0 &&
            this.selectedTaskTemplate.contents[0].mimeType === MimeTypeCurafida.MY_MEDAX
        ) {
            this.form = this.selectedTaskTemplate.contents[0].jsonData as QuestionnaireTemplate;
        } else {
            this.form = null;
        }
    }

    openArticulateCourse(event: MouseEvent): void {
        event.stopPropagation();
        this.updateInterval = setInterval(async () => {
            this.xapiCourseInfo = await this.therapyXApiService.getCourseInfo(
                this.patient.username,
                this.exerciseSessionOfUser.exerciseSession.id,
            );
            this.xapiCourseInfo.lessons.sort((xl1, xl2) => {
                return xl1?.ordinal - xl2?.ordinal;
            });
            this.finishedLessons =
                (
                    await this.therapyXApiService.getFinishedLessions(
                        this.patient.username,
                        this.exerciseSessionOfUser.exerciseSession.id,
                    )
                )?.lessonIds ?? [];
        }, 10000);
        this.iab.create(this.articulateLaunchUrl, '_blank');
    }

    dismissModal(): void {
        this.modalCtrl.dismiss('true');
    }
}
