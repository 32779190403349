import { ActivatedRoute, Params, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { CurafidaSegmentComponent } from '../../common/components/curafida-segment/curafida-segment.component';
import { UserRoles } from '../../auth/entities/user';
import { RoutingSegment } from '../../common/entities/routing-segment';

@Injectable({
    providedIn: 'root',
})
export class RoutingService {
    constructor(
        public router: Router,
        public activatedRoute: ActivatedRoute,
    ) {}

    async changingQueryParams<T>(
        newSegmentValue: string,
        newSegmentValueName: string,
        segmentType?: string,
        segmentComponent?: CurafidaSegmentComponent<T>,
    ): Promise<string> {
        const queryParams: Params = {};
        if (this.activatedRoute.snapshot.queryParamMap.keys.length > 0) {
            for (const otherQueryParam of this.activatedRoute.snapshot.queryParamMap.keys) {
                queryParams[otherQueryParam] = this.activatedRoute.snapshot.queryParamMap.get(otherQueryParam);
            }
        }
        queryParams[newSegmentValueName] = newSegmentValue;
        await this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams,
            queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
        if (segmentComponent) {
            segmentComponent.segmentType = newSegmentValue?.toUpperCase() as unknown as T;
        }
        return newSegmentValue?.toUpperCase();
    }

    async openUserDetails(role: UserRoles | RoutingSegment, username: string, replaceUrl = true): Promise<void> {
        const commands =
            role.toLocaleLowerCase() === RoutingSegment.PATIENT_MANAGEMENT
                ? [RoutingSegment.MEMBER, RoutingSegment.PATIENT_MANAGEMENT, RoutingSegment.DETAIL, username]
                : [
                      RoutingSegment.MEMBER,
                      RoutingSegment.ADMINISTRATION,
                      RoutingSegment.USER,
                      role.toLocaleLowerCase(),
                      RoutingSegment.DETAIL,
                      username,
                  ];
        await this.router.navigate(commands, { replaceUrl });
    }
}
