import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutosizeModule } from 'ngx-autosize';
import { NgModule } from '@angular/core';
import { UserMainInfoComponent } from './user-main-info/user-main-info.component';
import { CommonComponentsModule } from '../../common/components/common-components.module';
import { TranslateModule } from '@ngx-translate/core';
import { CurafidaCommonModule } from '../../common/curafida-common.module';
import { SendVerificationEmailComponent } from './send-verificaton-email/send-verification-email.component';
import { PasswordChangeKeyCloakComponent } from './password-change-keycloak/password-change-key-cloak.component';
import { UserListModalComponent } from './user-list-modal/user-list-modal.component';
import { UserShortInfoComponent } from './user-short-info/user-short-info.component';
import { GroupMainInfoComponent } from './group-main-info/group-main-info.component';
import { GroupListModalComponent } from './group-list-modal/group-list-modal.component';
import { GroupShortInfoComponent } from './group-short-info/group-short-info.component';
import { RoleListModalComponent } from './role-list-modal/role-list-modal.component';
import { TableModule } from '../../table/table.module';
import { TableComponentsModule } from '../../table/components/table-components.module';
import { SupervisedPatientListComponent } from './supervised-patient-list/supervised-patient-list.component';
import { RegisterFormComponent } from './register-form/register-form.component';
import { PatientCareManagementComponent } from './user-main-info/patient-care-management/patient-care-management.component';
import { UserTagComponent } from './user-tag/user-tag.component';
import { UserTagViewComponent } from './user-tag-view/user-tag-view.component';
import { UserTagListModalComponent } from './user-tag-list-modal/user-tag-list-modal.component';
import { PatientListComponent } from './patient-list/patient-list.component';
import { PatientCardComponent } from './patient-card/patient-card.component';
import { ReportTypeModalComponent } from './report-type-list-modal/report-type-modal.component';
import { HateoasModule } from '../../hateoas/hateoas.module';
import { CurafidaTableComponent } from '../../table/components/curafida-table/curafida-table.component';
import { LoadingComponent } from '../../common/components/loading/loading';

@NgModule({
    declarations: [
        PatientCardComponent,
        UserMainInfoComponent,
        GroupMainInfoComponent,
        SendVerificationEmailComponent,
        PasswordChangeKeyCloakComponent,
        UserListModalComponent,
        GroupListModalComponent,
        UserShortInfoComponent,
        GroupShortInfoComponent,
        RoleListModalComponent,
        SupervisedPatientListComponent,
        RegisterFormComponent,
        PatientCareManagementComponent,
        UserTagComponent,
        UserTagViewComponent,
        UserTagListModalComponent,
        PatientListComponent,
        ReportTypeModalComponent,
    ],
    imports: [
        CommonModule,
        IonicModule,
        RouterModule,
        CommonComponentsModule,
        ReactiveFormsModule,
        FormsModule,
        AutosizeModule,
        TableModule,
        TableComponentsModule,
        TranslateModule,
        CurafidaCommonModule,
        HateoasModule,
        CurafidaTableComponent,
        LoadingComponent,
    ],
    exports: [
        UserMainInfoComponent,
        GroupMainInfoComponent,
        SendVerificationEmailComponent,
        PasswordChangeKeyCloakComponent,
        UserListModalComponent,
        GroupListModalComponent,
        UserShortInfoComponent,
        GroupShortInfoComponent,
        RoleListModalComponent,
        SupervisedPatientListComponent,
        RegisterFormComponent,
        UserTagComponent,
        UserTagViewComponent,
        UserTagListModalComponent,
        PatientListComponent,
        ReportTypeModalComponent,
    ],
})
export class UserComponentsModule {}
