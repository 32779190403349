import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CurafidaTranslatePipe } from './pluralization-translate.pipe';
import { IonicModule } from '@ionic/angular';

@NgModule({
    declarations: [CurafidaTranslatePipe],
    imports: [IonicModule, TranslateModule],
    exports: [CurafidaTranslatePipe],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TranslationModule {}
