import { User, UserRoles } from '../../auth/entities/user';
import { IconId } from './icon_type';
import { CurafidaFeatureEnum } from '../../config/entities/base-page-config';
import { SegmentType } from './segment.type';
import { FeatureConfigs } from './curafida-frontend-configuration';

export class CurafidaSegmentItem<T> {
    id?: string;
    name: string;
    icon: string | IconId;
    value: T;
    badge?: number;
    permittedRoles?: (UserRoles | string)[];
    viewMode?: ViewMode[] = [];

    constructor(args: {
        id?: string;
        name: string;
        icon?: string | IconId;
        value: T;
        badge?: number;
        permittedRoles?: (UserRoles | string)[];
        viewMode?: ViewMode[];
    }) {
        this.id = args.id;
        this.name = args.name;
        this.icon = args.icon;
        this.value = args.value;
        this.badge = args.badge;
        this.permittedRoles = args.permittedRoles;
        this.viewMode = args.viewMode;
    }

    static addSegmentElementToList(
        arg: {
            id?: string;
            feature: string | CurafidaFeatureEnum;
            userRoles: UserRoles[];
            name: string;
            iconName: string | IconId;
            segmentType: SegmentType;
            blockedUserRoles?: UserRoles[];
            viewMode?: ViewMode[];
            mobileOrder?: number;
        },
        features: FeatureConfigs,
        segmentList: CurafidaSegmentItem<SegmentType>[],
        loggedInUser: User,
    ) {
        if (features[arg.feature]?.enabled && this.checkUserRoles(arg.userRoles, arg.blockedUserRoles, loggedInUser)) {
            segmentList.push(
                new CurafidaSegmentItem({
                    id: arg.id,
                    name: arg.name,
                    icon: arg.iconName,
                    value: arg.segmentType,
                    permittedRoles: arg.userRoles,
                    viewMode: arg.viewMode,
                }),
            );
        }
    }

    static checkUserRoles(userRoles: UserRoles[], blockedUserRoles: UserRoles[], loggedInUser: User) {
        if (blockedUserRoles) {
            for (const userRole of blockedUserRoles) {
                if (loggedInUser.roles.includes(userRole)) return false;
            }
        }
        const userRoleIntersection = userRoles.filter((val1) => {
            return loggedInUser.roles.find((val2) => val1 === val2);
        });
        return userRoleIntersection.length > 0;
    }
}

export enum ViewMode {
    web,
    mobile,
}
