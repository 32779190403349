import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { IconColour, IconId, IconSizeTyp } from '../../entities/icon_type';

const FOLDER_PATH = '../../assets/app-icons';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'curafida-app-icon',
    templateUrl: './curafida-app-icon.component.html',
    styleUrls: ['./curafida-app-icon.component.scss'],
})
export class CurafidaAppIconComponent implements OnInit {
    @Input() iconColour = IconColour.PRIMARY;
    @Input() iconSizeType: IconSizeTyp = IconSizeTyp.segment;
    iconFontSize: string;

    private _iconName: IconId | string;

    get iconName(): IconId | string {
        return this._iconName;
    }

    @Input() set iconName(value: IconId | string) {
        this._iconName = value;
    }

    lookupFilename = (iconName: IconId | string): string => {
        switch (iconName) {
            case IconId.ANNOUNCEMENT:
                return 'newspaper.svg';
            case IconId.CALENDAR:
                return 'calendar-icon.svg';
            case IconId.CALL:
                return 'call-icon.svg';
            case IconId.CHAT:
                return 'chat-icon.svg';
            case IconId.CLIPBOARD:
                return 'clipboard-icon.svg';
            case IconId.COURSES:
                return 'courses-icon.svg';
            case IconId.DOCUMENTS:
                return 'documents-icon.svg';
            case IconId.EDUCATION:
                return 'education-icon.svg';
            case IconId.FIGURE:
                return 'figure-icon.svg';
            case IconId.FILE:
                return 'file-tray-icon.svg';
            case IconId.FOOTPRINTS:
                return 'footprints-icon.svg';
            case IconId.GROUP:
                return 'group-icon.svg';
            case IconId.HEARTBEAT:
                return 'heartbeat-icon.svg';
            case IconId.LIGHTBULB:
                return 'lightbulb-icon.svg';
            case IconId.MEDICATION:
                return 'medication-icon.svg';
            case IconId.NOTES:
                return 'notes-icon.svg';
            case IconId.OVERVIEW:
                return 'overview-icon.svg';
            case IconId.PATIENTS:
                return 'patients-icon.svg';
            case IconId.PROFILE:
                return 'profile-icon.svg';
            case IconId.SETTING:
                return 'setting-icon.svg';
            case IconId.STAR:
                return 'star-icon.svg';
            case IconId.TASKS:
                return 'tasks-icon.svg';
            case IconId.TRAIL:
                return 'trail-sign-icon.svg';
            case IconId.TRAINING:
                return 'training-icon.svg';
            case IconId.PERSON:
                return 'person-icon.svg';
        }
    };

    getPath = () => {
        const filename = this.lookupFilename(this._iconName);
        //how to handle this error properly is probably a question for someone else
        if (!filename) console.warn('no icon found');
        return `${FOLDER_PATH}/${filename}`;
    };
    IconColour = IconColour;

    ngOnInit(): void {
        if (this.iconSizeType === IconSizeTyp.segment) {
            this.iconFontSize = '27px';
        } else if (this.iconSizeType === IconSizeTyp.button) {
            this.iconFontSize = '50px';
        } else if (this.iconSizeType === IconSizeTyp.popover) {
            this.iconFontSize = '20px';
        }
    }
}
