import { SelectInputItem } from '../../../common/components/curafida-input/curafida-select-input/curafida-select-input.component';

const options: SelectInputItem[] = [
    { value: '', label: 'ACADEMIC_TITLE.NONE' },
    { value: 'Prof.', label: 'ACADEMIC_TITLE.PROF' },
    { value: 'Dr.', label: 'ACADEMIC_TITLE.DR' },
    { value: 'Prof. Dr.', label: 'ACADEMIC_TITLE.PROF_DR' },
];

export function academicTitleOptions(): SelectInputItem[] {
    return [...options];
}
