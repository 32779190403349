import { EventEmitter, Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { debounceTime, flatMap, map, switchMap } from 'rxjs/operators';
import {
    AccountType,
    LoginUserWithoutPasswordDto,
    PlainUserDto,
    UpdateUserDto,
    User,
    UserRoles,
    UserRolesDto,
} from '../../../auth/entities/user';
import { ApiService } from '../../../api';
import { PaginatedResponse, SortBy, SortOrder } from '../../../common/entities/paginated-response';
import { IsUsername } from '../../../auth/entities/user/is-username';
import { Utils } from '../../Utils';
import { LoadingService } from '../../../common/services/loading/loading.service';
import { Group, GroupLevel } from '../../entities/group';
import { CurafidaAuthService } from '../../../auth/services';
import { UserCustomPropertySchema } from '../../../auth/entities/user/user-custom-property';
import { plainToInstance } from 'class-transformer';
import { Content } from '../../../therapy/entities/content';
import { FileItem } from 'ng2-file-upload';
import {
    PatientReportCreateDto,
    PatientReportTemplate,
} from '../../../common/components/patient-report-modal/patient-report.model';
import { UserSearchParams } from '../../entities/user-search-params';
import { CurafidaFrontendConfiguration } from '../../../common/entities/curafida-frontend-configuration';

export interface GetUserArguments {
    offset?: number;
    limit?: number;
    role?: UserRoles | string;
    disabled?: boolean;
    filter?: string;
    sortOrder?: SortOrder;
    sortBy?: SortBy;
    tagUuids?: string[];
    withTags?: boolean;
    withLastActivityDate?: boolean;
    accountType?: AccountType;
    emailVerified?: boolean;
    hasTherapy?: boolean;
    finished?: boolean;
    inactive?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class UsersService {
    constructor(
        @Optional() @Inject('environmentConfig') private environmentConfig: CurafidaFrontendConfiguration,
        protected http: HttpClient,
        private authService: CurafidaAuthService,
        private loadingService: LoadingService,
    ) {}

    /**
     * GetUsers
     * @param args GetUserArguments
     * receive a list of users
     */
    async getUsers(args?: GetUserArguments): Promise<PaginatedResponse<User[]>> {
        const groupsOfUser = this.authService.getSession().user.groups;
        const errorMessage =
            'Es ist ein Fehler im Zusammenhang mit Ihrem Benutzerkonto aufgetreten. Daher werden Sie in 10 Sekunden automatisch ausgeloggt.\nBitte wenden Sie sich an service@ztm.de um dieses Problem zu beheben.';
        if (!groupsOfUser || groupsOfUser.length === 0) {
            await this.authService.logoutUserWithError(errorMessage);
            throw Error(errorMessage);
        }
        const groupsWithHighestLevel = this.getHighestHierarchyGroupPaths(groupsOfUser);
        if (groupsWithHighestLevel.length !== 1) {
            await this.authService.logoutUserWithError(errorMessage);
            throw Error(errorMessage);
        }

        // TODO: in the future this should be received as a parameter
        const searchSubgroups = true;
        const url = new URL(`${ApiService.url}groups/${encodeURIComponent(groupsWithHighestLevel[0])}/-/members`);
        if (searchSubgroups) url.searchParams.set('searchSubgroups', `${searchSubgroups}`);
        if (args) {
            if (args.role) url.searchParams.set('role', `${args.role}`);
            if (args.withTags != null) url.searchParams.set('withTags', `${args.withTags}`);
            if (args.offset) url.searchParams.set('offset', `${args.offset}`);
            if (args.limit || args.limit === 0) url.searchParams.set('limit', `${args.limit}`);
            if (args.filter) url.searchParams.set('filter', `${args.filter}`);
            if (!args.sortBy) args.sortBy = SortBy.LASTNAME;
            url.searchParams.set('sortBy', `${args.sortBy}`);
            if (args.disabled != null) url.searchParams.set('disabled', `${args.disabled}`);
            if (args.withLastActivityDate != null) {
                url.searchParams.set('withLastActivityDate', `${args.withLastActivityDate}`);
            }
            if (!args.sortOrder) args.sortOrder = SortOrder.ASC;
            url.searchParams.set('sortOrder', `${args.sortOrder}`);
            if (args.tagUuids?.length > 0) {
                for (const tagUuid of args.tagUuids) {
                    url.searchParams.append('tagUuids', `${tagUuid}`);
                }
            }
            if (args.disabled != null) url.searchParams.set('disabled', `${args.disabled}`);
            if (args.accountType) url.searchParams.set('accountType', `${args.accountType}`);
            if (args.emailVerified != null) url.searchParams.set('emailVerified', `${args.emailVerified}`);
            if (args.hasTherapy != null) url.searchParams.set('hasTherapy', `${args.hasTherapy}`);
            if (args.finished != null) url.searchParams.set('finished', `${args.finished}`);
            if (args.inactive != null) url.searchParams.set('inactive', `${args.inactive}`);
        }
        return this.http.get<PaginatedResponse<User[]>>(url.toString(), ApiService.options).toPromise();
    }

    async getUsers$(searchParams: EventEmitter<UserSearchParams>): Promise<Observable<PaginatedResponse<User[]>>> {
        const searchSubgroups = true;
        const groupsOfUser = this.authService.getSession().user.groups;
        const errorMessage =
            'Es ist ein Fehler im Zusammenhang mit Ihrem Benutzerkonto aufgetreten. Daher werden Sie in 10 Sekunden automatisch ausgeloggt.\nBitte wenden Sie sich an service@ztm.de um dieses Problem zu beheben.';
        if (!groupsOfUser || groupsOfUser.length === 0) {
            await this.authService.logoutUserWithError(errorMessage);
            throw Error(errorMessage);
        }
        const groupsWithHighestLevel = this.getHighestHierarchyGroupPaths(groupsOfUser);
        if (groupsWithHighestLevel.length !== 1) {
            await this.authService.logoutUserWithError(errorMessage);
            throw Error(errorMessage);
        }
        return searchParams.pipe(
            map((searchParams) => {
                const url = new URL(
                    `${ApiService.url}groups/${encodeURIComponent(groupsWithHighestLevel[0])}/-/members`,
                );
                if (searchSubgroups) url.searchParams.set('searchSubgroups', `${searchSubgroups}`);
                // build query param string
                if (searchParams.offset) url.searchParams.set('offset', `${searchParams.offset}`);
                if (searchParams.limit) url.searchParams.set('limit', `${searchParams.limit}`);
                if (searchParams.filter) url.searchParams.set('filter', `${searchParams.filter}`);
                if (searchParams.sortBy) url.searchParams.set('sortBy', `${searchParams.sortBy}`);
                if (searchParams.sortOrder) url.searchParams.set('sortOrder', `${searchParams.sortOrder}`);
                if (searchParams.role) url.searchParams.set('role', `${searchParams.role}`);
                if (searchParams.withTags != null) {
                    url.searchParams.set('withTags', `${searchParams.withTags}`);
                }

                if (searchParams.tagUuids?.length > 0) {
                    for (const tagUuid of searchParams.tagUuids) {
                        url.searchParams.append('tagUuids', `${tagUuid}`);
                    }
                }
                if (searchParams.withLastActivityDate != null) {
                    url.searchParams.set('withLastActivityDate', `${searchParams.withLastActivityDate}`);
                }
                if (searchParams.disabled != null) {
                    url.searchParams.set('disabled', `${searchParams.disabled}`);
                }
                if (searchParams.accountType) {
                    url.searchParams.set('accountType', `${searchParams.accountType}`);
                }
                if (searchParams.emailVerified != null) {
                    url.searchParams.set('emailVerified', `${searchParams.emailVerified}`);
                }
                if (searchParams.hasTherapy != null) {
                    url.searchParams.set('hasTherapy', `${searchParams.hasTherapy}`);
                }
                if (searchParams.finished != null) {
                    url.searchParams.set('finished', `${searchParams.finished}`);
                }
                if (searchParams.inactive != null) {
                    url.searchParams.set('inactive', `${searchParams.inactive}`);
                }
                return url;
            }),
            switchMap((url) => this.http.get<PaginatedResponse<User[]>>(url.toString(), ApiService.options)),
        );
    }

    /**
     * PostPlainUser
     * @param user PlainUserDto
     * @param groupPaths string[]
     * Create a new user with an username but without a password and an email address, ensure its membership in the given groupPaths
     */
    async postPlainUser(user: PlainUserDto, groupPaths?: string[]): Promise<User> {
        this.loadingService.startLoadingModal(); // Use startLoadingController Default Initmessage is not correct for the request
        const url = new URL(`${ApiService.url}users/plainuser`);
        if (groupPaths) {
            for (const path of groupPaths) {
                url.searchParams.append('groupPaths', path);
            }
        }
        return await this.http
            .post<User>(url.toString(), user, ApiService.options)
            .toPromise()
            .finally(() => {
                this.loadingService.stopLoadingModal();
            });
    }

    async downloadPatientReport(
        url: string,
        dto: PatientReportCreateDto,
        headerImg?: FileItem,
        footerImg?: FileItem,
        signatureImg?: FileItem,
        showCreatorUser = true,
    ): Promise<Content> {
        const formData = new FormData();
        const dtoString = JSON.stringify(dto);
        formData.append('patientReportCreateDto', dtoString);
        if (headerImg) formData.append('letterHeaderImage', headerImg.file.rawFile as unknown as File);
        if (footerImg) formData.append('letterFooterImage', footerImg.file.rawFile as unknown as File);
        if (signatureImg) formData.append('userSignatureImage', signatureImg.file.rawFile as unknown as File);
        const targetUrl = new URL(url);
        // Default for showCreatorUser in the endpoint is 'true', only override the param if it is explicitly false
        if (showCreatorUser === false) targetUrl.searchParams.set('showCreatorUser', showCreatorUser.toString());
        const headers = new HttpHeaders().append('authorization', ApiService.options.headers.get('authorization'));
        headers.append('content-type', 'multipart/form-data');
        const options = { headers, withCredentials: true };
        return await this.http.post<Content>(targetUrl.toString(), formData, options).toPromise();
    }

    async getPatientReportTemplates(
        groupPath: string,
        limit?: number,
        offset?: number,
        withContents?: boolean,
    ): Promise<PaginatedResponse<PatientReportTemplate[]>> {
        const url = new URL(`${ApiService.url}groups/${groupPath}/-/patientReport/template`);
        if (limit) url.searchParams.set('limit', limit.toString());
        if (offset) url.searchParams.set('offset', offset.toString());
        if (withContents) url.searchParams.set('withContents', withContents.toString());
        return this.http
            .get<PaginatedResponse<PatientReportTemplate[]>>(url.toString(), ApiService.options)
            .toPromise();
    }

    async getPatientReportTemplateByUuid(
        groupPath: string,
        patientReportTemplateUuid: string,
        withContents?: boolean,
    ): Promise<PatientReportTemplate> {
        const url = new URL(
            `${ApiService.url}groups/${groupPath}/-/patientReport/template/${patientReportTemplateUuid}`,
        );
        if (withContents) url.searchParams.set('withContents', withContents.toString());
        return this.http.get<PatientReportTemplate>(url.toString(), ApiService.options).toPromise();
    }

    /**
     * PostLoginUser
     * @param user LoginUserWithoutPasswordDto
     * @param executeActionsEmail boolean
     * @param groupPaths string[]
     * @param requireOtpConfiguration boolean - require user to create new OTP configuration for 2FA
     * Create a new user with a username and an email address, ensure its membership in the given groupPaths and its roles
     */
    async postLoginUser(
        user: LoginUserWithoutPasswordDto,
        roles: UserRoles[],
        groupPaths?: string[],
        executeActionsEmail = true,
        requireOtpConfiguration?: boolean,
    ): Promise<User> {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}users/loginuser`);
        if (!roles || roles.length < 1) {
            throw new Error('Roles array needs to be set to be able to create a user');
        }
        for (const role of roles) {
            url.searchParams.append('roles', role.toString());
        }
        if (executeActionsEmail) url.searchParams.append('executeActionsEmail', executeActionsEmail.toString());
        if (requireOtpConfiguration) {
            url.searchParams.append('requireOtpConfiguration', requireOtpConfiguration.toString());
        }
        if (groupPaths) {
            for (const path of groupPaths) {
                url.searchParams.append('groupPaths', path);
            }
        }
        return await this.http
            .post<User>(url.toString(), user, ApiService.options)
            .toPromise()
            .finally(() => {
                this.loadingService.stopLoadingModal();
            });
    }

    /**
     * PostLoginPatient
     * @param user LoginUserWithoutPasswordDto
     * @param executeActionsEmail boolean
     * @param groupPaths string[]
     * @param requireOtpConfiguration boolean - require user to create new OTP configuration for 2FA
     * Create a new patient with a username and an email address, ensure its membership in the given groupPaths
     */
    async postLoginPatient(
        user: LoginUserWithoutPasswordDto,
        groupPaths?: string[],
        executeActionsEmail = true,
        requireOtpConfiguration?: boolean,
    ): Promise<User> {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}users/loginpatient`);
        if (executeActionsEmail) url.searchParams.set('executeActionsEmail', executeActionsEmail.toString());
        if (requireOtpConfiguration) {
            url.searchParams.set('requireOtpConfiguration', requireOtpConfiguration.toString());
        }
        if (groupPaths) {
            for (const path of groupPaths) {
                url.searchParams.append('groupPaths', path);
            }
        }
        return await this.http
            .post<User>(url.toString(), user, ApiService.options)
            .toPromise()
            .finally(() => {
                this.loadingService.stopLoadingModal();
            });
    }

    /**
     * SendUpdatePasswordActionEmail
     * @param username username of user
     * Send a password reset email to the user
     */
    sendUpdatePasswordActionEmail(username: string): Promise<void> {
        this.loadingService.startLoadingModal();
        return this.http
            .post<void>(`${ApiService.url}users/${username}/sendUpdatePasswordActionEmail`, null, ApiService.options)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    /**
     UpgradeToLoginPatient
     * @param username string
     * @param email {email: string}
     * @param executeActionsEmail boolean
     * @param requireOtpConfiguration boolean- require user to create new OTP configuration for 2FA
     Upgrade a patient (PlainUser) to a patient with login
     */
    upgradeToLoginPatient(
        username: string,
        email: { email: string },
        executeActionsEmail = true,
        requireOtpConfiguration?: boolean,
    ): Promise<User> {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}users/${username}/upgradeToLoginPatient`);
        if (executeActionsEmail) url.searchParams.set('executeActionsEmail', executeActionsEmail.toString());
        if (requireOtpConfiguration) {
            url.searchParams.set('requireOtpConfiguration', requireOtpConfiguration.toString());
        }
        return this.http
            .post<User>(url.toString(), email, ApiService.options)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    /**
     * AssignRolesToUser
     * @param username string
     * @param roles {roleNames: string[]}
     * Assigns roles to a user
     */
    assignRolesToUser(username: string, roles: { roleNames: string[] }): Promise<User> {
        this.loadingService.startLoadingModal();
        return this.http
            .post<User>(`${ApiService.url}users/${username}/assignRoles`, roles, ApiService.options)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    /**
     * AssignRolesToUser
     * @param username string
     * @param roles {roleNames: string[]}
     * Removes roles from a user
     */
    removeRolesFromUser(username: string, roles: { roleNames: string[] }): Promise<User> {
        this.loadingService.startLoadingModal();
        return this.http
            .post<User>(`${ApiService.url}users/${username}/removeRoles`, roles, ApiService.options)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    /**
     * PutUser
     * @param user User
     * @param username string
     * Change the user class
     */
    async putUser(user: UpdateUserDto, username: string): Promise<User> {
        this.loadingService.startLoadingModal();
        return await this.http
            .put<User>(`${ApiService.url}users/${username}`, user, ApiService.options)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    /**
     * DeleteUser
     * @param username string
     * Delete an user
     */
    deleteUser(username: string) {
        return this.http.delete(`${ApiService.url}users/${username}`, ApiService.options).toPromise();
    }

    /**
     * GetUser
     * @param username string
     * Get one user class
     */
    getUser(username: string): Promise<User> {
        return this.http.get<User>(`${ApiService.url}users/${username}`, ApiService.options).toPromise();
    }

    /**
     * PutUserRole
     * @param username string
     * @param userRoleDto UserRoleDto
     * @Restriction: Online for the RECEPTIONIST
     * Change the role List of an user
     */
    putUserRole(username: string, userRoleDto: UserRolesDto) {
        this.loadingService.startLoadingModal();
        const response = this.http
            .put(`${ApiService.url}users/${username}/roles`, userRoleDto, ApiService.options)
            .toPromise();
        this.loadingService.stopLoadingModal();
        return response;
    }

    async isUsername(username: string): Promise<IsUsername> {
        if (!username || username.length < 1) {
            throw new Error('An empty string cannot be checked.');
        }
        let baseUrl: string;
        if (ApiService.url) {
            baseUrl = ApiService.url;
        } else {
            baseUrl = this.environmentConfig.features?.user?.registerUrl;
            if (!baseUrl.endsWith('/')) {
                baseUrl += '/';
            }
        }
        return this.http.get<IsUsername>(`${baseUrl}users/${username}/isUsername`, ApiService.options).toPromise();
    }

    getUsernameSuggestion(
        formGroup: FormGroup,
        firstNameControlName: string,
        lastNameControlName: string,
    ): Observable<string> {
        const inputSubject = new Subject<string>();
        formGroup.get([firstNameControlName]).valueChanges.subscribe(inputSubject);
        formGroup.get([lastNameControlName]).valueChanges.subscribe(inputSubject);

        return inputSubject.pipe(
            debounceTime(400),
            flatMap(() => {
                const firstName = formGroup.get(firstNameControlName).value;
                const lastName = formGroup.get(lastNameControlName).value;
                return this.generateUsernameSuggestion(this.cleanString(firstName), this.cleanString(lastName));
            }),
        );
    }

    cleanString(text: string) {
        let newText = '';
        const textArray = text.split(' ');
        for (const s of textArray) {
            if (s !== '') {
                newText += s.trim() + ' ';
            }
        }
        return newText.trim();
    }

    getGroupsOfUser(
        username: string,
        limit?: number,
        offset?: number,
        sortBy?: SortBy,
        sortOrder?: SortOrder,
        filter?: string,
        categories?: string[],
        withEvents?: boolean,
        withSubgroups?: boolean,
        level?: GroupLevel,
    ): Promise<PaginatedResponse<Group[]>> {
        const url = new URL(`${ApiService.url}users/${username}/groups`);
        if (limit) url.searchParams.set('limit', limit.toString());
        if (offset) url.searchParams.set('offset', offset.toString());
        if (!sortBy) sortBy = SortBy.NAME;
        url.searchParams.set('sortBy', sortBy);
        if (!sortOrder) sortOrder = SortOrder.ASC;
        url.searchParams.set('sortOrder', sortOrder);
        if (filter) url.searchParams.set('filter', filter);
        if (level) url.searchParams.set('level', level);
        if (categories) {
            for (const category of categories) {
                url.searchParams.append('category', category);
            }
        }
        if (withEvents) url.searchParams.set('withEvents', withEvents.toString());
        if (withSubgroups) url.searchParams.set('withSubgroups', withSubgroups.toString());
        return this.http.get<PaginatedResponse<Group[]>>(url.toString(), ApiService.options).toPromise();
    }

    async assignUserToGroup(username: string, groupPath: string) {
        this.loadingService.startLoadingModal();
        const body = [groupPath];
        const url = new URL(`${ApiService.url}users/${username}/groups`);
        return await this.http
            .put(url.toString(), body, ApiService.options)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    /*
     * Chooses the groups with the highest hierarchy level from an array of group paths, returns them in an array
     */
    getHighestHierarchyGroupPaths(groupPaths: string[]): string[] {
        const highestHierarchyLevel = Math.min(...groupPaths.map((group) => group.split('/').length));
        return groupPaths.filter((group) => group.split('/').length === highestHierarchyLevel);
    }

    /*
     * Checks if the user in the parameters meets all the conditions to manage groups
     */
    isUserGroupManager(user: User): boolean {
        const groupPaths = this.getHighestHierarchyGroupPaths(user.groups);
        // Highest level is tenant level = 3; organization level = 4;
        const lowestLevel = 5;
        const memberOfOnlyOneGroup = groupPaths.length === 1;
        const notMemberOfLowestLevelGroup = groupPaths[0].split('/').length < lowestLevel;
        const hasManageGroupRole = user.roles.includes(UserRoles.manage_group);
        return memberOfOnlyOneGroup && notMemberOfLowestLevelGroup && hasManageGroupRole;
    }

    /*
     * Checks if the user in the parameters meets all the conditions to manage organizations
     */
    isUserOrganizationManager(user: User): boolean {
        const groupPaths = this.getHighestHierarchyGroupPaths(user.groups);
        // Highest level is tenant level = 3; organization level = 4;
        const lowestLevel = 4;
        const memberOfOnlyOneGroup = groupPaths.length === 1;
        const notMemberOfLowestLevelGroup = groupPaths[0].split('/').length < lowestLevel;
        const hasManageGroupRole = user.roles.includes(UserRoles.manage_group);
        return memberOfOnlyOneGroup && notMemberOfLowestLevelGroup && hasManageGroupRole;
    }

    /*
     * Checks if the user in the parameters is on lowest group level (GroupCategory.CENTRAL_FACILITY or GroupCategory.COOPERATION_FACILITY)
     */
    isUserMemberOfLowestLevel(user: User): boolean {
        const groupPaths = this.getHighestHierarchyGroupPaths(user.groups);
        // Lowest level is 5
        const lowestLevel = 5;
        return groupPaths[0].split('/').length === lowestLevel;
    }

    /*
     * Takes a username and returns the full name of the user in question. The default format is 'lastname, firstname'
     */
    async getUserFullName(username: string, lastNameFirst = true): Promise<string> {
        const user = await this.getUser(username);
        return lastNameFirst ? `${user.lastname}, ${user.firstname}` : `${user.firstname} ${user.lastname}`;
    }

    async updatePatient(user: UpdateUserDto, username: string): Promise<User> {
        this.loadingService.startLoadingModal();
        return await this.http
            .put<User>(`${ApiService.url}patients/${username}`, user, ApiService.options)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async deletePatient(username: string): Promise<User> {
        return await this.http.delete<User>(`${ApiService.url}patients/${username}`, ApiService.options).toPromise();
    }

    /**
     * SendUpdatePasswordActionEmail
     * @param username username of user
     * Send a password reset email to the user
     */
    async sendUpdatePasswordActionEmailForPatient(username: string): Promise<void> {
        this.loadingService.startLoadingModal();
        return await this.http
            .post<void>(`${ApiService.url}patients/${username}/sendUpdatePasswordActionEmail`, null, ApiService.options)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async updateEmail(username: string, email: string): Promise<User> {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}users/${username}/email`);
        url.searchParams.set('executeActionsEmail', true.toString());
        return await this.http
            .put<User>(url.toString(), { email }, ApiService.options)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async getUserCustomPropertySchemasByGroup(
        groupPath: string,
        userRoles?: string[],
    ): Promise<UserCustomPropertySchema[]> {
        const url = new URL(`${ApiService.url}groups/${groupPath}/-/userCustomPropertySchemas`);
        userRoles?.forEach((role) => url.searchParams.append('userRoles', role));
        return await this.http
            .get<UserCustomPropertySchema[]>(url.toString(), ApiService.options)
            .map((source) => plainToInstance(UserCustomPropertySchema, source))
            .toPromise();
    }

    async getUserHasOtpConfiguredOrRequired(username: string): Promise<boolean> {
        const url = new URL(`${ApiService.url}users/${username}/otpConfiguration`);
        const response = await this.http.get<{ hasOtp: boolean }>(url.toString(), ApiService.options).toPromise();
        return response.hasOtp;
    }

    async setRequireOtpConfiguration(
        username: string,
        requireOtpConfiguration: boolean,
        executeActionsEmail?: boolean,
    ): Promise<void> {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}users/${username}/requireOtpConfiguration`);
        url.searchParams.set('requireOtpConfiguration', requireOtpConfiguration.toString());
        if (executeActionsEmail) url.searchParams.set('executeActionsEmail', executeActionsEmail.toString());
        return await this.http
            .put<void>(url.toString(), null, ApiService.options)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    private async generateUsernameSuggestion(firstName: string, lastName: string): Promise<string> {
        if (!firstName || !lastName || firstName.length + lastName.length < 4) {
            return '';
        }
        let i = 0;
        let usernameExists = true;
        let usernameSuggestion = `${firstName.toLowerCase()}.${lastName.toLowerCase()}`;
        usernameSuggestion = Utils.removeDiacritics(usernameSuggestion);
        usernameSuggestion = usernameSuggestion.replace(/[^\x00-\x7F]/g, '');
        while (usernameSuggestion.includes(' ')) usernameSuggestion = usernameSuggestion.replace(' ', '.');
        let username = usernameSuggestion;
        while (usernameExists) {
            if (i > 0) {
                username = `${usernameSuggestion}${i}`;
            }
            i++;
            const usernameCheck = await this.isUsername(username);
            usernameExists = usernameCheck.isUsername;
        }
        return username;
    }
}
