import { Component, Input } from '@angular/core';
import { StyleService } from '../../../common/services/style/style.service';
import { ItemAdapterComponent, TableItem } from '../../../table/entities/table';
import { isPast } from 'date-fns';
import { FrontendTherapySession } from '../../entities/therapy-session/therapy-session';

@Component({
    template: `
        <div
            >{{ data.startDateOfTherapy | date: 'dd.MM.yyyy' }} - <br />
            <span [ngClass]="{ 'past-date-warning': isDateInPast() }"
                >{{ data.endDateOfTherapy | date: 'dd.MM.yyyy' }}
            </span>
        </div>
    `,
})
export class TrainingIntervalItemAdapterComponent implements ItemAdapterComponent {
    @Input() data: FrontendTherapySession;
    @Input() itemSetting: TableItem;
    isMobile: boolean;

    constructor(private styleService: StyleService) {
        this.isMobile = this.styleService.isMobile$;
    }

    isDateInPast() {
        return isPast(new Date(this.data.endDateOfTherapy));
    }
}
