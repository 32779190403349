import { ActionItemType } from '../../table/entities/action-menu.item';

export class KioskActionElement {
    type = ActionItemType.INFO_TEXT;
    title: string;
    description: string;
}

export class KioskActionButton extends KioskActionElement {
    type = ActionItemType.BUTTON;
    action: string;
    icon: string;
    iconColor: string;
}

export class ButtonsModalDefinition {
    title: string;
    actionElements: KioskActionElement[];
}
