// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kiosk-notification-body {
  white-space: pre-line;
}

#attachment ion-button {
  margin-top: 2em;
}`, "",{"version":3,"sources":["webpack://./../../libs/kiosk/components/kiosk-notifications-modal/kiosk-notifications-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;AACJ;;AAEA;EACI,eAAA;AACJ","sourcesContent":[".kiosk-notification-body {\n    white-space: pre-line;\n}\n\n#attachment ion-button {\n    margin-top: 2em;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
