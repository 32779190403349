import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { PipesTherapyModule } from '../pipes/pipes-therapy.module';
import { ContentsListCheckboxComponent, ExerciseListCheckboxComponent } from './checkbox-list';
import { FileUploadModule } from 'ng2-file-upload';
import { ExerciseContentsComponent } from './exercise/exercise-contents/exercise-contents.component';
import { CommonComponentsModule } from '../../common/components/common-components.module';
import { TaskListComponent } from './task/task-list/task-list.component';
import { PatientPlanListComponent } from './task/patient-plan-list/patient-plan-list.component';
import { ExerciseListModalComponent } from './modal/exercise-list-modal/exercise-list-modal.component';
import { TherapyTaskTemplateConfigModalComponent } from './modal/therapy-task-template-config/therapy-task-template-config-modal.component';
import { AlternativeAppointmentComponent } from './course/alternative-appointment/alternative-appointment.component';
import { CurafidaCommonModule } from '../../common/curafida-common.module';
import { ExerciseCatalogComponent } from './training/exercise-catalog/exercise-catalog.component';
import { RouterModule } from '@angular/router';
import { FeedbackComponent } from './training/feedback/feedback/feedback.component';
import { PulseComponent } from './training/feedback/pulse/pulse.component';
import { FeedbackModalComponent } from './training/feedback/feedback-modal/feedback-modal.component';
import { ExerciseExplanationComponent } from './training/exercise-explanation/exercise-explanation.component';
import { ContentTrainingComponent } from './training/content-training/content-training.component';
import { ExerciseContentTrainingDefinitionComponent } from './training/exercise-content-training-definition/exercise-content-training-definition.component';
import { ContentOverviewModalComponent } from './modal/content-overview-modal/content-overview-modal.component';
import { TagModalComponent } from './modal/tag-modal/tag-modal.component';
import { ExerciseTagViewComponent } from './exercise-tag-view/exercise-tag-view.component';
import { TaskFormComponent } from './task/task-form/task-form.component';
import { FeedbackCommentsComponent } from './training/exercise-comments/feedback-comments.component';
import { ExerciseCommentModalComponent } from './training/exercise-comment/exercise-comment-modal.component';
import { TaskOverviewComponent } from './task/task-overview/task-overview.component';
import { ScaleNumberComponent } from './training/feedback/scale-number/scale-number.component';
import { UserComponentsModule } from '../../user/components/user-components.module';
import { PatientLearningListComponent } from './learning/patient-learning/patient-learning-list/patient-learning-list.component';
import { LearningOverviewComponent } from './learning/learning-overview/learning-overview.component';
import { PatientLearningDetailComponent } from './learning/patient-learning/patient-learning-detail/patient-learning-detail.component';
import { TherapyLearningContentInputComponent } from './learning/therapy-learning-content-input/therapy-learning-content-input.component';
import { TrainingOverviewComponent } from './training/training-overview/training-overview.component';
import { TherapyTemplateListComponent } from './therapy-template-list/therapy-template-list.component';
import { ChartComponentsModule } from '../../chart/components/chart-component.module';
import { RangeSelectAdapterComponent } from './table-adapter/range-selector-item-adapter.component';
import { TableComponentsModule } from '../../table/components/table-components.module';
import { ExerciseSessionTypeAdapterComponent } from './table-adapter/exercise-session-type-adapter.component';
import { FlagFeedbackAdapterComponent } from './table-adapter/flag-feedback-adapter.component';
import { CommentFeedbackAdapterComponent } from './table-adapter/comment-feedback-adapter.component';
import { BorgPainFeedbackAdapterComponent } from './table-adapter/borg-pain-feedback-adapter.component';
import { FeedbackAdapterComponent } from './table-adapter/feedback-adapter.component';
import { TagsAdapterComponent } from './table-adapter/tags-adapter.component';
import { FinishedAppointmentItemAdapterComponent } from './table-adapter/finished-appoinment-item-adapter.component';
import { PipeTableLib } from '../../table/pipe/pipes-table-lib.module';
import { MyMedaxModule } from '../../my-medax/my-medax.module';
import { ProgramPatientListComponent } from './program/program-patient-list/program-patient-list.component';
import { LearningTemplateOverviewComponent } from './learning/learning-template-overview/learning-template-overview.component';
import { CourseArticleVariantCardComponent } from './course/course-article-variant-card/course-article-variant-card.component';
import { TaskTemplateOverviewComponent } from './task/task-template-overview/task-template-overview.component';
import { ConceptMainComponent } from './manual/concept-main/concept-main.component';
import { SwiperModule } from 'swiper/angular';
import { ProgramOverviewComponent } from './program/program-overview/program-overview.component';
import { ProgramPlanListComponent } from './program/program-plan-list/program-plan-list.component';
import { CreateProgramTaskPlanComponent } from './program/create-program-task-plan/create-program-task-plan.component';
import { CreateProgramLearningPlanComponent } from './program/create-program-learning-plan/create-program-learning-plan.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { TherapyPdfViewerComponent } from './training/therapy-pdf-viewer/therapy-pdf-viewer.component';
import { ProgramMainInformationComponent } from './program/program-main-information/program-main-information.component';
import { TrainingIntervalItemAdapterComponent } from './table-adapter/training-interval-item-adapter.component';
import { TemplateShopFormComponent } from './template-shop-form/template-shop-form.component';
import { TemplateContentFormComponent } from './template-content-form/template-content-form.component';
import { CurafidaThumbnailComponent } from './curafida-thumbnail/curafida-thumbnail.component';
import { CourseExerciseChildModalComponent } from './manual/course-exercise-child-modal/course-exercise-child-modal.component';
import { CurafidaTagsInputComponent } from './curafida-tags-input/curafida-tags-input.component';
import { CaregiverTaskPreviewComponent } from './task/caregiver-task-preview/caregiver-task-preview.component';
import { CalendarTimePlanerComponent } from './calendar-time-planer/calendar-time-planer.component';
import { PatientTaskPreviewComponent } from './task/patient-task-preview/patient-task-preview.component';
import { ExerciseSessionFilterModalComponent } from './exercise-session-filter-modal/exercise-session-filter-modal.component';
import { ProgramTemplateOverviewComponent } from './program/program-template-overview/program-template-overview.component';
import { VideoConferenceModule } from '../../video-conference/video-conference.module';
import { ParticipationComponent } from './task/participation/participation.component';
import { ExerciseContentPreviewComponent } from './exercise-contents-preview/exercise-content-preview.component';
import { TrainingProcessComponent } from './training/process/training-process/training-process.component';
import { TrainingExerciseProcessComponent } from './training/process/training-exercise/training-exercise-process.component';
import { TrainingConclusionProcessComponent } from './training/process/training-conclusion/training-conclusion.component';
import { TrainingInfoProcessComponent } from './training/process/training-info-process/training-info-process.component';
import { PatientLearningPreviewComponent } from './learning/patient-learning/patient-learning-preview/patient-learning-preview.component';
import { PaginationComponent } from './pagination-component/pagination.component';
import { ResponsibleNamePipe } from './task/task-overview/responsible-name.pipe';
import { ExerciseSessionAppointmentInputComponent } from './exercise-session-appointment/exercise-session-appointment-input.component';
import { TaskActionsPipe } from './task/task-actions.pipe';
import { HateoasModule } from '../../hateoas/hateoas.module';
import { ParticipantListModalComponent } from './task/participation/participant-list/participant-list.modal';
import { StateDetailItemsPipe } from '../state-detail-items.pipe';
import { TranslationModule } from '../../translation/translation.module';
import { IonicModule } from '@ionic/angular';
import { CurafidaTimelineComponent } from '../../common/components/curafida-timeline/curafida-timeline.component';
import { ContentTypeIconSrcPipe } from '../../common/pipes/image-icon-src.pipe';
import { TimelineIconPathPipe } from '../../common/components/curafida-timeline/curafida-timeline-icon-path.pipe';
import { TaskTemplateListModalComponent } from './task/task-templates-list/task-template-list-modal.component';
import { TaskPreconditionsConfigModalComponent } from './modal/task-preconditions-config/task-preconditions-config-modal.component';
import { FilterLearningSummaryContentPipe } from '../pipes/filter-learning-summary-contents.pipe';
import { HideLearningContentsPipe } from '../pipes/hide-learning-contents.pipe';
import { IconFilterSelectionComponent } from './modal/task-filter-modal/icon-selection/icon-filter-selection.component';
import { TagSelectionComponent } from './modal/task-filter-modal/tag-selection/tag-selection.component';
import { TaskFilterModalComponent } from './modal/task-filter-modal/task-filter-modal.component';
import { PatientTaskPlanListComponent } from './task/patient-task-plan-list/patient-task-plan-list.component';
import { RangeSelectorGroupComponent } from '../../common/components/range-selector-group/range-selector-group.component';
import { RangeSelectorComponent } from '../../common/components/range-selector/range-selector.component';
import { TagListModalComponent } from './modal/tag-list-modal/tag-list-modal.component';
import { CurafidaTableComponent } from '../../table/components/curafida-table/curafida-table.component';
import { LoadingComponent } from '../../common/components/loading/loading';

@NgModule({
    declarations: [
        TagListModalComponent,
        ExerciseListCheckboxComponent,
        ContentsListCheckboxComponent,
        ExerciseContentsComponent,
        TaskListComponent,
        AlternativeAppointmentComponent,
        ExerciseListModalComponent,
        ExerciseCatalogComponent,
        FeedbackComponent,
        PulseComponent,
        ScaleNumberComponent,
        FeedbackModalComponent,
        ExerciseExplanationComponent,
        ExerciseContentTrainingDefinitionComponent,
        ExerciseSessionAppointmentInputComponent,
        ContentTrainingComponent,
        ExerciseListModalComponent,
        ContentOverviewModalComponent,
        TagModalComponent,
        FeedbackCommentsComponent,
        ExerciseCommentModalComponent,
        TagListModalComponent,
        ExerciseTagViewComponent,
        TaskFormComponent,
        PatientPlanListComponent,
        TaskOverviewComponent,
        PatientLearningListComponent,
        LearningOverviewComponent,
        PatientLearningDetailComponent,
        TherapyLearningContentInputComponent,
        TrainingOverviewComponent,
        TherapyTemplateListComponent,
        RangeSelectAdapterComponent,
        ExerciseSessionTypeAdapterComponent,
        FlagFeedbackAdapterComponent,
        CommentFeedbackAdapterComponent,
        BorgPainFeedbackAdapterComponent,
        FeedbackAdapterComponent,
        TagsAdapterComponent,
        FinishedAppointmentItemAdapterComponent,
        ProgramPatientListComponent,
        LearningTemplateOverviewComponent,
        CourseArticleVariantCardComponent,
        TaskTemplateOverviewComponent,
        ProgramTemplateOverviewComponent,
        ConceptMainComponent,
        ProgramOverviewComponent,
        ProgramPlanListComponent,
        CreateProgramTaskPlanComponent,
        CreateProgramLearningPlanComponent,
        TherapyPdfViewerComponent,
        ProgramMainInformationComponent,
        TrainingIntervalItemAdapterComponent,
        TemplateShopFormComponent,
        TemplateContentFormComponent,
        CurafidaThumbnailComponent,
        CourseExerciseChildModalComponent,
        CurafidaTagsInputComponent,
        CaregiverTaskPreviewComponent,
        CalendarTimePlanerComponent,
        PatientTaskPreviewComponent,
        ExerciseSessionFilterModalComponent,
        ParticipationComponent,
        ExerciseContentPreviewComponent,
        TrainingProcessComponent,
        TrainingExerciseProcessComponent,
        TrainingConclusionProcessComponent,
        PatientLearningPreviewComponent,
        PaginationComponent,
        TaskTemplateListModalComponent,
        ResponsibleNamePipe,
        TaskActionsPipe,
        ParticipantListModalComponent,
        StateDetailItemsPipe,
        TherapyTaskTemplateConfigModalComponent,
        TaskPreconditionsConfigModalComponent,
        IconFilterSelectionComponent,
        TagSelectionComponent,
        TaskFilterModalComponent,
        PatientTaskPlanListComponent,
    ],
    imports: [
        TrainingInfoProcessComponent,
        RangeSelectorComponent,
        RangeSelectorGroupComponent,
        CommonComponentsModule,
        IonicModule,
        TranslationModule,
        PipesTherapyModule,
        FileUploadModule,
        TableComponentsModule,
        UserComponentsModule,
        CurafidaCommonModule,
        RouterModule,
        SwiperModule,
        ChartComponentsModule,
        PipeTableLib,
        MyMedaxModule,
        PdfViewerModule,
        VideoConferenceModule,
        HateoasModule,
        CurafidaTimelineComponent,
        ContentTypeIconSrcPipe,
        TimelineIconPathPipe,
        CurafidaTableComponent,
        LoadingComponent,
    ],
    exports: [
        TagListModalComponent,
        ExerciseListCheckboxComponent,
        ContentsListCheckboxComponent,
        ExerciseContentsComponent,
        TaskListComponent,
        ExerciseListModalComponent,
        AlternativeAppointmentComponent,
        ExerciseCatalogComponent,
        ExerciseExplanationComponent,
        ExerciseContentTrainingDefinitionComponent,
        ContentTrainingComponent,
        ExerciseListModalComponent,
        ContentOverviewModalComponent,
        TagModalComponent,
        FeedbackCommentsComponent,
        ExerciseCommentModalComponent,
        TagListModalComponent,
        ExerciseTagViewComponent,
        TaskFormComponent,
        PatientPlanListComponent,
        TaskOverviewComponent,
        PatientLearningListComponent,
        LearningOverviewComponent,
        PatientLearningDetailComponent,
        TherapyLearningContentInputComponent,
        TrainingOverviewComponent,
        TherapyTemplateListComponent,
        RangeSelectAdapterComponent,
        ExerciseSessionTypeAdapterComponent,
        FlagFeedbackAdapterComponent,
        CommentFeedbackAdapterComponent,
        BorgPainFeedbackAdapterComponent,
        FeedbackAdapterComponent,
        TagsAdapterComponent,
        FinishedAppointmentItemAdapterComponent,
        ProgramPatientListComponent,
        LearningTemplateOverviewComponent,
        CourseArticleVariantCardComponent,
        TaskTemplateOverviewComponent,
        ProgramTemplateOverviewComponent,
        ConceptMainComponent,
        ProgramOverviewComponent,
        ProgramPlanListComponent,
        CreateProgramTaskPlanComponent,
        CreateProgramLearningPlanComponent,
        TherapyPdfViewerComponent,
        ProgramMainInformationComponent,
        TrainingIntervalItemAdapterComponent,
        TemplateShopFormComponent,
        TemplateContentFormComponent,
        CurafidaThumbnailComponent,
        CourseExerciseChildModalComponent,
        CurafidaTagsInputComponent,
        CaregiverTaskPreviewComponent,
        CalendarTimePlanerComponent,
        PatientTaskPreviewComponent,
        ExerciseSessionFilterModalComponent,
        ExerciseContentPreviewComponent,
        TrainingProcessComponent,
        TrainingExerciseProcessComponent,
        TrainingConclusionProcessComponent,
        PatientLearningPreviewComponent,
        PaginationComponent,
        TaskTemplateListModalComponent,
        FilterLearningSummaryContentPipe,
        HideLearningContentsPipe,
        TherapyTaskTemplateConfigModalComponent,
        TaskPreconditionsConfigModalComponent,
        ResponsibleNamePipe,
    ],
    providers: [ResponsibleNamePipe, TaskActionsPipe],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TherapyComponentsModule {}
