import { Pipe, PipeTransform } from '@angular/core';
import { Content } from '../entities/content';
import { ContentUsage } from '../entities/content/content-usage';
import { MimeTypeCurafida } from '../../common/entities/mime.type';

@Pipe({
    name: 'hideQuestionnaireContents',
})
export class HideQuestionnaireContentsPipe implements PipeTransform {
    transform(contents: Content[]): Content[] {
        if (contents) {
            contents = contents.filter((it) => {
                if (it.mimeType !== MimeTypeCurafida.MY_MEDAX) {
                    return it;
                }
            });
        }
        return contents;
    }
}
