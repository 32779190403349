import { Pipe, PipeTransform } from '@angular/core';
import { TimelineItemTyp } from './timeline.entity';
import { PlanerItemResource } from '../../../journal/entities/planer-item-resource';

@Pipe({
    standalone: true,
    name: 'timelineIconPath',
})
export class TimelineIconPathPipe implements PipeTransform {
    transform(item: PlanerItemResource): string {
        switch (item.type) {
            case TimelineItemTyp.TRAINING:
                return 'assets/timeline-icon/training.svg';
            case TimelineItemTyp.TASK:
                switch (item.exerciseSubType) {
                    case TimelineItemTyp.LEARNING:
                        return 'assets/timeline-icon/learning.svg';
                    case TimelineItemTyp.MISSION:
                        return 'assets/timeline-icon/mission.svg';
                    case TimelineItemTyp.FREETEXT:
                        return 'assets/timeline-icon/freetext.svg';
                    case TimelineItemTyp.FHIR_QUESTIONNAIRE:
                    case TimelineItemTyp.QUESTIONNAIRE:
                        return 'assets/timeline-icon/form.svg';
                    case TimelineItemTyp.INFORMATION:
                        break;
                    case TimelineItemTyp.VIDEO_CONFERENCE:
                        return 'assets/timeline-icon/video.svg';
                }
                break;
            case TimelineItemTyp.LEARNING:
                return 'assets/timeline-icon/learning.svg';
            case TimelineItemTyp.ANNOUNCEMENT:
                break;
            case TimelineItemTyp.EXAMINATION:
                return 'assets/timeline-icon/measurement.svg';
            case TimelineItemTyp.GROUP_COURSE:
            case TimelineItemTyp.COURSE:
                return 'assets/timeline-icon/course.svg';
            case TimelineItemTyp.SINGLE_COURSE:
                return 'assets/timeline-icon/single-course.svg';
            case TimelineItemTyp.PATIENT_REPORT_CREATED:
                return 'assets/timeline-icon/document.svg';
            case TimelineItemTyp.USER_CHANGED_EVENT:
            case TimelineItemTyp.USER_ROLES_CHANGED_EVENT:
            case TimelineItemTyp.CAREGIVER_CHANGED_EVENT:
            case TimelineItemTyp.SUPERVISOR_CHANGED_EVENT:
                return 'assets/timeline-icon/cog.svg';
            default:
                return null;
        }
    }
}
