import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { CurafidaPopoverSelectionComponent } from '../../../table/components/curafida-popover-selection/curafida-popover-selection.component';
import { ActionType, ItemAdapterComponent, TableItem } from '../../../table/entities/table';
import { User } from '../../../auth/entities/user';
import { RoutingSegment } from '../../../common/entities/routing-segment';

@Component({
    selector: 'patient-card',
    templateUrl: './patient-card.component.html',
    styleUrls: ['./patient-card.component.scss'],
})
export class PatientCardComponent implements ItemAdapterComponent {
    @Input() data: User;
    @Input() itemSetting: TableItem;
    @Output() updateList = new EventEmitter<boolean>();
    @Output() actionSelected = new EventEmitter<{ action: ActionType; mouseEvent: MouseEvent }>();

    constructor(
        public router: Router,
        readonly popoverController: PopoverController,
    ) {}

    getTagLabels = (): string[] => this.data.tags.map((tag) => tag.label);

    openPatientDetail(): void {
        this.router.navigate([
            RoutingSegment.MEMBER,
            RoutingSegment.PATIENT_MANAGEMENT,
            RoutingSegment.DETAIL,
            this.data.username,
        ]);
    }

    async openActionSelection(ev?) {
        ev.stopPropagation();
        const popover = await this.popoverController.create({
            component: CurafidaPopoverSelectionComponent,
            cssClass: 'my-custom-class',
            event: ev,
            translucent: true,
            componentProps: {
                // @ts-ignore
                actionItems: this.data.actions,
            },
        });
        await popover.present();
        const { data } = await popover.onDidDismiss();
        if (data) this.actionSelected.emit({ action: data, mouseEvent: ev });
    }
}
