// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host(.checkbox-checked) {
  border-color: var(--ion-color-success);
  background: var(--ion-color-success);
}`, "",{"version":3,"sources":["webpack://./../../libs/therapy/components/learning/patient-learning/patient-learning-detail/patient-learning-detail.component.scss"],"names":[],"mappings":"AAAA;EACI,sCAAA;EACA,oCAAA;AACJ","sourcesContent":[":host(.checkbox-checked) {\n    border-color: var(--ion-color-success);\n    background: var(--ion-color-success);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
