import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Genders, User } from '../../../auth/entities/user';
import { KioskTaskVideoModalComponent } from '../kiosk-task-video-modal/kiosk-task-video-modal.component';
import { TaskResource } from '../../../therapy/components/task/task.resource';

@Component({
    selector: 'lib-kiosk-task-preview-video-modal',
    templateUrl: './kiosk-task-preview-video-modal.component.html',
    styleUrls: ['./kiosk-task-preview-video-modal.component.scss'],
})
export class KioskTaskPreviewVideoModalComponent implements OnInit {
    title: string;
    @Input()
    taskResource: TaskResource;
    readonly Genders = Genders;
    @Input()
    loggedInUser: User;
    isFinished = false;

    constructor(private modalCtrl: ModalController) {}

    async ngOnInit(): Promise<void> {
        this.title = this.taskResource.title;
    }

    async dismissModal(): Promise<void> {
        await this.modalCtrl.dismiss({ isFinished: this.isFinished });
    }

    async startVideo(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: KioskTaskVideoModalComponent,
            cssClass: 'full-screen-modal',
            componentProps: {
                taskResource: this.taskResource,
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data.action === 'finished') {
            this.isFinished = true;
            this.title = 'KIOSK.TASK.FINISHED_MODAL_TITLE';
        }
    }

    async finishedTask(): Promise<void> {
        await this.modalCtrl.dismiss({ isFinished: true });
    }
}
