export const UPLOAD_IMAGE_MIME_TYPE_WHITELIST = [
    /*
     * Image formats
     */
    // Image formats also supported by the major web browsers.
    // See https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Image_types
    // 'image/apng',
    // 'image/avif', atm (2021-11-23) avif produces errors with sharp@0.29.3
    'image/gif',
    'image/jpeg',
    'image/png',
    // 'image/svg+xml', xml-file-upload is not allowed at this moment to prevent XXE when syncing files to foreign systems.
    'image/webp',
    // Legacy and not reccomended image formats:
    // 'image/bmp',
    'image/tiff',
    // 'image/x-icon',
];
