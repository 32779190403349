import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ParticipationCreationDto, ParticipationDto } from '../../../entities/exerciseSession/participation.dto';

export class ParticipationForm {
    readonly group: FormGroup;

    private initialState: string;

    constructor(formBuilder: FormBuilder, caregivers: string[] = [], supervisors: string[] = []) {
        this.group = formBuilder.group({
            isActivated: new FormControl({ value: true, disabled: false }),
            isPatientParticipant: new FormControl({ value: false, disabled: false }),
            caregivers: new FormControl({ value: caregivers, disabled: false }),
            supervisors: new FormControl({ value: supervisors, disabled: false }),
        });
        this.initialState = JSON.stringify(this.group.getRawValue());
    }

    get hasChanged(): boolean {
        return JSON.stringify(this.group.getRawValue()) !== this.initialState;
    }

    pushCaregiver(username: string): void {
        if (!this.group.controls.caregivers.value.includes(username)) {
            this.group.controls.caregivers.patchValue([...this.group.controls.caregivers.value, username]);
        }
    }

    pushSupervisor(username: string): void {
        if (!this.group.controls.supervisors.value.includes(username)) {
            this.group.controls.supervisors.patchValue([...this.group.controls.supervisors.value, username]);
        }
    }

    apply(participationDto: ParticipationDto): void {
        this.group.controls.isPatientParticipant.patchValue(participationDto.isPatientParticipant);
        this.group.controls.caregivers.patchValue(participationDto.caregivers);
        this.group.controls.supervisors.patchValue(participationDto.supervisors);
        this.initialState = JSON.stringify(this.group.getRawValue());
    }

    toDto(): ParticipationCreationDto {
        return {
            isPatientParticipant: this.group.controls.isPatientParticipant.value,
            caregivers: this.group.controls.caregivers.value,
            supervisors: this.group.controls.supervisors.value,
        };
    }
}
