import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';

export class ProgressBarStep {
    timestamp?: string | Date;
    text?: string;
    cancelled?: boolean;
    skipped?: boolean;

    constructor(public state: string) {
        this.text = state;
    }
}

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'step-progress-bar',
    templateUrl: './step-progress-bar.component.html',
    styleUrls: ['./step-progress-bar.component.scss'],
})
export class StepProgressBarComponent implements OnInit {
    @Input() activeStepIndex = 0;
    @Input() progressBarSteps: ProgressBarStep[];
    @Input() isCancelled = false;
    @Input() cancelledText = '';
    @Input() showTimestamp = true;
    @Input() isMobile = false;

    dateFormat: string;
    timeFormat: string;
    langSubscription: Subscription;

    constructor(private translate: TranslateService) {}

    ngOnInit() {
        this.setDateFormat();
        this.langSubscription = this.translate.onLangChange.subscribe(() => {
            this.setDateFormat();
        });
    }

    private setDateFormat() {
        this.dateFormat = 'MM/dd/yyyy';
        this.timeFormat = 'hh:mm';
        if (this.translate.currentLang === 'de') {
            this.dateFormat = 'dd.MM.yyyy';
            this.timeFormat = 'HH:mm';
        }
    }
}
