import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../../api';
import { UsersTherapyStats } from '../../entities/user-therapy-stats/users-therapy-stats';
import { ParentTherapyProgress } from '../../entities/user-therapy-stats/parent-therapy-progress';
import { PaginatedResponse } from '../../../common/entities/paginated-response';

@Injectable({
    providedIn: 'root',
})
export class TherapyStatsService {
    constructor(protected http: HttpClient) {}

    async getUserTherapyStats(username: string): Promise<UsersTherapyStats[]> {
        const url = `${ApiService.url}users/${username}/therapyStats`;
        return this.http.get<UsersTherapyStats[]>(url, ApiService.options).toPromise();
    }

    async getParentTherapyProgress(
        username?: string,
        limit?: number,
        offset?: number,
    ): Promise<PaginatedResponse<ParentTherapyProgress[]>> {
        const url = new URL(`${ApiService.url}users/${username ?? '-'}/parentTherapyProgresses`);
        if (limit && offset) {
            url.searchParams.set('limit', limit.toString());
            url.searchParams.set('offset', offset.toString());
        }
        return this.http
            .get<PaginatedResponse<ParentTherapyProgress[]>>(url.toString(), ApiService.options)
            .toPromise();
    }
}
