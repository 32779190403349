import { Injectable } from '@angular/core';
import { PaginatedResponse, SortOrder } from '../../common/entities/paginated-response';
import { AnnouncementResource } from '../entities/annoucement.ressource';
import { AnnouncementCreateDto, AnnouncementDto } from '../entities/annoucement.dto';
import { ApiService } from '../../api';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
    AnnouncementTag,
    AnnouncementTagDto,
    UpdateAnnouncementTagDto,
} from '../entities/announcement-tag/announcement-tag.entity';
import {
    AnnouncementTagCategory,
    AnnouncementTagCategoryDto,
    UpdateAnnouncementTagCategoryDto,
} from '../entities/announcement-tag/announcement-tag-category.entity';
import { AnnouncementSortProperties, SearchAnnouncementOptions } from '../entities/announcement-pagination-options';
import { HypermediaResource } from '../../hateoas/hateoas.model';
import { Content } from '../../therapy/entities/content';
import { ContentFormatType } from '../../common/entities/content-format-type';
import { FileContentService } from '../../common/services/content/file-content.service';
import { NoAuthorizationPipe } from '../../hateoas/authorization.pipe';
import { Logger, LoggingService } from '../../logging/logging.service';
import { Capacitor } from '@capacitor/core';

@Injectable({
    providedIn: 'root',
})
export class AnnouncementService {
    tagCategoriesModuleUrl = ApiService.url + ApiService.apiRootResponse?._links?.announcementTagCategories?.href;
    tagRoot = ApiService.url + ApiService.apiRootResponse?._links?.tag?.href;
    announcementModuleUrl = ApiService.url + ApiService.apiRootResponse?._links?.announcements?.href;
    protected readonly log: Logger;

    constructor(
        protected http: HttpClient,
        private fileContentService: FileContentService,
        private loggingService: LoggingService,
        private noAuthorizationPipe: NoAuthorizationPipe,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    async getAllAnnouncements({
        limit = 10,
        offset = 0,
        sortBy = AnnouncementSortProperties.UPDATED_AT,
        sortOrder = SortOrder.DESC,
        filterByIsActive: filterByIsActive = true,
    }: SearchAnnouncementOptions): Promise<PaginatedResponse<AnnouncementResource[]>> {
        if (this.noAuthorizationPipe.transform(ApiService.apiRootResponse, 'announcements', 'GET')) {
            this.log.error('No authorization to open get announcements');
            return;
        }
        const url = new URL(this.announcementModuleUrl);
        url.searchParams.append('limit', limit.toString());
        url.searchParams.append('offset', offset.toString());
        url.searchParams.append('sortBy', sortBy);
        url.searchParams.append('sortOrder', sortOrder);
        url.searchParams.append('filterByIsActive', filterByIsActive.toString());

        return this.http.get<PaginatedResponse<AnnouncementResource[]>>(url.toString(), ApiService.options).toPromise();
    }

    fetchAnnouncement(announcement: AnnouncementResource): Promise<AnnouncementResource> {
        if (this.noAuthorizationPipe.transform(announcement, 'self', 'GET')) {
            this.log.error('No authorization to open get announcement with uuid: ', announcement.uuid);
        } else {
            const url = ApiService.url + announcement?._links?.self?.href;
            return this.http.get<AnnouncementResource>(url.toString(), ApiService.options).toPromise();
        }
    }

    createOneAnnouncement(announcement: AnnouncementCreateDto, files?: File[]) {
        const url = new URL(this.announcementModuleUrl);
        const headers = new HttpHeaders().append('authorization', ApiService.options.headers.get('authorization'));
        headers.append('content-type', 'multipart/form-data');
        const multipartOptions = { headers, withCredentials: true };
        const formData = new FormData();
        formData.append('announcementDto', JSON.stringify(announcement));
        if (files) {
            files.forEach((file) => formData.append('files', file));
        }
        return this.http.post<AnnouncementResource>(url.toString(), formData, multipartOptions).toPromise();
    }

    updateOneAnnouncement(announcement: AnnouncementResource, files?: File[]) {
        const url = new URL(ApiService.url + announcement._links.self.href);
        const headers = new HttpHeaders().append('authorization', ApiService.options.headers.get('authorization'));
        headers.append('content-type', 'multipart/form-data');
        const multipartOptions = { headers, withCredentials: true };
        const formData = new FormData();
        if (files) {
            files.forEach((file) => formData.append('files', file));
        }
        formData.append('announcementDto', JSON.stringify(AnnouncementDto.fromResource(announcement)));
        return this.http.put<AnnouncementResource>(url.toString(), formData, multipartOptions).toPromise();
    }

    deleteOneAnnouncement(announcement: AnnouncementResource) {
        const url = new URL(ApiService.url + announcement?._links?.self?.href);
        return this.http.delete<void>(url.toString(), ApiService.options).toPromise();
    }

    async openContentInNewWindow(
        content: Content,
        preferredImageFormat = ContentFormatType.MEDIUM_SIZE,
    ): Promise<void> {
        const url = new URL(ApiService.url + content?._links?.download?.href);
        if (Capacitor.isNativePlatform()) {
            await this.fileContentService.openObjectURLinMobileDevice(url.toString(), content.origFileName);
        } else {
            await this.fileContentService.openObjectURLinNewWindow(url.toString(), undefined, preferredImageFormat);
        }
    }

    async getContentURL(content: Content, preferredImageFormat = ContentFormatType.MEDIUM_SIZE): Promise<string> {
        try {
            const url = new URL(ApiService.url + content?._links?.download?.href);
            return await this.fileContentService.getObjectURL(url.toString(), undefined, preferredImageFormat);
        } catch (e) {
            this.log.error(e);
        }
    }

    async downloadContent(content: Content): Promise<void> {
        const url = new URL(ApiService.url + content?._links?.download?.href);
        await this.fileContentService.downloadObjectURLinBrowser(url.toString(), content.origFileName);
    }

    async getIndex(): Promise<HypermediaResource> {
        return this.http.get<HypermediaResource>(ApiService.url, ApiService.options).toPromise();
    }

    // NOT YET IMPLEMENTED
    //
    async getAnnouncementTagCategories(args?: {
        offset?: number;
        limit?: number;
    }): Promise<PaginatedResponse<AnnouncementTagCategory[]>> {
        const url = new URL(`${this.tagCategoriesModuleUrl}`);
        if (args.offset) url.searchParams.set('offset', args.offset.toString());
        if (args.limit) url.searchParams.set('limit', args.limit.toString());
        return this.http
            .get<PaginatedResponse<AnnouncementTagCategory[]>>(url.toString(), ApiService.options)
            .toPromise();
    }

    async createAnnouncementTagCategories(
        announcementTagCategoryDto?: AnnouncementTagCategoryDto,
    ): Promise<AnnouncementTagCategory[]> {
        return this.http
            .post<
                AnnouncementTagCategory[]
            >(`${this.tagCategoriesModuleUrl}`, announcementTagCategoryDto, ApiService.options)
            .toPromise();
    }

    async updateAnnouncementTagCategories(
        updateAnnouncementTagCategoryDto: UpdateAnnouncementTagCategoryDto,
    ): Promise<AnnouncementTagCategory[]> {
        return this.http
            .put<
                AnnouncementTagCategory[]
            >(`${this.tagCategoriesModuleUrl}/${updateAnnouncementTagCategoryDto.uuid}`, updateAnnouncementTagCategoryDto, ApiService.options)
            .toPromise();
    }

    async deleteTagCategories(announcementTagCategoryUuid?: string) {
        return this.http
            .delete(`${this.tagCategoriesModuleUrl}/${announcementTagCategoryUuid}`, ApiService.options)
            .toPromise();
    }

    async createAnnouncementTag(announcementTagDto?: AnnouncementTagDto): Promise<AnnouncementTag> {
        return this.http.post<AnnouncementTag>(`${this.tagRoot}`, announcementTagDto, ApiService.options).toPromise();
    }

    async updateAnnouncementTag(updateAnnouncementTagDto?: UpdateAnnouncementTagDto): Promise<AnnouncementTag> {
        return this.http
            .put<AnnouncementTag>(
                `${this.tagRoot}/${updateAnnouncementTagDto.uuid}`,
                updateAnnouncementTagDto,
                ApiService.options,
            )
            .toPromise();
    }

    async deleteAnnouncementTag(tagUuid?: string) {
        return this.http.delete(`${this.tagRoot}/${tagUuid}`, ApiService.options).toPromise();
    }
}
