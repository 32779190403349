import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { UsersService } from '../../services/user';
import { CurafidaAuthService } from '../../../auth/services';
import { ToastService } from '../../../common/services/toast-service/toast-service.service';
import { StyleService } from '../../../common/services/style/style.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'password-change-keycloak',
    templateUrl: './password-change-key-cloak.component.html',
    styleUrls: ['./password-change-key-cloak.component.scss'],
})
export class PasswordChangeKeyCloakComponent {
    @Output()
    changeFlag = new EventEmitter<boolean>();
    isMobile = false;

    constructor(
        protected router: Router,
        protected formBuilder: FormBuilder,
        protected usersService: UsersService,
        protected authService: CurafidaAuthService,
        protected toastService: ToastService,
        protected styleService: StyleService,
    ) {
        this.isMobile = this.styleService.isMobile$;
    }

    async submitPassword() {
        await this.authService.authorizationCodeAuth(true);
    }
}
