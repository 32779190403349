// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.training-title {
  font-weight: bold;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  max-width: 100%;
  height: 50px;
}

.pagination-button {
  width: 40px;
  height: 40px;
  --padding-bottom: 0;
  --padding-top: 0;
  --padding-start: 0;
  --padding-end: 0;
}

.icon-pagination {
  padding-top: 5px;
  padding-bottom: 5px;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./../../libs/therapy/components/pagination-component/pagination.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,qBAAA;EACA,4BAAA;EACA,gBAAA;EACA,oBAAA;EACA,eAAA;EACA,YAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,mBAAA;EACA,YAAA;AACJ","sourcesContent":[".training-title {\n    font-weight: bold;\n    -webkit-line-clamp: 2;\n    -webkit-box-orient: vertical;\n    overflow: hidden;\n    display: -webkit-box;\n    max-width: 100%;\n    height: 50px;\n}\n\n.pagination-button {\n    width: 40px;\n    height: 40px;\n    --padding-bottom: 0;\n    --padding-top: 0;\n    --padding-start: 0;\n    --padding-end: 0;\n}\n\n.icon-pagination {\n    padding-top: 5px;\n    padding-bottom: 5px;\n    height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
