import { Pipe, PipeTransform } from '@angular/core';

export enum RandomizationGroup {
    /** Interventionsgruppe */
    A = 'IG',

    /** Kontrollgruppe */
    B = 'KG',
}

@Pipe({
    name: 'fullName',
})
export class FullNamePipe implements PipeTransform {
    transform(
        value: { firstname: string; lastname: string; randomizationGroup?: string },
        isLastnameFirst = false,
    ): string {
        let fullName: string;
        if (!value.firstname && !value.lastname) {
            fullName = '-';
        } else if (isLastnameFirst) {
            fullName = [value.lastname, value.firstname].filter((x) => x != '').join(', ');
        } else {
            fullName = [value.firstname, value.lastname].filter((x) => x != '').join(' ');
        }
        return this.addRandomizationGroupIfExists(fullName, value.randomizationGroup);
    }

    private addRandomizationGroupIfExists(fullName: string, randomizationGroup?: string): string {
        if (!randomizationGroup) {
            return fullName;
        }
        return fullName + ' (' + RandomizationGroup[randomizationGroup] + ')';
    }
}
