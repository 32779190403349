import { Component, OnDestroy } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { ModalController, NavController, PopoverController } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AppConfiguration, BasePageConfig, PageConfig } from '../../../config/entities';
import { User, UserRoles } from '../../../auth/entities/user';
import { ConfigService } from '../../../config/services';
import { CurafidaAuthService } from '../../../auth/services';
import { StyleService } from '../../../common/services/style/style.service';
import { SplashScreenStateService } from '../../../common/services/splash-screen-state.service';
import { ToolbarService } from '../../../common/services/toolbar/toolbar.service';
import { PopoverMenuComponent } from '../../../common/components/popover-menu/popover-menu.component';
import { TutorialSlidesModalComponent } from '../../../measurement/components/tutorial-slides-modal/tutorial-slides-modal.component';
import { LanguageSelectionComponent } from '../../../common/components/language-selection/language-selection.component';
import { ToolbarType } from '../../../common/entities/view/toolbar-type';
import { RoutingSegment } from '../../../common/entities/routing-segment';
import { CurafidaSession } from '../../../auth/entities/user/curafida-session';
import { CurafidaCommonModule } from '../../../common/curafida-common.module';
import { CoreCurafidaPipesModule } from '../../../config/pipes/core-curafida-pipes.module';
import { DicoMenuFilterPipe } from '../../pipes/dico-menu-filter.pipe';

@Component({
    standalone: true,
    selector: 'app-dico-vertical-toolbar',
    templateUrl: './toolbar-vertical.html',
    styleUrls: ['./toolbar-vertical.scss'],
    imports: [RouterLink, CurafidaCommonModule, CoreCurafidaPipesModule, DicoMenuFilterPipe],
})
export class ToolbarVerticalComponent implements OnDestroy {
    title: string;
    backButtonStringName = '';
    showBackButtonAndBurgerMenuIcon = true;
    modalDefinition;
    helpURL: string = null;
    logoName = 'logo.png';
    appConfig: AppConfiguration;
    roles: UserRoles[] | string[] = [];
    session: CurafidaSession = new CurafidaSession();
    showCurrentUser = false;
    showTitle = false;

    langSubscription: Subscription;
    langList: string[] = [];
    currentLang: string;
    isMobile = false;
    toolbarVisibility = true;
    toolbarType = ToolbarType.DICO_HORIZONTAL;
    ToolbarType = ToolbarType;
    networkSubscription: Subscription;

    constructor(
        public router: Router,
        public configService: ConfigService,
        private popoverController: PopoverController,
        public authService: CurafidaAuthService,
        private iab: InAppBrowser,
        private translate: TranslateService,
        private styleService: StyleService,
        private modalCtrl: ModalController,
        private toolbarService: ToolbarService,
        private navController: NavController,
        private splashScreenStateService: SplashScreenStateService,
    ) {
        this.isMobile = this.styleService.isMobile$;
        this.toolbarService.subscribeTitle((t) => {
            this.title = t;
        });
        this.toolbarService.subscribeBackButtonStringName((t) => {
            this.backButtonStringName = t;
        });
        this.toolbarService.subscribeToolbarType((t) => {
            this.toolbarType = t;
        });
        this.toolbarService.subscribeBackButtonAndBurgerMenuIconState((t) => {
            this.showBackButtonAndBurgerMenuIcon = t;
        });
        this.toolbarService.subscribeUserRole((t) => {
            this.roles = t;
        });
        this.toolbarService.subscribeHelpModalComponent((t) => {
            this.modalDefinition = t;
        });
        this.initToolbar();
    }

    ngOnDestroy(): void {
        this.langSubscription?.unsubscribe();
        this.networkSubscription?.unsubscribe();
    }

    isLoginPage(): boolean {
        return this.router.url.includes('/login');
    }

    isRootPage(): boolean {
        let isRootPage = false;
        const currentUrl = this.router.url;
        if (!this.roles || this.roles.length === 0) {
            isRootPage = currentUrl === '/login';
        }
        isRootPage = isRootPage ? isRootPage : currentUrl === this.configService.config.defaultUrl;
        if (isRootPage) {
            return true;
        }
        for (const pageConfig of this.configService.config.pageConfigs) {
            if (currentUrl === pageConfig.url) {
                isRootPage = pageConfig.configuration.headerVisibility || pageConfig.configuration.menuVisibility;
            }
            if (isRootPage) {
                return true;
            }
        }
        for (const generalPageConfig of this.configService.config.generalPageConfigs) {
            if (currentUrl === generalPageConfig.url) {
                isRootPage =
                    generalPageConfig?.configuration?.headerVisibility ||
                    generalPageConfig?.configuration?.menuVisibility;
            }
            if (isRootPage) {
                return true;
            }
        }
        return false;
    }

    async findCurrentPage() {
        let page = this.configService.config.pageConfigs.find((currentPage) => {
            if (currentPage.configuration.toolbarVisibility) {
                this.toolbarVisibility = currentPage.configuration.toolbarVisibility;
            }
            return this.router.url.includes(currentPage.url);
        });
        if (!page) {
            for (const subpage of this.configService.config.pageConfigs) {
                if (subpage.childPageConfigs) {
                    page = subpage.childPageConfigs.find((currentPage) => {
                        if (currentPage.configuration.toolbarVisibility) {
                            this.toolbarVisibility = currentPage.configuration.toolbarVisibility;
                        }
                        return this.router.url.includes(currentPage.url);
                    });
                }
            }
        }

        if (!page) {
            page = this.configService.config.generalPageConfigs.find((currentPage) => {
                if (currentPage.configuration.toolbarVisibility) {
                    this.toolbarVisibility = currentPage.configuration.toolbarVisibility;
                }
                return this.router.url.includes(currentPage.url);
            });
        }
        if (!page) {
            for (const subpage of this.configService.config.generalPageConfigs) {
                if (subpage.childPageConfigs) {
                    page = subpage.childPageConfigs.find((currentPage) => {
                        if (currentPage.configuration.toolbarVisibility) {
                            this.toolbarVisibility = currentPage.configuration.toolbarVisibility;
                        }
                        return this.router.url.includes(currentPage.url);
                    });
                }
            }
        }
        return page;
    }

    hasRole(page: PageConfig) {
        for (const pageRole of page.role) {
            // @ts-ignore
            if (this.roles.includes(pageRole)) {
                return true;
            }
        }
    }

    async presentPopover(ev, subPagesConfig: BasePageConfig[]) {
        subPagesConfig = subPagesConfig.filter((page) => this.hasRole(page));
        const popover = await this.popoverController.create({
            component: PopoverMenuComponent,
            translucent: true,
            event: ev,
            componentProps: {
                subPages: subPagesConfig,
            },
        });
        return await popover.present();
    }

    async openHelp() {
        if (this.helpURL) this.iab.create(this.helpURL);
        if (this.modalDefinition) {
            const modal = await this.modalCtrl.create({
                component: TutorialSlidesModalComponent,
                componentProps: {
                    modalDefinition: this.modalDefinition,
                },
            });
            await modal.present();
            await modal.onDidDismiss();
        }
    }

    async presentLangPopover(ev: any) {
        const popover = await this.popoverController.create({
            component: LanguageSelectionComponent,
            cssClass: '',
            event: ev,
            translucent: true,
        });
        return await popover.present();
    }

    goToPreviousPage() {
        this.navController.pop();
    }

    async openPage(url: string) {
        await this.router.navigate([url]);
    }

    async logout() {
        this.splashScreenStateService.start();
        await this.authService.logout();
        await this.router.navigate(['login']);
    }

    // Check if at least one of the categories is in the URL, for header highlighting
    mainTitleActive(categories: RoutingSegment[]) {
        for (const category of categories) {
            if (this.router.url.includes(category)) {
                return true;
            }
        }
        return false;
    }

    private async initToolbar() {
        this.session = this.authService.getSession();
        if (!this.session || !this.session.user) {
            // This dirty piece of codeblock should not be here
            // but i am afraid other code depend on this
            // TODO: remove
            this.session = new CurafidaSession();
            this.session.user = new User();
        }
        this.roles = await this.authService.getRoles();
        this.logoName = this.configService.config?.theme?.logoUrl;
        this.langList = this.translate.getLangs();
        this.currentLang = this.translate.currentLang;
        this.langSubscription = this.translate.onLangChange.subscribe(() => {
            this.currentLang = this.translate.currentLang;
        });
        this.title = await this.toolbarService.getTitle();
        this.appConfig = this.configService.config;
        const page = await this.findCurrentPage();
        if (page?.configuration?.helpUrl && page?.configuration?.hasHelpIcon) {
            this.helpURL = page?.configuration?.helpUrl;
        }
        if (page) {
            this.showCurrentUser = page.configuration.showCurrentUser;
            this.showTitle = page.configuration.showPageTitle;
        }
        // })
    }
}
