import { AnnouncementResource } from './annoucement.ressource';

export class AnnouncementDto {
    title: string;

    textBody: string;

    contentUuids: string[];

    tagUuids: string[];

    hasVisibilitySet: boolean;

    visibleForSupervisors?: boolean;

    visibleForPatients?: boolean;

    visibleForMyPatients?: boolean;

    hasPublishDateSet: boolean;

    publishStartDate?: Date;

    /**
     * A null value means "Kein Enddatum".
     */
    publishEndDate?: Date;

    public static from({
        title = '',
        textBody = '',
        contentUuids = [],
        tagUuids = [],
        hasVisibilitySet = false,
        hasPublishDateSet = false,
        visibleForSupervisors = false,
        visibleForPatients = false,
        visibleForMyPatients = false,
        publishStartDate,
        publishEndDate,
    }: AnnouncementDto): AnnouncementDto {
        return {
            title: title,
            textBody: textBody,
            contentUuids: contentUuids,
            tagUuids: tagUuids,
            hasVisibilitySet: hasVisibilitySet,
            hasPublishDateSet: hasPublishDateSet,
            visibleForSupervisors: visibleForSupervisors,
            visibleForPatients: visibleForPatients,
            visibleForMyPatients: visibleForMyPatients,
            publishStartDate: publishStartDate,
            publishEndDate: publishEndDate,
        };
    }

    public static fromResource(announcementResource: AnnouncementResource): AnnouncementDto {
        const dto: AnnouncementDto = {
            title: announcementResource.title,
            textBody: announcementResource.textBody,
            contentUuids: announcementResource.contents.map((content) => content.uuid),
            tagUuids: announcementResource.tags.map((tag) => tag.uuid),
            hasVisibilitySet: announcementResource.hasVisibilitySet,
            hasPublishDateSet: announcementResource.hasPublishDateSet,
            visibleForSupervisors: announcementResource.visibleForSupervisors,
            visibleForPatients: announcementResource.visibleForPatients,
            visibleForMyPatients: announcementResource.visibleForMyPatients,
            publishStartDate: announcementResource.publishStartDate,
            publishEndDate: announcementResource.publishEndDate,
        };
        if (!announcementResource.hasVisibilitySet) {
            dto.visibleForSupervisors = undefined;
            dto.visibleForPatients = undefined;
            dto.visibleForMyPatients = undefined;
        }
        if (!announcementResource.hasPublishDateSet) {
            dto.publishStartDate = undefined;
            dto.publishEndDate = undefined;
        }

        return dto;
    }
}

export class AnnouncementCreateDto {
    title: string;

    textBody: string;

    tagUuids: string[];

    hasVisibilitySet: boolean;

    visibleForSupervisors?: boolean;

    visibleForPatients?: boolean;

    visibleForMyPatients?: boolean;

    hasPublishDateSet: boolean;

    publishStartDate?: Date;

    /**
     * A null value means "Kein Enddatum".
     */
    publishEndDate?: Date;

    public static from({
        title = '',
        textBody = '',
        tagUuids = [],
        hasVisibilitySet = false,
        hasPublishDateSet = true,
        visibleForSupervisors = false,
        visibleForPatients = false,
        visibleForMyPatients = false,
        publishStartDate,
        publishEndDate = null,
    }: AnnouncementCreateDto): AnnouncementCreateDto {
        return {
            title: title,
            textBody: textBody,
            tagUuids: tagUuids,
            hasVisibilitySet: hasVisibilitySet,
            hasPublishDateSet: hasPublishDateSet,
            visibleForSupervisors: visibleForSupervisors,
            visibleForPatients: visibleForPatients,
            visibleForMyPatients: visibleForMyPatients,
            publishStartDate: publishStartDate,
            publishEndDate: publishEndDate,
        };
    }

    public static fromResource(announcementResource: AnnouncementResource): AnnouncementCreateDto {
        const createDto: AnnouncementCreateDto = {
            title: announcementResource.title,
            textBody: announcementResource.textBody,
            tagUuids: announcementResource.tags.map((tag) => tag.uuid),
            hasVisibilitySet: announcementResource.hasVisibilitySet,
            hasPublishDateSet: announcementResource.hasPublishDateSet,
            visibleForSupervisors: announcementResource.visibleForSupervisors,
            visibleForPatients: announcementResource.visibleForPatients,
            visibleForMyPatients: announcementResource.visibleForMyPatients,
            publishStartDate: announcementResource.publishStartDate,
            publishEndDate: announcementResource.publishEndDate,
        };
        if (!announcementResource.hasVisibilitySet) {
            createDto.visibleForSupervisors = undefined;
            createDto.visibleForPatients = undefined;
            createDto.visibleForMyPatients = undefined;
        }
        if (!announcementResource.hasPublishDateSet) {
            createDto.publishStartDate = undefined;
            createDto.publishEndDate = undefined;
        }
        return createDto;
    }
}
