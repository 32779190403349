// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item {
  border: 1px solid var(--ion-color-light);
  margin-bottom: 2px;
  margin-top: 3px;
  width: 50%;
  margin-left: 10px;
}

.service-button {
  display: flex;
  flex-wrap: nowrap;
  width: 100% !important;
  flex-direction: row;
}

.curafida-item-input {
  --padding-start: 0 !important;
}

.button-container {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100% !important;
  flex-direction: row;
}

ion-card {
  margin-top: 18px;
  margin-bottom: 18px;
}

.lines {
  border-top: 1px solid var(--ion-color-light-shade);
  background: white !important;
}`, "",{"version":3,"sources":["webpack://./../../libs/user/components/group-main-info/group-main-info.component.scss"],"names":[],"mappings":"AAAA;EACI,wCAAA;EACA,kBAAA;EACA,eAAA;EACA,UAAA;EACA,iBAAA;AACJ;;AAEA;EACI,aAAA;EACA,iBAAA;EACA,sBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,6BAAA;AACJ;;AAGA;EACI,aAAA;EACA,iBAAA;EACA,qBAAA;EACA,yBAAA;EACA,sBAAA;EACA,mBAAA;AAAJ;;AAGA;EACI,gBAAA;EACA,mBAAA;AAAJ;;AAGA;EACI,kDAAA;EACA,4BAAA;AAAJ","sourcesContent":["ion-item {\n    border: 1px solid var(--ion-color-light);\n    margin-bottom: 2px;\n    margin-top: 3px;\n    width: 50%;\n    margin-left: 10px;\n}\n\n.service-button {\n    display: flex;\n    flex-wrap: nowrap;\n    width: 100% !important;\n    flex-direction: row;\n}\n\n.curafida-item-input {\n    --padding-start: 0 !important;\n    //width: 60%!important;\n}\n\n.button-container {\n    display: flex;\n    flex-wrap: nowrap;\n    align-items: flex-end;\n    justify-content: flex-end;\n    width: 100% !important;\n    flex-direction: row;\n}\n\nion-card {\n    margin-top: 18px;\n    margin-bottom: 18px;\n}\n\n.lines {\n    border-top: 1px solid var(--ion-color-light-shade);\n    background: white !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
