/**
 * A ContentUsage is required for access control decisions on contents.
 */
export enum ContentUsage {
    EXERCISE_CONTENT = 'EXERCISE_CONTENT',
    EXERCISE_SESSION_USER_RESULT = 'EXERCISE_SESSION_USER_RESULT',
    MEDICAL_HISTORY = 'MEDICAL_HISTORY',

    /**
     * Contents related to a measurement.
     * E.g. a Raw ECG measurement, a Wound doc picture,
     * a Stethoscope audio file or a PDF document.
     */
    MEASUREMENT_CONTENT = 'MEASUREMENT_CONTENT',

    CHAT_ATTACHMENT = 'CHAT_ATTACHMENT',
    ARTICULATE_CONTENT = 'ARTICULATE_CONTENT',
    ARTICULATE_SUMMARY_CONTENT = 'ARTICULATE_SUMMARY_CONTENT',
    WEBSHOP_CONTENT = 'WEBSHOP_CONTENT',
    CONSULTATION_CONTENT = 'CONSULTATION_CONTENT',

    SCREENING = 'SCREENING',

    ORDER_CONTENT = 'ORDER_CONTENT',
    NOTE_CONTENT = 'NOTE_CONTENT',

    PATIENT_PDF_REPORT = 'PATIENT_PDF_REPORT',
    PATIENT_PDF_REPORT_TEMPLATE_IMG = 'PATIENT_PDF_REPORT_TEMPLATE_IMG',
}
