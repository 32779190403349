import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ExerciseSessionOfUserFrontend } from '../../entities/exerciseSession';
import { ItemAdapterComponent, TableItem } from '../../../table/entities/table';
import { ModalController, Platform } from '@ionic/angular';
import { AlternativeAppointmentComponent } from '../course/alternative-appointment/alternative-appointment.component';

@Component({
    template: `
        <div
            style="width: 95%;"
            [ngClass]="{ 'mobile-font': isMobile }">
            <ion-button
                (click)="showAlternativeAppointment()"
                class="btn-font-medium"
                [disabled]="data.disabled"
                style="height: 40px">
                Sitzung zuordnen
            </ion-button>
        </div>
    `,
})
export class FinishedAppointmentItemAdapterComponent implements ItemAdapterComponent {
    @Input() data: ExerciseSessionOfUserFrontend;
    @Input() itemSetting: TableItem;
    @Output() updateList = new EventEmitter<boolean>();
    isMobile: boolean;

    constructor(
        private platform: Platform,
        private modalCtrl: ModalController,
    ) {
        if (platform.is('mobile')) this.isMobile = true;
    }

    async showAlternativeAppointment() {
        const modal = await this.modalCtrl.create({
            component: AlternativeAppointmentComponent,
            backdropDismiss: false,
            cssClass: 'full-width-modal-css',
            componentProps: {
                exerciseSessionOfUser: this.data,
            },
        });
        await modal.present();
        const data = await modal.onDidDismiss();
        if (data) this.updateList.emit(true);
    }
}
