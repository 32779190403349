import { SortOrder } from '../../common/entities/paginated-response';

export enum AnnouncementSortProperties {
    UUID = 'UUID',
    TITLE = 'TITLE',
    TEXT_BODY = 'TEXT_BODY',
    IS_ACTIVE = 'IS_ACTIVE',
    CREATED_AT = 'CREATED_AT',
    UPDATED_AT = 'UPDATED_AT',
    CREATOR = 'CREATOR_USERNAME',
    PUBLISH_START_DATE = 'PUBLISH_START_DATE',
    PUBLISH_END_DATE = 'PUBLISH_END_DATE',
}
export interface SearchAnnouncementOptions {
    offset?: number;
    limit?: number;
    sortBy?: AnnouncementSortProperties;
    sortOrder?: SortOrder;
    tagUuids?: string[];
    filterByIsActive?: boolean;
}
