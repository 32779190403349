import { Pipe, PipeTransform } from '@angular/core';
import { PageConfig } from '../../config/entities';
import { CurafidaFeatureEnum } from '../../config/entities/base-page-config';

@Pipe({
    standalone: true,
    name: 'DicoMenuFilterPipe',
})
export class DicoMenuFilterPipe implements PipeTransform {
    transform(menuItems: PageConfig[]): any {
        return menuItems?.filter((i) => i.module === CurafidaFeatureEnum.dico);
    }
}
