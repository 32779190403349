import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
export class ValidatorService {
    static isNumber(control: FormControl): any {
        if (isNaN(control.value)) {
            return {
                'not a number': true,
            };
        }

        if (control.value > 120) {
            return {
                'not realistic': true,
            };
        }

        return null;
    }

    static isInteger(control: FormControl): any {
        if (isNaN(control.value)) {
            return {
                'not a number': true,
            };
        }

        if (control.value % 1 !== 0) {
            return {
                'not a whole number': true,
            };
        }

        if (control.value > 200) {
            return {
                'not realistic': true,
            };
        }

        return null;
    }
}
