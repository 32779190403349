import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { JitsiComponent } from './components/jitsi.component';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        component: JitsiComponent,
    },
];

@NgModule({
    declarations: [JitsiComponent],
    imports: [IonicModule, FormsModule, CommonModule, RouterModule.forChild(routes)],
    exports: [JitsiComponent, RouterModule],
})
export class JitsiModule {}
