import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Content } from '../../../therapy/entities/content';
import { AnnouncementResource } from '../../../announcement/entities/annoucement.ressource';
import { PaginatedResponse } from '../../../common/entities/paginated-response';
import { UsersPreferencesService } from '../../../user/services/user-preferences';
import { AnnouncementSetting } from '../../../user/entities/user-preferences/user-preferences';
import { KioskAnnouncementContentModalComponent } from '../kiosk-action-element-modal copy/kiosk-announcement-content-modal.component';

@Component({
    selector: 'lib-kiosk-announcements-modal',
    templateUrl: './kiosk-announcements-modal.component.html',
    styleUrls: ['./kiosk-announcements-modal.component.scss'],
})
export class KioskAnnouncementsModalComponent implements OnInit {
    title: string;
    announcements: PaginatedResponse<AnnouncementResource[]>;
    announcementIndex = 0;
    Content = Content;

    constructor(
        private modalCtrl: ModalController,
        private usersPreferencesService: UsersPreferencesService,
    ) {}

    async ngOnInit(): Promise<void> {
        if (!this.usersPreferencesService.userPreferences.frontendSettings.announcementsSetting) {
            this.usersPreferencesService.userPreferences.frontendSettings.announcementsSetting =
                new AnnouncementSetting();
        }
        if (!this.usersPreferencesService.userPreferences.frontendSettings.announcementsSetting.readUuid) {
            this.usersPreferencesService.userPreferences.frontendSettings.announcementsSetting.readUuid = [];
        }
        this.updateReadAnnouncementList();
    }

    updateReadAnnouncementList() {
        const readAnnouncementUuids =
            this.usersPreferencesService.userPreferences.frontendSettings.announcementsSetting?.readUuid;
        this.announcements.items
            .map((item) => item.uuid)
            .forEach((announcementUuid) => {
                if (!readAnnouncementUuids.includes(announcementUuid)) {
                    readAnnouncementUuids.push(announcementUuid);
                }
            });
        this.usersPreferencesService.userPreferences.frontendSettings.announcementsSetting.readUuid =
            readAnnouncementUuids;
        this.usersPreferencesService.update();
    }

    async dismissModal(): Promise<void> {
        await this.modalCtrl.dismiss();
    }

    async getPreviousMessage(): Promise<void> {
        this.announcementIndex--;
    }

    async getNextMessage(): Promise<void> {
        this.announcementIndex++;
    }

    async openContent(content: Content) {
        const modal = await this.modalCtrl.create({
            component: KioskAnnouncementContentModalComponent,
            cssClass: 'full-screen-modal',
            componentProps: {
                content: content,
            },
        });
        return await modal.present();
    }
}
