import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RoutingSegment } from '../../../common/entities/routing-segment';
import {
    ActionEmitter,
    ActionType,
    ButtonItemAdapterComponent,
    DateFormat,
    ItemType,
    TableUpdateValue,
} from '../../../table/entities/table';
import { User, UserRoles } from '../../../auth/entities/user';
import { PaginatedResponse, SortBy, SortOrder } from '../../../common/entities/paginated-response';
import { UsersService } from '../../services/user';
import { ToastService } from '../../../common/services/toast-service/toast-service.service';
import { IonicColor } from '../../../common/entities/toast/ionic-color';
import { CurafidaAuthService } from '../../../auth/services';
import { SupervisorService } from '../../services/supervisor';
import { MyMedaxPatientFormModalComponent } from '../../../my-medax/components/my-medax-patient-form-modal/my-medax-patient-form-modal.component';
import { ModalController } from '@ionic/angular';
import { ConfigService } from '../../../config/services';
import { ToolbarService } from '../../../common/services/toolbar/toolbar.service';
import { Logger, LoggingService } from '../../../logging/logging.service';
import { LoadingService } from '../../../common/services/loading/loading.service';
import { ModalAlertService } from '../../../common/services/modal';
import { ModuleLabel, ModuleName, PatientDataExportService } from '../../services/patient-data-export.service';
import {
    CheckboxListConfig,
    CheckboxListModalComponent,
} from '../../../common/components/checkbox-list-modal/checkbox-list-modal.component';
import { StyleService } from '../../../common/services/style/style.service';
import { UserTagListModalComponent } from '../user-tag-list-modal/user-tag-list-modal.component';
import { UserTag } from '../../entities/user-tag/user-tag.entity';
import { FrontendUser, UserBaseList } from '../../pages/user-management/user-base-list';
import { CurafidaSegmentItem } from '../../../common/entities/curafida-segment.item';
import { SegmentType } from '../../../common/entities/segment.type';
import { ButtonDefinition } from '../../../common/entities/buttons-list-toolbar';
import { CaregiverService } from '../../services/caregiver/caregiver.service';
import { StringItemAdapterComponent } from '../../../table/components/table-adapter/string-item-adapter.component';
import { TagsAdapterComponent } from '../../../therapy/components/table-adapter/tags-adapter.component';
import { ActionButton, ActionItemType } from '../../../table/entities/action-menu.item';
import { PatientCardComponent } from '../patient-card/patient-card.component';
import { FilterSettingItem } from '../../entities/user-preferences/user-preferences';
import { UsersPreferencesService } from '../../services/user-preferences';
import { UserTagsService } from '../../services/user-tags/user-tags.service';
import { FullNamePipe } from '../../full-name.pipe';
import { Observable, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

export class PatientListFilterSetting extends FilterSettingItem {
    tags: UserTag[];
    patientsListType: SegmentType;
    patientsListStatus: SegmentType;
}

@Component({
    selector: `lib-patient-list-component`,
    templateUrl: './patient-list.component.html',
    styleUrls: ['./patient-list.component.scss'],
})
export class PatientListComponent extends UserBaseList implements OnInit {
    title = 'Patientenliste';
    isMobile: boolean;
    patientsListType: SegmentType;
    patientsListStatus: SegmentType;
    segmentPatientCared: CurafidaSegmentItem<SegmentType>[] = [];
    buttonsToolbar: ButtonDefinition[] = [];
    segmentPatientStatus: CurafidaSegmentItem<SegmentType>[] = [];
    PatientCard = PatientCardComponent;
    readonly patientListFilterSetting: PatientListFilterSetting;
    protected readonly log: Logger;
    private getPatientList$: Observable<PaginatedResponse<User[]>>;

    constructor(
        public usersService: UsersService,
        public toastService: ToastService,
        private router: Router,
        public authService: CurafidaAuthService,
        public supervisorService: SupervisorService,
        public configService: ConfigService,
        private modalCtrl: ModalController,
        private toolbarService: ToolbarService,
        public loggingService: LoggingService,
        public loadingService: LoadingService,
        public modalAlertService: ModalAlertService,
        private patientDataExportService: PatientDataExportService,
        private styleService: StyleService,
        private modalController: ModalController,
        public caregiverService: CaregiverService,
        private usersPreferencesService: UsersPreferencesService,
        private userTagsService: UserTagsService,
        private translateService: TranslateService,
        fullNamePipe: FullNamePipe,
    ) {
        super(
            usersService,
            toastService,
            authService,
            supervisorService,
            configService,
            loggingService,
            loadingService,
            modalAlertService,
            caregiverService,
            fullNamePipe,
        );
        this.log = this.loggingService.getLogger(this.constructor.name);
        this.isMobile = this.styleService.isMobile$;
        this.initPatientListTableConfig();
        this.patientListFilterSetting =
            this.usersPreferencesService.userPreferences.filterSettings?.filterSettingItem?.find(
                (i) => i.name === this.constructor.name,
            ) as PatientListFilterSetting;

        if (this.patientListFilterSetting) {
            this.patientsListType = this.patientListFilterSetting.patientsListType ?? SegmentType.MY;
            this.patientsListStatus = this.patientListFilterSetting.patientsListStatus ?? SegmentType.ALL;
        } else {
            this.patientListFilterSetting = new PatientListFilterSetting(this.constructor.name);
        }
        if (!this.patientListFilterSetting?.tags) {
            this.patientListFilterSetting.tags = [];
        } else {
            this.checkIfSaveTagExisting().then(() => {
                return;
            });
        }
    }

    async checkIfSaveTagExisting(): Promise<void> {
        let isModified = false;
        const tagCategories = await this.userTagsService.getUserTagCategories({
            userRole: UserRoles.PATIENT,
        });
        let tagFromServer = [];
        for (const tags of tagCategories.items) {
            tagFromServer = tagFromServer.concat(tags.tags);
        }
        for (const savedTag of this.patientListFilterSetting.tags) {
            if (!tagFromServer.find((i) => i.uuid === savedTag.uuid) && !savedTag.uuid === null) {
                this.patientListFilterSetting.tags = this.patientListFilterSetting.tags.filter(
                    (i) => i.uuid !== savedTag.uuid,
                );
                isModified = true;
            }
        }
        if (isModified) {
            await this.updatePatientListFilterSetting();
        }
    }

    async updatePatientListFilterSetting(): Promise<void> {
        await this.usersPreferencesService.updateFilterSettingItem(this.patientListFilterSetting);
    }

    async ngOnInit(): Promise<void> {
        this.segmentPatientCared = [];
        if (
            !(
                this.loggedInUser.roles.includes(UserRoles.SUPERVISOR) ||
                this.loggedInUser.roles.includes(UserRoles.ANALYST)
            )
        ) {
            this.segmentPatientCared.push(new CurafidaSegmentItem({ name: 'Meine', value: SegmentType.MY }));
            this.segmentPatientCared.push(new CurafidaSegmentItem({ name: 'Alle', value: SegmentType.ALL }));
        }
        this.buttonsToolbar = [];
        this.buttonsToolbar.push(new ButtonDefinition('export', 'download-outline'));
        this.toolbarService.setTitle(this.title);
        this.initPatientStatusSegments();
        await this.getPatientList(this.patientsListType, { offset: this.patientOffset });
    }

    initPatientStatusSegments(): void {
        this.segmentPatientStatus = [
            new CurafidaSegmentItem({ name: 'PATIENT.FILTER.ALL', value: SegmentType.ALL }),
            new CurafidaSegmentItem({ name: 'PATIENT.FILTER.PASSIVE', value: SegmentType.PASSIVE }),
            new CurafidaSegmentItem({ name: 'PATIENT.FILTER.REGISTERED', value: SegmentType.REGISTERED }),
            new CurafidaSegmentItem({ name: 'PATIENT.FILTER.ACTIVE', value: SegmentType.ACTIVE }),
            new CurafidaSegmentItem({ name: 'PATIENT.FILTER.INACTIVE', value: SegmentType.INACTIVE }),
            new CurafidaSegmentItem({ name: 'PATIENT.FILTER.HAS_THERAPY', value: SegmentType.HAS_THERAPY }),
            new CurafidaSegmentItem({ name: 'PATIENT.FILTER.FINISHED_THERAPIES', value: SegmentType.FINISHED }),
            new CurafidaSegmentItem({ name: 'PATIENT.FILTER.DISABLED', value: SegmentType.DISABLED }),
        ];
    }

    initPatientListTableConfig(): void {
        (this.patientsListConfig.emptyListLabel = 'USER.PATIENT.ANY_ITEM'),
            (this.patientsListConfig.isOpenDetailEnable = true);
        const fullNameTableItem = {
            id: 'fullName',
            prop: 'fullName',
            header: 'Patient',
            type: ItemType.ADAPTER,
            adapter: StringItemAdapterComponent,
            width: '20%',
            sortBy: SortBy.LASTNAME,
            sortOrderMobile: 0,
            sortOrderWeb: 0,
            isMobileBold: true,
            sortOrder: SortOrder.ASC,
        };

        const birthdateTableItem = {
            id: 'birthdate',
            prop: 'birthdate',
            header: 'BIRTH_DATE',
            adapter: StringItemAdapterComponent,
            format: DateFormat.DATE,
            type: ItemType.ADAPTER,
            width: '18%',
            sortBy: SortBy.BIRTHDATE,
            sortOrderWeb: 1,
        };

        const updateTimeTableItem = {
            id: 'updated_at',
            prop: 'lastActivityDate',
            header: 'Änderungs-\ndatum',
            type: ItemType.ADAPTER,
            adapter: StringItemAdapterComponent,
            format: DateFormat.DATE_AND_HOUR_2_LINES,
            sortBy: SortBy.LAST_ACTIVITY_DATE,
            width: '15%',
            sortOrderWeb: 2,
        };

        const tagTableItem = {
            id: 'tags',
            prop: 'tags',
            header: 'TAG.PLURAL',
            adapter: TagsAdapterComponent,
            type: ItemType.ADAPTER,
            width: '39%',
            sortOrderWeb: 3,
        };
        const popoverTableItem = {
            prop: '',
            header: '',
            type: ItemType.ADAPTER,
            adapter: ButtonItemAdapterComponent,
            actionType: ActionType.POPOVER,
            fill: 'clear',
            icon: 'ellipsis-vertical',
            id: 'action_open',
            width: '8%',
            sortOrderWeb: 4,
            isMobileButton: true,
        };

        this.patientsListConfig.itemSettings = [
            fullNameTableItem,
            birthdateTableItem,
            updateTimeTableItem,
            tagTableItem,
            popoverTableItem,
        ];
    }

    async openPatientDetailPage(patient: User): Promise<void> {
        await this.router.navigate([
            RoutingSegment.MEMBER,
            RoutingSegment.PATIENT_MANAGEMENT,
            RoutingSegment.DETAIL,
            patient.username,
        ]);
    }

    async openFilterModal(): Promise<void> {
        const modal = await this.modalController.create({
            component: UserTagListModalComponent,
            cssClass: 'modal-tag-list-css',
            componentProps: {
                title: 'Filter',
                tags: this.patientListFilterSetting.tags,
                userRoles: UserRoles.PATIENT,
                dismissText: 'Speichern',
                clearSelectedTagText: 'Verwerfen',
                enableFilterOptions: true,
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data) {
            this.patientListFilterSetting.tags = data as UserTag[];
            await this.getPatientList(this.patientsListType, { offset: 0 }, this.patientSearchTerm, true);
        }
    }

    async performActionByType(value: ActionEmitter<FrontendUser>): Promise<void> {
        if (value.actionType) {
            if (value.actionType === ActionType.MODAL) {
                const modal = await this.modalCtrl.create({
                    component: MyMedaxPatientFormModalComponent,
                    cssClass: 'full-width-modal',
                    componentProps: {
                        user: value.item,
                    },
                });
                await modal.present();
            } else if (value.actionType === ActionType.OPEN_NEW_PAGE) {
                await this.openPatientDetailPage(value.item);
            } else if (value.actionType === ActionType.DELETE) {
                await this.deleteCurrentUser(value.item, UserRoles.PATIENT);
                await this.getPatientList(
                    this.patientsListType,
                    { offset: this.patientOffset },
                    this.patientSearchTerm,
                );
            }
        }
    }

    async setActionButton(type: string): Promise<void> {
        if (type === 'add') {
            await this.openNewPatientDetailPage();
        }
        if (type === 'export') {
            await this.openDataExportModal();
        }
    }

    async openNewPatientDetailPage(): Promise<void> {
        await this.router.navigate([
            RoutingSegment.MEMBER,
            RoutingSegment.PATIENT_MANAGEMENT,
            RoutingSegment.DETAIL,
            'new',
        ]);
    }

    async sortPatientList(event: { sortBy: SortBy | string; sortOrder?: SortOrder }): Promise<void> {
        if (event.sortOrder === null) {
            this.sortPatientList({ sortBy: SortBy.LASTNAME, sortOrder: SortOrder.ASC });
        } else {
            this.patientSortBy = event.sortBy as SortBy;
            this.patientSortOrder = event.sortOrder;
            try {
                await this.getPatientList(this.patientsListType, { offset: 0 }, this.patientSearchTerm);
            } catch (e) {
                this.log.error('Error in sortPatientList', e);
                await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
                this.patientsListConfig.list = new PaginatedResponse<FrontendUser[]>();
            }
        }
    }

    async openDataExportModal(): Promise<void> {
        const availableModules = [];
        if (this.loggedInUser.roles.includes(UserRoles.read_user)) {
            availableModules.push({
                name: ModuleName.USER_MASTER_DATA,
                label: ModuleLabel.USER_MASTER_DATA,
                selected: true,
                disabled: false,
            });
        }
        if (
            this.configService.config.features.anamnese?.enabled &&
            this.loggedInUser.roles.includes(UserRoles.manage_medical_history)
        ) {
            availableModules.push({
                name: ModuleName.MEDICAL_HISTORY,
                label: ModuleLabel.MEDICAL_HISTORY,
                selected: false,
                disabled: false,
            });
        }
        if (this.configService.config.features.chat?.enabled) {
            availableModules.push({ name: ModuleName.CHAT, label: ModuleLabel.CHAT, selected: false, disabled: true });
        }
        if (this.configService.config.features.task?.enabled) {
            availableModules.push({ name: ModuleName.TASK, label: ModuleLabel.TASK, selected: false, disabled: true });
        }
        if (this.configService.config.features.consultation?.enabled) {
            availableModules.push({
                name: ModuleName.CONSULTATION,
                label: ModuleLabel.CONSULTATION,
                selected: false,
                disabled: true,
            });
        }
        if (this.configService.config.features.order?.enabled) {
            availableModules.push({
                name: ModuleName.ORDER,
                label: ModuleLabel.ORDER,
                selected: false,
                disabled: true,
            });
        }
        if (this.configService.config.features.screening?.enabled) {
            availableModules.push({
                name: ModuleName.SCREENING,
                label: ModuleLabel.SCREENING,
                selected: false,
                disabled: true,
            });
        }
        if (this.configService.config.features.measurement?.enabled) {
            availableModules.push({
                name: ModuleName.MEASUREMENT,
                label: ModuleLabel.MEASUREMENT,
                selected: false,
                disabled: true,
            });
        }
        if (this.configService.config.features.training?.enabled) {
            availableModules.push({
                name: ModuleName.TRAINING,
                label: ModuleLabel.TRAINING,
                selected: false,
                disabled: true,
            });
        }
        if (this.configService.config.features.learning?.enabled) {
            availableModules.push({
                name: ModuleName.LEARNING,
                label: ModuleLabel.LEARNING,
                selected: false,
                disabled: true,
            });
        }
        if (this.configService.config.features.medication?.enabled) {
            availableModules.push({
                name: ModuleName.MEDICATION,
                label: ModuleLabel.MEDICATION,
                selected: false,
                disabled: true,
            });
        }
        if (this.configService.config.features.note?.enabled) {
            availableModules.push({ name: ModuleName.NOTE, label: ModuleLabel.NOTE, selected: false, disabled: true });
        }
        if (this.configService.config.features.log?.enabled) {
            availableModules.push({
                name: ModuleName.LOGBOOK,
                label: ModuleLabel.LOGBOOK,
                selected: false,
                disabled: true,
            });
        }
        const listConfig: CheckboxListConfig = {
            availableItemList: availableModules,
            splitListInColumns: true,
            listHeader: 'Datenkategorien',
            emptyListText: 'Keine Exportkategorien vorhanden',
        };
        const modal = await this.modalCtrl.create({
            component: CheckboxListModalComponent,
            cssClass: 'full-width-modal',
            componentProps: {
                title: 'Daten exportieren',
                titleIcon: 'warning-outline',
                modalBodyText: 'Bitte wählen Sie aus, welche Daten Sie exportieren möchten.',
                listConfigs: [listConfig],
                confirmButtonText: 'Daten exportieren',
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data && data.value.length > 0) {
            await this.exportPatientData(data.value);
        }
    }

    async exportPatientData(modules: ModuleName[]): Promise<void> {
        const groupPaths = this.usersService.getHighestHierarchyGroupPaths(this.loggedInUser.groups);
        try {
            for (const group of groupPaths) {
                await this.patientDataExportService.getPatientDataExport(
                    group,
                    modules,
                    true,
                    UserRoles.PATIENT,
                    null,
                    this.patientSearchTerm,
                );
            }
        } catch (e) {
            this.log.error('Error in exportPatientData', e);
            await this.toastService.showToast(
                'Beim Exportieren der Daten ist ein Fehler aufgetreten',
                IonicColor.danger,
            );
        }
    }

    async getPatientList(
        patientsListType: SegmentType,
        value: TableUpdateValue,
        searchTerm?: string,
        hasNewPreferencesValue = false,
    ): Promise<void> {
        if (value.sortOrder === null) {
            value.sortBy = SortBy.LASTNAME;
            value.sortOrder = SortOrder.ASC;
        } else {
            this.patientSortBy = value?.sortBy as SortBy;
            this.patientSortOrder = value?.sortOrder;
        }
        this.isLoadingSuccess = false;
        this.isLoading = true;
        if (value.offset != 0) {
            this.patientOffset = value.offset;
        }
        if (searchTerm) this.patientSearchTerm = searchTerm;
        this.patientsListType = patientsListType;
        if (hasNewPreferencesValue) await this.updatePatientListFilterSetting();
        this.subscriptions?.forEach((subscription) => subscription.unsubscribe());

        if (this.loggedInUser.roles.includes(UserRoles.SUPERVISOR)) {
            this.getPatientList$ = this.supervisorService.getSupervisedPatients$(this.newSearchParams);
        } else if (patientsListType === SegmentType.ALL || this.loggedInUser.roles.includes(UserRoles.ANALYST)) {
            await this.usersService
                .getUsers$(this.newSearchParams)
                .then((getUsers$) => (this.getPatientList$ = getUsers$));
        } else if (patientsListType === SegmentType.MY) {
            this.getPatientList$ = this.caregiverService.fetchCaredPatients$(this.newSearchParams);
        } else {
            this.getPatientList$ = throwError(new Error(this.translateService.instant('USER.ERROR.FETCH_LIST')));
        }

        this.subscriptions = [
            this.getPatientList$.subscribe({
                next: (patientList) => {
                    this.patientsListConfig.list = patientList as PaginatedResponse<FrontendUser[]>;
                    this.patientsListConfig.list.items.forEach((frontendUser) => {
                        frontendUser.fullName = this.fullNamePipe.transform(frontendUser, true);
                        frontendUser.actions = [];
                        if (this.configService.config.isUsingMyMedax) {
                            frontendUser.actions.push(
                                new ActionButton(ActionItemType.BUTTON, 'Formular ausfüllen', ActionType.MODAL),
                            );
                        }
                        if (this.loggedInUser.roles.includes(UserRoles.delete_patient)) {
                            frontendUser.actions.push(
                                new ActionButton(
                                    ActionItemType.BUTTON,
                                    'Patientenkonto löschen',
                                    ActionType.DELETE,
                                    null,
                                    null,
                                    IonicColor.danger,
                                ),
                            );
                        }
                    });
                    this.isLoadingSuccess = true;
                    this.isLoading = false;
                },
                error: (error) => {
                    this.log.error('Error in getPatientList', error);
                    this.toastService.showToast(error.message, IonicColor.danger);
                    this.patientsListConfig.list = new PaginatedResponse<FrontendUser[]>();
                    this.patientsListConfig.list.items = [];
                    this.isLoadingSuccess = false;
                    this.isLoading = false;
                },
            }),
        ];
        this.newSearchParams.emit({
            username: this.loggedInUser.username,

            offset: value.offset * this.limit,
            limit: this.limit,
            filter: this.patientSearchTerm,
            sortOrder: this.patientSortOrder,
            sortBy: this.patientSortBy,
            tagUuids: this.patientListFilterSetting.tags?.map((i) => i.uuid),
            withTags: true,
            withLastActivityDate: true,
            ...this.getPatientListStatusArgs(this.patientsListStatus),
        });
    }

    async setSegmentTypeStatus(segmentType: SegmentType): Promise<void> {
        this.patientsListStatus = segmentType;
        this.patientListFilterSetting.patientsListStatus = this.patientsListStatus;
        return await this.getPatientList(this.patientsListType, { offset: 0 }, this.patientSearchTerm, true);
    }

    async setSegmentType(segmentType: SegmentType): Promise<void> {
        this.patientsListType = segmentType;
        this.patientListFilterSetting.patientsListType = this.patientsListType;
        return await this.getPatientList(this.patientsListType, { offset: 0 }, this.patientSearchTerm, true);
    }
}
