// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flex-container {
  display: flex;
  flex-flow: nowrap;
  justify-content: center;
  align-content: center;
  padding: 0;
  margin: 0;
  list-style: none;
}

.flex-item {
  width: 9%;
  height: 120px;
  margin-top: 10px;
  padding: 5px;
  text-align: center;
}

.selected {
  --border-width: 5px;
  --border-style: solid;
  --border-color: black;
}

.borg-button {
  font-size: 20px;
  color: black;
}

span {
  font-size: 20px;
}

ion-button {
  width: 100%;
  height: 130px;
  --border-width: 1px;
}`, "",{"version":3,"sources":["webpack://./../../libs/kiosk/components/kiosk-feedback-borg-modal/kiosk-feedback-borg-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,iBAAA;EACA,uBAAA;EACA,qBAAA;EAEA,UAAA;EACA,SAAA;EACA,gBAAA;AAAJ;;AAGE;EACE,SAAA;EACA,aAAA;EACA,gBAAA;EACA,YAAA;EACA,kBAAA;AAAJ;;AAGE;EACE,mBAAA;EACA,qBAAA;EACA,qBAAA;AAAJ;;AAGE;EACE,eAAA;EACA,YAAA;AAAJ;;AAGE;EACE,eAAA;AAAJ;;AAGE;EACE,WAAA;EACA,aAAA;EACA,mBAAA;AAAJ","sourcesContent":[".flex-container {\n    display: flex;\n    flex-flow: nowrap;\n    justify-content: center;\n    align-content: center;\n    \n    padding: 0;\n    margin: 0;\n    list-style: none;\n  }\n  \n  .flex-item {\n    width: 9%;\n    height: 120px;\n    margin-top: 10px;\n    padding: 5px;\n    text-align: center;\n  }\n\n  .selected{\n    --border-width: 5px;\n    --border-style: solid;\n    --border-color: black;\n  }\n\n  .borg-button{\n    font-size: 20px; \n    color: black;\n  }\n  \n  span{\n    font-size: 20px;\n  }\n\n  ion-button {\n    width: 100%;\n    height: 130px;\n    --border-width: 1px;\n  }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
