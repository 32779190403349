import { Component, Input, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CurafidaInputComponent } from '../../../common/components/curafida-input/curafida-input';
import { Logger } from 'loglevel';
import { ToastService } from '../../../common/services/toast-service/toast-service.service';
import { LoggingService } from '../../../logging/logging.service';
import { TagListModalComponent } from '../modal/tag-list-modal/tag-list-modal.component';
import { ExerciseType } from '../../entities/exerciseSession';
import { Tag } from '../../entities/tag/tag.entity';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'curafida-tags-input',
    templateUrl: './curafida-tags-input.component.html',
    styleUrls: ['./curafida-tags-input.component.scss'],
})
export class CurafidaTagsInputComponent extends CurafidaInputComponent {
    protected readonly log: Logger;
    @Input()
    exerciseType = ExerciseType.TASK;
    @Input()
    titleControlName?: string;
    @Input()
    hideResetButton: boolean;

    constructor(
        protected modalCtrl: ModalController,
        protected toastService: ToastService,
        private loggingService: LoggingService,
        private modalController: ModalController,
        @Self()
        @Optional()
        public ngControl: NgControl,
        public translateService: TranslateService,
    ) {
        super(ngControl, translateService);
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    async updateTagList() {
        if (!this.ngControl.disabled) {
            const modal = await this.modalController.create({
                component: TagListModalComponent,
                cssClass: 'modal-tag-list-css',
                componentProps: {
                    title: this.titleControlName
                        ? this.formGroup.controls[this.titleControlName].value
                        : this.formGroup.controls.title.value,
                    exerciseTags: this.formGroup.controls[this.formControlName].value,
                    exerciseType: this.exerciseType,
                    hideResetButton: this.hideResetButton,
                },
            });
            await modal.present();
            const { data } = await modal.onDidDismiss();
            if (data) {
                this.formGroup.controls[this.formControlName].setValue(data);
                this.formGroup.controls[this.formControlName].markAsDirty();
                this.formGroup.markAsDirty();
            }
        }
    }

    removeTag(tag: Tag) {
        this.formGroup.controls[this.formControlName].setValue(
            this.formGroup.controls[this.formControlName].value.filter((i) => i.uuid !== tag.uuid),
        );
        this.formGroup.markAsDirty();
    }
}
