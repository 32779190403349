import { RoutingSegment } from '../../common/entities/routing-segment';
import { ExerciseToolSetting, ExerciseToolSettings, ExerciseType } from '../../therapy/entities/exerciseSession';
import { Injectable } from '@angular/core';
import { Logger, LoggingService } from '../../logging/logging.service';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { JitsiService } from '../jitsi/jitsi.service';
import { MedflexService } from '../medflex/medflex.service';
import { BrowserNavigationService } from '../../common/services/browser-navigation/browser-navigation.service';
import { ApiService } from '../../api';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../common/services/toast-service/toast-service.service';
import { IonicColor } from '../../common/entities/toast/ionic-color';
import { HypermediaResource } from '../../hateoas/hateoas.model';

@Injectable({ providedIn: 'root' })
export class VideoConferenceService {
    private readonly log: Logger;

    constructor(
        private loggingService: LoggingService,
        private router: Router,
        private modalCtrl: ModalController,
        private readonly jitsiService: JitsiService,
        private readonly medflexService: MedflexService,
        private browser: BrowserNavigationService,
        private readonly http: HttpClient,
        private readonly translateService: TranslateService,
        private readonly toastService: ToastService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    isVideoConferenceUrl(url: string): boolean {
        return url.startsWith(`/${RoutingSegment.MEMBER}/${RoutingSegment.VIDEO_CONFERENCE}/`);
    }

    joinVideoConference(
        resource: { id: number; exerciseToolSettings?: ExerciseToolSettings } & HypermediaResource,
        exerciseType: ExerciseType,
    ): void {
        const tools = resource.exerciseToolSettings.enabledTools;
        if (!(tools?.length > 0)) {
            this.log.error('No video conference tools configured in this exercise session!');
            return;
        }

        const id = resource.id;
        const tool = tools[0];
        switch (tool.name) {
            case 'MEDFLEX': {
                if (!this.isVideoConferenceUrl(this.router.url)) {
                    this.medflexService.rememberUrl(this.router.url);
                }
                this.router
                    .navigateByUrl(
                        `/${RoutingSegment.MEMBER}/${RoutingSegment.VIDEO_CONFERENCE}/${RoutingSegment.EXERCISE_SESSIONS}/${id}/${exerciseType}/medflex`,
                    )
                    .catch(this.log.error);
                break;
            }
            case 'JITSI': {
                if (!this.isVideoConferenceUrl(this.router.url)) {
                    this.jitsiService.rememberUrl(this.router.url);
                }
                this.router
                    .navigateByUrl(
                        `/${RoutingSegment.MEMBER}/${RoutingSegment.VIDEO_CONFERENCE}/${RoutingSegment.EXERCISE_SESSIONS}/${id}/${exerciseType}/jitsi`,
                    )
                    .catch(this.log.error);
                break;
            }
            case 'ALFAVIEW': {
                this.http
                    .get<ExerciseToolSetting>(
                        `${ApiService.url}${resource._links.videoConferenceTool.href}`,
                        ApiService.options,
                    )
                    .subscribe({
                        next: (it) => this.browser.openTargetSystem(it.config.joinUrl),
                        error: (it) => {
                            this.log.error(it);
                            if (it.error?.message) {
                                this.toastService.showToast(
                                    this.translateService.instant(it.error.message),
                                    IonicColor.danger,
                                );
                            }
                        },
                    });
                break;
            }
            case 'VIDEO_CONFERENCE_EXTERNAL_URL': {
                this.browser.openTargetBlank(tool.config.url);
                break;
            }
            default: {
                this.log.error(`Tool name ${tool.name} not registered!`);
            }
        }
        this.modalCtrl.dismiss().catch(this.log.error);
    }
}
