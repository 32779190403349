// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-size {
  font-size: 35px;
}

.img {
  border: 0;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 10%;
}

.image {
  flex-shrink: 0;
}`, "",{"version":3,"sources":["webpack://./../../libs/therapy/components/exercise-contents-preview/exercise-content-preview.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;;AAEA;EACI,SAAA;EACA,YAAA;EACA,SAAA;EACA,UAAA;EACA,UAAA;AACJ;;AAEA;EACI,cAAA;AACJ","sourcesContent":[".icon-size {\n    font-size: 35px;\n}\n\n.img {\n    border: 0;\n    height: 100%;\n    margin: 0;\n    padding: 0;\n    width: 10%;\n}\n\n.image {\n    flex-shrink: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
