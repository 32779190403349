// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-flow: row;
  flex: 1 1 auto;
}

#jitsi-iframe {
  width: 100%;
  height: 100%;
}

.hang-up-button {
  width: 10rem;
  cursor: pointer;
  background-color: #650000;
}
.hang-up-button .big {
  font-size: 7rem;
}`, "",{"version":3,"sources":["webpack://./../../libs/video-conference/jitsi/components/jitsi.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,cAAA;EACA,cAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;AACJ;;AAEA;EACI,YAAA;EACA,eAAA;EACA,yBAAA;AACJ;AACI;EACI,eAAA;AACR","sourcesContent":[":host {\n    display: flex;\n    flex-flow: row;\n    flex: 1 1 auto;\n}\n\n#jitsi-iframe {\n    width: 100%;\n    height: 100%;\n}\n\n.hang-up-button {\n    width: 10rem;\n    cursor: pointer;\n    background-color: #650000;\n\n    .big {\n        font-size: 7rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
