import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ItemType, TableConfig, TableItem, TableUpdateValue } from '../../../../table/entities/table';
import { ToastService } from '../../../../common/services/toast-service/toast-service.service';
import { IonicColor } from '../../../../common/entities/toast/ionic-color';
import { TherapyTemplate } from '../../../entities/therapy/therapy-template';
import { TherapyTemplatesService } from '../../../services/therapy-templates';
import { SortBy, SortOrder } from '../../../../common/entities/paginated-response';
import { ExerciseType } from '../../../entities/exerciseSession';
import { StringItemAdapterComponent } from '../../../../table/components/table-adapter/string-item-adapter.component';
import { CheckBoxItemAdapterComponent } from '../../../../table/components/table-adapter/checkbox-item-adapter.component';
import { CommonComponentsModule } from '../../../../common/components/common-components.module';
import { TableModule } from '../../../../table/table.module';
import { Logger, LoggingService } from '../../../../logging/logging.service';
import { CurafidaTableComponent } from '../../../../table/components/curafida-table/curafida-table.component';

@Component({
    selector: 'lib-therapy-template-list-modal',
    templateUrl: './therapy-template-list-modal.component.html',
    styleUrls: ['./therapy-template-list-modal.component.scss'],
    standalone: true,
    imports: [CommonComponentsModule, TableModule, CurafidaTableComponent],
})
export class TherapyTemplateListModalComponent implements OnInit {
    title: string;
    selectedTherapyTemplates: TherapyTemplate[] = [];
    therapyTemplateListConfig: TableConfig<TherapyTemplate[]> = new TableConfig();
    searchTerm: string;
    isMultipleChoice = false;
    anyItem: string;
    sortBy = SortBy.TITLE;
    sortOrder = SortOrder.ASC;
    exerciseType = ExerciseType.COURSE;
    tableItems: TableItem[];
    showOldSelectedExercise = false;
    private limit = 10;
    addToSelectedTherapyTemplates: TherapyTemplate[] = [];
    private readonly log: Logger;

    constructor(
        private modalCtrl: ModalController,
        private therapyTemplatesService: TherapyTemplatesService,
        private toastService: ToastService,
        private loggingService: LoggingService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
        this.therapyTemplateListConfig.isOpenDetailEnable = true;
        this.therapyTemplateListConfig.itemSettings = [
            {
                id: 'selected',
                prop: 'selected',
                header: '',
                adapter: CheckBoxItemAdapterComponent,
                type: ItemType.ADAPTER,
                width: '10%',
                sortOrderWeb: 0,
            },
            {
                id: 'title',
                prop: 'title',
                header: 'NAME',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '40%',
                sortBy: SortBy.TITLE,
                sortOrderWeb: 1,
            },
            {
                id: 'description',
                prop: 'description',
                header: 'DESCRIPTION',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '50%',
                sortBy: SortBy.DESCRIPTION,
                sortOrderWeb: 2,
            },
        ];
    }

    async ngOnInit() {
        if (this.tableItems) this.therapyTemplateListConfig.itemSettings = this.tableItems;
        if (!this.isMultipleChoice && this.addToSelectedTherapyTemplates.length > 1) {
            throw new Error(
                'If the single choice option is set, there cannot be more than one selected exercise as an input.',
            );
        }
        await this.initTherapyTemplateList({ offset: 0 }, this.searchTerm);
    }

    async initTherapyTemplateList(value: TableUpdateValue, searchTerm: string) {
        if (value.limit) this.limit = value.limit;
        this.sortBy = value?.sortBy as SortBy;
        this.sortOrder = value?.sortOrder;
        try {
            this.therapyTemplateListConfig.list = await this.therapyTemplatesService.getTherapyTemplates({
                offset: value.offset * this.limit,
                limit: this.limit,
                filter: searchTerm,
                sortOrder: this.sortOrder,
                sortBy: this.sortBy,
                exerciseType: this.exerciseType,
                disabled: false,
            });
            for (const exercise of this.therapyTemplateListConfig.list.items) {
                exercise.selected = !!this.selectedTherapyTemplates.find((e) => e.id === exercise.id);
            }
            if (this.showOldSelectedExercise === false) {
                const responseLength = this.therapyTemplateListConfig.list.items.length;
                this.therapyTemplateListConfig.list.items = this.therapyTemplateListConfig.list.items.filter(
                    (therapyTemplate) =>
                        !this.selectedTherapyTemplates?.some(
                            (selectedTemplate) => selectedTemplate?.id === therapyTemplate.id,
                        ),
                );
                const removedItemsCount = responseLength - this.therapyTemplateListConfig.list.items.length;
                this.therapyTemplateListConfig.list.count = this.therapyTemplateListConfig.list.items.length;
                this.therapyTemplateListConfig.list.total =
                    this.therapyTemplateListConfig.list.total - removedItemsCount;
            }
        } catch (error) {
            this.log.error(error);
            this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
        }
    }

    onActionOnItem(clickedTherapyTemplate: TherapyTemplate) {
        clickedTherapyTemplate.selected = !clickedTherapyTemplate.selected;
        if (clickedTherapyTemplate) {
            if (clickedTherapyTemplate.selected) {
                if (!this.isMultipleChoice) {
                    this.addToSelectedTherapyTemplates = [clickedTherapyTemplate];
                    for (const therapyTemplate of this.therapyTemplateListConfig.list.items) {
                        if (clickedTherapyTemplate.id !== therapyTemplate.id) therapyTemplate.selected = false;
                    }
                } else {
                    this.addToSelectedTherapyTemplates.push(clickedTherapyTemplate);
                }
            } else {
                if (!this.isMultipleChoice) {
                    this.addToSelectedTherapyTemplates = [];
                } else {
                    this.addToSelectedTherapyTemplates = this.addToSelectedTherapyTemplates.filter(
                        (e) => e.id !== clickedTherapyTemplate.id,
                    );
                }
            }
        }
    }

    async returnSelectedTherapyTemplate() {
        this.modalCtrl.dismiss(this.addToSelectedTherapyTemplates);
    }

    async sortTherapyTemplateList(event: { sortBy: SortBy; sortOrder?: SortOrder }) {
        this.sortBy = event.sortBy;
        this.sortOrder = event.sortOrder;
        await this.initTherapyTemplateList({ offset: 0 }, this.searchTerm);
    }
}
