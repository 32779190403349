// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-row {
  margin-bottom: 10px;
}

.left-col {
  font-weight: bold;
  width: 25%;
  margin-right: 25px;
}

.right-col {
  width: 75%;
}`, "",{"version":3,"sources":["webpack://./../../libs/common/components/offline-media-sync/offline-media-sync.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,UAAA;EACA,kBAAA;AACJ;;AAEA;EACI,UAAA;AACJ","sourcesContent":[".content-row {\n    margin-bottom: 10px;\n}\n\n.left-col {\n    font-weight: bold;\n    width: 25%;\n    margin-right: 25px;\n}\n\n.right-col {\n    width: 75%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
