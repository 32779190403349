import { Component, OnInit, ViewChild } from '@angular/core';
import { TutorialDefinition } from '../../../measurement/entities/tutorial-slide';
import { ModalController, NavParams } from '@ionic/angular';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

@Component({
    selector: 'loading-tutorial-modal',
    templateUrl: './loading-tutorial-modal.component.html',
    styleUrls: ['./loading-tutorial-modal.component.scss'],
})
export class LoadingTutorialModalComponent implements OnInit {
    tutorialDefinition: TutorialDefinition;
    title = '';

    config: SwiperOptions = {
        slidesPerView: 1,
        spaceBetween: 0,
        navigation: false,
        pagination: false,
    };
    @ViewChild('swiper') swiper: SwiperComponent;
    isBeginning = true;
    isEnd = false;
    currentIndex = 0;
    isTutorialChecked = false;

    constructor(
        private modalCtrl: ModalController,
        private params: NavParams,
    ) {}

    async ngOnInit() {
        this.tutorialDefinition = this.params.get('modalDefinition');
        if (this.tutorialDefinition) this.title = this.tutorialDefinition.title;
    }
}
