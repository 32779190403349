import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ChildExercise, Exercise } from '../../../entities/exercise';
import { TrainingContent, TrainingExercise } from '../../../entities/training/training';
import { Content, ContentMetaDataType } from '../../../entities/content';
import { PhysicalExerciseGoalDto } from '../../../entities/exerciseGoal/exerciseGoalDto';
import { ExercisesService } from '../../../services/exercises';
import { StyleService } from '../../../../common/services/style/style.service';

@Component({
    selector: 'lib-course-exercise-child-modal',
    templateUrl: './course-exercise-child-modal.component.html',
    styleUrls: ['./course-exercise-child-modal.component.scss'],
})
export class CourseExerciseChildModalComponent implements OnInit {
    @Input()
    exercise: Exercise;
    trainingExercises: TrainingExercise[];
    exerciseIndex = 0;
    areContentVisible = false;
    isMobile: boolean;

    constructor(
        private modalController: ModalController,
        private exerciseService: ExercisesService,
        private styleService: StyleService,
    ) {
        this.isMobile = this.styleService.isMobile$;
    }

    async initModal() {
        let index = 0;
        this.trainingExercises = [];
        for (const childExercise of this.exercise.childExercises) {
            const exercise = await this.exerciseService.getExerciseById(childExercise.childId);
            const trainingExercise = new TrainingExercise();
            trainingExercise.title = childExercise.childExercise.title;
            trainingExercise.id = childExercise.childId;
            trainingExercise.order = index;
            if (
                exercise.contents?.filter((i) => i.jsonData?.contentMetaDataType !== ContentMetaDataType.THUMBNAIL)
                    .length > 0
            ) {
                await this.setExerciseContent(
                    exercise.contents
                        .filter((i) => i.jsonData !== null)
                        .filter((i) => i.jsonData?.contentMetaDataType !== ContentMetaDataType.THUMBNAIL),
                    trainingExercise,
                );
            }
            trainingExercise.exerciseGoal = childExercise.childExercise?.exerciseGoals.filter(
                (i) => i.active,
            )[0] as PhysicalExerciseGoalDto;
            this.trainingExercises.push(trainingExercise);
            index++;
        }
    }

    async setExerciseContent(contents: Content[], autoTrainingExercise: TrainingExercise) {
        autoTrainingExercise.contents = [];
        for (const content of contents) {
            const autoTrainingContent = new TrainingContent();
            autoTrainingContent.uuid = content.uuid;
            autoTrainingContent.name = content?.jsonData?.name;
            autoTrainingContent.order = content?.jsonData?.order;
            autoTrainingContent.description = content.description;
            autoTrainingContent.mimeType = content.mimeType;
            autoTrainingExercise.contents.push(autoTrainingContent);
        }
        autoTrainingExercise.contents.sort((a, b) => {
            if (a?.order < b?.order) {
                return -1;
            } else if (a?.order > b?.order) {
                return 1;
            }
            return 0;
        });
    }

    dismissModal() {
        this.modalController.dismiss();
    }

    ngOnInit(): void {
        this.initModal();
    }

    isButtonOutline(childExercise: ChildExercise) {
        return this.exercise.childExercises.findIndex((i) => i.childId === childExercise.childId) === this.exerciseIndex
            ? 'solid'
            : 'outline';
    }

    isButtonHidden(buttonIndex: number) {
        if (this.exerciseIndex <= 2) {
            return buttonIndex >= 4;
        } else if (this.exerciseIndex >= this.exercise.childExercises.length - 3) {
            return buttonIndex <= this.exercise.childExercises.length - 5;
        } else if (this.exercise.childExercises.length - 3 > this.exerciseIndex || this.exerciseIndex > 3) {
            return this.exerciseIndex - 1 > buttonIndex || this.exerciseIndex + 1 < buttonIndex;
        }
    }

    goToPreviousStep() {
        if (this.exerciseIndex > 0) this.exerciseIndex = this.exerciseIndex - 1;
    }

    goToNextStep() {
        if (this.exerciseIndex < this.exercise.childExercises.length) this.exerciseIndex = this.exerciseIndex + 1;
    }

    showContent() {
        this.areContentVisible = !this.areContentVisible;
    }
}
