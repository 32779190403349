import { NgModule } from '@angular/core';
import { TableComponentsModule } from './components/table-components.module';
import { DatePipe } from '@angular/common';

@NgModule({
    imports: [TableComponentsModule],
    providers: [DatePipe],
    exports: [TableComponentsModule],
})
export class TableModule {}
