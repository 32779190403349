// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.state-icon {
  font-size: 25px;
  padding-right: 5px;
}

.state-paragraph {
  align-items: center;
  display: flex;
}

p {
  margin-top: 0 !important;
}

.button-start-video-conference {
  height: 350px;
  width: 50%;
  padding-bottom: 20px;
  --border-radius: 10px;
  margin-left: 25%;
}`, "",{"version":3,"sources":["webpack://./../../libs/video-conference/components/join-conference/join-video-conference.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,kBAAA;AACJ;;AAEA;EACI,mBAAA;EACA,aAAA;AACJ;;AAEA;EACI,wBAAA;AACJ;;AAEA;EACI,aAAA;EACA,UAAA;EACA,oBAAA;EACA,qBAAA;EACA,gBAAA;AACJ","sourcesContent":[".state-icon {\n    font-size: 25px;\n    padding-right: 5px;\n}\n\n.state-paragraph {\n    align-items: center;\n    display: flex;\n}\n\np {\n    margin-top: 0 !important;\n}\n\n.button-start-video-conference {\n    height: 350px;\n    width: 50%;\n    padding-bottom: 20px;\n    --border-radius: 10px;\n    margin-left: 25%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
